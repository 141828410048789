import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import config from "src/config/config";
import { withAppContext } from 'src/store/initAppContext'
import styled, { withTheme } from 'styled-components'
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from 'src/components/Placeholder';
import RightMarkIcon from 'src/components/icon/RightMarkIcon';
import Footer from 'src/components/footer/footer';

const WorldCupEvolutionCasinoCup = (props) => {
    const {
        app: { brandId },
        theme,
    } = props;

    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const domain = brandId == 31 || brandId == 32 ? "Yolo247" : "BaaziAdda";

    return (
        <>
            <MainWrapper>
                <ContentWrapper>
                    <TextWrapper>
                        <p>This world cup, experience casino games like never before! In association with Evolution, we bring you a thrilling Live casino promotion!</p>
                        <p>Presenting the bonus of the season with our casino games! Get set & place your bets!</p>
                    </TextWrapper>
                    <YellowDivider></YellowDivider>
                    <HeadingWrapper>
                        <h1>Experience Excitement with Evolution!</h1>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.WorldCupEvlCasinoCupPrizeImg}`} effect="none" placeholder={<PlaceholderImage />} />
                    </HeadingWrapper>
                    <BoxWarpper>
                        <BoxContent>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.WorldCupEvlCasinoCupBoxIconOne}`} effect="none" placeholder={<PlaceholderImage />} />
                            <h1>REGISTER</h1>
                            <p>Open the door to endless thrills by creating your Yolo247 account.</p>
                        </BoxContent>
                        <BoxContentTwo>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.WorldCupEvlCasinoCupBoxIconTwo}`} effect="none" placeholder={<PlaceholderImage />} />
                            <h1>deposit & dive in</h1>
                            <p>Deposit in your account. Keep it loaded and ready for action throughout  T20 World Cup.</p>
                        </BoxContentTwo>
                        <BoxContentThree>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.WorldCupEvlCasinoCupBoxIconThree}`} effect="none" placeholder={<PlaceholderImage />} />
                            <h1>win big</h1>
                            <p>With each game you play, you're one step closer to achieving greatness. Showcase your skills, dominate the leader board, and claim your share of the incredible prize pool.</p>
                        </BoxContentThree>
                    </BoxWarpper>
                    <PrizeTextWrapper>
                        <p>Play Lightning Dragon Tiger, Dragon Tiger and Red door Roulette and win additional bonuses and free bets. Total prizes are worth <span className='yellow'>INR 18 lakhs!</span></p>
                        <p>The tournament is valid from <span className='yellow'>2nd June to</span> <span className='yellow'>29th June 2024!</span></p>
                        <p>This promotion is only applicable for Qualified games: please check the Promotion Schedule tables.</p>
                    </PrizeTextWrapper>
                    <PrizeWrpper>
                        <TextPrize>
                            <RightMarkIcon />
                            <p>Qualified game: Red Door Roulette.</p>
                        </TextPrize>
                        <TextPrize>
                            <RightMarkIcon />
                            <p>Players who win on any of the numbers: 0-01-02-03-22-23-24-31-32-33 will receive an additional prize of 100 INR.</p>
                        </TextPrize>
                        <TextPrize>
                            <RightMarkIcon />
                            <p>The minimum combined bet amount on numbers is 100 INR.</p>
                        </TextPrize>
                        <TextPrize>
                            <RightMarkIcon />
                            <p>Each player can win this promotional prize once a day.</p>
                        </TextPrize>
                        <TextPrize>
                            <RightMarkIcon />
                            <p>Evolution reserves the right to modify, suspend, or terminate the promotion at any time and without prior notice.</p>
                        </TextPrize>
                    </PrizeWrpper>
                    <Divider></Divider>
                    <GameWrapper>
                        <h1>Qualified game</h1>
                        <ImageWrapper>
                        <Link to="/online-casino-games/Ldragontiger" className='blockCasinoLink'>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.WorldCupEvlCasinoCupDragonTiger}`} effect="none" placeholder={<PlaceholderImage />} />
                        </Link>
                        </ImageWrapper>
                        <ul>
                            <li>Player will randomly get the bonus of INR 100.</li>
                            <li>There’s no minimum wager requirement.</li>
                            <li>Each player can win the Lucky Drop more than 5 times in a day.</li>
                            <li>Evolution reserves the right to modify, suspend, or terminate the promotion at any time and without prior notice.</li>
                        </ul>
                    </GameWrapper>
                    <Divider></Divider>
                    <TermsAndCondition>
                        <h4>Terms & Conditions</h4>
                        <ul>
                            <li>This promotion is valid for all players.</li>
                            <li>The promotion is valid from 2nd June to 29th June 2024. </li>
                            <li>The winners will be chosen based on highest bet amount.</li>
                            <li>No Minimum bet amount required to enter this contest.</li>
                            <li>No Minimum odds to be maintained.</li>
                            <li>Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.</li>
                            <li>Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. {domain} reserves the right to disqualify players if collusion or any foul play takes place in the promotion.</li>
                            <li>{domain} reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.</li>
                            <li>{domain} General Terms & Conditions apply.</li>
                        </ul>
                    </TermsAndCondition>
                </ContentWrapper>
            </MainWrapper>
            <Footer />
        </>
    )
}

export default withTheme(withAppContext(WorldCupEvolutionCasinoCup));

//Styled Components

const MainWrapper = styled.div`
    width: 98%;
    height: auto;
    border: 1px solid white;
    border-top: 0;
    display: flex;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em;
    -webkit-backdrop-filter: brightness(0.5);
    backdrop-filter: brightness(0.5);
`;

const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.WorldCupEvolutionCasinoCupBackground")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 2rem;

    .lazy-load-image-background {
      display: flex !important;
      justify-content: center !important;
    }

    .secondTitle {
        padding-top: 2rem;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 0 3rem 0;

    p {
        color: #FFF;
        text-align: center;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: 119.5%; /* 25.095px */
        margin-bottom: 0;
    }
`;

const PrizeTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 0 3rem 0;

    p {
        color: #FFF;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 148.5%; /* 20.79px */
    }

    .yellow {
        color: #FFE356;
    }
`;

const PrizeWrpper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 11.534px;
    border: 0.66px solid #C1DAFF;
    background: radial-gradient(circle at left, #112333 0%, #010406 50%);
    padding: 2.4rem 3rem;
    margin-bottom: 3rem;
`;

const TextPrize = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    p {
        padding: 0.6rem 0 0.6rem 1rem;
        margin: 0;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 148.5%; /* 20.79px */
    }
`;

const HeadingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    h1 {
        color: #FFDC40;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 41.09px; /* 171.209% */
    }

    img {
        width: 50%;
    }
`;

const BoxWarpper = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 2.5rem;
    padding: 2rem;
    width: 85%;
`;

const BoxContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: flex-start;
    border: 1px solid #E7AA1A;
    border-radius: 15px;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.WorldCupEvlCasinoCupBoxOne")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 2rem 0 3rem 0;
    width: 50%;

    h1 {
        color: #FFDC40;
        font-size: 20.869px;
        font-style: normal;
        font-weight: 700;
        line-height: 148.5%; /* 30.99px */
        text-transform: uppercase;
        margin-bottom: 0;
        padding: 1rem 2rem 0 2rem;
    }

    p {
        color: #FFF;
        text-align: justify;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 148.5%; /* 20.79px */
        padding: 0 2rem;
    }

    img {
        width: 50%;
    }
`

const BoxContentTwo = styled(BoxContent)`
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.WorldCupEvlCasinoCupBoxTwo")});
`;

const BoxContentThree = styled(BoxContent)`
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.WorldCupEvlCasinoCupBoxThree")});
`;

const GameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em 2rem 4rem 2rem;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.WorldCupEvlCasinoCupQualifierGameBg")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;

    h1 {
        color: #FFDC40;
        text-align: center;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 41.09px; /* 136.967% */
        text-transform: uppercase;
        padding-bottom: 2rem;
    }

    ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        list-style-type: "\❂";
        padding-top: 3rem;
        width: 65%;
    }

    & li::marker {
        color: #FFDC40;
    }

    li {
        color: #FFF;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: 179.5%;
        text-transform: capitalize;
        padding-left: 0.4rem;
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 50%;
    }
`;

const Divider = styled.div`
    height: 2px;
    width: 100%;
    background: radial-gradient(#FFFDF6 0%, #00000000 50%);
`;

const YellowDivider = styled(Divider)`
    background: radial-gradient(#B28C04 0%, #00000000 50%);
`;

const TermsAndCondition = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background: radial-gradient(38.26% 45.18% at 50% 0%, rgba(255, 249, 226, 0.12) 0%, rgba(2, 13, 2, 0.00) 100%);
    width: 65%;

    & li {
        color: #FFF;
        text-align: left;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 114.286% */
        text-transform: capitalize;
        list-style-type: decimal;
        padding-bottom: 1.5rem;
    }

    & h4 {
        color: #FFDC40;
        text-align: center;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 41.09px; /* 136.967% */
        text-transform: uppercase;
    }
`;