import React, { useEffect } from 'react'
import { MainInfoWrapper } from 'src/components/udb/commonStyle';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BannerWrapper } from './BblBonusInner';
import PlaceholderImage from 'src/components/Placeholder';
import cookieUtil from 'src/util/cookieUtil';
import { ECookieName } from 'src/util/utilModel';
import FooterContainer from "src/components/footer/footer";
import { useNavigate } from "react-router-dom";
import Intl from 'src/components/common/Intl';


const Language = cookieUtil.get(ECookieName.COOKIE_LANG)


const Arjith_promo = (props) => {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const navigate = useNavigate();


    return (
        <MainInfoWrapper1>
            <BannerWrapper1>

                <LazyLoadImage src={(Language === 'hindi-ind') ? `${config.imageV3Host}/static/cashsite/${theme.image.arjithHinBG}` : `${config.imageV3Host}/static/cashsite/${theme.image.arjithConcert}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
                <Btn onClick={() => navigate('/member/finance/deposit')}>
                    <Intl langKey="Deposit@promo" />
                </Btn>
            </BannerWrapper1>

            <InformationWrap>
                <TextWrap>
                    <h3> <Intl langKey="arjith@promohead" /></h3>
                    <p> <Intl langKey="arjith@promoheadP" />
                    </p>
                </TextWrap>
                <BackGroundWrapper>
                    <LazyLoadImage src={(Language === 'hindi-ind') ? `${config.imageV3Host}/static/cashsite/${theme.image.arjithpic}` : `${config.imageV3Host}/static/cashsite/${theme.image.arjithpic}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
                </BackGroundWrapper>
            </InformationWrap>
            <TableDiv>
                <LazyLoadImage src={(Language === 'hindi-ind') ? `${config.imageV3Host}/static/cashsite/${theme.image.tableHin}` : `${config.imageV3Host}/static/cashsite/${theme.image.tableprice}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
            </TableDiv>
            <ListDiv>
                <ul>
                    <li><Intl langKey="arjith@list1" /> </li>
                    <li><Intl langKey="arjith@list2" /><a>{brandId ===31 ?"social@yolo247.co":"social@yolo247.club"}</a><Intl langKey="arjith@extra" /></li>
                    <li><Intl langKey="arjith@list3" /></li>
                </ul>
            </ListDiv>
            <TermsWrap>
                <h3><Intl langKey="Aviator@GeneralTermsConditionTittle" /></h3>
                <ol>
                    <li><Intl langKey="arjith@term1" /></li>
                    <li><Intl langKey="arjith@term2" /></li>
                    <li> <Intl langKey="arjith@term3" /></li>
                    <li><Intl langKey="arjith@term4" /></li>
                    <li><Intl langKey="arjith@term5" /></li>
                </ol>
            </TermsWrap>
            <FooterContainer />
        </MainInfoWrapper1>
    )
}

export default withTheme(withAppContext(Arjith_promo));


const Btn = styled.button`
position: absolute;
bottom: -4px;
left: 115px;
padding: 20px;
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 22px;
letter-spacing: 1px;
color: #FFFFFF;
background: #0EAD69;
border-radius: 10px;
border: none;
cursor: pointer;
/* @media only screen and (max-width: 1200px) {
    bottom: 0px;
} */
`
const TermsWrap = styled.div`
width: 100%;
height: 709px;
background-position-x: right;
background-repeat-x: no-repeat;
background-size: contain;
background-position: right;
background-repeat: no-repeat;
background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.arjithsec')});
padding: 5rem;
h3{
    color: #FFD085;
    
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 32px;
line-height: 70px;
letter-spacing: 0.83px;
}

ol{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 70px;
color: #FAFAF9
}
`
const ListDiv = styled.div`
ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 7rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.3px;
    color: #FFFFFF;
}

a {
    color: #FFD085;
}
`

const TextWrap = styled.div`
width: 50%;
padding: 0rem 8rem 2rem 7rem;
h3{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 40px;
line-height: 50px;
letter-spacing: 0.3px;

color: #FFD085;
}

p{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 28px;
letter-spacing: 0.3px;
color: rgba(239, 237, 232, 0.65);
}

`

const InformationWrap = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
`


const BackGroundWrapper = styled.div`
  width: 50%;
  padding: 0rem 6rem 0rem 4rem;

`;


export const MainInfoWrapper1 = styled(MainInfoWrapper)`
background: url(${config.imageV3Host}/static/cashsite/${theme('image.arjithsingh_BG')});

`

const TableDiv = styled.div`
width: 100%;
padding:5rem 5rem 0rem 5.5rem;
`


const BannerWrapper1 = styled(BannerWrapper)`

& img {
    padding: 2.5rem 8rem 1rem 5rem;

}
`
