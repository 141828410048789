import React from 'react'
import styled from 'styled-components';
import config from 'src/config/config';
import { theme } from 'styled-tools';

type customRadioButtonProps = {
  name: string;
  label: string | React.ReactNode;
  value: string;
  id?: string

  onChange: (e: React.ChangeEvent<HTMLInputElement>, value?: string) => void;
} & Omit<React.ComponentProps<'input'>, 'name' | 'onChange' | 'value'>

const CustomRadioButton = ({ name, label, value, id='', onChange, ...props }: customRadioButtonProps) => {
  return (
    <Container>
      <input type="radio" name={name} value={value}  onChange={(e) => onChange(e, value)} {...props} />
      <span id={id} className="checkmark">{label}</span>

    </Container>
  )
}

export default CustomRadioButton;

export const Container = styled.label`
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-left: 0px;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  margin-right: 1em;

input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkmark {
  // position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  // top: 0;
  // left: 0;
  height: 45px;
  width: 100%;
  background: #ffffff;
  border: ${theme("palette.channelSelectedBorder")};
  /* box-shadow: 0px 0px 40px rgba(16, 11, 73, 0.4); */
  border-radius: 3px;
}
&:hover input:not([disabled]) ~ .checkmark {
  background: ${theme('palette.secondary-background-color-linear-new1')};
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(16, 11, 73, 0.4);
  color: #fff;
}
input:checked ~ .checkmark {
  background: ${theme('palette.secondary-background-color-linear-new1')};
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(16, 11, 73, 0.4);
  color: #fff;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

input:checked ~ .checkmark:before {
  background: #e42a09;
}
`