import React, { useEffect, useState } from "react";
import Pagination from "src/components/common/Pagination";
import CalenderIcon from "src/components/icon/CalenderIcon";
import TurnoverStatementTable from "src/components/member/TurnoverStatementTable";
import { Notify } from "src/components/Notification";
import {
  FirstCol,
  Header,
  MainInfoWrapper,
  SecondCol,
  SvgIcon,
  TableFooter,
  // TableWrapper,
} from "src/components/udb/commonStyle";
// import { Col } from '../../../components/common/table/baseTableStyles';
import DatePicker from "react-datepicker";
import { BlockPick, DatePickerWrapper } from "src/components/udb/DatePickerUdb";
import UDBHeader from "src/components/UDBHeader";
import styled, { css, withTheme } from 'styled-components';
import { ifProp, theme } from "styled-tools";
import Intl from "../../../components/common/Intl";
import { getTurnoverStatement } from "../../../config/api";
import { withAppContext } from "src/store/initAppContext";
// import media from "src/util/mediaUtil";
import TurnoverExhangeTable, { exchangeTurnoverDatas } from "src/components/member/TurnoverExchangeTable";
import SEOContents from "src/components/SEOContents";

type StyleProps = {
  type?: string;
  isActive?: boolean;
  status?: string;
  isOpened?: boolean;
  displayState?: boolean;
};

type turnoverDatas = {
  BetAmount: string,
  TurnOver: string,
  UserName: string,
  WinLossAmount: string,
  eventId: string,
  game: string,
  platform: string,
  provider: string,
  settleDate: string,
  settleTime: string
}

type turnoverDataArrayProps = {
  data: turnoverDatas[];
  total_turnover: string;
}

type filteredExchangeDataProps = {
  exchange: exchangeTurnoverDatas[];
  exchange_turnover: string
}

type ParamsProp = {
  type?: string;
  start_date?: string;
  end_date?: string;
  page?: number,
  limit?: number,
}

const changeUpperCase = (str) => `${str.charAt(0).toUpperCase()}${str.substr(1)}`;

const TurnoverStatement = (props:any) => {
  const { app: {
    brandId
  }, theme } = props;
  const date = new Date();
  const [startAt, setStartAt] = useState(new Date(new Date().setHours(0, 0, 0)));
  const [endAt, setEndAt] = useState(new Date(date.setHours(23, 59, 59))); //Date
  const minDate = date.setDate(date.getDate() - 90);

  const [page, setPage] = useState(1); //pagination
  const [count, setCount] = useState(0); //total number of records in DB of a particular type
  const limit = 15;//for record

  const [errorMessage, setErrorMessage] = useState("")
  const [filterType, setFilterType] = useState("Exchange");//4 table data
  const [totalTurnoverAmount, setTotalTurnoverAmount] = useState<number>(0);
  const [turnoverDataArray, setTurnoverDataArray] = useState({} as turnoverDataArrayProps);
  const [filteredExchangeData, setFilteredExchangeData] = useState({} as filteredExchangeDataProps);
  const [filterTypes, setFilterTypes] = useState([]);// for left filter

  useEffect(() => {
    window.scrollTo(0, 0);
    getTurnoverData({ type: 'all' });
    getTurnoverData({ type: filterType });
  }, [startAt, endAt]);

  const handlefromDateChange = (startAt: Date | null) => {
    setStartAt(startAt);
  };

  const handletoDateChange = (endAt: Date | null) => {
    setEndAt(endAt);
  };

  const handleChangePageIndex = (index: number) => {
    setPage(index);
    getTurnoverData({ page: index })
  };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  const getTurnoverType = (data) => {
    let newData = data.reduce((prev, curr) => {
      let currObjKey = Object.keys(curr);
      let obj = {};
      obj['name'] = changeUpperCase(currObjKey[0]);
      obj['turnover'] = curr[currObjKey[1]];
      return [...prev, obj]
    }, []);
    return newData;
  }

  const getTurnoverData = (param: ParamsProp) => {
    let params = {
      type: param.type || filterType,
      start_date: param.start_date || startAt.toISOString(),
      end_date: param.end_date || endAt.toISOString(),
      page: param.page || page,
      limit: param.limit || limit
    };


    let startD: any = new Date(params['start_date']);
    let endD: any = new Date(params['end_date']);
    let diff: any = Math.round((endD - startD) / (1000 * 60 * 60 * 24));

    if (diff < 1) {
      Notify.error('Start date must be greater than or equal to end date');
      return;
    }
    else if (diff > 31) {
      Notify.error('Date difference should not exceed 31 days');
      return;
    }

    getTurnoverStatement(params)
      .then((response) => {
        const { data, total_turnover, count } = response.data;
        if (data) {
          setCount(count);
          if (params.type === 'all') {
            let statementFilers = getTurnoverType(data);
            setFilterTypes(statementFilers)
            setTotalTurnoverAmount(total_turnover.toFixed(2));
          }
          else if (params.type === "Exchange") {
            setFilteredExchangeData(data[0])
          }
          else {
            setTurnoverDataArray(data)
          }
        }

        if (response.data.success === false) {
          Notify.error(response.data.data.status);
        }
      })
      .catch((err) => Notify.error(err.response.data.error));
  };

  const filterDataWithType = (names) => {
    getTurnoverData({ type: names })
    setFilterType(names);
  };

  return (
    <MainInfoWrapper>
       <SEOContents pageName="memberTurnoverStatement" />
      <Header>
        <FirstCol>
          <UDBHeader title="SIDEBAR@TurnoverStatement" />
        </FirstCol>
        <SecondCol>
          <DatePickerWrapper>
            <SvgIcon>
              <CalenderIcon />
            </SvgIcon>
            <BlockPick>
              <DatePicker
                selected={startAt}
                onChange={handlefromDateChange}
                onChangeRaw={handleDateChangeRaw}
                minDate={minDate}
                maxDate={new Date()}
                placeholderText="From"
              />
            </BlockPick>
            <BlockPick>
              <DatePicker
                selected={endAt}
                onChange={handletoDateChange}
                onChangeRaw={handleDateChangeRaw}
                minDate={startAt}
                maxDate={new Date()}
                placeholderText="To"
              />
            </BlockPick>
          </DatePickerWrapper>
        </SecondCol>
      </Header>
      <ContainerWrapper>
        <Container>
          <FilterWrapper>
            <FilterHead>
              <Intl langKey="TUNOVERStatement@TurnoverDetails" />
            </FilterHead>
            <FilterInnerWrapper>
                <FilterButton type="all">
                  <TypeName type="all">Total</TypeName>
                  <AmountSec type="all">{totalTurnoverAmount}</AmountSec>
                </FilterButton>
                {filterTypes.map((types, index) => (
                  <FilterButton
                    isActive={types.name === filterType}
                    onClick={(e) =>
                      filterDataWithType(types.name)
                    }
                    key={types.name}
                  >
                    <TypeName>{types.name}</TypeName>
                    <AmountSec>{types.turnover}</AmountSec>
                  </FilterButton>
                ))}
             </FilterInnerWrapper>
          </FilterWrapper>

          {filterType === "Exchange" ?
            <TurnoverExhangeTable filteredExchangeData={filteredExchangeData} />
            :
            <TurnoverStatementTable turnoverDataArray={turnoverDataArray} />
          }
        </Container>
        <TurnoverTableFooter>
          <FooterLeftSec>1 OF 150 TRANSACTIONS</FooterLeftSec>
          <Pagination
            numberPerPage={15}
            totalLength={count}
            onClick={handleChangePageIndex}
            currentIndex={page}
          />
        </TurnoverTableFooter>
      </ContainerWrapper>
    </MainInfoWrapper>
  );
};

export default withTheme(withAppContext(TurnoverStatement))

const ContainerWrapper = styled.div`
  width: 100%;
  background: ${theme('palette.betDetailsTableBg')};
  border-radius: 5px;
  min-height: 70vh;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 5px;
`;
const FilterWrapper = styled.div`
  width: 24%;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0px 15px 5px 10px;
`;
const TypeName = styled.h6<Pick<StyleProps, "type">>`
  font-size: 13px;
  margin: 3px 0px;
  font-weight: 500;
`;
const AmountSec = styled.div<Pick<StyleProps, "type">>`
  font-size: ${(props) => (props.type === "all" ? "15px" : "13px")};
  margin-top: 0.25em;
  color: ${(props) => (props.type === "all" ? "#00FF92" : "#000000")};
  font-weight: 600;
`;
const FilterHead = styled.div`
  background: ${theme('palette.betDetailsFilterHeadBg')};
  box-shadow: 0px 0px 5px rgba(19, 14, 74, 0.4);
  border-radius: 5px;
  font-weight: 700;
  font-size: 15px;
  line-height: 11px;
  text-transform: capitalize;
  color: #272626;
  text-align: center;
  padding: 15px;
  margin: 10px 0px;
  line-height: 15px;
`;
const FilterButton = styled.div<Pick<StyleProps, "isActive" | "type">>`
  padding: 15px 7px;
  background-color: ${(props) =>
  props.type === "all" ? "#141415" : "transparent"};
  border: 0;
  position: relative;
  z-index: 0;
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  border-radius: 4px;
  color: ${(props) => (props.type === "all" ? "#ffffff" : "#44556F")};

  &::before {
    content: "";
    display: block;
    position: absolute;
    right: -4px;
    left: -4px;
    bottom: -4px;
    top: -4px;
    border-radius: 2px;
    z-index: -1;
  }

  ${ifProp(
      "isActive",
      css`
      ${TypeName} {
        font-weight: 600;
        color: ${theme('palette.betDetailsTableTextColorActive')};
      }
      :after {
        content: "";
        display: block;
        position: absolute;
        right: -13px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid ${theme('palette.betDetailsTableTextColorActive')};
        transform: rotate(270deg);
      }
      background-color: ${theme('palette.betDetailsFilterTab')};

      ${AmountSec}{
         color: ${theme('palette.betDetailsTableTextColorActive')};
      }

    `,
      css``
    )}
`;

const TurnoverTableFooter = styled(TableFooter)`
  padding: 10px 5px;
  width: 97%;
  border-top: 1px dashed ${theme('palette.marqueBg')};
  margin: 0px auto;
`;

const FooterLeftSec = styled.h5`
  font-size: 12px;
  font-weight: 600;
  color: ${theme('palette.marqueBg')};
  margin: 3px 0px;
`;
// Styled components
export const MarketColContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
  color: ${theme('palette.betDetailsTableTextColor')};
  font-size: 12px;

	> div {
		justify-content: flex-start;
    width: 170px;
		font-size: 12px;
		text-align: left;
		font-weight: 600;
	}
`;
export const TableSubheadRow = styled.div<Pick<StyleProps, "isOpened">>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    justify-content: center;
    color: ${theme('palette.betDetailsTableSubHead')};
    margin: 0px 0px 5px 0px;
    font-size: 13px;
    border-radius: 3px;

    ${ifProp(
  /* Default is circle-up-chevron */
  'isOpened',
  css`
			background-color: ${theme('palette.betDetailsTableSubHeadActive')};
		`,
  css`
		 	background: ${theme('palette.betDetailsTableTextColorActive')};
		`,
)};
`;
export const TableSubheadTexts2 = styled.span`
   font-size: 11px;
   color: ${theme('palette.betDetailsTableSubHeadInner')};
`

const FilterInnerWrapper = styled.div`
  margin-top: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: ${theme('palette.betDetailsTableInnerBg')};
  border-radius: 10px;
`