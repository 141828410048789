import React from "react";

type Props = {};

const SupoortIcon = ({ }: Props) => {
  return (
    <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-outside-1_3753_56" maskUnits="userSpaceOnUse" x="0.365234" y="0.95459" width="15" height="15" fill="black">
        <rect fill="white" x="0.365234" y="0.95459" width="15" height="15" />
        <path d="M7.40145 14.027C10.7352 14.027 13.4377 11.3245 13.4377 7.99081C13.4377 4.6571 10.7352 1.95459 7.40145 1.95459C4.06774 1.95459 1.36523 4.6571 1.36523 7.99081C1.36523 9.05003 1.63806 10.0455 2.11727 10.9109L1.81113 13.6388L4.5613 13.3185C5.4078 13.7707 6.37469 14.027 7.40145 14.027Z" />
      </mask>
      <path d="M4.5613 13.3185L5.03249 12.4364L4.75646 12.289L4.4456 12.3252L4.5613 13.3185ZM2.11727 10.9109L3.11103 11.0224L3.1466 10.7054L2.99209 10.4264L2.11727 10.9109ZM1.81113 13.6388L0.817372 13.5273C0.783368 13.8303 0.889461 14.1323 1.10552 14.3474C1.32157 14.5625 1.62398 14.6674 1.92683 14.6321L1.81113 13.6388ZM12.4377 7.99081C12.4377 10.7722 10.1829 13.027 7.40145 13.027V15.027C11.2874 15.027 14.4377 11.8768 14.4377 7.99081H12.4377ZM2.36523 7.99081C2.36523 5.20938 4.62003 2.95459 7.40145 2.95459V0.95459C3.51546 0.95459 0.365234 4.10481 0.365234 7.99081H2.36523ZM7.40145 2.95459C10.1829 2.95459 12.4377 5.20938 12.4377 7.99081H14.4377C14.4377 4.10481 11.2874 0.95459 7.40145 0.95459V2.95459ZM7.40145 13.027C6.54304 13.027 5.73756 12.8131 5.03249 12.4364L4.09011 14.2005C5.07804 14.7282 6.20634 15.027 7.40145 15.027V13.027ZM2.99209 10.4264C2.59288 9.70555 2.36523 8.87627 2.36523 7.99081H0.365234C0.365234 9.22379 0.683235 10.3855 1.24246 11.3953L2.99209 10.4264ZM1.12351 10.7993L0.817372 13.5273L2.8049 13.7503L3.11103 11.0224L1.12351 10.7993ZM1.92683 14.6321L4.677 14.3117L4.4456 12.3252L1.69543 12.6455L1.92683 14.6321Z" fill="white" mask="url(#path-1-outside-1_3753_56)" />
      <path d="M16.4564 17.2415L16.2208 16.8005L16.3588 16.7267L16.5142 16.7449L16.4564 17.2415ZM19.0781 14.6589L18.5812 14.7146L18.5634 14.5562L18.6407 14.4167L19.0781 14.6589ZM19.4065 17.5851L19.9033 17.5294C19.9203 17.6809 19.8673 17.8319 19.7593 17.9394C19.6512 18.047 19.5 18.0994 19.3486 18.0818L19.4065 17.5851ZM19.3848 11.5265C19.3848 8.22663 16.7097 5.55151 13.4097 5.55151V4.55151C17.2619 4.55151 20.3848 7.67434 20.3848 11.5265H19.3848ZM13.4097 17.5016C14.427 17.5016 15.3834 17.2478 16.2208 16.8005L16.692 17.6825C15.7132 18.2054 14.5953 18.5016 13.4097 18.5016V17.5016ZM18.6407 14.4167C19.1147 13.5606 19.3848 12.5759 19.3848 11.5265H20.3848C20.3848 12.7496 20.0695 13.9006 19.5155 14.9011L18.6407 14.4167ZM19.5749 14.6031L19.9033 17.5294L18.9096 17.6409L18.5812 14.7146L19.5749 14.6031ZM19.3486 18.0818L16.3985 17.7381L16.5142 16.7449L19.4643 17.0885L19.3486 18.0818ZM8.20148 14.4572C9.22705 16.2756 11.1755 17.5016 13.4097 17.5016V18.5016C10.8002 18.5016 8.52609 17.0683 7.33047 14.9485L8.20148 14.4572Z" fill="white" />
      <path d="M3.93359 9.81665C4.9365 9.81665 9.86687 9.81665 10.8698 9.81665" stroke="white" stroke-linecap="round" />
      <path d="M3.93359 7.94824C4.9365 7.94824 9.86687 7.94824 10.8698 7.94824" stroke="white" stroke-linecap="round" />
      <path d="M3.93359 6.16504C4.9365 6.16504 9.86687 6.16504 10.8698 6.16504" stroke="white" stroke-linecap="round" />
      <path d="M15.3003 12.7346C15.2523 12.7346 15.2103 12.7526 15.1743 12.7886C15.1443 12.8246 15.1293 12.8666 15.1293 12.9146V13.4096H14.0763V12.8966C14.0763 12.1046 14.4483 11.7086 15.1923 11.7086H15.2373C15.2853 11.7086 15.3243 11.6906 15.3543 11.6546C15.3903 11.6186 15.4083 11.5766 15.4083 11.5286V10.4576C15.4083 10.4096 15.3903 10.3676 15.3543 10.3316C15.3243 10.2956 15.2853 10.2776 15.2373 10.2776H14.5533C14.5053 10.2776 14.4633 10.2956 14.4273 10.3316C14.3973 10.3676 14.3823 10.4096 14.3823 10.4576V10.9166H13.2663V10.4846C13.2663 9.69263 13.6383 9.29663 14.3823 9.29663H15.3813C16.1253 9.29663 16.4973 9.69263 16.4973 10.4846V11.5466C16.4973 12.3386 16.1253 12.7346 15.3813 12.7346H15.3003ZM13.9773 14.5616C13.9773 14.3816 14.0403 14.2286 14.1663 14.1026C14.2923 13.9766 14.4453 13.9136 14.6253 13.9136C14.8053 13.9136 14.9583 13.9766 15.0843 14.1026C15.2103 14.2286 15.2733 14.3816 15.2733 14.5616C15.2733 14.7416 15.2103 14.8946 15.0843 15.0206C14.9583 15.1466 14.8053 15.2096 14.6253 15.2096C14.4453 15.2096 14.2923 15.1466 14.1663 15.0206C14.0403 14.8946 13.9773 14.7416 13.9773 14.5616Z" fill="white" />
    </svg>
  );
};
export default SupoortIcon;
