import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, switchProp, theme, withProp } from 'styled-tools';

// Utils
import { darken, lighten } from 'polished';
import util from '../../util/util';

interface AmountProp {
	// 顯示小數點後幾位
	fractionDigits?: number;
	// 進位方式
	roundMethod?: 'ceil' | 'floor' | 'round';
	noPositiveSign?: boolean;
	convertToUnit?: boolean;
	diffColor?: string;
}

let amoutTriggerTime: number;
export class Amount extends React.PureComponent<AmountProp> {
	render() {
		const { children, fractionDigits = 2, roundMethod = 'round', convertToUnit } = this.props;
		const now = Date.now();
		let highVolume = false
		let num: number = parseFloat(children as string);
		if (isNaN(num)) {
			now - amoutTriggerTime > 3000 &&
				console.warn('[Amount]: children prop is expected to be a number or number in string.', num);
			amoutTriggerTime = now;
			num = 0;
		} else {
			num = util.numRound(num, { fractionDigits, roundMethod });
		}

		if (num > 500000) {
			highVolume = true
		}

		const amount = !!convertToUnit
			? util.convertToUnit(num, fractionDigits)
			: num.toLocaleString('en', { minimumFractionDigits: fractionDigits });

		// return <>{highVolume ? '500,000+' : amount}</>;
		return <>{amount}</>;

	}
}

let incomeTriggerTime: number;
export const Income: React.SFC<AmountProp> = props => {
	const { children, noPositiveSign = false, diffColor } = props;

	let num: number = parseFloat(children as string);
	if (isNaN(num)) {
		Date.now() - incomeTriggerTime > 3000 &&
			console.warn('[Income]: children prop is expected to be a number or number in string.', num);
		incomeTriggerTime = Date.now();
		num = 0;
	}

	const prefix = num > 0 && !noPositiveSign ? '+' : '';
	const sign = num > 0 ? 'positive' : num < 0 ? 'negative' : '';
	return (
		<IncomeWrapper sign={sign} diffColor={diffColor}>
			{prefix}
			<Amount {...props} />
		</IncomeWrapper>
	);
};

// Styled Component
export const IncomeWrapper: any = styled.span`
	color: ${switchProp('sign', {
	positive: '#05CC61',
	negative: "#fd6c8d",
})};

	${switchProp('diffColor', {
	LIGHTER: css`
			color: ${switchProp('sign', {
		positive: 'green',
		negative: 'red',
	})};
		`,
	DARKER: css`
			color: ${switchProp('sign', {
		positive: 'green',
		negative: 'red',
	})};
		`,
})}
`;

