import { useEffect } from "react"
import PlaceholderImage from 'src/components/Placeholder';
import { MainInfoWrapper } from 'src/components/udb/commonStyle';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import cookieUtil from 'src/util/cookieUtil';
import { ECookieName } from 'src/util/utilModel';
import Intl from 'src/components/common/Intl';
import FooterContainer from "src/components/footer/footer";
import { useNavigate } from "react-router-dom";


function crazyCrashPromo(props) {
  const { app: {
    brandId
  }, theme } = props;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = useNavigate()

  const Language = cookieUtil.get(ECookieName.COOKIE_LANG)

  const redirectToGame = () => {
    navigate("/online-casino-games/PopularGames")
  }

  return (
    <MainInfoWrapper>
      <BannerWrapper>
        <LazyLoadImage src={(Language === 'hindi-ind') ? `${config.imageV3Host}/static/cashsite/${theme.image.crazyCrashInnerMainBanner}` : `${config.imageV3Host}/static/cashsite/${theme.image.crazyCrashInnerMainBanner}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
        <PlayNowButtonNew onClick={redirectToGame}>Play Now</PlayNowButtonNew>
      </BannerWrapper>
      <FifaFeverContentWrapper>

        <CrazyCrashTopSec>
          <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.crazyCrashInnerTextPara}`} effect="none" width="75%" placeholder={<PlaceholderImage />} alt="crazyCrash" />
          <CrazyCrashGameTagsWrap>
            <CrayCrashTagWrap>
              <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.GameThumb1}`} effect="none" width="100%" placeholder={<PlaceholderImage />} alt="Game 1" />
              <CrazyCrashPlayNowBtn onClick={() => navigate("/online-casino-games/PopularGames/jetx")}>Play Now</CrazyCrashPlayNowBtn>
            </CrayCrashTagWrap>
            <CrayCrashTagWrap>
              <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.GameThumb2}`} effect="none" width="100%" placeholder={<PlaceholderImage />} alt="Game 2" />
              <CrazyCrashPlayNowBtn onClick={() => navigate("/online-casino-games/PopularGames/CRICKETX")}>Play Now</CrazyCrashPlayNowBtn>
            </CrayCrashTagWrap>
            <CrayCrashTagWrap>
              <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.GameThumb3}`} effect="none" width="100%" placeholder={<PlaceholderImage />} alt="Game 3" />
              <CrazyCrashPlayNowBtn onClick={() => navigate("/online-casino-games/PopularGames/BALLOON")}>Play Now</CrazyCrashPlayNowBtn>
            </CrayCrashTagWrap>
            <CrayCrashTagWrap>
              <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.GameThumb4}`} effect="none" width="100%" placeholder={<PlaceholderImage />} alt="Game 4" />
              <CrazyCrashPlayNowBtn onClick={() => navigate("/online-casino-games/PopularGames/FOOTBALLX")}>Play Now</CrazyCrashPlayNowBtn>
            </CrayCrashTagWrap>
            <CrayCrashTagWrap>
              <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.GameThumb5}`} effect="none" width="100%" placeholder={<PlaceholderImage />} alt="Game 5" />
              <CrazyCrashPlayNowBtn onClick={() => navigate("/online-casino-games/PopularGames/PLINKO")}>Play Now</CrazyCrashPlayNowBtn>
            </CrayCrashTagWrap>
            <CrayCrashTagWrap>
              <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.GameThumb7}`} effect="none" width="100%" placeholder={<PlaceholderImage />} alt="Game 7" />
              <CrazyCrashPlayNowBtn onClick={() => navigate("/online-casino-games/PopularGames/CAPPADOCIA")}>Play Now</CrazyCrashPlayNowBtn>
            </CrayCrashTagWrap>
          </CrazyCrashGameTagsWrap>
        </CrazyCrashTopSec>

        <FifaFeverFirstSec>
          <FifaFeverFirstSecHead className="enterPromo"><Intl langKey="PROMOTIONS@crazyCrashPromoFIRSTHEAD" /></FifaFeverFirstSecHead>
          <Section>
            <ImageWrapper>
              <RocketImageWrap>
                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.crazyCrashRedJet}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
              </RocketImageWrap>
            </ImageWrapper>
            <ProcessColumn>
              <li>
                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.CrazyCrashRegisterIcon}`} effect="none" placeholder={<PlaceholderImage />} />
                <LiTextContent>
                  <StepTittle><Intl langKey="FifaFever@Step1" /></StepTittle>
                  <TextContent><Intl langKey="PROMOTIONS@FIFAINNERFIRSTStep1" /></TextContent>
                </LiTextContent>
                <StepImg src={`${config.imageV3Host}/static/cashsite/${theme.image.crazyCrashStepOne}`}></StepImg>
              </li>
              <li>
                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.CrazyCrashDepositIcon}`} effect="none" placeholder={<PlaceholderImage />} />
                <LiTextContent>
                  <StepTittle><Intl langKey="FifaFever@Step2" /></StepTittle>
                </LiTextContent>
                <StepImg src={`${config.imageV3Host}/static/cashsite/${theme.image.crazyCrashStepTwo}`}></StepImg>
              </li>
            </ProcessColumn>
          </Section>

          <OffersSection>
            <ItalicTextFull className='marginZero readMoreOffer'><Intl langKey="PROMOTIONS@FIFAINNERSECONDHEAD" /></ItalicTextFull>
          </OffersSection>

          <CrazyCrashOffersWrap>
            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.crazyCrashOffer1}`} effect="none" width="45%" placeholder={<PlaceholderImage />} />
            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.crazyCrashOffer2}`} effect="none" width="45%" placeholder={<PlaceholderImage />} />
            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.crazyCrashOffer3}`} effect="none" width="45%" placeholder={<PlaceholderImage />} />
            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.crazyCrashOffer4}`} effect="none" width="45%" placeholder={<PlaceholderImage />} />
            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.crazyCrashOffer5}`} effect="none" width="45%" placeholder={<PlaceholderImage />} />
          </CrazyCrashOffersWrap>
        </FifaFeverFirstSec>

        <FifaFeverFirstSecHead className="priceAlloc"><Intl langKey="PROMOTIONS@crazyCrashPromoPrizeHEAD" /></FifaFeverFirstSecHead>
        <PriceTableWrapper>
          <PriceTable>
            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.crazyCrashPriceDetailsTable}`} effect="none" placeholder={<PlaceholderImage />} />
          </PriceTable>
        </PriceTableWrapper>



        <TermsConditionWrapper>
          <TermsConditionPoints>
            <FifaFeverFirstSecHead className='terms'><Intl langKey="Aviator@GeneralTermsConditionTittle" /></FifaFeverFirstSecHead>
            <ul>
              <li><TextContent><Intl langKey="crazyCrash@GeneralTermsConditionFirst" /></TextContent></li>
              <li><TextContent><Intl langKey="crazyCrash@GeneralTermsConditionSecond" /> <StepTittle><Intl langKey="crazyCrash@GeneralTermsConditionSecondValidFrom" /></StepTittle> <Intl langKey="crazyCrash@GeneralTermsConditionSecondWinnersWillBe" /> <StepTittle><Intl langKey="crazyCrash@GeneralTermsConditionSecondValidOn" /></StepTittle> </TextContent></li>
              <li><TextContent><Intl langKey="crazyCrash@GeneralTermsConditionThird" /></TextContent></li>
              <li><TextContent><Intl langKey="crazyCrash@GeneralTermsConditionFourth" /></TextContent></li>
              <li><TextContent><Intl langKey="crazyCrash@GeneralTermsConditionFifth" /></TextContent></li>
              <li><TextContent><Intl langKey="crazyCrash@GeneralTermsConditionSixth" /></TextContent></li>
              <li><TextContent><Intl langKey="crazyCrash@GeneralTermsConditionSeventh" /></TextContent></li>
              <li><TextContent><Intl langKey="crazyCrash@GeneralTermsConditionEighth" /></TextContent></li>
            </ul>
            <PlayNowButtonNew2 onClick={redirectToGame}>Play Now</PlayNowButtonNew2>
          </TermsConditionPoints>
        </TermsConditionWrapper>


      </FifaFeverContentWrapper>
      <FooterContainer />
    </MainInfoWrapper>
  )
}

export default withTheme(withAppContext(crazyCrashPromo));

//Styled Components
export const OffersSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const PriceTableWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    width: 115px;
    height: 225px;
    display: flex;
    position: absolute;
    left: 0px;
    top: 0px;
    background: url(${config.imageV3Host}/static/cashsite/${theme('image.crazyCrashFIreRocketLeft')});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  &::after {
    content: "";
    width: 115px;
    height: 225px;
    display: flex;
    position: absolute;
    right: 0px;
    bottom: 0px;
    background: url(${config.imageV3Host}/static/cashsite/${theme('image.crazyCrashPlanetRight')});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

`;

const PriceTable = styled.div`  
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  text-align: center;


  img {
    max-width: 100%;
  }
`;

export const ItalicTextFull = styled.p`  
  width: 100%;
  color: ${theme('palette.fifafeverTextWhiteColor')};
  font-style: italic;
  font-size: 19px;
  text-align: center;
  display: flex;
  align-items: center;

  
  &.readMoreOffer{
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.crazyCrashOffersHead')});
        width: 320px;
        height: 50px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
  }
`;

const StepImg = styled.img`
  width: 92px;
  height: 87px;
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

const ProcessColumn = styled.ul`
    width: 40%;
    padding: 2rem;
    position: relative;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    li {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 1rem;
        padding: 1.5rem;
        border-radius: 8.09632px;
        background: ${theme('palette.FifaFeverProcessColumnLiBg')};
        border: ${theme('palette.FifaFeverProcessColumnLiBorder')};
        backdrop-filter: blur(40.3956px);
        position: relative;
    }

    img {
        width: 58px;
        height: 58px;
    }
`;

const LiTextContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    p {
        margin: 0;
        margin-top: 10px;
    }

    span {
        width: 82%;
        text-transform: uppercase;
        font-size: 18px;
    }
`;

const ImageWrapper = styled.div`
    width: 40%;
    display: flex;
    padding-top: 47px;

    img {
        max-width: 100%;
    }
`;

export const Section = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem 4rem;
  position: relative;
  margin: 0rem 0rem 3rem 0rem;


  .imgWrap{
    padding: 0px;
  }

  .row-reverse{
    width: 40%;
    gap: 0px;
    padding: 0px;
    li{
        border: none;
        display: flex;
        align-items: center;
        gap: 0px;
        padding:0px;
        flex-direction: row;
        background: none;
    }
    .textC{
      text-align: right;
    }
  }
 

  &::after {
      content: "";
      width: 73px;
      height: 165px;
      right: 0px;
      bottom: 0px;
      position: absolute;
      /* background: url(${config.imageV3Host}/static/cashsite/${theme('image.crazyCrashOrangeRIght')}); */
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
`;

const FifaFeverContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(${config.imageV3Host}/static/cashsite/${theme('image.crazyCrashInnerBg')});
  background-position: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  color: ${theme('palette.fifafeverTextWhiteColor')};
  padding-top: 4rem;
  position: relative;

  .marginZero {
    margin-top: 0;
  }
`;
const BannerWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: -5px;
  transition: 700ms;
  position: relative;

  img {
      max-width: 100%;
      transition: 700ms;
  }
`;

const FifaFeverFirstSec = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

 
`;

export const FifaFeverFirstSecHead = styled.h2`
    width: 252px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 30px;
    line-height: 40px;
    text-transform: capitalize;
    text-align: center;
    font-family: 'Inter-Bold';

     &.enterPromo{
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.crazyCrashEnterHead')});
        height: 100px;
        width: 270px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-size: 27px;
     }
     &.aviator{
        color:#E50539;
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.aviatorEnterHead')});
        height: 100px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-size: 27px;
     }
     &.frstbet{
        color:rgba(239, 65, 35, 1);
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.crazyCrashEnterHead')});
        height: 100px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-size: 27px;
        p{
          font-size: 27px;
  background: -webkit-linear-gradient(rgba(255, 197, 27, 1), rgba(254, 255, 126, 1), rgba(255, 255, 130, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
        }
     }

     &.platinum{
        color:#FFC51B;
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.platinumh1')});
        height: 100px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-size: 27px;
        p{
          font-size: 27px;
  background: -webkit-linear-gradient(rgba(255, 197, 27, 1), rgba(254, 255, 126, 1), rgba(255, 255, 130, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
        }
     }

     &.platinum1{
      color:#FFC51B;
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.platinumh2')});
        height: 100px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-size: 27px;
        p{
          font-size: 27px;
  background: -webkit-linear-gradient(rgba(255, 197, 27, 1), rgba(254, 255, 126, 1), rgba(255, 255, 130, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
        }
     }

     &.wcbetboost{
      color:#FFC51B;
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.wcplay')});
        height: 100px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-size: 27px;
        p{
          font-size: 27px;
  background: -webkit-linear-gradient(rgba(255, 197, 27, 1), rgba(254, 255, 126, 1), rgba(255, 255, 130, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
        }
     }

     &.wcbetboost2{
      color:#FFC51B;
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.wctc')});
        height: 100px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-size: 27px;
        p{
          font-size: 27px;
  background: -webkit-linear-gradient(rgba(255, 197, 27, 1), rgba(254, 255, 126, 1), rgba(255, 255, 130, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
        }
     }

     &.frstbetprize{
        color:rgba(239, 65, 35, 1);
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.crazyCrashPrizeHead')});
        height: 100px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-size: 27px;
        p{
          font-size: 27px;
  background: -webkit-linear-gradient(rgba(255, 197, 27, 1), rgba(254, 255, 126, 1), rgba(255, 255, 130, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
        }
     }
     &.tableBg {
      color:rgba(239, 65, 35, 1);
        background: url(${config.imageV3Host}/static/cashsite/brand/6/image/tableBgImg.png);
        height: 100px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-size: 27px;
        p{
          font-size: 27px;
          background: -webkit-linear-gradient(rgba(255, 197, 27, 1), rgba(254, 255, 126, 1), rgba(255, 255, 130, 1));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
     &.frstbettc{
        color:#CC8401;
        height: 100px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-size: 27px;
        margin: 0;
        p{
          color: #CC8401;
font-size: 36px;
font-style: italic;
font-weight: 700;
line-height: normal;
letter-spacing: 1.8px;
text-transform: uppercase;
        }
     }
     &.funkytime{
      color: #DD127B;
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.funkyenter')});
        height: 100px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-size: 27px;
     }
     &.funkyprize{
      color: #DD127B;
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.funkyprize')});
        height: 100px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-size: 27px;
        margin-bottom: 3rem;
     }
     &.funkyTC{
      color: #DD127B;
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.funkyTC')});
        height: 100px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-size: 27px;
     }
     &.aviatorChallenge{
        color:#E50539;
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.aviatorChallenge')});
        height: 100px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-size: 27px;
        
     }
     &.aviatorPrize{
        color:#E50539;
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.aviatorPrize')});
        height: 100px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-size: 27px;
        margin-bottom: 4rem;
     }

     &.firstbet{
      background: none;
      color:white; 
      width: 100%;
      padding: 2.5rem;
      &::after {
      content: "";
      width: 190px;
      height: 4px;
      background: url(${config.imageV3Host}/static/cashsite/${theme('image.TittleLinefirstbet')});
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
     }

     &.priceAlloc{
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.crazyCrashPrizeHead')});
        height: 80px;
        width: 300px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-size: 25px;
        display: flex;
        align-items: center;
        text-align: center;
        font-size: 30.588px;
        font-style: normal;
        font-weight: 700;
        line-height: 41.09px; /* 134.333% */
        text-transform: uppercase;
        color: #FFF964;
        margin: 3px 0px;
        justify-content: flex-end;

        &::after {
            content: "";
            background: none;
        }
     }

     &.terms{
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.crazyCrashTCHead')});
        height: 80px;
        width: 400px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-size: 25px;
        display: flex;
        align-items: center;
        text-align: center;
        font-size: 30.588px;
        font-style: normal;
        font-weight: 700;
        line-height: 41.09px; /* 134.333% */
        text-transform: uppercase;
        color: #FFF964;
        margin: 3px 0px;
        justify-content: flex-end;

        &::after {
            content: "";
            background: none;
        }
     }

    &::after {
      content: "";
      width: 190px;
      height: 10px;
      background: url(${config.imageV3Host}/static/cashsite/${theme('image.TittleLineImg')});
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
`;


export const TermsConditionPoints = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 2rem;
    border-radius: 11px;
    padding: 1rem 3rem;
    position: relative;
    align-items: center;

    p {
        padding-left: 1rem;
        text-transform: capitalize;
    }

    ul {
        list-style: number;
        color: #fff;
        padding-left: 35px;
        margin-top: 0;

        li {
            p{
                display: flex;
                gap: 5px;
                padding-left: 0rem;
                margin: 0 0 10px 0;
                flex-wrap: wrap;
                line-height: 25px;
            }
        }
    }

    .terms{
      white-space: nowrap;
    }
`;

const TermsConditionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;
  padding-right: 8rem;
`;


const TextContent = styled.p`
display: flex;
color: ${theme('palette.catch99TextContentColor')};
font-size: 17px;
`;

const StepTittle = styled.span`
display: flex;
color: #FEFD00;
font-size: 17px;
font-family: 'Inter-Bold';
`;

const CrazyCrashTopSec = styled.div`
   display: flex;
   flex-direction: column;
   width: 80%;
   justify-content: center;
   height: auto;
   margin-bottom: 1rem;
   align-items: center;

   & > span > img {
      width: 90%;
   }

   & > span {
      display: flex !important;
      justify-content: center !important;
   }
`
const CrazyCrashGameTagsWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 3rem 0rem 1rem 0rem;
`
const CrayCrashTagWrap = styled.div`
    width: 120px;
    height: 158px;
    border: 2px solid #FEFD00;
    z-index: 99;
`
const CrazyCrashPlayNowBtn = styled.button`
    font-size: 14px;
    color: #000000;
    text-align: center;
    width: 100%;
    padding: 7px 15px;
    background-color:#FEFD00;
    border: 1px solid #FEFD00;
    border-bottom: 3px solid #FEFD00;
    font-weight: 700;
    cursor: pointer; 
`
const CrazyCrashOffersWrap = styled.div`
   width: 80%;
   height: auto;
   display: flex;
   justify-content: center;
   flex-wrap: wrap;
   gap: 2em;
   z-index: 99;
   margin: 1rem 0rem;

   & span > img {
    width: 100%;
   }
`

const RocketImageWrap = styled.div`
    position: absolute;
    left: 5%;
    top: 8%;

    img {
        max-width: 60%;
    }
`

export const PlayNowButtonNew = styled.button`
    background: #FEFD00;
    box-shadow: 0px 0px 15px rgba(254, 253, 0, 0.5);
    border-radius: 38.7857px;
    width: auto;
    height: auto;
    padding: 10px 15px;
    color: #000000;
    position: absolute;
    outline: none;
    border: 2px solid #FEFD00;
    font-size: 18px;
    font-weight: 700;
    right: 26%;
    bottom: 30%;
    text-transform: uppercase;
    z-index: 99;
    cursor: pointer;

    &:hover {
        background: #FFFFFF;
        border: 2px solid #FEFD00;
    }
`
export const PlayNowButtonNew2 = styled.button`
   background: linear-gradient(225deg, #FFC51B 0%, #FEFF7E 49.48%, #FFFF82 100%), linear-gradient(225deg, #DC4510 0%, #F9CA68 50.52%, #E45117 100%), #EF4123;
   box-shadow: 0px 0px 10px #eaff03;
    border-radius: 38.7857px;
    width: auto;
    height: auto;
    padding: 10px 15px;
    color: #000;
    outline: none;
    /* border: 2px solid #FEFD00; */
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 2rem;
    z-index: 99;
    margin-top: 3rem;
    cursor: pointer;

    &:hover {
        background: #FEFD00;
        /* border: 2px solid #FEFD00; */
    }
`