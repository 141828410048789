import React from 'react'

type Props = {}

const FastBankDepositIcon = (props: Props) => {
  return (
    <svg
    width={80}
    height={53}
    viewBox="0 0 80 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M75.0843 25.7331L56.4903 14.0129C56.27 13.8748 55.9802 13.8748 55.7599 14.0129L37.1658 25.7331C36.9665 25.8639 36.8422 26.0845 36.8422 26.3188V27.6979C36.8422 28.0767 37.1521 28.387 37.5306 28.387H39.5965V30.4552C39.5965 30.834 39.9063 31.1443 40.2848 31.1443H71.9635C72.342 31.1443 72.6519 30.834 72.6519 30.4552V28.387H74.7178C75.0962 28.387 75.4061 28.0767 75.4061 27.6979V26.3188C75.4061 26.0845 75.2818 25.8639 75.0825 25.7331H75.0843ZM56.1255 27.0088C51.5741 26.8688 51.5751 20.2532 56.1255 20.1141C60.6769 20.2542 60.676 26.8697 56.1255 27.0088Z"
      fill="white"
    />
    <path d="M59.5681 43.5553V32.5243H52.6812V43.5553H59.5681Z" fill="white" />
    <path d="M49.9269 43.5553V32.5243H43.04V43.5553H49.9269Z" fill="#FF9657" />
    <path
      d="M69.2104 43.5553V32.5243H62.3234V43.5553H69.2104Z"
      fill="#09AD4F"
    />
    <path
      d="M74.7196 47.6918H72.6537V45.6235C72.6537 45.2447 72.3438 44.9344 71.9653 44.9344C70.1929 44.9344 64.2008 44.9344 62.3241 44.9344C59.0379 44.9344 53.214 44.9344 49.9278 44.9344C48.0383 44.9344 42.0929 44.9344 40.2866 44.9344C39.9081 44.9344 39.5983 45.2447 39.5983 45.6235V47.6918H37.5324C37.1539 47.6918 36.844 48.002 36.844 48.3808V51.8282C36.844 52.207 37.1539 52.5173 37.5324 52.5173H74.7205C75.0989 52.5173 75.4088 52.207 75.4088 51.8282V48.3808C75.4088 48.002 75.0989 47.6918 74.7205 47.6918H74.7196Z"
      fill="white"
    />
    <path
      d="M23.2209 17.4173H26.3774C28.6947 17.4173 28.6956 13.9086 26.3774 13.9086H23.2209C20.9036 13.9086 20.9036 17.4173 23.2209 17.4173Z"
      fill="white"
    />
    <path
      d="M17.6766 48.9894H14.5201C12.2019 48.9894 12.2019 52.4972 14.5201 52.4972H17.6766C19.9939 52.4972 19.9939 48.9894 17.6766 48.9894Z"
      fill="white"
    />
    <path
      d="M31.2935 13.9095C28.9908 13.9095 28.989 17.4182 31.2935 17.4182H40.8835C43.188 17.4182 43.188 20.9259 40.8835 20.9259H9.99088C7.68547 20.9259 7.68639 24.4336 9.99088 24.4336H24.0144C26.3198 24.4336 26.3134 27.9413 24.0144 27.9413H19.2409C16.9409 27.9413 16.9364 31.449 19.2409 31.449H33.7945C36.0999 31.449 36.099 34.9567 33.7945 34.9567H2.08741C-0.217989 34.9567 -0.217075 38.4654 2.08741 38.4654H21.634C23.9394 38.4654 23.9349 41.9731 21.634 41.9731H17.1759C14.8723 41.9731 14.8714 45.4808 17.1759 45.4808H30.202C32.5074 45.4808 32.5047 48.9885 30.202 48.9885H22.9229C20.6193 48.9885 20.6184 52.4962 22.9229 52.4962H36.5451C36.2444 52.4221 36.1109 52.1503 36.1109 51.8273V48.1969C36.1109 47.678 36.5579 47.2333 36.9821 47.2333H38.6823V45.5275C38.6823 44.7295 39.3286 44.0825 40.1257 44.0825H41.8598V31.9533H39.4163C38.9602 31.9533 38.5909 31.5835 38.5909 31.1269V29.1401C38.5909 29.0267 38.4995 28.9351 38.3861 28.9351H37.004C36.5122 28.9351 36.1109 28.5325 36.1109 28.0411V26.0433C36.1109 25.809 36.2352 25.5885 36.4345 25.4576L54.5715 14.012C54.6803 13.9443 54.8055 13.9095 54.9317 13.9086H31.2935V13.9095Z"
      fill="white"
    />
    <path
      d="M14.4133 31.449C16.7306 31.449 16.7306 27.9413 14.4133 27.9413H11.2569C8.93865 27.9413 8.93957 31.449 11.2569 31.449H14.4133Z"
      fill="white"
    />
    <path
      d="M68.6848 0.755371C62.9671 0.755371 58.3324 5.39521 58.3324 11.1193C58.3324 16.8433 62.9671 21.4832 68.6848 21.4832C74.4025 21.4832 79.0372 16.8433 79.0372 11.1193C79.0372 5.39521 74.4025 0.755371 68.6848 0.755371ZM68.6486 19.7438C63.9104 19.7438 60.0699 15.899 60.0699 11.1555C60.0699 6.412 63.9104 2.56724 68.6486 2.56724C73.3868 2.56724 77.2273 6.412 77.2273 11.1555C77.2273 15.899 73.3868 19.7438 68.6486 19.7438Z"
      fill="#5BA2CA"
    />
    <path
      d="M68.6116 18.5123C72.6898 18.5123 75.9958 15.2026 75.9958 11.1199C75.9958 7.0371 72.6898 3.72742 68.6116 3.72742C64.5334 3.72742 61.2274 7.0371 61.2274 11.1199C61.2274 15.2026 64.5334 18.5123 68.6116 18.5123Z"
      fill="#5BA2CA"
    />
    <path
      d="M64.7689 11.7233L62.4731 11.5363L64.2258 9.78382C64.4649 9.54472 64.796 9.42331 65.1331 9.45051L65.9741 9.51912C65.4748 10.3463 64.9959 11.2723 64.7689 11.7233ZM69.3574 13.6749L69.2887 12.8348C68.4614 13.334 67.5352 13.8121 67.0842 14.0391L67.2713 16.3345L69.024 14.582C69.2631 14.343 69.3845 14.0119 69.3574 13.6749ZM69.9162 8.89172C69.5067 8.48222 68.8423 8.48222 68.4327 8.89172C68.0232 9.30122 68.0232 9.96552 68.4327 10.375C68.8423 10.7845 69.5067 10.7845 69.9162 10.375C70.3258 9.96552 70.3258 9.30122 69.9162 8.89172ZM71.5362 7.27192C71.9254 7.66102 72.2557 8.08481 72.528 8.53281C72.126 9.78001 71.3349 10.9081 70.2745 11.7844C69.2646 12.6274 66.6747 13.8935 66.6747 13.8935L64.9129 12.132C64.9129 12.132 66.1792 9.54251 67.0224 8.53281C67.898 7.47251 69.027 6.68232 70.2745 6.27962C70.7225 6.55182 71.1478 6.88282 71.5362 7.27192ZM70.1636 8.64441C69.6176 8.09841 68.7329 8.09841 68.1869 8.64441C67.6408 9.19031 67.6408 10.0749 68.1869 10.6208C68.7329 11.1668 69.6176 11.1668 70.1636 10.6208C70.7096 10.0749 70.7096 9.19031 70.1636 8.64441ZM71.7572 7.05102C72.0913 7.38512 72.3877 7.74702 72.6434 8.13012C72.8138 7.44922 72.8689 6.73812 72.7904 6.01712C72.0702 5.93875 71.359 5.99379 70.6772 6.16422C71.0603 6.41982 71.4231 6.71692 71.7572 7.05102ZM64.0479 12.6485L66.1588 14.7592L66.4733 14.1371L64.6701 12.3341L64.0479 12.6485Z"
      fill="white"
    />
  </svg>
  )
}

export default FastBankDepositIcon