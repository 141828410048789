import { useState } from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { AES, pad, mode, enc } from "crypto-js";

// Imported Components
import config from "src/config/config";
import { LoginInput } from "src/components/Auth/Login/Login";
import {
  AuthButtonWrap,
  AuthSubmitBtn,
  OtpButton,
  VerifyButton,
  PhoneNumberAndOtpWrap,
  PhoneNumberAndVerifyWrap
} from "src/components/Auth/commonStyles";
import Intl from "src/components/common/Intl";
import OTPTimer from 'src/pages/auth/Signup/OTPTimer'
import { withAppContext } from "src/store/initAppContext";
import { verifyUser, validateandsendOtp } from "src/config/api";

export type UserOptions = {
  userName: string;
  phoneNumber: string;
  password: string;
  otpCode: string;
};

interface IUsernameProps {
  onSendOtpHandler: (event: React.MouseEvent<HTMLElement>, value: any) => void;
  otpHandle: boolean;
  loading: boolean;
  setOtpLoader: boolean;
  parentCallback: (e: any) => void;
  showTimer: boolean;
  showResend: boolean;
  app: any;
  // isSuccess?: boolean;
}

function ForgetUsername(props: IUsernameProps) {
  const { onSendOtpHandler, app: { setState, showTimer, showResend, brandId } } = props;
  const [page, setPage] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const [userName, setUsername] = useState("");
  const {
    handleSubmit,
    control,
    getValues,
    watch,
  } = useForm({
    mode: "onChange",
  });
  const TOKEN_SECRET_KEY = config.secretKey;
  const watchMobileNumber = watch("MobileNumber", 0);
  const watchOTPCode = watch("OTP", 0);
  const [networkMessage, setNetworkMessage] = useState("");
  const [] = useState(false);
  const [retrieveStatus, setRetrieveStatus] = useState(false);
  const [otpsent, setOtpsent] = useState(false);
  const [otpLoader, setOtpLoader] = useState(false);
  const [verifyLoader, setVerifyLoader] = useState(false);

  const VerifyIcon = `${"static/Image/check-circle.png"}`;
  const ArrowIcon = `${"static/Image/arrow-right-circle.png"}`;
  const NoneIcon = `${"static/Image/"}`;

  const Icons = { ArrowIcon, VerifyIcon, NoneIcon };

  const onSubmit = (data) => {
    //console.log(data);
  };

  const showUsername = () => {
    setPage(1);
   // setUsername(localStorage.getItem("UserName"));
  };

  const gotoLogin = () => {
    props.parentCallback(0);
  };

  //API to send OTP to the registered mobile number
  const sendOTP = async (formData) => {
    const info = JSON.stringify({
      "phoneNumber": watchMobileNumber.trim(),
      "phoneCountry": "in",
      "brandId":brandId
    });
    const encryptedTarget = enc.Utf8.parse(info);
    const encryptSecretKey = enc.Utf8.parse(TOKEN_SECRET_KEY);
    const authInfo = AES.encrypt(encryptedTarget, encryptSecretKey, {
      iv: enc.Utf8.parse(""),
      padding: pad.Pkcs7,
      mode: mode.CBC,
    }).ciphertext.toString();
    let data = {
      resetInfo: authInfo,
    };
    setOtpLoader(true);
    setOtpsent(true);
    validateandsendOtp(data)
      .then((response) => {
        
        setOtpLoader(false);
        setIsSuccess(true)
        setNetworkMessage(response.data.message);
        if (typeof setState === 'function') {
          let _reqData = {
            showTimer: true
          }
          setState(_reqData)
        }
        setTimeout(() => {
          if (typeof setState === 'function') {
            let _reqData = {
              showTimer: false,
              showResend: true
            }
            setState(_reqData)
          }
        }, 75000);
      })
      .catch((error) => {
        setOtpLoader(false)
        setIsSuccess(false)
        setOtpsent(false);
        let errorResponse = error.response?.data
        setNetworkMessage(errorResponse?.error);
      })
  };

  //API to retrieve the userName
  const retrieveUser = async (formData) => {
    setVerifyLoader(true)
    const info = JSON.stringify({
      phoneNumber: watchMobileNumber.trim(),
      phoneCountry: "in",
      otpCode: watchOTPCode,
      brandId:brandId
    });
    const encryptedTarget = enc.Utf8.parse(info);
    const encryptSecretKey = enc.Utf8.parse(TOKEN_SECRET_KEY);
    const authInfo = AES.encrypt(encryptedTarget, encryptSecretKey, {
      iv: enc.Utf8.parse(""),
      padding: pad.Pkcs7,
      mode: mode.CBC,
    }).ciphertext.toString();
    let data = {
      retrieveInfo: authInfo,
    };
    verifyUser(data)
      .then((response) => {
        if(response.data){
          let successResponse = response.data.message
          setRetrieveStatus(true);
          setNetworkMessage(successResponse);
          setVerifyLoader(false)
          setIsSuccess(true)
          setUsername( response.data.user)
        }
        
      })
      .catch((error) => {
        let errorResponse = error.response.data
        setNetworkMessage(errorResponse.error);
        setVerifyLoader(false)
        setIsSuccess(false)
      })
  };

  const formatInput = (e) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum = e.key === "e" || e.key === "." || e.key === "+" || e.key === "-" || e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'E';
    }
    else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  }

  return (
    <ForgetUsernameWrap>
      {page === 0 && (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
         
          <Controller
            control={control}
            name="MobileNumber"
            rules={{
              required: {
                value: true,
                message: "* Please enter Mobile Number",
              },
              minLength: { value: 10, message: "* Minimum Character : 10" },
              maxLength: { value: 10, message: "* Too Many Characters" }
            }}
            render={({
              field: { onChange, value },
              fieldState: { error },
            }) => (
              <>
                <PhoneNumberAndOtpWrap>
                  <p>+91</p>
                  <LoginInput
                    id="mobilenumber"
                    placeholder={<Intl langKey="SIGNUP@ENTERNUMBER" />}
                    type="number"
                    onChange={onChange}
                    onKeyDown={formatInput}
                    onWheel={(e) => e.target.blur()}
                    value={value}
                    error={error}
                    req={true}
                    onPaste={(e)=>{
                    e.preventDefault()
                    return false;
                    }} 
                    onCopy={(e)=>{
                    e.preventDefault()
                    return false;
                    }}
                  />
                  <OtpButton
                    disabled={value?.length !== 10 || otpsent}
                    isActive={value?.length === 10}
                    type="button"
                    onClick={sendOTP}
                  >
                    {otpsent ? (
                      <>
                         <Intl langKey="AUTH@SENT" />  <img className="send-otp" src={Icons.VerifyIcon} alt="" />
                      </>
                    ) : (
                        <>
                          {
                            otpLoader ?  <Intl langKey="AUTH@SENDING" /> : <> <Intl langKey="AUTH@OTP" /> <img className="send-otp" src={Icons.ArrowIcon} alt="ArrowIcon" /></>
                          }
                        </>
                      )}
                  </OtpButton>

                </PhoneNumberAndOtpWrap>
                <h1>{error && error.message}</h1>
                <div className="timer" >{showTimer ? <OTPTimer /> : null}</div>
                {showResend ?
                  <div className="message">
                    <p><Intl langKey="AUTH@RESENDOTP" /><Resendbutton onClick={(event) => onSendOtpHandler(event, getValues)}><Intl langKey="AUTH@RESEND" /></Resendbutton></p>
                  </div>
                  : null}
              </>
            )}
          />
          {otpsent &&
            <Controller
              control={control}
              name="OTP"
              rules={{
                required: { value: true, message: "* Please enter otp" },
                minLength: { value: 6, message: "* Enter 6 digit OTP" },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <PhoneNumberAndVerifyWrap>
                    <LoginInput
                      id="otpcode"
                      placeholder={<Intl langKey="LOGIN@ENTEROTP" />}
                      type="number"
                      onChange={onChange}
                      onKeyDown={formatInput}
                      onWheel={(e) => e.target.blur()}
                      value={value}
                      error={error}
                      req={true}
                      onPaste={(e)=>{
                      e.preventDefault()
                      return false;
                      }} 
                      onCopy={(e)=>{
                      e.preventDefault()
                      return false;
                      }}
                    />
                    <VerifyButton
                      disabled={value?.length !== 6 || retrieveStatus}
                      isActive={value?.length === 6}
                      type="button"
                      onClick={(e) => retrieveUser(e)}
                    >
                      {retrieveStatus ? <Intl langKey="AUTH@VERIFIED" /> : verifyLoader ? <Intl langKey="AUTH@VERIFYING" /> : <Intl langKey="AUTH@VERIFY" /> }
                    </VerifyButton>
                  </PhoneNumberAndVerifyWrap>
                  <h1>{error && error.message}</h1>
                </>
              )}
            />
          }

            <div className={`${isSuccess ? 'success-wrap' : 'error-wrap'}`}>
               {networkMessage?.length !== 0 && <p className='username-error1' >{networkMessage}</p>}
            </div>

          <AuthButtonWrap>
            <AuthSubmitBtn
              disabled={!retrieveStatus}
              type="button"
              onClick={() => showUsername()}
            >
             <Intl langKey="USERNAME@BUTTON" />
            </AuthSubmitBtn>
          </AuthButtonWrap>

          {/* <AuthButtonWrap>
            <AuthPaginationButton isActive={true}></AuthPaginationButton>
            <AuthPaginationButton></AuthPaginationButton>
          </AuthButtonWrap> */}

        </form>


      )}
      {page === 1 && (
        <form>
             
          <label>
            <LoginInput
              id="userName"
              placeholder=""
              type="text"
              onChange={null}
              value={userName}
              error={null}
              req={true}
              readonly={true}
            />
            
            
            <div className={`${isSuccess ? 'success-wrap' : 'error-wrap'}`}>
              {networkMessage?.length !== 0 && <p className='username-error1' >{networkMessage}</p>}
            </div>
            <AuthButtonWrap>
              <AuthSubmitBtn
                className="submit-button"
                type="button"
                onClick={() => gotoLogin()}
              >
              <Intl langKey="USERNAME@LOGIN" />
              </AuthSubmitBtn>
            </AuthButtonWrap>

          </label>
        </form>
      )}


    </ForgetUsernameWrap>
  );
}

export default withAppContext(ForgetUsername);

const ForgetUsernameWrap = styled.div`
& .error-wrap {
  & p {
    font-size: 15px;
    text-align: center;
    color: white;
    font-weight: 500;
    background-color: #c91414;
    padding: 5px;
    border-radius: 30px;
  }
}
& .success-wrap {
  & p {
      font-size: 15px;
      text-align: center;
      color: white;
      font-weight: 500;
      background-color: green;
      padding: 5px;
      border-radius: 30px;
    }
}

       .timer{
          color: #FF6060;
          font-size: 13px;
          font-style: italic;
          display: flex;
          justify-content: flex-end;
      }

      .message {
        color: #FF6060;
        font-size: 13px;
        font-style: italic;
        display: flex;
        justify-content: flex-end;

        p{
          margin: 3px 0px;
        }
        
      }
`;

const Resendbutton = styled.button`
    color: #fff;
    border: transparent;
    font-style: italic;
    text-decoration: underline;
    background: transparent;
    cursor: pointer

`;

