import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import Footer from 'src/components/footer/footer';

function FomoAviatrixFlyHigh(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = (brandId == 39 || brandId == 40) ? "FOMO7" : 'Baazi Adda';


    return (
        <>
            <InformationWrapper>
                <HeadWrapper>
                    <p><span className='white'>FOMO</span><span className='orange'>7</span> brings you free bets on your favorite game – Aviatrix, a game that takes your entertainment quotient to new heights. Brace yourself for an edge of the seat adventure, where strategy meets skills. A total pool prize of ₹1,00,00,000 worth of free bets await!</p>
                    <p>You Want to be the highest paid Pilot? Fly high & win!</p>
                </HeadWrapper>
                <ROWrapper>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.FomoAviatrixFlyHighRoPlaneImg}`}
                        alt="jetxPromoStepThree"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                    />
                    <RoContent>
                        <h1>Embark on a high flying adventure:</h1>
                        <TextWrapper>
                            <span>Stage&nbsp;1:</span>
                            <p>Make at least 1 bet with NFT aircraft in 24 hours.</p>
                        </TextWrapper>
                        <TextWrapper>
                            <span>Stage&nbsp;2:</span>
                            <PlayerWarpper>
                                <p>heer for your aircraft to win the daily tournament. Claim the reward after victory!</p>
                            </PlayerWarpper>
                        </TextWrapper>
                        <TextWrapper>
                            <span>Stage&nbsp;3:</span>
                            <p>Earn points with each bet. Points are equal to your bet amount! Higher your points, higher your chances of winning.!</p>
                        </TextWrapper>
                        <TextWrapper>
                            <span>Bet & Win:</span>
                            <p>Win everyday at Midnight!</p>
                        </TextWrapper>
                    </RoContent>
                </ROWrapper>
                <DespositWrapper>
                    <DepositContent>
                        <h1>Register</h1>
                        <p>Log in to your {domain} account and head to Aviatrix!</p>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.FomoAviatrixFlyHighRoImgOne}`}
                            alt="jetxPromoStepThree"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                    </DepositContent>
                    <DepositContent>
                        <h1>Deposit & Dive In</h1>
                        <p>Select your preferred deposit method and dive headfirst into the immersive world of Aviatrix. Let the excitement fuel your every move as you chase victory and fortune!</p>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.FomoAviatrixFlyHighRoImgTwo}`}
                            alt="jetxPromoStepThree"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                    </DepositContent>
                    <DepositContent>
                        <h1>Place Your Bets</h1>
                        <p>Zero down on your strategy, get your game face on, and launch into the thrilling world of Aviatrix.</p>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.FomoAviatrixFlyHighRoImgThree}`}
                            alt="jetxPromoStepThree"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                    </DepositContent>
                    <DepositContent>
                        <h1>Soar to Victory</h1>
                        <p>Navigate through the challenges, plan your bets, and win free bets.</p>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.FomoAviatrixFlyHighRoImgFour}`}
                            alt="jetxPromoStepThree"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                    </DepositContent>
                </DespositWrapper>
                <Termsandconditions>
                    <TermsandconditionsHeading>
                        <h1>Terms & Conditions</h1>
                    </TermsandconditionsHeading>
                    <TermsandconditionsBody>
                        <ol>
                            <li>This promotion is valid for all new & existing players.</li>
                            <li>This promotion is valid from 2nd June 2024 to 29th  June 2024.</li>
                            <li>Points are equal to the bet amount.</li>
                            <li>Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.</li>
                            <li>Multiple accounts will not be considered for this promotion. All players will be subject for duplicate checking. {domain} reserves the right to disqualify players if collusion or any foul play takes place in the promotion.</li>
                            <li> {domain} reserves the right to amend, cancel, and reclaim or refuse any promotion at its own discretions.</li>
                            <li>{domain} General Terms & Conditions apply.</li>
                        </ol>
                    </TermsandconditionsBody>
                </Termsandconditions>
            </InformationWrapper>
            <Footer />
        </>
    )
}



export default withTheme(withAppContext(FomoAviatrixFlyHigh));

export const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 0 3rem 8rem 3rem;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em;
    border: 1px solid #E5B358;
    border-radius: 0 0 20px 20px;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.FomoAviatrixFlyHighBackground")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
`;

const HeadWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 20% 3rem 20%;
    gap: 1rem;

    p {
        margin: 0;
        color: #FFF;
        text-align: center;
        font-size: 15.682px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 23.523px */
        text-transform: capitalize;
    }

    .orange {
        color: #FF7C47;
        font-weight: 700;
        text-transform: capitalize;
    }

    .white {
        font-weight: 700;
    }
`;

export const UnderLine = styled.div`
    height: 2px;
    width: 184px;
    background: radial-gradient(#FFF 0%, rgba(153, 153, 153, 0.00) 80%);
`;

export const Divider = styled.div`
    height: 2px;
    width: 100%;
    background: radial-gradient(#1182C1 0%, #00000000 50%);
`;

const ROWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 80%;
    height: auto;
    margin: 3rem 10% 3rem 10%;
    padding: 4rem 3rem;
    position: relative;
    /* background: url(${config.imageV3Host}/static/cashsite/${theme("image.FomoAviatrixFlyHighRoBackground")});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; */
    border-radius: 20px;
    border: 2px solid #F90;
    background: rgba(0, 0, 0, 0.70);
    position: relative;

    img {
        position: absolute;
        width: 32rem;
    left: 4%;
    /* top: 4.5%; */
    bottom: 0%;
    }
`;

const DespositWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    gap: 2.5rem;
    padding: 2rem;
    flex-wrap: wrap;
`;

const DepositContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 20px;
    border: 1px solid #F90;
    background: radial-gradient(1165.69% 282.76% at -64.11% -58.63%, #795929 0%, #131312 39.94%, #131211 66.87%, #40321D 100%);
    padding: 2rem 17% 2rem 2rem;
    width: 40%;
    gap: 1rem;
    position: relative;

    h1 {
        margin: 0;
        color: #FF9C07;
        font-size: 21px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }

    p {
        margin: 0;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
    }

    img {
        position: absolute;
        width: 13rem;
        top: -21%;
        right: 0%;
    }
`;

const RoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 50%;
    padding-right: 2rem;

    h1 {
        margin: 0;
        color: #F90;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 43.08px */
        text-transform: capitalize;
        text-align: left;
        padding-bottom: 1.5rem;
    }
`;

const TextWrapper = styled.div`
    display: flex;

    span {
        color: #F90;
        font-weight: 700;
    }

    p {
        margin: 0;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-transform: capitalize;
        text-align: left;
        padding-left: 0.5rem;
    }
`;

const PlayerWarpper = styled(TextWrapper)`
    flex-direction: column;
`;

const Termsandconditions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    width: 85%;
    height: auto;
    padding: 5rem 3rem;
`;

const TermsandconditionsHeading = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 85%;
    padding: 1rem;

    h1 {
        margin: 0;
        color: #FF9C07;
        font-size: 31.364px;
        font-style: normal;
        font-weight: 700;
        line-height: 46.027px; /* 146.751% */
        text-transform: capitalize;
    }
`;

const TermsandconditionsBody = styled.div`
    width: 85%;

    ol {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        padding-top: 1rem;
    }
    li {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.8px; /* 142.5% */
        text-transform: capitalize;
    }
`;