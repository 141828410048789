import React from 'react'

type Props = {}

export default function UserNameIcon(props) {
  return (
  <svg viewBox="0 0 21 21" fill="none" xmlns="">
  <path d="M18.5294 3.70581H2.47062C1.42062 3.70581 0.617676 4.50875 0.617676 5.55875V15.4411C0.617676 16.4911 1.42062 17.294 2.47062 17.294H18.5294C19.5794 17.294 20.3824 16.4911 20.3824 15.4411V5.55875C20.3824 4.50875 19.5794 3.70581 18.5294 3.70581ZM19.1471 15.4411C19.1471 15.8117 18.9 16.0588 18.5294 16.0588H2.47062C2.10003 16.0588 1.85297 15.8117 1.85297 15.4411V5.55875C1.85297 5.18816 2.10003 4.9411 2.47062 4.9411H18.5294C18.9 4.9411 19.1471 5.18816 19.1471 5.55875V15.4411Z" fill="white"/>
  <path d="M9.14132 9.45004C9.20308 9.20298 9.26485 8.89416 9.26485 8.6471C9.26485 7.28828 8.15308 6.17651 6.79426 6.17651C5.43544 6.17651 4.32367 7.28828 4.32367 8.6471C4.32367 8.95593 4.38544 9.20298 4.4472 9.45004C3.64426 9.82063 3.08838 10.6236 3.08838 11.6118V14.2059C3.08838 14.5765 3.33544 14.8236 3.70603 14.8236H9.8825C10.2531 14.8236 10.5001 14.5765 10.5001 14.2059V11.6118C10.5001 10.6236 9.94426 9.82063 9.14132 9.45004ZM6.79426 7.41181C7.47367 7.41181 8.02955 7.96769 8.02955 8.6471C8.02955 9.32651 7.47367 9.8824 6.79426 9.8824C6.11485 9.8824 5.55897 9.32651 5.55897 8.6471C5.55897 7.96769 6.11485 7.41181 6.79426 7.41181ZM9.26485 13.5883H4.32367V11.6118C4.32367 11.0559 4.69426 10.6236 5.18838 10.5618C5.62073 10.9324 6.17661 11.1795 6.79426 11.1795C7.41191 11.1795 7.96779 10.9324 8.40014 10.5618C8.89426 10.6853 9.26485 11.1177 9.26485 11.6118V13.5883ZM17.2943 7.41181H12.3531C11.9825 7.41181 11.7354 7.65887 11.7354 8.02945C11.7354 8.40004 11.9825 8.6471 12.3531 8.6471H17.2943C17.6648 8.6471 17.9119 8.40004 17.9119 8.02945C17.9119 7.65887 17.6648 7.41181 17.2943 7.41181ZM17.2943 9.8824H12.3531C11.9825 9.8824 11.7354 10.1295 11.7354 10.5C11.7354 10.8706 11.9825 11.1177 12.3531 11.1177H17.2943C17.6648 11.1177 17.9119 10.8706 17.9119 10.5C17.9119 10.1295 17.6648 9.8824 17.2943 9.8824ZM17.2943 12.353H12.3531C11.9825 12.353 11.7354 12.6 11.7354 12.9706C11.7354 13.3412 11.9825 13.5883 12.3531 13.5883H17.2943C17.6648 13.5883 17.9119 13.3412 17.9119 12.9706C17.9119 12.6 17.6648 12.353 17.2943 12.353Z" fill="white"/>
  </svg>
  )
}


