import React, {useRef, useState } from "react";
import styled from "styled-components";
import Intl from "src/components/common/Intl";
import config from "src/config/config";
import { AES, pad, mode, enc } from 'crypto-js';
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { setProfilePassword } from '../../config/api'
import { Notify } from '../../components/Notification';
import { PasswordInputField } from "../../components/udb/Password";
import { BodyWrapper as PasswordBodyWrapper } from "src/components/udb/commonStyle";
import { AuthButtonWrap, AuthSubmitBtn } from "../Auth/commonStyles";
import cookieUtil from "src/util/cookieUtil";
import { ECookieName } from "src/util/utilModel";
//icon imports
import EyeIcon from 'src/components/icon/Eye';
import HideIcon from 'src/components/icon/Hide';

type FormValues = {
  curentPassWord: any;
  newProfilePassWord: any;
  confirmNewProfilePassWord: any;
};

const TOKEN_SECRET_KEY = config.secretKey;

function AgentChangePassword({ onClose, userState }) {
  const [values, setValues] = useState({
    showCurrPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  });


  let navigate = useNavigate();

  const {
    handleSubmit,
    formState: { isValid, errors },
    reset,
    watch,
    control
  } = useForm<FormValues>({
    mode: "all",
    defaultValues: {
      curentPassWord: "",
      newProfilePassWord: "",
      confirmNewProfilePassWord: "",
    },
  });

  const curentPassWord = useRef({});
  curentPassWord.current = watch("curentPassWord", "");
  const newprofilePassword = useRef({});
  newprofilePassword.current = watch("newProfilePassWord", "");

  // handleEnter function is when we press enter the cursor should come down to next input field------------------------------
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const handleSuccess = () => {
    cookieUtil.set(ECookieName.COOKIE_IS_DEFAULT_PASSWORD, JSON.stringify(false));
    Notify.success('Your Password has been Successfully Changed');
  
    onClose();
    resetHandle();
     if(userState && typeof userState === "function"){
      userState();
     } else {
       navigate("/")
     }
  }

  // token key we are using to encrypt the data which is passed down as payload for the api----------------------------------
  function onSubmit({ curentPassWord, newProfilePassWord }) {

    const info = JSON.stringify({ old_password: curentPassWord.trim(), new_password: newProfilePassWord.trim() });
    const encryptedTarget = enc.Utf8.parse(info)
    const encryptSecretKey = enc.Utf8.parse(TOKEN_SECRET_KEY)
    const changePasswordInfo = AES.encrypt(encryptedTarget, encryptSecretKey, {
      iv: enc.Utf8.parse(''),
      padding: pad.Pkcs7,
      mode: mode.CBC,
    }).ciphertext.toString();

    let bodyParameter = {
      "data": changePasswordInfo
    };

    setProfilePassword(JSON.stringify(bodyParameter)).then((response) => {
      if (response.data.success) {
            handleSuccess();
        }
    }).catch((error) => {
        if (error.response.data.data.message === "Old and new password can't be same") {
          Notify.error("Old and new password can't be same")
        } else {
          Notify.error('Old Password is wrong !!')
        }
      })
  }

  const resetHandle = () => {
    reset({ curentPassWord: '', newProfilePassWord: '', confirmNewProfilePassWord: '' });
  };

  const handleClickShowCurrPassword = () => {
    setValues({ ...values, showCurrPassword: !values.showCurrPassword });
  };

  const handleClickShowNewPassword = () => {
    setValues({ ...values, showNewPassword: !values.showNewPassword });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <BodyWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name='curentPassWord'
            rules={{
              required: { value: true, message: 'Please Enter Current Account Password' },
              // minLength: { value: 8, message: "* Min 8 Characters" },
              // maxLength: { value: 16, message: "* Max 16 Characters" }
            }}
            render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
              <SignUpPasswrdWrap>
              <PasswordInputField
                id="curentPassWord"
                placeholder="Current Account Password"
                type={values.showCurrPassword ? "text" : "password"}
                onChange={onChange}
                onKeyPress={handleEnter}
                onBlur={onBlur}
                value={value}
                error={error}
                req={true}
                name={'curentPassWord'}
              />
               <PasswordHide onClick={handleClickShowCurrPassword}
                  onMouseDown={handleMouseDownPassword}
                >{values.showCurrPassword ? <HideIcon /> : <EyeIcon />}
                </PasswordHide>
          </SignUpPasswrdWrap>
          )}
          />

          <Controller
            control={control}
            name='newProfilePassWord'
            rules={{
              required: { value: true, message: "Please Enter New Account Password" },
              minLength: { value: 8, message: "* Minimum Length : 8" },
              maxLength: { value: 24, message: "* Max Length reached" },
              pattern: {
                value: /^(?=.*?[A-z])(?=.*?[0-9]).{8,}$/,
                message:
                  "Must contain at least one number, one letter, and at least 8 or more characters",
              },
            }}
            render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
              <SignUpPasswrdWrap>
                <PasswordInputField
                  id="newProfilePassWord"
                  placeholder="New Account Password"
                  type={values.showNewPassword ? "text" : "password"}
                  onChange={onChange}
                  onKeyPress={handleEnter}
                  onBlur={onBlur}
                  value={value}
                  error={error}
                  req={true}
                  name={'newProfilePassWord'}
                />
                <PasswordHide onClick={handleClickShowNewPassword}
                  onMouseDown={handleMouseDownPassword}
                >{values.showNewPassword ? <HideIcon /> : <EyeIcon />}
                </PasswordHide>
          </SignUpPasswrdWrap>
            )}
          />

          <Controller
            control={control}
            name='confirmNewProfilePassWord'
            rules={{
              required: { value: true, message: 'Please Confirm New Account Password' },
              minLength: { value: 8, message: "* Min 8 Characters" },
              maxLength: { value: 16, message: "* Max 16 Characters" },
              validate: value => value === newprofilePassword.current || "The passwords do not match !!"
            }}
            render={({ field: { onChange, value, onBlur }, fieldState: { error } }) =>(
              <SignUpPasswrdWrap>
                <PasswordInputField
                  id="confirmNewProfilePassWord"
                  placeholder="Confirm New Account Password"
                  type={values.showConfirmPassword ? "text" : "password"}
                  onChange={onChange}
                  onKeyPress={handleEnter}
                  onBlur={onBlur}
                  value={value}
                  error={error}
                  req={true}
                  name={'confirmNewProfilePassWord'}
                />
                <PasswordHide onClick={handleClickShowConfirmPassword}
                onMouseDown={handleMouseDownPassword}
              >{values.showConfirmPassword ? <HideIcon /> : <EyeIcon />}
              </PasswordHide>
        </SignUpPasswrdWrap>
             )}
          />


          <AuthButtonWrap>
            <AuthSubmitBtn
              type="submit"
              disabled={!isValid}
            >
              <Intl langKey="PASSWORD@BUTTON" />
            </AuthSubmitBtn>
          </AuthButtonWrap>
        </form>
      </BodyWrapper>
    </>
  );
}
export default AgentChangePassword;

//Styled Components
const BodyWrapper = styled(PasswordBodyWrapper)`
  height:100%;
  width: 100%;
  background: linear-gradient(0deg, #000000, #000000), linear-gradient(180deg, #121239 -3.33%, #151439 100%);  
  border-radius: 8px;
  opacity: 0.90;
  display: flex;
  justify-content: center;
`;

const SignUpPasswrdWrap = styled.div`
    position: relative;

    & input {
      width: 90%;
    }
`;
const PasswordHide = styled.div`
    display: block;
    width: auto;
    position: absolute;
    right: 40px;
    top: 20px;
    cursor: pointer;
`
