import React, { useState, useEffect } from 'react';
import styled, { withTheme } from 'styled-components';
import { withAppContext } from 'src/store/initAppContext';
import config from "src/config/config";
import Intl from 'src/components/common/Intl';
import { theme } from "styled-tools";
import { Progress } from "react-sweet-progress";
import {
    ProgressAmountInnerWrap as TierProgressAmountInnerWrap,
    ProgressBarAmountWrapper as TierProgressBarAmountWrapper,
    ProgressBarMaxRound as TierProgressBarMaxRound
} from 'src/components/udb/bonus/PromotionContainer';

import { getUserTierInfo } from 'src/config/api';
import { Notify } from '../../../components/Notification';

type AccountInfoTierComponentProps = {
    TierName?: string;
    percent?: number;
}

type TierName = 'blue' | 'silver' | 'gold' | 'platinum' | 'elite' | '';
type TierProps = {
    prev_tier_name?: TierName;
    curr_tier_name: TierName;
    next_tier_name?: TierName;
    curr_turnover?: string;
    next_turnover?: string;
    next_turnover_title: string,
    tier_bg_img: string;
}
const defaultTierData: TierProps = {
    prev_tier_name: '',
    curr_tier_name: 'blue',
    next_tier_name: 'silver',
    curr_turnover: "0",
    next_turnover: "500000",
    tier_bg_img: 'BlueTierCard',
    next_turnover_title: "",
}

//source - src/theme/snk/client/image.json
const TierBgImg = {
    blue: 'BlueTierCard',
    silver: 'SilverTierCard',
    gold: 'GoldTierCard',
    platinum: 'PlatinumTierCard',
    elite: 'EliteTierCard'
}

const getTierName = (name) => name.toLocaleLowerCase().replace(' tier', '');

function AccountInfoTierComponent(props) {
    const { theme, data: { firstName, lastName, email, phoneNumber } } = props;
    const [tierdata, setTierdata] = useState(defaultTierData);

    useEffect(() => {
        getUserTierInfo().then(res => {
            const { data = {} } = res.data || {};
            if (data.curr_tier_info) {
                const { curr_tier_info, next_tier_info, prev_tier_info, curr_turnover } = data;
                const curr_tier = getTierName(curr_tier_info.tier_name);
                const bgName = TierBgImg[curr_tier]
                setTierdata({
                    curr_tier_name: curr_tier,
                    prev_tier_name: getTierName(prev_tier_info.tier_name),
                    next_tier_name: getTierName(next_tier_info.tier_name),
                    curr_turnover: curr_turnover,
                    next_turnover: next_tier_info.min_turnover,
                    next_turnover_title: next_tier_info.min_turnover_title,
                    tier_bg_img: theme.image[bgName]
                });
            }
        }).catch(e => {
            let error = 'Something went wrong please try again'
            if (e.response && e.response.data) {
                error = e.response.data.error;
            }
            Notify.error(error);
            //setIsEdit(false)
        });
    }, [])


    const tierName = tierdata.curr_tier_name;
    
    //removing commas from trunover values
    const currTurnoverWithoutComas:any = tierdata.curr_turnover.replaceAll(',', '');
    const nextTurnoverWithoutComas:any = tierdata.next_turnover.replaceAll(',', '');
    const percent: any = (currTurnoverWithoutComas / nextTurnoverWithoutComas) * 100;
  
    
    let tierPercent = percent > "100" ? "100" : percent;
    let prevTierName: string = tierdata.prev_tier_name;
    let nextTierName = tierdata.next_tier_name;
    let nextTurnover:any = tierdata.next_turnover_title;

    prevTierName = (!prevTierName && tierName === 'blue') ? 'Started Blue' : `Finished ${prevTierName}`;
    if (tierName === 'platinum') {
        prevTierName = 'You have reached platinum level!';
        nextTierName = '';
        tierPercent = 100;
        nextTurnover = ""
    }

    return (
        <Wrapper>

            <TierCard href='/loyalty' type={tierdata.tier_bg_img}>
                <AutofillText TierName={tierName}>
                    <label><Intl langKey="TIER@TierName" /></label>
                    <p>{`${firstName === null ||  firstName === "" ? "NA" : firstName} ${lastName === null || lastName === "" ? "NA" : lastName}`}</p>
                </AutofillText>

                <AutofillText TierName={tierName}>
                    <label><Intl langKey="TIER@TierContactNumber" /></label>
                    <p>{`${phoneNumber === null || phoneNumber === ""  ? "NA" : phoneNumber}`}</p>
                </AutofillText>

                <AutofillText TierName={tierName}>
                    <label><Intl langKey="TIER@TierEmail" /></label>
                    <p>{`${email === null || email === "" ? "NA" : email}`}</p>
                </AutofillText>
            </TierCard>

            {tierName === 'elite' && (
                <MostValuableCust><Intl langKey="TIER@MostValuableCustomer"/></MostValuableCust>)
            }
            {tierName !== 'elite' &&
                (<CurrentTurnOver>
                    <Intl langKey="TIER@CurrentTurnOver" />
                    <TurnOver>{tierdata.curr_turnover}</TurnOver>
                </CurrentTurnOver>)}

            {tierName !== 'elite' && (<ProgressbarWrapper TierName={tierName}>
                <StartTier className="firstTier" TierName={tierName}>
                    {tierdata.curr_tier_name && tierdata.curr_tier_name}
                </StartTier>
                <Progress
                    percent={tierPercent}
                    theme={{
                        active: {
                            color: "",
                        }
                    }}
                />
                <StartTier className="lastTier" TierName={tierName}>
                    {nextTierName && nextTierName}
                </StartTier>
                <StatusTextTier>
                    <TierStartedText>
                        {prevTierName && <>{prevTierName}</>}
                    </TierStartedText>
                    <TierEndText>
                        {nextTurnover ? <>₹{nextTurnover} To {tierdata.next_tier_name}</> : null}
                    </TierEndText>
                </StatusTextTier>
                <ProgressAmountInnerWrap percent={tierPercent} className="parenthover">
                    <ProgressBarMaxRound TierName={tierName}></ProgressBarMaxRound>
                    <ProgressBarAmountWrapper className="hovereffect">
                        <p>{tierdata.curr_turnover}</p>
                    </ProgressBarAmountWrapper>
                </ProgressAmountInnerWrap>
            </ProgressbarWrapper>)}

        </Wrapper>
    )
}

export default withTheme(withAppContext(AccountInfoTierComponent));

//Styled Components
const ProgressAmountInnerWrap = styled(TierProgressAmountInnerWrap) <Pick<AccountInfoTierComponentProps, "percent">>`
    top: -3px;
    left: ${(p) =>
        p.percent === 100
            ? `${p.percent - 1}%`
            : p.percent > 0
                ? `${p.percent - 1}%`
                : "0px"};
`;

const ProgressBarMaxRound = styled(TierProgressBarMaxRound) <Pick<AccountInfoTierComponentProps, "TierName">>`
    display: block;
    background: ${(p) => (p.TierName === "blue" ? "#60A0FF" : p.TierName === "silver" ? "#D4D4D4" : p.TierName === "gold" ? "#BD8A13" : "#A9C6D4")};

    &:hover + {
        ${TierProgressBarAmountWrapper} {
            display: block;
        }
    }
`;

const ProgressBarAmountWrapper = styled(TierProgressBarAmountWrapper)`
    display: none;
    left: -25px;
    top: 13px;
`;

const MostValuableCust: any = styled.div`
    display: flex;
    align-items: center;
    width: 335px;
    color: ${theme('palette.mostValuableCustColor')};
    letter-spacing: 0.01em;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    padding: 1rem 0 0;

    :before {
        content: "";
        display: flex;
        width: 55px;
        height: 1px;
        background-color: ${theme('palette.mostValuableCustColor')};
    }

    :after {
        content: "";
        display: flex;
        width: 55px;
        height: 1px;
        background-color: ${theme('palette.mostValuableCustColor')};
    }
`;

const TierEndText = styled.div``;
const TierStartedText = styled.div``;

const StatusTextTier = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
    font-size: 12px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: ${theme('palette.CurrentTurnOver')};
`;

const StartTier: any = styled.p<Pick<AccountInfoTierComponentProps, "TierName">>`
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: ${(p) => (p.TierName === "blue" ? "#60A0FF" : p.TierName === "silver" ? "#D4D4D4" : p.TierName === "gold" ? "#BD8A13" : "#A9C6D4")};
  margin: 0px;
`;

const ProgressbarWrapper = styled.div<Pick<AccountInfoTierComponentProps, "TierName">>`
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: ${(p) => (p.TierName === "blue" ? "normal" : p.TierName === "silver" ? "normal" : p.TierName === "gold" ? "normal" : "center")};
    color: ${(p) => (p.TierName === "blue" ? "#60A0FF" : p.TierName === "silver" ? "#D4D4D4" : p.TierName === "gold" ? "#BD8A13" : "#A9C6D4")};
    position: relative;

    .firstTier {
        position: ${(p) => (p.TierName === "platinum" ? "static" : "absolute")};
        left: 0;
        top: -18px;
        margin-bottom: 10px;
    }

    .lastTier {
        position: absolute;
        right: 0;
        top: -18px;
        color: ${(p) => (p.TierName === "blue" ? "#D4D4D4" : p.TierName === "silver" ? "#BD8A13" : p.TierName === "gold" ? "#A9C6D4" : "#A9C6D4")};
    }

    .react-sweet-progress-symbol {
        display: none;
    }

    .react-sweet-progress-line {
        background-color: #d9d9d954!important;
        :hover + {
            ${TierProgressBarAmountWrapper} {
                display: block;
            }
        }
    }

    &:hover .parenthover > .hovereffect {
        display: block;
    }

    .react-sweet-progress-line-inner {
        background-color: ${(p) => (p.TierName === "blue" ? "#60A0FF" : p.TierName === "silver" ? "#D4D4D4" : p.TierName === "gold" ? "#BD8A13" : "#A9C6D4")}!important;
        box-shadow: ${(p) => (p.TierName === "blue" ? "0px 0px 7px rgb(96 179 255 / 56%)" : p.TierName === "silver" ? "0px 0px 7px rgba(212, 212, 212, 0.71)" : p.TierName === "gold" ? "0px 0px 7px rgba(189, 138, 19, 0.57)" : "0px 0px 7px rgba(169, 198, 212, 1)")};
    }

    ${ProgressAmountInnerWrap} {
        display: ${(p) => (p.TierName === "blue" ? "flex" : p.TierName === "silver" ? "flex" : p.TierName === "gold" ? "flex" : "none")};
    }
`;

const AutofillText = styled.div<Pick<AccountInfoTierComponentProps, "TierName">>`
    display: flex;
    flex-direction: column;
    color: ${theme('palette.text-light')};
    color: ${(p) => (p.TierName === "blue" ? "#fff" : p.TierName === "silver" ? "#D4D4D4" : p.TierName === "gold" ? "#000" : p.TierName === "platinum" ? "#BD8A13" : "#262626")};
    gap: 0.3rem;
    cursor: auto;

    &:first-child {
        width: 100%;
        text-transform: capitalize;
    }

    label {
        font-size: 12px;

        @media only screen and (max-width: 1600px){
            font-size: 11px;
        }
    }

    p {
        font-size: 14px;
        margin: 0;
        overflow: hidden;

        @media only screen and (max-width: 1600px){
            font-size: 12px;
        }
    }
`;

const TurnOver = styled.div`
    font-size: 14px;
    padding-left: 5px;
    font-weight: bold;
    color: ${theme('palette.text-light')};
`;

const CurrentTurnOver = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    color: ${theme('palette.CurrentTurnOver')};
    font-size: 12px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
`;

const TierCard = styled.a`
    width: 400px;
    height: 260px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    column-gap: 1rem;
    background-color: transparent;
    border: none;
    background: ${(props) => `url(${config.imageV3Host}/static/cashsite/${props.type})`}; //props.type
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: 2rem;
    
    @media only screen and (min-width: 1700px){
        width: 500px;
        height: 300px;
        gap: 1rem;
        column-gap: 3rem;
        overflow:hidden;
    }
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
`;