import React, { useEffect, useState } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import { withTheme } from 'styled-components';
import seoData from "./seoData";
import { Helmet } from "react-helmet";


type Props = {
    app: any;
    brandId: any;
    pageName: string;
}

type MetaTypes = {
    title: string;
    description: string;
    canonical: string;
    alternate?:string;
    keywords: string;
}

const SEOContents = (props: Props) => {
const [currContent, setCurrContent] = useState( {} as MetaTypes );
const _path = window.location.pathname !== '/';


// fetching the brandId 
const { app: { brandId } } = props;
// fetching the page name as props 
    const { pageName } = props;
   

//fetching details from the json according to brandId and page name
useEffect(() => {
    let _seoObject:MetaTypes;

    if (brandId && pageName) {
        _seoObject = seoData[brandId][pageName]||{}; 
    } else {
        _seoObject = seoData[brandId].default;
    }

    setCurrContent(_seoObject)

    //remove existing 
    if(_seoObject &&_seoObject?.title){ updateSEOMeta();}

}, [pageName, brandId]) //[pageName, brandId]

    const updateSEOMeta = () => {
        if (typeof document === 'object') {
            let _title = document.querySelector('title');
            let _description = document.querySelector('[name="description"]');
            let _keywords = document.querySelector('[name="keywords"]');
            if(_title){ _title.parentElement.removeChild(_title);}
            if(_description) { _description.parentElement.removeChild(_description);}
            if(_keywords) { _keywords.parentElement.removeChild(_keywords);}  
        }
    }

  return (
    <Helmet>
        <meta charSet="utf-8" />
        {currContent && currContent.title && <title>{currContent.title}</title>}
        {currContent && currContent.description && <meta name="description" content={currContent.description} />}
        {/* {currContent && currContent.keywords && <meta name="keywords" content={currContent.keywords}></meta>} */}
        {currContent && currContent.canonical && <link rel="canonical" href={currContent.canonical} />}
        
        {/* <link rel="alternate" href={currContent.canonical} hrefLang="en-IN" /> */}
        {currContent && currContent.alternate && <link rel="alternate" media="only screen and (max-width:640px)" href={currContent.alternate} />}
        {/* {currContent && currContent.canonical && _path && <link rel="canonical" href={`${currContent.canonical}/`} />} */}
    </Helmet>
)
}

export default withTheme(withAppContext(SEOContents));