import cookieUtil from "./cookieUtil";
import { ECookieName } from "./utilModel";

const reportEvent = (eventName, params, callback = null) => {
    if (typeof window != 'undefined' && typeof window.optimoveSDK != 'undefined') {
        try {
            const memberId = cookieUtil.get(ECookieName.COOKIE_MEMBERID);
            const SDK_ID = memberId ? memberId : null;
            // const SDK_ID = getInitialVisitorID();
            //Adding additional variables to add additional parameters in the future
            const parameters = { ...params }
            if (parameters.hasOwnProperty('memberid')) {
                parameters['memberid'] = Number(parameters['memberid']);
            }
            window.optimoveSDK.API.reportEvent(eventName, parameters, callback, SDK_ID)
        } catch (error) {
            console.log('Error in sending event', error);
        }
    }
}

const getChannel = () => sessionStorage.getItem("sessionMarketSource") || "0";
const getMemberId = () => cookieUtil.get(ECookieName.COOKIE_MEMBERID) || 0;

export const getInitialVisitorID = () => window.optimoveSDK && window.optimoveSDK.API.getInitialVisitorID();

export function CRMPageVisit(pageURL = '', pageTitle = 'Online Betting in India for Sports & Casino', pageCategory = '') {
    if (typeof window != 'undefined' && typeof window.optimoveSDK != 'undefined') {

        setTimeout(() => {
            pageURL = pageURL || window.location.href;
            pageTitle = pageTitle || document.title;
            pageCategory = pageCategory || getChannel();
            const memberId = cookieUtil.get(ECookieName.COOKIE_MEMBERID);
            const SDK_ID = memberId ? memberId : null;
            // const SDK_ID = getInitialVisitorID();
            if(!pageTitle) {
                pageTitle = 'Online Betting in India for Sports & Casino';
            }

            window.optimoveSDK.API.setPageVisit(pageURL, pageTitle, pageCategory, SDK_ID);
        }, 10);
    }
}

//Signup
export function CRMSignupBtnClick(brandId) {
    const parameters = {
        channel: getChannel(),
        brandid:brandId
    }

    reportEvent('click_signup_button', parameters);
}

export function CRMOTPBtnClick(mobe, brand, userId) {
    const parameters = {
        channel: getChannel(),
        brandid: brand,
        mobile: mobe,
        username: userId
    }

    reportEvent('signup_otp', parameters);
}

export function CRMRegistrationSuccess(brandId, username, memberId) {
    const parameters = {
        channel: getChannel(),
        brandid: brandId,
        username,
        memberid: memberId || getMemberId()
    }

    reportEvent('registration_done', parameters);
}

//Login 
export function CRMLoginBtnClick(brandId) {
    const parameters = {
        channel: getChannel(),
        brandid:brandId
    }

    reportEvent('click_login_button', parameters);
}

export function CRMLoginSuccess(brandId, username, memberId) {
    const parameters = {
        channel: getChannel(),
        brandid: brandId,
        username,
        memberid: memberId || getMemberId()
    }

    reportEvent('login_done', parameters);
}

//LiveTV
export function CRMLiveTVClick(brandId, memberId, matchId = '') {
    const parameters = {
        channel: getChannel(),
        brandid: brandId,
        memberid: memberId || getMemberId(),
        matchid:matchId
    }

    reportEvent('livetv_click', parameters);
}

//whatsapp button click
export function CRMWhatsappClick(brandId) {
    const parameters = {
        channel: getChannel(),
        brandid: brandId
    }

    reportEvent('whatsapp_click', parameters);
}

//download app
export function CRMDownloadAppHandler(brandId, memberId) {
    const parameters = {
        channel: getChannel(),
        brandid: brandId,
        memberid: memberId || getMemberId()
    }
    reportEvent('header_download_app_click', parameters);
}

//Social media 
export function CRMClickSMIcon(brandId, memberId, mediaName) {
    const parameters = {
        channel: getChannel(),
        brandid: brandId,
        memberid: memberId || getMemberId(),
        media: mediaName
    }
    reportEvent('social_media_click', parameters);
}

//Header menubar click
export function CRMHeaderMenuClick(menu, brandId, url) {
    const events = {
        "casino": "menu_casino_click",
        "instant_games": "menu_instant_games_click",
        "hotgames": "menu_hot_games_click",
        "Sports": "menu_sports_click",
        "promotions": "menu_bonus_click",
    }

    const parameters = {
        channel: getChannel(),
        brandid: brandId,
        memberid: getMemberId(),
        gameurl: url
    }

    reportEvent(`${events[menu]}`, parameters);
}

//for Deposit
export function CRMDepositHeaderClick(brandId) {
    const parameters = {
        channel: getChannel(),
        brandid: brandId,
        memberid: getMemberId()
    }

    reportEvent('deposite_header_click', parameters);
}

export function CRMProfileMenuClick(brandId, menuLabel = '') {
    //currently CRM event is required for Deposit & Withdrawal only
    const eventName = menuLabel === 'SIDEBAR@Deposit' ? 'deposite_menu_click' : (menuLabel === 'SIDEBAR@Withdrawal' ? 'withdrawal_menu_click' : '');

    if (eventName) {
        const parameters = {
            channel: getChannel(),
            brandid: brandId,
            memberid: getMemberId()
        }

        reportEvent(eventName, parameters);
    }
}

export function CRMDepPaymentMethodClick(brandId, methodId) {
    const events = {
        101: 'deposite_fastbank_click',
        102: 'deposite_fastupi_click',
        2: 'deposite_regularupi_click',
        1: 'deposit_netbank_click',
    }
    if (methodId && events.hasOwnProperty(methodId)) {
        const parameters = {
            channel: getChannel(),
            brandid: brandId,
            memberid: getMemberId(),
            methodid: methodId
        }

        reportEvent(`${events[methodId]}`, parameters);
    }
}

export function CRMDepositChannelClick(brandId, methodId, amount, paymentGatewayId) {
    const parameters = {
        channel: getChannel(),
        brandid: brandId,
        memberid: getMemberId(),
        methodid: methodId,
        amount,
        paymentgatewayid:paymentGatewayId
    }

    reportEvent('deposite_channel_click', parameters);
}

export function CRMDepositSubmitClick(brandId, methodId, amount, paymentGatewayId) {
    const parameters = {
        channel: getChannel(),
        brandid: brandId,
        memberid: getMemberId(),
        methodid: methodId,
        amount,
        paymentgatewayid:paymentGatewayId
    }

    reportEvent('deposite_submit_click', parameters);
}

export function CRMWithdrawalBtnClick(brandId, eventLabel) {
    const parameters = {
        channel: getChannel(),
        brandid: brandId,
        memberid: getMemberId()
    }

    reportEvent(`withdrawal_${eventLabel}`, parameters);
}

export function CRMBetDetailClick(brandId) {
    const parameters = {
        channel: getChannel(),
        brandid: brandId,
        memberid: getMemberId()
    }

    reportEvent('betdetail_menu_click', parameters);
}

export function CRMNotificationIconClick(brandId) {
    const parameters = {
        channel: getChannel(),
        brandid: brandId,
        memberid: getMemberId()
    }

    reportEvent('notification_bell_icon_click', parameters);
}

export function CRMGameProviderClick(brandId, providerInfo) {
    const { id, icon } = providerInfo || {}
    const parameters = {
        channel: getChannel(),
        brandid: brandId,
        memberid: getMemberId(),
        providerindex: id,
        providericonURL: icon,
        pageurl: window.location.pathname
    }

    reportEvent('game_provider_clicks', parameters);
}
// Bew Event
// Desktop Banner Click
export function CRMDesktopBannerClick(brandId,url) {
    const parameters = {
        channel: getChannel(),
        brandid: brandId,
        memberid: getMemberId(),
        bannerurl:url
    }

    reportEvent('desktop_homepage_banner_click', parameters);
}

export function CRMDesktopMiniBannerClick(brandId,url) {
    const parameters = {
        channel: getChannel(),
        brandid: brandId,
        memberid: getMemberId(),
        bannerurl:url
    }

    reportEvent('desktop_homepage_mini_banner_click', parameters);
}