import React, { useEffect } from 'react'
import PlaceholderImage from 'src/components/Placeholder';
import { MainInfoWrapper } from 'src/components/udb/commonStyle';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Intl from 'src/components/common/Intl';
import FooterContainer from "src/components/footer/footer";
import { FifaFeverFirstSecHead, PlayNowButtonNew2, Section } from './crazyCrashPromo';
import { useNavigate } from 'react-router-dom';


function FunkyTime(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigate = useNavigate();

    return (
        <MainInfoWrapper>
            <BannerWrapper>
                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.FunkyPromoBanner}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
                <PlayNowBtn onClick={() => navigate("/online-casino-games/funky-time")} className='play'>Play Now</PlayNowBtn>
            </BannerWrapper>
            <ContentWrapper>
                <NoBorderTop>
                    <p> Prepare yourself for an electrifying experience with Evolution's mind-boggling BET & WIN promotion! Get ready to be blown away by an astonishing prize pool of  <StepTittle>INR 64 lakhs</StepTittle> among  <StepTittle>8000 winners</StepTittle> that awaits your arrival! Brace yourself for an adrenaline-fueled adventure as you compete for the chance to win an incredible weekly pool prize of <StepTittle>INR 1,600,000.</StepTittle> </p>
                    <p>Step into the thrilling realm of Evolution's newly launched game, Funky Time, and unlock your path to victory! Don't miss out on this opportunity to win big!</p>
                    <p>But wait, there's more! Participating is as effortless as 1-2-3:</p>
                </NoBorderTop>
                <FifaFeverFirstSecHead1 className="funkytime">How to participate?</FifaFeverFirstSecHead1>
                <Section>
                    <ImageWrapper>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.funkywheel}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                    </ImageWrapper>
                    <ProcessColumn>
                        <li>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.RegisterIconfunkytime}`} effect="none" placeholder={<PlaceholderImage />} />
                            <LiTextContent>
                                <StepTittle>REGISTER IF YOU HAVE NOT</StepTittle>
                                <TextContent>Embark on an exhilarating journey by creating your very own account. Sign up using your name and mobile number, and get ready to embark on an unforgettable gaming experience like no other.</TextContent>
                            </LiTextContent>
                        </li>
                        <li>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.GameIconfunkytime}`} effect="none" placeholder={<PlaceholderImage />} />
                            <LiTextContent>
                                <StepTittle>DEPOSIT & UNLEASH</StepTittle>
                                <TextContent>Choose your preferred deposit method and dive headfirst into the immersive world of Evolution's Funky Time. Let the thrill and excitement power your every move as you chase after victory and fortune!</TextContent>
                            </LiTextContent>
                        </li>
                        <li>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.wheelIconfunkytime}`} effect="none" placeholder={<PlaceholderImage />} />
                            <LiTextContent>
                                <StepTittle>WIN BIG!</StepTittle>
                                <TextContent>With every game you play, you're one step closer to seizing greatness. Showcase your skills, dominate the leaderboard, and claim your share of the staggering prize pool.</TextContent>
                            </LiTextContent>
                        </li>
                    </ProcessColumn>
                </Section>
                <NoBorderTop>
                    <p> Don't let this sensational opportunity slip through your grasp. Be a part of Evolution's BET & WIN promotion and unleash your inner champion. It's time to leave your mark on the gaming world!</p>
                    <p><StepTittle>✪</StepTittle> The winners are based on the players who wager in Evolution Funky time game during the promotion from <StepTittle>
                        21st June – 18th July 2023</StepTittle></p>
                    <p>
                        <StepTittle>✪</StepTittle>  Winners will be determined on a weekly basis from the <StepTittle>21st June to 18th July 2023</StepTittle></p>
                    <p>
                        <StepTittle>✪</StepTittle>  Winners will be determined on a weekly basis from the <StepTittle>21st June to 18th July 2023</StepTittle></p>
                    <p>
                        <StepTittle>✪</StepTittle>  This promotion is only applicable for <StepTittle>Evolution Funky Time game only</StepTittle> </p>
                    <p><StepTittle>✪</StepTittle> There would be <StepTittle>2,000 winners</StepTittle> each week who can win from a pool prize of <StepTittle>INR 1,600,000</StepTittle></p>
                    <p><StepTittle>✪</StepTittle>  In total, <StepTittle>8,000 winners</StepTittle> stand a chance to win from a pool prize of <StepTittle>INR 64 lacs</StepTittle></p>
                </NoBorderTop>

                <JackPotTable>
                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.funkytable1}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
                </JackPotTable>
                <FifaFeverFirstSecHead1 className="funkyprize">Prize Allocation</FifaFeverFirstSecHead1>
                <JackPotTable>
                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.funkytable2}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
                </JackPotTable>
                <FifaFeverFirstSecHead1 className="funkyTC">Terms & Conditions</FifaFeverFirstSecHead1>
                <TermsConditionPoints>

                    <ul>
                        <li><TextContent><Intl langKey="Funkytime@GeneralTermsConditionFirst" /></TextContent></li>
                        <li><TextContent><Intl langKey="Funkytime@GeneralTermsConditionSecond" /></TextContent></li>
                        <li><TextContent><Intl langKey="Funkytime@GeneralTermsConditionThird" /></TextContent></li>
                        <li><TextContent><Intl langKey="Funkytime@GeneralTermsConditionFourth" /></TextContent></li>
                        <li><TextContent><Intl langKey="Funkytime@GeneralTermsConditionFifth" /></TextContent></li>
                        <li><TextContent><Intl langKey="Funkytime@GeneralTermsConditionSixth" /></TextContent></li>
                        <li><TextContent><Intl langKey="Funkytime@GeneralTermsConditionSeventh" /></TextContent></li>
                    </ul>
                </TermsConditionPoints>
                <PlayNowBtn onClick={() => navigate("/online-casino-games/funky-time")}>Play Now</PlayNowBtn>
            </ContentWrapper>
            <FooterContainer />
        </MainInfoWrapper>
    )
}

export default withTheme(withAppContext(FunkyTime));

export const PlayNowBtn = styled.button`
 background: linear-gradient(183.17deg, #B21764 0%, #DE117C 50.52%, #C60067 100%), #EF4123;
box-shadow: 0px 0px 32.8716px rgba(255, 0, 133, 0.6);
border-radius: 33.3444px;
    width: auto;
    height: auto;
    padding: 10px 15px;
    color: #ffffff;
    outline: none;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 2rem;
    z-index: 99;
    margin-top: 3rem;
    cursor: pointer;

    &:hover {
        background: #B21764;
    }
`
const FifaFeverFirstSecHead1 = styled(FifaFeverFirstSecHead)`
text-transform: uppercase;
font-size: 30px;
`
const LiTextContent = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;

    p {
        margin: 0;
        margin-top: 10px;
    }

    span {
        text-transform: uppercase;
        font-size: 18px;
    }
`;

const NoBorderTop = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0rem 17rem 2.5rem 17rem;
    align-items: flex-start;

    p {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        color: ${theme('palette.catch99TextContentColor')};
        font-size: 21px;
        line-height: 30px;
        gap: 5px;
        text-align: justify;
        margin: 13px 0;
    }
    span{
        font-size: 21px;
    }
`;

export const TermsConditionPoints = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 2rem;
    /* border: ${theme('palette.AviatorjackPotWrapperBgBorder')}; */
    border-radius: 16px;
    padding: 3rem;
    /* background: ${theme('palette.AviatorTermsConditionPointsBg')}; */

    p {
        padding-left: 1rem;
        text-transform: capitalize;
    }

    ul {
        list-style: number;
        color: #fff;
        padding-left: 35px;
        margin-top: 0;

        li {
            p{
                display: flex;
                gap: 5px;
                padding-left: 0rem;
                margin: 0 0 10px 0;
            }
        }
    }
`;

const JackPotTable = styled.div`
    width: 70%;
    display: flex;
padding-bottom: 3rem;
    img {
        max-width: 100%;
    }
`;

const ImageWrapper = styled.div`
     width: 35%;
    display: flex;
    padding-top: 47px;

    img {
        max-width: 100%;
    }
`;

const TextContent = styled.p`
    display: flex;
    color: ${theme('palette.catch99TextContentColor')};
    font-size: 17px;
`;

const StepTittle = styled.span`
    display: flex;
    color: #DD127B;
    font-size: 17px;
    font-family: 'Inter-Bold';
    display: contents;
`;

const ProcessColumn = styled.ul`
    width: 50%;
    padding: 2rem;
    position: relative;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    li {
        border:2px solid #DD127B;
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 1.5rem;
        padding: 1.5rem;
        border-radius: 8.09632px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.005) 100%);
        mix-blend-mode: normal;
        backdrop-filter: blur(42.6023px);
    }

    img {
        width: 98px;
        height: 98px;
    }
 `;

export const NewContent = styled.div`
     width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-position: 100% 5%;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 4rem 0;  
`
export const ContentWrapper = styled(NewContent)`
    background: url(${config.imageV3Host}/static/cashsite/${theme('image.funkyContentWrapperBg')});

`;
export const BannerWrapper = styled.div`
    width: 100%;
    display: flex;
    transition: 700ms;
    flex-direction: column;
    position: relative;
    img {
        max-width: 100%;
        transition: 700ms;
    }
    .play{
     position: absolute;
    width: 14%;
    align-items: center;
    bottom: 14%;
    left: 21%;
    font-style: normal;
font-weight: 700;
font-size: 22.2296px;
line-height: 27px;
display: flex;
align-items: center;
letter-spacing: 0.01em;
text-transform: uppercase;
justify-content: center;
color: #FFFFFF;
    }
`;  