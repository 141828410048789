import React, { useEffect, useState } from 'react';
// import AccountStatementTable from '../../Statements/account-statement';
import AccountStatementTable from './AccountStatementTable'
import styled, { withTheme } from 'styled-components';
import { withAppContext } from "src/store/initAppContext";
import { color } from 'src/sass-files/variable';
import Intl from '../../../components/common/Intl';
import { ifProp, theme, withProp } from 'styled-tools';
// Styled components
import { ITransactionType } from '../../../graphql/schema';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AccountStatementTittle from 'src/images/account_statement.png';
import DatePickerIcon from 'src/images/calendar.png';
import { FirstCol, Header, MainInfoWrapper as AccountStatmntMainInfoWrapper, SecondCol, SvgIcon, TableBodyWrapper } from 'src/components/udb/commonStyle';
import UDBHeader from 'src/components/UDBHeader';
import SEOContents from 'src/components/SEOContents';
//import { DepositeTypeWrapper } from './History';
// import CustomRadioButton, { Container } from 'src/components/udb/kyc/CustomRadioButton';
// import DatePickerUdb from 'src/components/udb/DatePickerUdb';
// import DatePicker from "react-datepicker";
// import Bg from '../../images/iMAGE1_iexch.png';
// import config from 'src/config/config';
// import TypePicker from 'src/components/member/TypePicker';
// import DateSelector from "../Statements/account-statement";
// import { useQuery } from '@apollo/client';
// import { GET_ACCOUNT_STATEMENT } from 'src/graphql/member';
//import util from 'src/util/util';

interface IState {
  startAt: Date;
  endAt: Date;
  type: ITransactionType;
}

const { secondarycolor } = color;

// let totalProfit = +232.33;
// let totalLoss = -32.33;

function AccountStatementPage(props) {
  const { app: {
    brandId
  }, theme } = props
  useEffect(() => {
    window.scrollTo(0, 0)
  })

  const [startAt, setStartAt] = useState(new Date());
  const [endAt, setEndAt] = useState(new Date());
  const [type, setType] = useState(ITransactionType.Betting);
  const [selectedInd, setSelectedInd] = useState(0)

  const handleDayChange = (from: Date, to: Date) => {
    setStartAt(from)
    setEndAt(to)
  };

  const handleTypeChange = (index: number) => {
    const types = [ITransactionType.Betting, ITransactionType.Settling, ITransactionType.All];
    const type = types[index];
    if (type) {
      setType(type);
    }
  };

  // const { data, loading, error } = useQuery(GET_ACCOUNT_STATEMENT, {
  //   variables: {
  //     input: { type, period: { start: new Date(util.fixedFrom(startAt).getTime()), end: new Date(util.fixedFrom(endAt).getTime()) } },
  //   }
  // }
  // );

  const date = new Date();
  const minDate = date.setDate((new Date()).getDate() - 30);


  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  }

  const handlefromDateChange = (startAt: Date | null) => {
    setStartAt(startAt)

  }

  const handletoDateChange = (endAt: Date | null) => {
    setEndAt(endAt)
  }

  return (
    <MainInfoWrapper>
      <SEOContents pageName="memberAccountStatement" />
      <Header>
        <FirstCol>
          <UDBHeader title="SIDEBAR@ACCOUNTSTATEMENT" />
        </FirstCol>

      </Header>
      {/* <Container> */}
      <FilterSection>
        <TypesTab>
          <Tabs defaultIndex={selectedInd} onSelect={handleTypeChange}>
            <TabList>
              <Tab><Intl langKey="ACCOUNT_STATEMENT@BETTING_P&L" /></Tab>
              <Tab><Intl langKey="ACCOUNT_STATEMENT@SETTLING" /></Tab>
              <Tab><Intl langKey="ACCOUNT_STATEMENT@ALL" /></Tab>
            </TabList>
          </Tabs>
        </TypesTab>
      </FilterSection>

      <AccountStatementTable type={type} startAt={startAt} endAt={endAt} />

      {/* <FilterWrapper>
              {
              trying(sportIds, gameIds, sportTransactionGroup, gameTransactionGroup)
              }
          </FilterWrapper>
          {content} */}
      {/* </Container> */}
    </MainInfoWrapper>
  );
}

export default withTheme(withAppContext(AccountStatementPage));

//Styled Components
const MainInfoWrapper = styled(AccountStatmntMainInfoWrapper)`
  position: relative;
`;

const TittleIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-image: url(${AccountStatementTittle});
  background-size: auto;
  background-repeat: no-repeat;
  margin-right: 5px;
`;

const Tittle: any = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props: any) => (props.theme === 'Dark' ? props.customStyle['new_whitelable_dark_font_color'] : props.customStyle['secondary-background-color-new'])};  // color: ${theme('palette.secondary-background-color-new')};
  
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
  padding-bottom: 10px;
`;

const AccountContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const HeaderBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 10px;
`;

const AccountStatementWrapper: any = styled.div`
  width: 100%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 20px;
  background-size: cover;
  background-position: 100%;
`;

const StatementWrapper = styled.div`
  width: 100%;
  display: flex;
  
`;

const FilterSection = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	position:relative;
	
	font-weight: bold;
`;

const PageTitleName: any = styled.div`
	display:block;
`;

const TypesTab = styled.div`


    .react-tabs {
        width: 100%;
	}
	
	.react-tabs__tab-list {
		border-bottom: 1px solid #aaa;
		margin: 0 0 10px;
		padding: 0;
		display: flex;
		align-items: center;
		flex-direction: row;
	}

    .react-tabs__tab {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 13px 15px;
      margin-right: 1em;
      text-transform: capitalize;
      color: rgba(0,0,0,0.8);
      font-size: 14px;
      position: static;
      font-weight: normal;
      background: rgba(255,255,255,0.8);
      border: 1px solid #423FC1;
      /* box-shadow: 0px 0px 40px rgb(16 11 73 / 40%); */
      border-radius: 3px;
    }

    .react-tabs__tab--selected {
      border-radius: 3px;
      background: ${theme('palette.secondary-background-color-linear-new1')};
      border: 1px solid #FFFFFF;
      box-shadow: 0px 0px 40px rgb(16 11 73 / 40%);
      color: #fff;
      &:hover {
        background: ${theme('palette.secondary-background-color-linear-new1')};
        border: 1px solid #FFFFFF;
        /* box-shadow: 0px 0px 40px rgb(16 11 73 / 40%); */
        color: #fff;
      }
	}
	
	.react-tabs__tab-list {
		border-bottom: none;
	}
`;

const DatePickerWrapper = styled.div`
//   width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const CalenderIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-image: url(${DatePickerIcon});
  background-size: auto;
  background-repeat: no-repeat;
  margin-right: 10px;
`;

const DateSelector = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position:relative;
  
  font-weight: bold;
`;

export const SearchButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(180deg, #D0B99F 0%, #DFC7A3 100%);
  border-radius: 5px;
  padding: 15px 30px;
  color: ${secondarycolor};
  border: none;

  :hover {
    background: linear-gradient(180deg, #DFC7A3 0%, #D0B99F 100%);
  }
`;

const BlockPick = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #E9F1F8;
  border-radius: 5px;
  padding: 0px 10px;
  margin-right: 10px;
  text-transform: capitalize;
  color: #203974;
  font-size: 12px;

  input {
    border: none;
    background: none;
    display: flex;
    height: 47px;
    text-align: center;
  }
`;