import React, { useEffect } from 'react'
import PlaceholderImage from 'src/components/Placeholder';
import { MainInfoWrapper } from 'src/components/udb/commonStyle';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import cookieUtil from 'src/util/cookieUtil';
import { ECookieName } from 'src/util/utilModel';
import Intl from 'src/components/common/Intl';
import FooterContainer from "src/components/footer/footer";


const Language = cookieUtil.get(ECookieName.COOKIE_LANG)

function BblBonusInner(props) {
  const { app: {
    brandId
  }, theme } = props;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const domain = brandId===31|| brandId===32 ? 'Yolo247':'Baazi Adda';


  return (
    <MainInfoWrapper1>
      <h1>BIG CASH THIS <span>BIG BASH !!!</span></h1>
      <h3>
        Valid for both Casino & Sports players
      </h3>

      <FifaFeverContentWrapper>

        <BBLFIrstSecWrapper>
          <BBLFIrstSecLeft>
            <h5>Get ready to cash in big this Big Bash season with {domain}!</h5>
            <p>Embark on an exhilarating cricket odyssey during the sizzling Big Bash League (BBL)! Elevate your betting escapades with our exclusive offer, meticulously crafted to infuse the pure joy of the game into every wager. This is your chance to hit it big.Don't miss out on the action-packed betting experience! 🚀🔥</p>
            <h5>Daily Delights, Weekly Wonders</h5>
            <p>Get ready for heart-thumping moments in the BBL season as {domain} presents a dazzling promotion. Amp up your excitement with daily deposits and seize a turbocharged weekly double cashback of up to 10%! Immerse yourself in the thrill, and let each match be a celebration of your betting finesse.</p>

          </BBLFIrstSecLeft>

          <BBLFirstSecRIght>
            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.BBLCricketBatting}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />``
          </BBLFirstSecRIght>
        </BBLFIrstSecWrapper>


        <FifaFeverFirstSec>
          <FifaFeverFirstSecHead>How it’s Woven into <span>BBL Glory?</span> </FifaFeverFirstSecHead>
          <Section>
            <BBLFIrstSecLeft className='ndlist'>
              <h6>Knock Daily Wagers Out of the Park</h6>
              <p>Just like BBL players knocking boundaries, make a daily deposit from Monday to Sunday. Keep the thrill alive throughout the week, echoing the excitement of the BBL that keeps fans enchanted.</p>
              <h6>Sustain Your Betting Bliss</h6>
              <p>Keep up your betting streak for all seven days, mirroring the constancy of BBL players throughout the tournament. This streak is your passport to unlocking a phenomenal additional cashback bonus.</p>
              <h6>Celebrate Like a Betting Maestro</h6>
              <p>Successfully deposit every day of the week, and you qualify for the additional cashback bonus – your victorious moment in the enchanting realm of BBL betting.</p>
              <h6>Magic from Losses</h6>
              <p>At the end of each week, transform your losses into magic with our sensational cashback bonus. Avail up to an incredible 10% cashback on your net losses incurred throughout the week – a game-changing move in the delightful world of online betting.</p>
              <h6>Monday's Joyous Gift</h6>
              <p>Begin your week with a joyous gift! The cashback bonus amount will be promptly showered into your account every Monday, presenting a remarkable opportunity to elevate your earnings and dive back into the BBL festivities with renewed enthusiasm.</p>

            </BBLFIrstSecLeft>

          </Section>
        </FifaFeverFirstSec>

        <BBLBonusContentWrapper>
          <p className='note1'>Don't let this chance slip away to make this BBL season a symphony of joy.
            Join {domain} now and revel in the delight of cricket betting at its most exquisite!</p>
          <OfferSectionWrapper>
            <OffersSection>
              <NewOfferCardsWrapper>
                <p>Daily Deposit Bonus Scoreboard for Blue, Silver, Gold & Platinum Tiers</p>
                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.offerbbl}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
              </NewOfferCardsWrapper>
            </OffersSection>
          </OfferSectionWrapper>

          <TermsConditionWrapper>
            <TermsConditionPoints>
              <FifaFeverFirstSecHead className='terms'>TERMS & CONDITIONS:</FifaFeverFirstSecHead>
              <ul>
                <li><TextContent>This promotion is valid for all new & existing <StepTittle>{domain}</StepTittle> members.</TextContent></li>
                <li><TextContent>his bonus amount will be capped at <StepTittle>INR 7,000.</StepTittle></TextContent></li>
                <li><TextContent>Bonus amount will be issued into your Main wallet as <StepTittle>cashback bonus</StepTittle> every Monday only
                  if you have successfully deposited all 7 days in a week<StepTittle>(Mon-Sun).</StepTittle>
                </TextContent></li>
                <li><TextContent>Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus
                  rollover requirements.
                </TextContent></li>
                <li><TextContent>Multiple accounts will not be considered for this promotion eligibility / criteria. All players
                  will be subject for duplicate checking. {domain}
                  reserves the right to disqualify players if
                  collusion or any foul play takes place in the promotion.
                </TextContent></li>
                <li><TextContent>This promotion cannot be used in conjunction with, or to contribute to the eligibility for,
                  any other promotion.
                </TextContent></li>
                <li><TextContent>{domain} reserves the right to amend, cancel and reclaim or refuse any promotion at its
                  own discretions.</TextContent></li>
                <li><TextContent>In the event, any fraudulent activity of the player(s) has been identified, {domain} reserves
                  the right to withhold your funds and reject your withdrawals.</TextContent></li>
                <li><TextContent>{domain} General Terms & Conditions apply</TextContent></li>
              </ul>
            </TermsConditionPoints>
          </TermsConditionWrapper>

        </BBLBonusContentWrapper>
      </FifaFeverContentWrapper>
      <FooterContainer />
    </MainInfoWrapper1>
  )
}

export default withTheme(withAppContext(BblBonusInner));

//Styled Components
const MainInfoWrapper1 = styled(MainInfoWrapper)`
h1{
  color: #FFF;
text-align: center;
text-shadow: 0px 6.566504955291748px 6.566504955291748px rgba(0, 0, 0, 0.25);
font-size: 47.825px;
font-style: normal;
font-weight: 800;
margin: 0 auto;
padding-top: 2rem;
}
span{
  color: #E6F01E;
font-family: Inter;
font-size: 47.825px;
font-style: normal;
font-weight: 800;
}
h3{
  color: #E6F01E;
text-align: justify;
font-size: 31.83px;
font-style: normal;
font-weight: 300;
line-height: 128.5%;
text-transform: uppercase;
margin: 0 auto;
}
`
const OffersSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &::after {
      content: "";
      width: 175px;
      height: 330px; 
      background: url(${config.imageV3Host}/static/cashsite/${theme('image.BBLFireBallRight')});
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      top: 23em;
      bottom: 20em;
    }
`;

export const StepImg = styled.img`
  width: 92px;
  height: 87px;
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

export const ProcessColumn = styled.ul`
    width: 40%;
    padding: 2rem;
    position: relative;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    li {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 1rem;
        padding: 1.5rem;
        border-radius: 8.09632px;
        background: ${theme('palette.FifaFeverProcessColumnLiBg')};
        border: ${theme('palette.FifaFeverProcessColumnLiBorder')};
        backdrop-filter: blur(40.3956px);
        position: relative;
    }

    img {
        width: 58px;
        height: 58px;
        object-fit: contain;
    }
`;

export const LiTextContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    p {
        margin: 0;
        margin-top: 10px;
    }

    span {
        width: 82%;
        text-transform: uppercase;
        font-size: 18px;
    }
`;

export const ImageWrapper = styled.div`
    width: 40%;
    display: flex;
    padding-top: 47px;

    img {
        max-width: 100%;
    }

    &::before {
      content: "";
      width: 250px;
      height: 350px;
      background: url(${config.imageV3Host}/static/cashsite/${theme('image.BBLLeftPaintSplash')});
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      left:0px;
    }

`;

export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem 4rem;
  .ndlist{
width: 39%;
  }
  p{   
font-family: 'Inter';
font-style: italic;
font-weight: 400;
font-size: 19px;
line-height: 23px;
text-align: center;
color: #FFFFFF;

    }
    
      ::before{
    content: "";
    width: 527px;
    height: 455px;
      display: flex;
      position: absolute;
      left: -150px;
      background: url(${config.imageV3Host}/static/cashsite/${theme('image.BBLBWBatting')});
      background-position: bottom;
      background-size: contain;
      background-repeat: no-repeat;
   }

   ::after{
    content: "";
    width: 477px;
    height: 643px;
      display: flex;
      position: absolute;
      right: 0;
      background: url(${config.imageV3Host}/static/cashsite/${theme('image.BBLBWcatch')});
      background-position: bottom;
      background-size: contain;
      background-repeat: no-repeat;
   }
  
`;

export const FifaFeverContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: ${theme('palette.fifafeverTextWhiteColor')};
  padding-top: 1rem;
  position: relative;

  .marginZero {
    margin-top: 0;
  }

`;
export const BannerWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 22px;
  transition: 700ms;


  img {
      max-width: 100%;
      transition: 700ms;
  }
`;

export const FifaFeverFirstSec = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

`;

export const FifaFeverFirstSecHead = styled.h1`
    width: 252px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 30px;
    line-height: 40px;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Inter-Bold';
/* padding-bottom:2rem; */
/* margin-top: 6rem; */

    & span {
        width: 252px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-size: 30px;
        line-height: 40px;
        margin: 0px 0px;
        text-transform: capitalize;
        text-align: center;
        font-family: 'Inter-Bold';
        color: ${theme('palette.BBLTextSecondary')};
    }

    /* &::after {
      content: "";
      width: 190px;
      height: 10px;
      background: url(${config.imageV3Host}/static/cashsite/${theme('image.TittleLineImg')});
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    } */
`;

export const TermsConditionPoints = styled.div`
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 2rem;
    border: ${theme('palette.BBLTermsBorder')};
    border-radius: 11px;
    padding: 3rem;
    background: ${theme('palette.BBLCardsBg')};
    mix-blend-mode: normal;
    backdrop-filter: blur(37.4365px);
    padding-top: 16rem 0 6rem 0;
    padding-right: 9rem;
    position: relative;
    span{
      color: #E6F01E;
      font-size: 17px;
    }

    &::after {
      content: "";
      width: 300px;
      height: 140px;
      display: flex;
      position: absolute;
      right: 3rem;
      top: 0;
      background: url(${config.imageV3Host}/static/cashsite/${theme('image.BBLTCText')});
      background-position: bottom;
      background-size: contain;
      background-repeat: no-repeat;
    }

    p {
        padding-left: 1rem;
        text-transform: capitalize;
    }

    ul {
        list-style: number;
        color: ${theme('palette.BBLTextPrimary')};
        padding-left: 35px;
        margin-top: 0;

        li {
            p{
                display: flex;
                gap: 5px;
                padding-left: 0rem;
                margin: 0 0 10px 0;
                flex-wrap: wrap;
            }
        }
    }

    .terms{
      width: 100%;
    display: flex;
    align-items: baseline;
    font-size: 30px;
    color: #E6F01E;
    }
`;

export const TermsConditionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;
`;


export const TextContent = styled.p`
display: flex;
color: ${theme('palette.catch99TextContentColor')};
font-size: 17px;
`;

export const StepTittle = styled.span`
display: block;
color:#DABA57;
font-size: 17px;
font-family: 'Inter-Bold';
`;

export const BBLFIrstSecWrapper = styled.div`
   width: 80%;
   height: auto;
   display:flex;
   margin: auto;
   justify-content: space-around;
   align-items: center;
`
export const BBLFIrstSecLeft = styled.div`
   width: 50%;
   h5{color: #E6F01E;
font-size: 36px;
font-style: normal;
font-weight: 700;
margin-bottom: 0;
}
h6{
  color: #E6F01E;
text-align: justify;
font-size: 30px;
font-style: normal;
font-weight: 700;
margin: 0 auto;
text-align: center;
}
   & p {
     font-size: 16px;
     font-weight:400;
     text-align: left;
     color: ${theme('palette.BBLTextPrimary')};

     & span {
        font-size: 16px;
        font-weight:700;
        text-align: left;
        color: ${theme('palette.BBLTextSecondary')};
     }
   }

 
`
export const BBLFirstSecRIght = styled.div`
   width: 40%;
   & img {
        width:100%;
        height: auto;
   }

   &::after {
      content: "";
      width: 129px;
      height: 350px;
      display: flex;
      position: absolute;
      right: 0;
      top: 115px;
      background: url(${config.imageV3Host}/static/cashsite/${theme('image.BBLrightpaintsplash')});
      background-position: bottom;
      background-size: contain;
      background-repeat: no-repeat;
    }
`
const BBLBonusContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(${config.imageV3Host}/static/cashsite/${theme('image.BBLInnerMidBg')});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: ${theme('palette.fifafeverTextWhiteColor')};
  padding-top: 4rem;
  position: relative;
  .note1{
    color: #FFF;
text-align: center;
font-size: 28px;
font-style: normal;
font-weight: 900;
line-height: 30px; /* 28px */
text-transform: capitalize;
padding: 0rem 9rem;
  }
  
`

const OfferSectionWrapper = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    padding-bottom: 5rem;

     &::after {
        content: "";
        width: 80%;
        height: 100%;
        display: flex;
        position: absolute;
        left: -104px;
        bottom: 0;
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.BattingBottomBBL')});
        background-position: bottom;
        background-size: contain;
        background-repeat: no-repeat;
    }
`
const NewOfferCardTitle = styled.h5`
     font-size: 20px;
     font-family: 'Inter';
     letter-spacing: 0.02em;
     font-style: italic;
     text-transform: uppercase;
     color: #000000;
     text-align:center;
     margin: 0px auto;
     padding: 10px 0px;
     background: ${theme('palette.BBLCardsHeadBg')};
     border-radius: 13px 13px 0px 0px;
     width: 101%;
     margin-left: -0.5%;
     z-index: 99;
`

const NewOfferCardBody = styled.div`
    width: auto;
    height: auto;
    padding: 20px 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    & h6 {
        font-size: 18px;
        font-weight: 900;
        font-family: 'Inter';
        letter-spacing: 0.02em;
        font-style: italic;
        text-transform: uppercase;
        color: ${theme('palette.BBLTextSecondary')};
        text-align:left;
        margin: 2px 0px;
    }
    & p {
        font-size: 16px;
        font-weight: 400;
        font-family: 'Inter';
        letter-spacing: 0.05em;
        text-transform: capitalize;
        color: ${theme('palette.fifafeverTextWhiteColor')};
        text-align:left;
        margin: 10px 0px 5px 0px;
    }
`

const NewOfferCards = styled.div`
    background: ${theme('palette.BBLCardsBg')};
    mix-blend-mode: normal;
    backdrop-filter: blur(40.3956px);
    border-radius: 13px;
    min-height: 350px;
    width: 40%;
    border: ${theme('palette.BBlCardsBorder')};
    border-top: none;
    z-index: 99;

   &:hover {
     border: ${theme('palette.BBlCardsBorderHover')};
     border-top: none;

        ${NewOfferCardTitle}{
           background: ${theme('palette.BBLCardsHeadHoverBg')};
           z-index: 999;
        }

        ${NewOfferCardBody}{
            & h6 {
                color: ${theme('palette.BBLHoverColor')};
            }
        }
    }
`

const NewOfferCardsWrapper = styled.div`
     display: flex;
     width: 68%;
     height: auto;
     justify-content: center;
     flex-wrap: wrap;
     gap: 50px;
     text-align: center;

     p{
      color: #E6F01E;
text-align: justify;
font-size: 30px;
font-style: normal;
font-weight: 700;
     }
     img{
      width: 100%;
     }
`
