import flatten from 'lodash/flatten';
import React, { Fragment, PureComponent } from 'react';

// Dependent Components
import { Income } from '../../components/common/NumberDisplay';
import {
	SectionWrapper,
	TypeButtonAmountWrapper,
	TypeButtonContainer,
	TypeButtonWrapper,
	TypeSectionTitle,
	TypeSelectionSeparator,
} from '../../components/member/filterStyles';

// Utils
import { getGTMId } from '../../util/gtmUtil';
import util from '../../util/util';
import Intl from '../common/Intl';

export type SelectionInfo = {
	name: string;
	id: number;
	profitLoss?: number;
	gameInfoList?: any;
};

interface ITypePickerProps {
	groupedInfoList: SelectionInfo[][];
	initialId?: number;
	onTypeChange?: (id: number) => void;
	isShowCredit?: boolean;
	diffColor?: string;
}

interface ITypePickerState {
	currentTypeId: number;
}

class TypePicker extends PureComponent<ITypePickerProps, ITypePickerState> {
	constructor(props: ITypePickerProps) {
		super(props);
		const { groupedInfoList, initialId } = props;
		this.state = {
			currentTypeId: initialId || groupedInfoList[0][0].id,
		};
	}

	handleClick = (id: number) => () => {
		const { onTypeChange, groupedInfoList } = this.props;
		this.setState({
			currentTypeId: id,
		});
		const pickedType = flatten(groupedInfoList).find(t => t.id === id);
		pickedType && onTypeChange && onTypeChange(pickedType.id);
	};

	render() {
		const { groupedInfoList, isShowCredit = false, diffColor } = this.props;
		const { currentTypeId } = this.state;
		return (
			<SectionWrapper>
				{isShowCredit && (
					<>
					<span className="gamesHeader"><Intl langKey="PROFIT_LOSS_TABLE@GAMES" /></span>
					
					<TypeSectionTitle>
						Total P&amp;L&nbsp;{' '}
						<Income diffColor={diffColor}>
							{flatten(groupedInfoList).reduce((a, c) => {
								// 加總前就先 round，避免與各分類所顯示的加總有落差
								return a + util.numRound(c.profitLoss || 0);
							}, 0)}
						</Income>
						
					</TypeSectionTitle>
					</>
				)}

				<TypeButtonContainer>
					{groupedInfoList.map((infoList, i) => {
						return (
							<Fragment key={i}>
								{/* {i !== 0 && <TypeSelectionSeparator><hr/></TypeSelectionSeparator>} */}
								{i !== 0 && <TypeSelectionSeparator></TypeSelectionSeparator>}
								{infoList.map(info => {
									const { id, name, profitLoss } = info;
									const notSpaceName = name.replace(/\s+/g, '');
									return (
										<TypeButtonWrapper
											onClick={this.handleClick(id)}
											isSelected={currentTypeId === id}
											key={id}
											id={getGTMId(`member_items_button_${notSpaceName}`)}
										>
											<span>{name}</span>
											{isShowCredit && (
												<TypeButtonAmountWrapper>
													<Income diffColor={diffColor}>{profitLoss}</Income>
												</TypeButtonAmountWrapper>
											)}
										</TypeButtonWrapper>
									);
								})}
							</Fragment>
						);
					})}
				</TypeButtonContainer>
			</SectionWrapper>
		);
	}
}

export default TypePicker;
