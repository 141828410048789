import React from 'react'

export default function CopyIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3215 6.99053L13.3216 6.99062C13.3772 7.04502 13.4214 7.10999 13.4516 7.18172L13.9125 6.98772L13.4516 7.18172C13.4818 7.25342 13.4974 7.33044 13.4974 7.40824C13.4974 7.40826 13.4974 7.40828 13.4974 7.4083V12.1558C13.4974 12.3107 13.4359 12.4593 13.3263 12.5689C13.2168 12.6784 13.0682 12.7399 12.9132 12.7399H8.57659C8.42167 12.7399 8.27308 12.6784 8.16353 12.5689C8.05398 12.4593 7.99243 12.3107 7.99243 12.1558V5.65081C7.99243 5.49588 8.05398 5.3473 8.16353 5.23775C8.27308 5.1282 8.42166 5.06665 8.57659 5.06665H11.1162C11.1162 5.06665 11.1162 5.06665 11.1162 5.06665C11.2688 5.06669 11.4154 5.12643 11.5245 5.2331C11.5245 5.23311 11.5245 5.23313 11.5245 5.23314L13.3215 6.99053Z" stroke="#FFD085" stroke-linecap="round" stroke-linejoin="round" />
            <mask id="path-2-inside-1_2338_12" fill="white">
                <path d="M12.6617 13.1108V14.195C12.6617 14.4825 12.5475 14.7583 12.3442 14.9616C12.1408 15.1649 11.8651 15.2792 11.5775 15.2792H7.2409C6.95336 15.2792 6.6776 15.1649 6.47428 14.9616C6.27096 14.7583 6.15674 14.4825 6.15674 14.195V8.23211C6.15674 7.94457 6.27096 7.66881 6.47428 7.46549C6.6776 7.26217 6.95336 7.14795 7.2409 7.14795H8.32506" />
            </mask>
            <path d="M13.6617 13.1108C13.6617 12.5586 13.214 12.1108 12.6617 12.1108C12.1094 12.1108 11.6617 12.5586 11.6617 13.1108H13.6617ZM6.15674 14.195L5.15674 14.195L6.15674 14.195ZM7.2409 7.14795V6.14795V7.14795ZM8.32506 8.14795C8.87735 8.14795 9.32506 7.70023 9.32506 7.14795C9.32506 6.59566 8.87735 6.14795 8.32506 6.14795V8.14795ZM11.6617 13.1108V14.195H13.6617V13.1108H11.6617ZM11.6617 14.195C11.6617 14.2173 11.6528 14.2387 11.6371 14.2545L13.0513 15.6687C13.4421 15.2779 13.6617 14.7478 13.6617 14.195H11.6617ZM11.6371 14.2545C11.6213 14.2703 11.5999 14.2792 11.5775 14.2792V16.2792C12.1303 16.2792 12.6604 16.0596 13.0513 15.6687L11.6371 14.2545ZM11.5775 14.2792H7.2409V16.2792H11.5775V14.2792ZM7.2409 14.2792C7.21858 14.2792 7.19717 14.2703 7.18139 14.2545L5.76718 15.6687C6.15803 16.0596 6.68815 16.2792 7.2409 16.2792V14.2792ZM7.18139 14.2545C7.16561 14.2387 7.15674 14.2173 7.15674 14.195L5.15674 14.195C5.15674 14.7478 5.37632 15.2779 5.76718 15.6687L7.18139 14.2545ZM7.15674 14.195V8.23211H5.15674V14.195H7.15674ZM7.15674 8.23211C7.15674 8.20979 7.16561 8.18838 7.18139 8.1726L5.76718 6.75839C5.37632 7.14924 5.15674 7.67936 5.15674 8.23211H7.15674ZM7.18139 8.1726C7.19717 8.15682 7.21858 8.14795 7.2409 8.14795V6.14795C6.68815 6.14795 6.15803 6.36753 5.76718 6.75839L7.18139 8.1726ZM7.2409 8.14795H8.32506V6.14795H7.2409V8.14795Z" fill="#FFD085" mask="url(#path-2-inside-1_2338_12)" />
            <circle cx="10.0771" cy="9.92285" r="8.92285" stroke="#FFD085" />
        </svg>
    )
}
