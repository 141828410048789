import React from 'react'

type Props = {}

const Eye = (props: Props) => {
    return (
        <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.6061 9.9709C17.662 5.87549 14.7232 3.81445 10.7837 3.81445C6.84209 3.81445 3.90537 5.87549 1.96123 9.97295C1.88325 10.1381 1.8428 10.3184 1.8428 10.501C1.8428 10.6836 1.88325 10.864 1.96123 11.0291C3.90537 15.1245 6.84414 17.1855 10.7837 17.1855C14.7253 17.1855 17.662 15.1245 19.6061 11.0271C19.7641 10.6948 19.7641 10.3093 19.6061 9.9709V9.9709ZM10.7837 15.709C7.47578 15.709 5.05381 14.0314 3.3455 10.5C5.05381 6.96855 7.47578 5.29102 10.7837 5.29102C14.0916 5.29102 16.5136 6.96855 18.2219 10.5C16.5156 14.0314 14.0936 15.709 10.7837 15.709ZM10.7017 6.89062C8.7083 6.89062 7.09228 8.50664 7.09228 10.5C7.09228 12.4934 8.7083 14.1094 10.7017 14.1094C12.695 14.1094 14.311 12.4934 14.311 10.5C14.311 8.50664 12.695 6.89062 10.7017 6.89062ZM10.7017 12.7969C9.43222 12.7969 8.40478 11.7694 8.40478 10.5C8.40478 9.23057 9.43222 8.20312 10.7017 8.20312C11.9711 8.20312 12.9985 9.23057 12.9985 10.5C12.9985 11.7694 11.9711 12.7969 10.7017 12.7969Z" fill="black" />
        </svg>
    )
}

export default Eye;