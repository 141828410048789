import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { theme } from 'styled-tools';
// import { titleFont } from './commonCSSStyles';

interface IProps {
    tabs: any,
    isTabsHidden: boolean
    render: (index: number, changeTab:(index: number) => void) => JSX.Element
    minWidthInContent?: string
}

export default function TabBar(props: IProps) {
    const [currentIndex, setCurrentIndex] = useState(0)
    const { tabs, render, minWidthInContent } = props
    const handleChange = (index) => {
        setCurrentIndex(index)
    }
    return (
        <TabContainer>
            <TabWrapper>
                {
                    tabs && tabs.map((name, index) => (
                        <SelectTab
                            isActive={currentIndex === index}
                            onClick={() => handleChange(index)}
                            key={name}
                        >
                            {name}
                        </SelectTab>
                    ))
                }
            </TabWrapper>
            <TabContent minWidthInContent={minWidthInContent}>{render(currentIndex,  handleChange)}</TabContent>
        </TabContainer>
    )
}

const SelectTab: any = styled.div`
    cursor: pointer;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: bold;
    color: ${theme('palette.TabDefaultTextColor')};
    position: relative;
    padding: 6px 15px;

	&:hover {
        color: ${theme('palette.TabActiveTextColor')};
	}

	${(props: any) =>
        props.isActive &&
        css`
            color: ${theme('palette.TabActiveTextColor')};

            :before {
                width: 100%;
                content: "";
                height: 3px;
                background-color: ${theme('palette.TabActiveBorderLine')};
                position: absolute;
                bottom: -2px;
                left: 0;
                right: 0;
            }
		`};
`;

const TabContainer: any = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: ${(props: any) => props.minWidthInContent || '0px'};
`;


const TabWrapper = styled.div`
    display: flex;
    margin: 0 0 15px;
    border-bottom: ${theme('palette.TabWrapperBorderBottom')};;
    padding-left: 20px;
    padding-top: 10px;
`;

const TabContent: any = styled.div`
	// background-color: ${theme('palette.agent-tab-color')};
	min-width: ${(props: any) => props.minWidthInContent || 'initial'};
`;