import React, { useContext, useEffect, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
//Components
// import DeviceTime from './device-time';
import HeaderAccountLinks from './header-account-links';
import { color } from 'src/sass-files/variable';
import cookieUtil from 'src/util/cookieUtil';
// import util from 'src/util/util';
import { ECookieName } from 'src/util/utilModel';
// import backgroundImage from 'src/images/account_bg.png';
import { Income, IncomeWrapper as AccountIncomeWrapper } from '../common/NumberDisplay';
// import { diffColor } from 'src/util/colorUtil';
import { Amount } from 'src/components/common/NumberDisplay';
import Intl from '../../components/common/Intl';
import TierComponent from './TierComponent';
import config from 'src/config/config';
import { getAllPromotions } from "src/config/api";

type TierProps = 'BLUE TIER' | 'SILVER TIER' | 'GOLD TIER' | 'PLATINUM TIER' | 'ELITE TIER';
const { tabsignup, secondarycolor, primaryColor } = color

const handleLogout = (e) => {
    cookieUtil.remove(ECookieName.COOKIE_TOKEN);
    cookieUtil.remove(ECookieName.COOKIE_USERNAME);
    cookieUtil.remove(ECookieName.COOKIE_LANG);
    cookieUtil.remove(ECookieName.COOKIE_MEMBERID);
    cookieUtil.remove(ECookieName.COOKIE_IS_DEFAULT_PASSWORD);
    cookieUtil.remove(ECookieName.COOKIE_IS_HAD_AUTH);
    cookieUtil.remove(ECookieName.COOKIE_ORDER_LIMIT);
    cookieUtil.remove(ECookieName.COOKIE_AGENT_TOKEN);
    cookieUtil.remove(ECookieName.COOKIE_IS_ONE_CLICK_ACTIVE);
    cookieUtil.remove(ECookieName.COOKIE_IS_ACCEPT_ALL);
    window.location.href = '/';
}

const defaultTier: TierProps = 'BLUE TIER'


function AfterloginDropdown(props) {
    const { theme } = props;
    const { config: { showtier = true } } = theme;

    const [totalBonusAmount, setTotalBonusAmount] = useState<number>(0);
    const [currentTier, setCurrentTier] = useState(defaultTier);

    useEffect(() => {
        window.scrollTo(0, 0);
        getPromotionList()
    }, []);

    const getPromotionList = () => {
        getAllPromotions()
            .then((response) => {
                const { data } = response.data
                setTotalBonusAmount(data.total_bonus_amount);
                setCurrentTier(data.curr_tier.replace('_', ' '));
                if (response.data.success === false) {
                    console.error(response.data.data.status);
                }
            })
            .catch((err) => console.error("Something Went Wrong, Please Try Again"));
    }

    // debugger
    return (
        <DropDownWrapper>
            <RightSection>
                {/* <DeviceTime/> */}
               <TierComponent tier={currentTier} showTier={showtier} />
                <CreditSection>
                    <CreditRow>
                        <CreditLabel>
                            <label><Intl langKey="ACCOUNT@BALANCE" /></label>
                        </CreditLabel>
                        <AvailableText>
                            <Amount>{props.credit.toFixed(2)}</Amount>
                        </AvailableText>
                    </CreditRow>

                    {/* <CreditRow>
                        <CreditLabel>
                        <label><Intl langKey="ACCOUNT@CREDIT_LIMIT" /></label>
                        </CreditLabel>
                        <AvailableText>
                            <LimitAmount>{props.creditLimit}</LimitAmount>
                        </AvailableText>
                    </CreditRow> */}

                    <CreditRow>
                        <CreditLabel>
                            <label><Intl langKey="ACCOUNT@BONUS" /></label>
                        </CreditLabel>
                        <AvailableText>
                            <IncomeWrapper>
                                <Income>{Math.round(totalBonusAmount)}</Income>
                            </IncomeWrapper>
                        </AvailableText>
                    </CreditRow>

                    <CreditRow>
                        <CreditLabel>
                            <label><Intl langKey="ACCOUNT@NET_EXPOSURE" /> <br></br></label>
                        </CreditLabel>
                        <AvailableText>
                            <ExposuretAmount>{props.exposure}</ExposuretAmount>
                        </AvailableText>
                    </CreditRow>
                </CreditSection>
                <HeaderAccountLinks />
                <LogoutWrapper>
                    <button onClick={handleLogout}><Intl langKey="MEMBER@LOG_OUT" /></button>
                    {/* <button>LOGOUT</button> */}
                </LogoutWrapper>
            </RightSection>
        </DropDownWrapper>
    )
}

export default withTheme(AfterloginDropdown);

//Styled Components
const RightSection = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    float: right;
`;

const IncomeWrapper = styled(AccountIncomeWrapper)`
    padding: 0;
    background: none;

    span {
        padding: 0;
        background: none;
        color: #38BC7D;
    }
`;

const LogoutWrapper = styled.div`
    width: 100%;

    button {
        width: 100%;
        padding: 15px 10px;
        color: ${secondarycolor};
        background: ${theme('palette.secondary-background-color-linear-new1')};
        border: none;
        text-transform: uppercase;
        font-size: 15px;
        cursor: pointer;
        font-weight: 700;
        letter-spacing: 0.05em;
        
        :hover {
            background: ${theme('palette.secondary-background-color-linear-new1')};
        }
    }
`;

const ExposuretAmount = styled.div`
    // color: #EBA02A;
    color:#F00101;
`;

const LimitAmount = styled.div`
    color: ${primaryColor};
`;

const AvailableText = styled.div`
    /* max-width: 62px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    color: #38BC7D;
    font-weight: 700;
`;

const CreditLabel = styled.div`
    /* width: 50%; */
    color: #000000;
    letter-spacing: 0.05em;
    text-transform: capitalize;
`;

const CreditRow = styled.div`
    width: 100%;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px;
    background-color: #F9F8FA;
    margin: 2px;
    background-position: center;
    background-size: contain;

`;

const CreditSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    justify-content: center;
    padding: 5px;  
`;

const DropDownWrapper = styled.div`
    width: 325px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    position: absolute;
    top: 70px;
    right: 12px;
    background-color:black;
    /* background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.myAccountBg')}); */
    z-index:9;
    background-position: 100%;
    background-size: contain;
`;