import React, { useEffect } from 'react';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import Intl from "../common/Intl";


// Dependent Components
import { buttonStyle, titleFont } from '../common/commonCSSStyles';
import Icon, { IconWrapper } from '../common/PNGIcon';
import Super7NoEvent from 'src/images/Go_to_event_logo.png';

// Util
import config from '../../config/config';
import routerUtil from '../../util/routerUtil';
import { useNavigate,useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { withAppContext } from 'src/store/initAppContext';


 function EventNotFound (props)  {

	
    let navigate = useNavigate();
	useEffect(() => {
        window.scrollTo(0,0)
    })
	
	let location = useLocation()
  	let {pathname} = location;
	return (
		<Wrapper>
			<Content>
				{/* <img src={Super7NoEvent} /> */}
				<Message theme={props.app.theme}><Intl langKey="TABLE@NO_EVENT" /></Message>
				{/* <Description theme={props.app.theme}><Intl langKey="EVENTS@EVENT_INVALID" /></Description> */}
				{pathname === '/' ? null : 
				<Button onClick={() => navigate('/')}>
				<Intl langKey="EVENTS@GOTO_HOME" /><Icon name="chevron-right-light" />
				</Button>
				}
			</Content>
		</Wrapper>
	);
};

export default withAppContext(EventNotFound);

// Styled Components

const Message: any = styled.div`
	${titleFont};
	font-size: 1.75em;
	font-weight: bold;
	color: ${(props) => (props.theme == 'Dark' ? '#FFF' : '#f4a322')};
	margin-bottom: 0.5em;
`;

const Description = styled.div`
	font-size: 14px;
	color: ${(props) => (props.theme == 'Dark' ? '#FFF' : '#f4a322')};
`;

const Button = styled.button`
	${buttonStyle};
	background: ${theme('palette.primary-background-color-linear-new')};
	// background-color: ${(props) => (props.theme == 'Dark' ? '#FFF' : '#09285F')};
	color: ${(props) => (props.theme == 'Dark' ? '#09285F' : '#FFF')};
	padding: 1em 1.5em;
	margin-top: 2em;
	font-size: 12px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);

	${IconWrapper} {
		margin-right: 0;
		margin-left: 0.5em;
	}
`;

const Logo: any = styled.div`
	margin-bottom: 1em;
	filter: grayscale(100%) contrast(0%);
	opacity: 0.25;
	width: 150px;
	height: 50px;
	background-image: url(${config.imageS3Host}/static/${(props: any) => props.theme.view}${theme('image.logo-h')});
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 8em;
	align-items: center;
	padding-top:100px;
	
`;

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
`;

const Subtab = styled.div`

.active {
        background: linear-gradient(180deg, #ABABAB -19.05%, #FBFBFB 44.64%, #FFFFFF 48.62%, #D0D0D0 108.33%);

        /* p{
            color: #686868;
        } */
      }
`;
