import React from "react";

type Props = {};

export default function Discount({}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 209.281 209.281"
      stroke="#E8E8E8"
    >
      <path d="m207.17 99.424-20.683-21.377 4.168-29.455a7.5 7.5 0 0 0-6.131-8.438l-29.298-5.137-13.941-26.278a7.5 7.5 0 0 0-9.919-3.223L104.64 18.582 77.916 5.517a7.5 7.5 0 0 0-9.92 3.223L54.055 35.018l-29.298 5.137a7.5 7.5 0 0 0-6.131 8.438l4.167 29.455L2.11 99.424a7.5 7.5 0 0 0 0 10.43l20.682 21.378-4.167 29.456a7.502 7.502 0 0 0 6.13 8.438l29.299 5.14 13.942 26.275a7.5 7.5 0 0 0 9.919 3.223l26.724-13.062 26.727 13.062a7.5 7.5 0 0 0 9.918-3.223l13.942-26.275 29.299-5.14a7.5 7.5 0 0 0 6.13-8.438l-4.168-29.456 20.684-21.378a7.5 7.5 0 0 0-.001-10.43zm-34.012 24.014a7.502 7.502 0 0 0-2.035 6.266l3.665 25.902-25.764 4.52a7.502 7.502 0 0 0-5.329 3.872l-12.26 23.105-23.502-11.486a7.495 7.495 0 0 0-6.587 0l-23.5 11.486-12.26-23.105a7.502 7.502 0 0 0-5.329-3.872l-25.764-4.52 3.664-25.902a7.498 7.498 0 0 0-2.036-6.265L17.935 104.64l18.186-18.797a7.501 7.501 0 0 0 2.036-6.265l-3.664-25.901 25.763-4.517a7.503 7.503 0 0 0 5.331-3.872L77.846 22.18l23.499 11.489a7.494 7.494 0 0 0 6.588 0l23.501-11.489 12.26 23.108a7.5 7.5 0 0 0 5.33 3.872l25.763 4.517-3.665 25.901a7.5 7.5 0 0 0 2.036 6.266l18.186 18.796-18.186 18.798z" />
      <path d="M80.819 98.979c10.014 0 18.16-8.146 18.16-18.158 0-10.016-8.146-18.164-18.16-18.164-10.015 0-18.162 8.148-18.162 18.164 0 10.013 8.148 18.158 18.162 18.158zm0-24.322a6.169 6.169 0 0 1 6.16 6.164 6.166 6.166 0 0 1-6.16 6.158 6.167 6.167 0 0 1-6.162-6.158 6.17 6.17 0 0 1 6.162-6.164zM140.867 68.414a5.998 5.998 0 0 0-8.484 0l-63.968 63.967a6 6 0 1 0 8.486 8.485l63.967-63.967a6 6 0 0 0-.001-8.485zM128.46 110.301c-10.013 0-18.158 8.146-18.158 18.158 0 10.016 8.146 18.164 18.158 18.164 10.016 0 18.164-8.148 18.164-18.164 0-10.012-8.148-18.158-18.164-18.158zm0 24.323c-3.395 0-6.158-2.765-6.158-6.164a6.166 6.166 0 0 1 6.158-6.158c3.398 0 6.164 2.763 6.164 6.158a6.171 6.171 0 0 1-6.164 6.164z" />
    </svg>
  );
}
