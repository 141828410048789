import styled from "styled-components"
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
// import ActiveArrow from "./arrow-active.svg"
// import InActiveArrow from "./arrow-inactive.svg"
import Intl from "src/components/common/Intl";
import ArrowActive from '../../icon/ArrowActive';
import ArrowInActive from '../../icon/ArrowInActive'
import { theme } from "styled-tools";

type ProgressBarProps = {
    percent: '25' | '75' | '50' | '100';
}
const ProgressBar = ({ percent }: ProgressBarProps) => {
    return (
        <ProgressWrapper>
            <ProgressTitles>
                <ProgressTitleWrap>
                    <p> <Intl langKey="Progress@BasicDetails" /> </p>
                    {percent === "25" || percent >= "50" || percent >= "75" || percent === "100" ? <ArrowActive /> : <ArrowInActive />}
                </ProgressTitleWrap>
                <ProgressTitleWrap>
                    <p><Intl langKey="Progress@Document" /></p>
                    {percent > "25" || percent > "50" || percent === "75" || percent === "100" ? <ArrowActive /> : <ArrowInActive />}
                </ProgressTitleWrap>
                <ProgressTitleWrap>
                    <p><Intl langKey="Progress@Review" /></p>
                    {percent === "75" || percent === "100" ? <ArrowActive /> : <ArrowInActive />}
                </ProgressTitleWrap>
                <ProgressTitleWrap>
                    <p><Intl langKey="Progress@Approval" /></p>
                    {percent === "100" ? <ArrowActive /> : <ArrowInActive />}
                </ProgressTitleWrap>
            </ProgressTitles>

            <Progress
                percent={percent}
                theme={{
                    success: {
                        color: '#FFD085',
                        symbol: '100%'
                    },
                    active: {
                        color: '#FFD085'
                    },
                    default: {
                        color: '#FFD085'
                    }
                }}
            />
        </ProgressWrapper>
    )
}

export default ProgressBar

export const ProgressWrapper = styled.div`
      width: 100%;
      display: block;
      padding: 10px;
       & > .react-sweet-progress {
           width: 60%;
        }
       &  .react-sweet-progress-symbol {
            width: 25px;
            height: 15px;
            padding-left: 10px;
            font-family: 'Inter';
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;
            color: #FFD085;
       }
`
export const ProgressTitles = styled.div`
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 60%;
      &  p {
          font-size: 11px;
          font-weight: 500;
          color:${theme('palette.progressBarBg')};
          line-height: 13px;
          margin: 5px 0px;
      }
`
export const ProgressTitleWrap = styled.div`
     display: flex;
     align-items: center;
     flex-direction: column;
`