import React, { useState } from 'react';
import styled from 'styled-components';
import ArrowRight from "../icon/ArrowRight";
import ArrowDown from "../icon/ArrowDown";
import { SvgIcon } from '../udb/commonStyle';

interface Props {
  openState: boolean
}

const Accordion = ({ title, content, lists }) => {
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const openAccordion = () => {
    setIsActive(!isActive);
    setIsOpen(true);
  };

  return (
    <>
        <AccordionItem>
        <AccordionTitle onClick={openAccordion}>
             <h6>{title}</h6>
             <SvgIcon> {isActive ? <ArrowDown /> : <ArrowRight /> } </SvgIcon>
       </AccordionTitle>
      {isActive && (
        <AccordionContent openState={isOpen}>
          <p dangerouslySetInnerHTML={content()}></p>
          {lists !== [] ?
            <ul className="fnq">
              {lists.map((list) => (
                <li>{list.content}</li>
              ))}
            </ul>
            : <></>
          }
        </AccordionContent>
      )}

        </AccordionItem>
    </>
  );
};

export default Accordion;

export const AccordionItem = styled.div`
  width: 100%;
  border-bottom: 1px solid white;
`;

export const AccordionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 15px;
  cursor: pointer;
  backdrop-filter: brightness(0.6);

  & h6 {
    font-weight: 500;
    font-size: 14px;
    /* line-height: 25px; */
    text-align: justify;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: white !important;
    margin: 3px 0px;
  }


`;
const AccordionContent = styled.div<Pick<Props, "openState">>`
  margin: 0px 0px;
  backdrop-filter: brightness(0.3);

  & p {
        font-weight: 400;
        font-size: 13px;
        /* line-height: 25px; */
        text-align: justify;
        letter-spacing: 0.01em;
        transition: height 0.8s ease-in;
        color: #ffffff;
        padding: 6px 20px;
        height: ${(props) => (props.openState ? "auto" : "0px")};
        width: 99%;
        margin: auto;
        overflow: hidden;
        box-sizing: border-box;
  }
  & ul {
    margin: 0px;
    padding: 0 0 10px 40px;
    list-style: square;
  }
  & li {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        text-align: justify;
        letter-spacing: 0.01em;
        transition: height 0.8s ease-in;
        color: #ffffff;
        padding: 2px 0px;
        width: 99%;
        margin: 0px;
  }
  & a {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        text-align: justify;
        letter-spacing: 0.01em;
        transition: height 0.8s ease-in;
        color: #f4a322;
        width: 99%;
        margin: 0px;
        overflow: hidden;
  }
`;
const LinkWrapper = styled.div`
    padding: 10px 20px;
`
