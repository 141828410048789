import React from 'react'

type Props = {}

const DepositDetailsUpiIcon = (props: Props) => {
  return (
    <svg
        width={9}
        height={12}
        viewBox="0 0 9 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75721 5.87871L5.79408 0L2.50195 11.5613L8.75721 5.87837V5.87871Z"
        fill="#097939"
        />
        <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.64944 5.87871L3.68666 0L0.394531 11.5613L6.64944 5.87837V5.87871Z"
        fill="#ED752E"
        />
  </svg>
  )
}

export default DepositDetailsUpiIcon