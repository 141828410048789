import React, { useState, useEffect } from 'react';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components'
import { theme } from 'styled-tools';

export const getUserTime = (timestamp) => {
  const mint = 60000;
  const maxReqMint = mint * 30;
  const secondsDiff = Date.parse(timestamp) - Date.now();
  const validdMints = secondsDiff + maxReqMint;
  let balanceMins = validdMints / mint;
  return balanceMins || 0;
}

type TimerDepositProps = {
  app: any;
  theme: any;
  created: any;
}

const TimerDeposit = (props: TimerDepositProps) => {
  const { app: { }, theme, created } = props;
  const [minutes, setMinutes] = useState(0);
  const [seconds, setseconds] = useState(0);

  useEffect(() => {
    let t = setInterval(() => {
      let balanceMins: any = getUserTime(created);
      let minutesTime = Math.floor(balanceMins);
      let secondsTime = Math.floor((balanceMins - minutesTime) * 60);
      setMinutes(minutesTime);
      setseconds(secondsTime);
      if (balanceMins <= 0) {
        clearInterval(t)
      }
    }, 1000)

    return () => {
      clearInterval(t)
    }
  }, []);

  return (
    <>
      {minutes <= 0 ? <></> :
        <MainWrapper>
        <img src={`${config.imageV3Host}/static/cashsite/${theme.image.iplLogo}`} />
        <img src={`${config.imageV3Host}/static/cashsite/${theme.image.ipltext}`} />
        <TimeDetails>
          <span>OFFER ENDS IN</span>
          <TimerDivDep>
          <span>00:{minutes}:{seconds < 10 ? '0' : ''}{seconds}</span>
          </TimerDivDep>
          <span>MINUTES</span>
        </TimeDetails>
      </MainWrapper>}
    </>
  );
}

export default withTheme(withAppContext(TimerDeposit))



export const TimerDivDep = styled.div`
  color: #6778E3;
  font-size: 25px;
  font-weight: bolder;
`
export const MainWrapper = styled.div`
    width: 47%;
    min-height: 109px;
    background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.depositToggleBG')});
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 6px 3px 3px;
    img{
        width: 24%;
    }
    .text{
      img{
        width: 30%;
      }
    }
`

export const TimeDetails = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-transform: uppercase;
color: #6778E3;
font-weight: 700;
font-size: 17.2762px;
`