import { useEffect } from "react";
import FooterContainer from "src/components/footer/footer";
import { withAppContext } from "src/store/initAppContext";
import styled, { withTheme } from "styled-components";


function RestrictedTerritories(){
    useEffect(() => {
        window.scrollTo(0,0)
    })
    return(
        <>
        <ContentWrapper>
        <h1>Restricted Territories disclaimer</h1>
            <p>The availability of the website does not constitute an offer, solicitation or invitations by us for the use of betting, 
                gaming or other service in any jurisdictions in which such activities are prohibited by law. Under no 
                circumstances will we be liable for any breach of state or country law that may occur as a result of your usage of the website.
            </p>
            <p>Access to this site is restricted in certain geographical territories. Customers residing in the listed territories are 
                prohibited from accessing the site and its services.
            </p>  

            <h1>The restricted territories are</h1>
            <p>Afghanistan, Algeria, Australia, Bosnia and Herzegovina, Bulgaria, Canada, China (People's Republic of), 
                Cuba, Cyprus, Denmark, Ethiopia, France (and French territories), Germany, Gibraltar, Iran (Islamic Republic of), 
                Iraq, Ireland, Italy, Korea (Democratic People's Republic of), Lao (People's Democratic Republic of), 
                Malta, Myanmar, Netherlands, New Zealand, Poland, Portugal, Puerto Rico, Qatar, Romania, Singapore, 
                Slovakia, South Africa, Spain, Sudan, Syrian Arab Republic, Turkey, Uganda, United Kingdom, United States 
                (and U.S. Territories), Yemen, Taiwan, Macau.
            </p>
            <p>In a country where it is legal only in a part of the territory, it is then the responsibility of the user to check 
                the local laws and use accordingly.
            </p>
            <p>Any territory in which the Operator is not permitted to operate pursuant to any Applicable Law</p>
			<p>Any territory in which the Operator’s licence(s) do not allow it to take bets from Players</p>
			<p>Prohibited Sanctioned Countries from which play/other transactions is not allowed: Cuba, Iran, North Korea, Syria and Crimea Region of the Ukraine</p>
            <p>
                We periodically review our ability to offer gambling facilities within territories that are not included in the list 
                above. When we are no longer able to offer gambling facilities in a specific territory, we will endeavor to update 
                this page accordingly and we reserve the right to restrict your account from using all, or part of our services.
            </p>
            <p>The availability of the website does not constitute an offer, solicitation or invitations by us for the use of betting, 
                gaming or other service in any jurisdictions in which such activities are prohibited by law. Under no 
                circumstances will we be liable for any breach of state or country law that may occur as a result of your usage 
                of the website.
            </p> 
        </ContentWrapper>
        <FooterContainer/>

        </>
    )
}


export default withTheme(withAppContext(RestrictedTerritories));




const ContentWrapper = styled.div`
	max-width: 800px;
    height: calc(100vh - 20px);
	margin: 0 auto;
	font-size: 12px;
	padding: 5.5em 1.25em;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: #000;
		margin-top: 1.23em;
	}

	h1 {
		font-size: 18px;
	}

	h2 {
		font-size: 16px;
	}

	h3 {
		font-size: 14px;
	}

	ul ul,
	ol {
		margin: 1em 0;
	}

	li {
		margin: 0.75em 0;
	}

	a {
		color: #00FF;
		text-decoration: underline;
	}
`;
