import React, { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";
import { theme } from "styled-tools";
import config from "../../../config/config";
import media from "../../../util/mediaUtil";
import _ from 'lodash';
import ExchangeTopGames from "../exchange-page/exchangepage-topgames/index";
import {  useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { withAppContext } from "src/store/initAppContext";
import { Helmet } from "react-helmet";
import SEOContents from "src/components/SEOContents";
import { ExchangeWrapp, HomepageExchangeWrapper } from "src/components/home-exchange";
import { useQuery } from "@apollo/client";
import { GET_EVENTS } from "src/graphql/events";
import util from "src/util/util";
import { IEventInfo } from "src/graphql/schema";
import Greaterthan from "src/images/svg/greaterthan";
import EventNotFound from "../EventNotFound";


const MainElection = (props) => {

  const { app: {
    brandId
  }, theme } = props;

  const { loading, error, data } = useQuery(GET_EVENTS, {
    fetchPolicy: "no-cache",
    variables: { input: { categoryId: 100, isInplay: true, singleFancy: true } },
    // pollInterval: 5000
  });

  const eventInfoList: IEventInfo[] = util.getValue(
    data,
    ["markets", "list"],
    []
  );

  const navigate = useNavigate()

	const groupMarketsByDescription = (eventList) => {
		const marketsWithDescription = eventList.flatMap(event =>
			event.snk.map(market => ({
				...event,
				description: market.description,
				marketType: market.marketType,
			})).filter((elm: { marketType: number; }) => elm.marketType === 25)
		);
		return _.groupBy(marketsWithDescription, 'description');
	};


  const renderMarketData = (eventList) => {
    const marketsWithDescription = eventList.flatMap(event =>
      event.snk.map(market => ({
        ...event,
        description: market.description,
      }))
    );
    return _.unionBy(marketsWithDescription, "eventName")
  }

  const groupedMarkets = groupMarketsByDescription(eventInfoList);
  const renderData = renderMarketData(eventInfoList)


  if (loading) {
    return <div>Loading...</div>;
  }

  return (

    <Bgdiv>
      <SEOContents pageName="specialMarket" />
      <ExchangeTopGames />
      <HomepageExchangeWrapper>
        <ExchangeWrapp>
          {
            Object.keys(groupedMarkets).map((name) => (
              <>
                <InPlayHeader> <span>✪</span> {name}</InPlayHeader>
                <TabContainer>
                  {
                    renderData.map((event: any) => {
                      if (event.description === name) {
                        return (
                            <>
                              <StyledTab key={event.eventId} onClick={() => navigate(`/market/100/-1/${event.eventId}/1`)}>
                                <TabText>{event.eventName}</TabText>
                                <IconWrapper><Greaterthan /></IconWrapper>
                              </StyledTab>

                            </>
                        )
                      }
                    })
                  }
              
              </TabContainer>
              </>
            ))
          }
        </ExchangeWrapp>
        {!loading && Object.keys(groupedMarkets).length === 0 && <EventNotFound/>}
      </HomepageExchangeWrapper>
    </Bgdiv>
  );
  // }
};

export default withTheme(withAppContext(MainElection));
const StyledTab = styled.div`
border-radius: 0px 0px 20px 0px;

border: 1px solid #6778E3;
background: linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(92deg, #0A286C 0%, #134ED2 100%);
  color: black;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between; 
  align-items: center;
  width: 35%; 
  height: 60px; 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  cursor: pointer;
  position: relative;
`;
export const InPlayHeader = styled.div`
  // width: 85%;
  width: 300px;

padding: 14px 10px;
 /* background: linear-gradient(90deg, #6778E3 0%, rgba(103, 120, 227, 0.00) 100%); */
 background: ${theme("palette.splmarketDivBG")};
 color: #FFF;
font-size: 17px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 1.26px;
text-transform: uppercase;


  :after {
    content: none;
    width: 45px;
    height: 100%;
    background: ${theme("palette.secondary-background-color-linear-new-after")};
    position: absolute;
    top: 0;
    right: -14px;
    transform: skew(18deg);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;
const TabText = styled.span`
color: #234294;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: uppercase;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;

const TabContainer = styled.div`
  display: flex;
  width: 100%; 
  padding: 18px;
  flex-wrap: wrap;
  gap: 24px;
  
`;
const Bgdiv = styled.div`
  background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.darkBG')});
  background-size: cover;
  background-repeat: norepeat;
  background-position: top right;
  justify-content: space-between;
  min-height: 100vh;
`