import React, { useContext, useEffect, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
//Components
import { color } from 'src/sass-files/variable';
import { Link } from "react-router-dom";
import Intl from '../../components/common/Intl';
import config from 'src/config/config';


//Images
// import TransferIcon from "../../images/minimize-2.png";
// import ChangePasswordIcon from "../../images/lock.png";
// import MyBetsIcon from "../../images/file-text.png";
// import SecurityIcon from "../../images/Lock_2.png";
// import ProfitLossIcon from "../../images/trending-up.png";
// import AccountStatementIcon from "../../images/layers.png";
// import CasinoStatementIcon from "../../images/dollar-sign.png";
import { withAppContext } from '../../store/initAppContext'
import { CRMBetDetailClick } from 'src/util/crmUtil';

const { tabsignup, secondarycolor, primaryColor } = color
const CHANGE_PASSWORD_HIDDEN_USERS = ["demo2001", "demo2002", "demo2003", "demo2004", "demo2005", "demo2006", "demo2007", "demo2008", "demo2009", "demo2010", "demo2011", "demo2012", "demo2013", "demo2014", "demo2015", "demo2016", "demo2017", "demo2018", "demo2019", "demo2020", "demo2021", "demo2022", "demo2023", "demo2024", "demo2025", "demo2026", "demo2027", "demo2028", "demo2029", "demo2030", "demo2031", "demo2032", "demo2033", "demo2034", "demo2035", "demo2036", "demo2037", "demo2038", "demo2039", "demo2040", "demo2041", "demo2042", "demo2043", "demo2044", "demo2045", "demo2046", "demo2047", "demo2048", "demo2049", "demo2050", "demo2051", "demo2052", "demo2053", "demo2054", "demo2055", "demo2056", "demo2057", "demo2058", "demo2059", "demo2060", "demo2061", "demo2062", "demo2063", "demo2064", "demo2065", "demo2066", "demo2067", "demo2068", "demo2069", "demo2070", "demo2071", "demo2072", "demo2073", "demo2074", "demo2075", "demo2076", "demo2077", "demo2078", "demo2079", "demo2080", "demo2081", "demo2082", "demo2083", "demo2084", "demo2085", "demo2086", "demo2087", "demo2088", "demo2089", "demo2090", "demo2091", "demo2092", "demo2093", "demo2094", "demo2095", "demo2096", "demo2097", "demo2098", "demo2099"]

function HeaderAccountLinks(props) {
    const { app: {
        username, brandId
    }, theme } = props
    const { config: {
        showDBMessageLink = true,
        showDBPromoLink = true,
        showDBProfileLink = true,
        showDBFinanceLink = true,
        showDBBetDetailLink = true
    } } = theme;
    return (
        <AccountLinksWrapper>
            {showDBPromoLink && <Link to='/member/promotions'>
                <img src={`${config.imageV3Host}/static/cashsite/${theme.image.AccPromotionsIcon}`} alt="AccPromotionsIcon" />
                <Intl langKey="TITLE@PROMOTIONS" />
            </Link>}

            {showDBProfileLink && <Link to='/member/profile'>
                <img src={`${config.imageV3Host}/static/cashsite/${theme.image.AccProfileIcon}`} alt="AccProfileIcon" />
                <Intl langKey="SIDEBAR@PROFILE" />
            </Link>}

            {showDBFinanceLink && <Link to='/member/finance'>
                <img src={`${config.imageV3Host}/static/cashsite/${theme.image.AccFinancesIcon}`} alt="AccFinancesIcon" />
                <Intl langKey="SIDEBAR@FINANCES" />
            </Link>}

            {showDBBetDetailLink && <Link onClick={()=>CRMBetDetailClick(brandId)} to='/member/betDetails/mybets'>
                <img src={`${config.imageV3Host}/static/cashsite/${theme.image.AccBetdetailsIcon}`} alt="AccBetdetailsIcon" />
                <Intl langKey="SIDEBAR@BETDETAIL" />
            </Link>}

            {showDBMessageLink && <Link to='/member/message'>
                <img src={`${config.imageV3Host}/static/cashsite/${theme.image.AccMessagesIcon}`} alt="AccMessagesIcon" />
                <Intl langKey="SIDEBAR@MESSAGES" />
            </Link>}

            {/* <Link to='/member/betDetails/profitandloss'>
                <img src={`${config.imageS3Host}/static/snk/${theme.image.profitIcon}`}/>
                <Intl langKey="MEMBER@PROFIT_LOSS" />
            </Link>

            <Link to='/member/finance/accountStatement'>
                <img src={`${config.imageS3Host}/static/snk/${theme.image.accountIcon}`}/>
                <Intl langKey="MEMBER@ACCOUNT_STATEMENT" />
            </Link>

            <Link to='/member/betDetails/casinostatement'>
                <img src={`${config.imageS3Host}/static/snk/${theme.image.casinoIcon}`}/>
                <Intl langKey="MEMBER@CASINO_STATEMENT" />
            </Link>

            <Link to={{ pathname: '/member/betDetails/sportbookstatement' }}>
                <img src={`${config.imageS3Host}/static/snk/${theme.image.sportsBookIcon}`}/>
                <Intl langKey="MEMBER@SPORTBOOK_STATEMENT" />
            </Link>
            
            <Link to='/balancetransfer'>
                <img src={`${config.imageS3Host}/static/snk/${theme.image.transferIcon}`}/>
                <Intl langKey="MEMBER@TRANSFER" />
            </Link>
            {
                !CHANGE_PASSWORD_HIDDEN_USERS.includes(username) && 
                <Link to='/member/profile/password'>
                <img src={`${config.imageS3Host}/static/snk/${theme.image.passwordIcon}`}/>
                <Intl langKey="MEMBER@CHANGE_PASSWORD" />
                </Link>
            } */}
            {/* <Link to='/security'>
            <img src={`${config.imageS3Host}/static/snk/${theme.image.securityIcon}`}/>
                <Intl langKey="MEMBER@SECURITY" />

            </Link> */}
            {/* <Link to='/member/profile/accountinfo'>
                <img src={`${config.imageS3Host}/static/snk/${theme.image.accountIcon}`}/>
                <Intl langKey="MEMBER@Dashboard" />
            </Link> */}
        </AccountLinksWrapper>
    )
}

export default withTheme(withAppContext(HeaderAccountLinks));

const AccountLinksWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0px 15px;

    a {
        letter-spacing: 0.03em;
        text-transform: capitalize;
        color: #FFFFFF;
        /* color: ${theme('palette.secondary-background-color-new')}; */
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        cursor: pointer;
        position: relative;
        font-size: 14px;
        letter-spacing: 0.1em;
        text-transform: capitalize;
        border-bottom: 1px dashed #FFFFFF;

        :last-child {
            border-bottom: none;
        }

        img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }

        :hover {
            /* background: ${theme('palette.account-dropdown-hover')}; */
            background: ${theme('palette.secondary-background-color-linear-new1')};
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }

        /* :hover:before {
            content: "";
            width: 5px;
            height: 40px;
            display: flex;
            position: absolute;
            left: 0px;
            top: 0px;
            background-color: #d9b67c;
        } */
    }
`;