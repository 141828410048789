import React, { useState } from 'react';
import styled from 'styled-components';
import { Label as LabelStyle, Select } from '../../udb/commonStyle';

type LoginInputProps = {
  onChange: (e?:any) => void,
  value: string,
  placeholder?: any,
  type: string,
  id: string,
  error: { message?: string },
  name?: string,
  req: boolean,
  onBlur?: (e:any) => void;
  onKeyDown?: (e: any) => void;
  onWheel?: (e: any) => void;
  onKeyPress?: (e: any) => void;
  readonly?: boolean;
  onPaste?: (e: any) => void;
  onCopy?: (e: any) => void;
  autoComplete?:string
}

export const LoginInput = ({ onChange, onBlur, value, placeholder, type, id, error, onKeyDown, onWheel, onKeyPress, readonly, onPaste, onCopy, ...props }: LoginInputProps) => {
  return (
    <>
      <Label>
        <PlaceHolder className={!value ? '' : 'dispN'}>
          {placeholder}{props.req && <span>*</span>}
        </PlaceHolder>
        <input value={value} type={type ? type : 'text'} onChange={onChange} onBlur={onBlur} id={id} onKeyDown={onKeyDown} readOnly={readonly} onWheel={onWheel} onKeyPress={onKeyPress} onPaste={onPaste} onCopy={onCopy} {...props} />
      </Label>
    </>
  )
}

export const Label = styled(LabelStyle)`
input[type=password]    
{
position: relative !important;
}

select {
        border:none;
        width:100%;
        z-index: 1;
        background: transparent;
      }
      input {
        border:none;
        width:94%;
        z-index: 1;
        background: transparent;
      }
      .dispN {
        display:none
      }

      .disabled {
        opacity: 0.7;
        background: #c5c5c7;

         input {
            cursor: not-allowed;
         }
    }
    
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
}
`;

const PlaceHolder = styled.span`
  position: absolute;
  font-size: .8rem;
  z-index: 1;
  text-transform: uppercase;

  span {
    color:red;
    padding-left:2px;
  } 
`;