import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import Footer from 'src/components/footer/footer';

function FomoEvolutionCasinoChallenge(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = (brandId == 39 || brandId == 40) ? "FOMO7" : 'Baazi Adda';


    return (
        <>
            <InformationWrapper>
                <HeadWrapper>
                    <p>This world cup, experience casino games like nowhere else! We bring you an action-packed Live casino promotion in association with Evolution!</p>
                    <p>We present the bonus of the season with our casino games! Get your chips, place your bets, <span className='yellow'>win the prize pool of 18 Lakhs!</span></p>
                </HeadWrapper>
                <Divider></Divider>
                <LoyaltyWrapper>
                    <HeadingText className='titleText'>
                        <h1>Experience Excitement with Evolution!</h1>
                    </HeadingText>
                    <UnderLine></UnderLine>
                </LoyaltyWrapper>
                <JoiningWrapper>
                    <JoiningContainer>
                        <JoiningContent>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.FomoAviatorWingsOfAdventureRoImgOne}`}
                                alt="jetxPromoStepThree"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <h2>Register</h2>
                            <UnderLine></UnderLine>
                            <p>Open the door to endless thrills by creating your {domain} account. Register using your name and mobile number and get ready to embark on an unforgettable journey like no other.</p>
                        </JoiningContent>
                        <JoiningContent>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.FomoAviatorWingsOfAdventureRoImgThree}`}
                                alt="jetxPromoStepThree"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <h2>Deposit</h2>
                            <UnderLine></UnderLine>
                            <p>Deposit in your {domain} account. Keep it loaded and ready for action throughout  T20 WC.</p>
                        </JoiningContent>
                        <JoiningContent>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.FomoAviatorWingsOfAdventureRoImgTwo}`}
                                alt="jetxPromoStepThree"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <h2>Win Big</h2>
                            <UnderLine></UnderLine>
                            <p>With each game you play, you're one step closer to achieving greatness. Showcase your skills, dominate the leader board, and claim your share of the incredible prize pool.</p>
                        </JoiningContent>
                    </JoiningContainer>
                </JoiningWrapper>
                <FormulaWrapper>
                    <h1>Evolution Casino Challenge!</h1>
                    <UnderLine></UnderLine>
                    <FormulaContent>
                        <p>Play Lightning Dragon Tiger, Dragon Tiger and Red door Roulette and Win  additional bonuses and free bets. Total prizes are worth INR 18 lakhs!.</p>
                        <p>The tournament is valid from <span className='yellow'>2nd June to 29th June 2024!</span></p>
                        <p>This promotion is only applicable for Qualified games: please check the Promotion Schedule tables.</p>
                    </FormulaContent>
                </FormulaWrapper>
                <ROWrapper>
                    <RoContent>
                        <h1>Crazy T20</h1>
                        <UnderLine></UnderLine>
                        <TextWrapper>
                            <span>✦</span>
                            <p>Qualified game: Red Door Roulette</p>
                        </TextWrapper>
                        <TextWrapper>
                            <span>✦</span>
                            <PlayerWarpper>
                                <p>Players who win on any of the numbers:</p>
                                <p>0-01-02-03-22-23-24-31-32-33 will receive an additional prize of 100 INR.</p>
                            </PlayerWarpper>
                        </TextWrapper>
                        <TextWrapper>
                            <span>✦</span>
                            <p>The minimum combined bet amount on numbers is 300 INR.</p>
                        </TextWrapper>
                        <TextWrapper>
                            <span>✦</span>
                            <p>Each player can win this promotional prize once a day.</p>
                        </TextWrapper>
                        <TextWrapper>
                            <span>✦</span>
                            <p>Evolution reserves the right to modify, suspend, or terminate the promotion at any time and without prior notice.</p>
                        </TextWrapper>
                    </RoContent>
                </ROWrapper>
                <ROWrapperLeft>
                    <RoContent>
                        <h1>Luck Bombs</h1>
                        <UnderLine></UnderLine>
                        <TextWrapper>
                            <span>✦</span>
                            <p>Qualified games: Dragon Tiger & Lightning Dragon Tiger</p>
                        </TextWrapper>
                        <TextWrapper>
                            <span>✦</span>
                            <p>Players will randomly get the bonus of INR 100.</p>
                        </TextWrapper>
                        <TextWrapper>
                            <span>✦</span>
                            <p>There’s no minimum wager requirement.</p>
                        </TextWrapper>
                        <TextWrapper>
                            <span>✦</span>
                            <p>Each player can win Luck Bombs more than 5 times in one day.</p>
                        </TextWrapper>
                        <TextWrapper>
                            <span>✦</span>
                            <p>Evolution reserves the right to modify, suspend, or terminate the promotion at any time and without prior notice.</p>
                        </TextWrapper>
                    </RoContent>
                </ROWrapperLeft>
                <Termsandconditions>
                    <TermsandconditionsHeading>
                        <h1>Terms & Conditions</h1>
                    </TermsandconditionsHeading>
                    <UnderLine></UnderLine>
                    <TermsandconditionsBody>
                        <ol>
                            <li>This promotion is valid for all players.</li>
                            <li>The promotion is valid from 2nd June to 29th June 2024.</li>
                            <li>The winners will be chosen based on highest bet amount.</li>
                            <li>No Minimum bet amount required to enter this contest.</li>
                            <li>No Minimum odds to be maintained.</li>
                            <li>Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.</li>
                            <li>Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. {domain} reserves the right to disqualify players if collusion or any foul play takes place in the promotion.</li>
                            <li>{domain} reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.</li>
                            <li>{domain} General Terms & Conditions apply.</li>
                        </ol>
                    </TermsandconditionsBody>
                </Termsandconditions>
            </InformationWrapper>
            <Footer />
        </>
    )
}



export default withTheme(withAppContext(FomoEvolutionCasinoChallenge));

export const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 0 3rem 8rem 3rem;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em;
    border: 1px solid #E5B358;
    border-radius: 0 0 20px 20px;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.FomoEvolutionCasinoChallengeBackground")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
`;

const HeadWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 20% 3rem 20%;
    gap: 1rem;

    p {
        margin: 0;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 160.3%; /* 25.648px */
    }

    .yellow {
        color: #FEBA3B;
        font-weight: 700;
        text-transform: capitalize;
    }
`;

const LoyaltyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: radial-gradient(37.24% 47.13% at 50% 0%, #051829 0%, rgba(1, 4, 6, 0.00) 100%);
    padding: 3rem;
    margin: 0 -2rem;

    .joiningTxt {
        padding-top: 1.5rem;
    }

    .titleText {
        padding: 1rem 0 1.2rem 0;
        h1 {
            margin: 0;
        }
    }
`;

const HeadingText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 91.67%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    p {
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120.3%; /* 19.248px */
    }
`;

export const UnderLine = styled.div`
    height: 2px;
    width: 184px;
    background: radial-gradient(#FFF 0%, rgba(153, 153, 153, 0.00) 80%);
`;

export const Divider = styled.div`
    height: 2px;
    width: 100%;
    background: radial-gradient(#1182C1 0%, #00000000 50%);
`;

const JoiningWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: -999;
    margin: 0 -3rem;
`;

const JoiningContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 3rem;
    padding: 2rem 0 3rem 0;
`;

const JoiningContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 5rem 1.5rem 2rem 1.5rem;
    width: 20%;
    border-radius: 10px;
    border: 0.8px solid rgba(255, 255, 255, 0.50);
    background: radial-gradient(136.33% 139.78% at 0% 0%, rgba(254, 186, 59, 0.00) 63.5%, rgba(254, 186, 59, 0.20) 100%), radial-gradient(136.33% 139.78% at 0% 0%, rgba(255, 95, 31, 0.20) 0%, rgba(255, 95, 31, 0.00) 53.5%), rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(12.5px);
    position: relative;

    p {
        margin-bottom: 0;
        color: #FFF;
        text-align: left;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 145%; /* 23.2px */
    }

    .yellow {
        color: #FEBA3B;
        font-weight: 700;
    }

    img {
        width: 10rem;
        position: absolute;
        right: 2%;
        bottom: 65%;
    }

    h2 {
        color: #FEBA3B;
        text-align: left;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 145%; /* 29px */
        text-transform: capitalize;
        margin: 0;
        padding-bottom: 1rem;
        width: 100%;
    }
`;

const FormulaWrapper = styled(JoiningWrapper)`
    justify-content: flex-start;
    align-items: flex-start;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.FomoEvolutionCasinoChallengeCasinoBackground")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: left;
    padding: 8rem 0 8rem 10rem;

    h1 {
        background: linear-gradient(180deg, #FED546 26.5%, #FE7C23 102.11%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
    }
`;

const FormulaContent = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 2.2rem;
    width: 37%;
    text-align: left;
    gap: 0.5rem;

    p {
        margin: 0;
        padding: 0;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 19.248px */
    }

    p {
        margin: 0;
        padding: 0.5rem 0;
        text-align: left;
    }

    .yellow {
        color: #FEBA3B;
        font-weight: 700;
        line-height: 180%;
    }
`;

const ROWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    border-radius: 30px;
    width: 85%;
    height: auto;
    margin: 3rem 7.5% 3rem 7.5%;
    padding: 8rem 3rem;
    /* padding-left: 40rem; */
    position: relative;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.FomoEvolutionCasinoChallengeRoRightBackground")});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    ::after {
        position: absolute;
        top: -2px; bottom: -2px;
        left: -2px; right: -2px;
        background: linear-gradient(90deg, #53C1FF, black, #53C1FF, black, #53C1FF);
        content: '';
        z-index: -1;
        border-radius: 30px;
    }
`;

const ROWrapperLeft = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 30px;
    width: 85%;
    height: auto;
    margin: 1rem 7.5% 3rem 7.5%;
    padding: 8rem 3rem;
    position: relative;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.FomoEvolutionCasinoChallengeRoLeftBackground")});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    ::after {
        position: absolute;
        top: -2px; bottom: -2px;
        left: -2px; right: -2px;
        background: linear-gradient(90deg, #53C1FF, black, #53C1FF, black, #53C1FF);
        content: '';
        z-index: -1;
        border-radius: 30px;
    }
`;

const RoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 40%;

    h1 {
        margin: 0;
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 91.67%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
        text-align: left;
    }
`;

const TextWrapper = styled.div`
    display: flex;

    span {
        color: #FEBA3B;
        font-weight: 700;
    }

    p {
        margin: 0;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120.3%; /* 19.248px */
        text-align: left;
        padding-left: 0.5rem;
    }
`;

const PlayerWarpper = styled(TextWrapper)`
    flex-direction: column;
`;

const Termsandconditions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    width: 75%;
    height: auto;
    margin: 1rem 12.5% 3rem 12.5%;
    padding: 5rem 3rem;
    position: relative;
    background: linear-gradient(#020405, rgba(0, 0, 0, 0.935), #020405);
    background-clip: padding-box;
    border: 2px solid transparent;

    ::after {
        position: absolute;
        top: -2px; bottom: -2px;
        left: -2px; right: -2px;
        background: linear-gradient(90deg, #53C1FF, black, #53C1FF, black, #53C1FF);
        content: '';
        z-index: -1;
        border-radius: 30px;
    }
`;

const TermsandconditionsHeading = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    padding-bottom: 2rem;

    h1 {
        margin: 0;
        padding: 0;
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 121.53%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
    }
`;

const TermsandconditionsBody = styled.div`
    ol {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        padding-top: 1rem;
    }
    li {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
`;