import React from 'react'

type Props = {}

const AccordionOpenIcon = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
            <path d="M10.2321 15C9.46225 16.3333 7.53775 16.3333 6.76795 15L0.27276 3.75C-0.49704 2.41667 0.46521 0.75 2.00481 0.75L14.9952 0.750001C16.5348 0.750002 17.497 2.41667 16.7272 3.75L10.2321 15Z" fill="white" />
        </svg>
    )
}

export const AccordionCloseIcon = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
            <path d="M2.00316 15.2516C0.463564 15.2516 -0.498688 13.585 0.271112 12.2516L6.7663 1.00165C7.5361 -0.331686 9.4606 -0.331687 10.2304 1.00165L16.7256 12.2516C17.4954 13.585 16.5331 15.2516 14.9935 15.2516L2.00316 15.2516Z" fill="white" />
        </svg>
    )
}

export default AccordionOpenIcon;