import { useEffect } from 'react'
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import FooterContainer from "src/components/footer/footer";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from 'src/components/Placeholder';

const IndianGalaxsysLeague = (props: any) => {

    const { app: { brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = brandId === 31 || brandId === 32 ? 'Yolo247' : brandId === 33 ? 'Baazi Adda' : 'Betdaily';

    return (
        <>
            <InnerPromoWrapper>
                <IndianGalaxysontentWrapper>
                    <InnerBox>
                        <MainHeads><h3 className='violet'>100 days of Cricket – 100 days of Galaxsys on {domain}!</h3></MainHeads>
                        <SecondRow>
                            <LiTextContent>
                                <TextContent>
                                    {domain} introduces 100 days of cash prizes and free bets on your favourite Galaxsys games – Cash Show & Rocketon- that take your betting experience to new heights. Brace yourself for an edge-of-the-seat adventure where strategy meets luck. A total pool prize of <span>₹ 30,00,000 </span> awaits you!
                                </TextContent>
                            </LiTextContent>
                            <LiTextContent>
                                <TextContent>
                                    Get your gaming wits on, walk the ramp & take off into space!
                                </TextContent>
                            </LiTextContent>
                        </SecondRow>

                        <IndianGalaxysBox>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.indianGalaxyLeagueModel}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                            <h4 className='violet'>Embark on a thrilling ride with <br /> supermodels & rockets:</h4>
                            <p>
                                <span> Bonus :</span> Cash Show & Rocketon players are only eligible for the monthly pool prizes. This bonus scheme is valid from 22nd March to 29th June and renews at the end of every month.
                            </p>
                            <p>
                                <span>Strategy Meets Luck : </span> These games are not just about glamour, thrill, and luck; it's all strategy. Plot your take-off wisely, watch supermodels walk the ramp, and seize opportunities to emerge as the ultimate betting maverick.
                            </p>
                            <p>
                                <span>Bet and Win : </span> Place bets and be eligible for the monthly pool prizes and free bets.
                            </p>

                            <p>
                                <span>Free Bets : </span> Random Free bets on Cash Show & Rocketon will be given to players who bet on these games on a weekly basis.
                            </p>

                            <IndianGalaxysBoxInner>
                                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.indianGalaxyLeagueLogo}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                            </IndianGalaxysBoxInner>
                        </IndianGalaxysBox>

                        <IndianGalaxysGameSec>
                            <h3 className='yellow'>Cash Show: Catwalk Century</h3>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.indianGalaxyLeagueCashShow}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />

                            <IndianGalaxyBoxWrap>
                                <IndianGalaxyBox>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.indianGalaxyLeagueIllus01}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                    <h4>Visit {domain}</h4>
                                    <p>
                                        Log in to your {domain} account, head to the Instant Games section, and choose Cash Show!
                                    </p>
                                </IndianGalaxyBox>

                                <IndianGalaxyBox>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.indianGalaxyLeagueIllus02}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                    <h4>Wager</h4>
                                    <p>
                                        The more you wager, the greater your chances of winning.
                                    </p>
                                </IndianGalaxyBox>

                                <IndianGalaxyBox>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.indianGalaxyLeagueIllus03}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                    <h4>Monthly Prizes</h4>
                                    <p>
                                        Every Month, our top 100 users with the highest wager will get a pool prize of ₹ 5 Lakhs.
                                    </p>
                                </IndianGalaxyBox>

                                <IndianGalaxyBox>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.indianGalaxyLeagueIllus04}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                    <h4>Validity</h4>
                                    <p>
                                        Promotion will be valid from 22 Mar’24 to 29 Jun’24.
                                    </p>
                                </IndianGalaxyBox>
                            </IndianGalaxyBoxWrap>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.indianGalaxyLeagueCashShowPrizeTable}`} effect="none" width="100%" className="prizeTable" placeholder={<PlaceholderImage />} />

                        </IndianGalaxysGameSec>

                        <IndianGalaxysGameSec>
                            <h3 className='red'>Rocketon: Launch Before Wicket</h3>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.indianGalaxyLeagueRocketon}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />

                            <IndianGalaxyBoxWrap>
                                <IndianGalaxyBox className='redbox'>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.indianGalaxyLeagueIllus01}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                    <h4>Visit {domain}</h4>
                                    <p>
                                        Log in to your {domain} account, head to the Instant Games section, and choose Rocketon!
                                    </p>
                                </IndianGalaxyBox>

                                <IndianGalaxyBox className='redbox'>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.indianGalaxyLeagueIllus02}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                    <h4>Wager</h4>
                                    <p>
                                        The more you wager, the greater your chances of winning.
                                    </p>
                                </IndianGalaxyBox>

                                <IndianGalaxyBox className='redbox'>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.indianGalaxyLeagueIllus03}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                    <h4>Monthly Prizes</h4>
                                    <p>
                                        Every Month, our top 100 users with the highest wager will get a pool prize of ₹ 5 Lakhs.
                                    </p>
                                </IndianGalaxyBox>

                                <IndianGalaxyBox className='redbox'>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.indianGalaxyLeagueIllus04}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                    <h4>Validity</h4>
                                    <p>
                                        Promotion will be valid from 22 Mar’24 to 29 Jun’24.
                                    </p>
                                </IndianGalaxyBox>
                            </IndianGalaxyBoxWrap>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.indianGalaxyLeagueRocketonPrizeTable}`} effect="none" width="100%" className="prizeTable" placeholder={<PlaceholderImage />} />

                        </IndianGalaxysGameSec>

                        <IndianGalaxysTerms>
                            <h4 className='violet'>Terms and Conditions:</h4>
                            <ul>
                                <li>This promotion is valid for all new & existing players.</li>
                                <li>This promotion is valid from <span>22nd Mar 2024 to 29th Jun 2024.</span></li>
                                <li>Results will be announced within 5 days from the end of every promo period.</li>
                                <li>The total cash prize is <span>₹ 3,000,000/-</span></li>
                                <li>Void/Cancelled/Draw bets and cashed-out bets do not count towards the deposit or bonus rollover requirements.</li>
                                <li>Multiple accounts will not be considered for this promotion eligibility/criteria. All players will be subject to duplicate checking.
                                    {domain} reserves the right to disqualify players if collusion or foul play occurs in the promotion.
                                </li>
                                <li>
                                    {domain} reserves the right to amend, cancel, reclaim or refuse any promotion at its own discretions
                                </li>
                                <li>{domain} General Terms & Conditions apply.</li>
                            </ul>
                        </IndianGalaxysTerms>
                    </InnerBox>
                </IndianGalaxysontentWrapper>
            </InnerPromoWrapper>
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(IndianGalaxsysLeague));


//Styled Components
const IndianGalaxysontentWrapper = styled.div`
    width: 100%;
    background: url(${config.imageV3Host}/static/cashsite/${theme('image.indianGalaxyLeagueInnerBg')});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    gap: 2em;

    p {
        color: #FFF;
        text-align: center;
        font-size: 21px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        margin: 5px 0px;
    }

    .m-0 {
        margin: 0;
    }

    .mt-5 {
        margin-top: 2em;
        width: 100%;
        margin-bottom: 0em;
    }

    .p-0 {
        padding: 0rem 20rem;

        span{
            color: #E5A20D;
        }
    }

    a {
        width: 100%;
        height: auto;

        & span {
            display: flex !important;
            justify-content: center !important;

            & img {
                width: 60%;
                height: 80%;
            }
        }
    }

    .lazy-load-image-background {
        display: flex !important;
        justify-content: center !important;
    }

    .container {
        max-width: 1620px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .violet {
        color: #C053FF;
    }
`;


const InnerPromoWrapper = styled.div`
    width: 98%;
    height: auto;
    border: 1px solid white;
    border-radius: 1px;
    background: rgba(111,111,111,0.5);
    margin: 0em 1.2em;
    -webkit-backdrop-filter: brightness(0.5);
    backdrop-filter: brightness(0.5);
`

const IndianGalaxysBox = styled.div`
   width: 90%;
   height: auto;
   border-radius: 40px;
   border: 1px solid #642D83;
   background: linear-gradient(135deg, rgba(255, 255, 255, 0.10) 0%, rgba(220, 145, 255, 0.10) 100%);
   padding: 2em;
   display: flex;
   flex-direction: column;
   gap: 15px;
   margin-top: 7em;
   padding-bottom: 2em;

   & > span > img {
    width: 35%;
    height: auto;
    margin-top: -140px;
   }

   & > h4 {
        color: #E50539;
        text-align: center;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 50.26px */
        text-transform: capitalize;
        margin: 3px 0px;
   }

   & p {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%;
        text-align: left;
        text-transform: capitalize;

        & span {
            color: #C053FF;
            text-align: justify;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 24.5px; /* 122.5% */
        }
    }
`

const IndianGalaxysBoxInner = styled.div`
    width: 95%;
    height: auto;
    display: flex;
    gap: 15px;
    align-items: center;
    margin: 1.5 0em;
    padding: 1em 0em 2em;

    & img {
        width: 60% !important;
        height: auto;
    }
`


const IndianGalaxysTerms = styled.div`
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin: 5em 0em 2em 0em;


    & li {
        color: #FFF;
        text-align: justify;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 165%;
        letter-spacing: -0.8px;
        text-transform: capitalize;

        & span {
            color: #ffffff;
            font-weight: 600;
        }
    }

    & h4 {
        text-align: left;
        font-size: 26px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 1.3px;
        text-transform: capitalize;
        color: #C053FF;
        margin: 10px 0px 0px 15px;
    }
`


const SecondRow = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 10px;
    margin-top: 2em;
`

const LiTextContent = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;

    p {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-transform: capitalize;
        margin: 3px 0px;
        text-align: left;
    }

    span {
        text-transform: uppercase;
        font-size: 18px;
    }
`;


const TextContent = styled.p`
    color: ${theme('palette.catch99TextContentColor')};
    font-size: 20px;

    & span {
        color: #ffffff !important;
        font-size: 20px !important;
        font-style: normal;
        font-weight: 900;
        line-height: 130%;
        text-transform: capitalize;
        margin: 0px;
        display: contents !important;
    }
`;

const StarBox = styled.p`
    color: #E50539 !important;
`
const MainHeads = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 90%;
    padding-top: 15px;

    & h3 {
        color: #E50539;
        text-align: left;
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        line-height: 179.5%; /* 50.26px */
        text-transform: capitalize;
        margin: 0px 0px;
    }
`

const InnerBox = styled.div`
    padding: 3em 0em;
    margin: auto;
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const IndianGalaxysGameSec = styled.div`
    display: flex;
    width: 90%;
    height: auto;
    flex-direction: column;
    align-items: center;
    margin-top: 3em;
    gap: 15px;

    & img {
        width: 60%;
        height: auto;
    }

    & h3 {
        color: #F2DA8E;
        text-align: center;
        font-size: 29px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 50.26px */
        text-transform: uppercase;
        margin: 3px 0px;
    }

    .prizeTable {
        width: 78%;
        height: auto;
        margin-top: 3em;
    }

    .red {
        color: #F00 !important;
    }
`

const IndianGalaxyBoxWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items:center;
    gap: 20px;
    width: 100%;
    margin-top: 5em;

    
    .redbox {
        border-radius: 20px;
        border: 1.5px solid #F00;
        background: radial-gradient(100% 100% at 49.8% 0%, rgba(255, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%), rgba(0, 0, 0, 0.50);
        box-shadow: 0px 0px 10px 0px rgba(255, 0, 0, 0.25);
    }
`

const IndianGalaxyBox = styled.div`
    border-radius: 20px;
    border: 1.5px solid #F2DA8E;
    background: radial-gradient(100% 100% at 49.8% 0%, rgba(242, 218, 142, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%), rgba(0, 0, 0, 0.50);
    box-shadow: 0px 0px 10px 0px rgba(242, 218, 142, 0.25);
    width: 18%;
    min-height: 270px;
    height: auto;
    padding: 1em 1em 2em;
    display: flex;
    flex-direction: column;
    gap: 10px;
 

    & h4 {
        color: #F00;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        margin: 3px 0px;
    }

    & p {
        color: #FFF;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 145%; /* 23.2px */
        text-transform: capitalize;
        margin: 3px 0px;
    }

    & img {
       width: 70%;
       height: auto;
       object-fit: contain;
       margin-top: -80px;
    }
`