import React from 'react'

type Props = {}

const NetbankingDepositIcon = (props: Props) => {
  return (
    <svg
    width={55}
    height={53}
    viewBox="0 0 55 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_104_61)">
      <path
        d="M31.6061 52.085C31.483 51.8489 30.159 50.6958 29.4648 49.9543C28.7706 49.2134 28.7706 46.9902 28.7706 46.9902H18.2226C18.2226 46.9902 18.2226 49.2134 17.5284 49.9543C16.8342 50.6951 15.5102 51.8489 15.3871 52.085C15.3423 52.1705 15.7437 52.4371 15.9816 52.4555C16.2195 52.4738 23.496 52.4555 23.496 52.4555C23.496 52.4555 30.7724 52.4738 31.0103 52.4555C31.2482 52.4371 31.6496 52.1705 31.6048 52.085H31.6061Z"
        fill="white"
      />
      <path
        d="M0.405518 41.2268V43.3474C0.405518 45.052 1.65633 46.4331 3.19871 46.4331H43.7959C45.3383 46.4331 46.5891 45.052 46.5891 43.3474V41.2268H0.405518Z"
        fill="white"
      />
      <path
        d="M44.6251 20.8539V38.7516H2.36946V12.1276H34.0683C34.0191 11.7281 33.9932 11.326 33.9932 10.9239C33.9932 10.6326 34.0065 10.3445 34.0311 10.0596H3.19871C3.12676 10.0596 3.05482 10.0627 2.98351 10.0684C1.54147 10.1875 0.405518 11.4894 0.405518 13.0781V40.6708H46.5891V20.5107C45.9574 20.6886 45.3004 20.8051 44.6251 20.8539V20.8539Z"
        fill="white"
      />
      <path
        d="M35.3518 21.76L24.7565 15.0663C24.6309 14.9878 24.4662 14.9878 24.3406 15.0663L13.7453 21.76C13.6317 21.8347 13.561 21.9607 13.561 22.095V22.8827C13.561 23.0992 13.7377 23.2765 13.9536 23.2765H15.1305V24.4575C15.1305 24.674 15.3073 24.8513 15.5231 24.8513H33.5747C33.7905 24.8513 33.9672 24.674 33.9672 24.4575V23.2765H35.1442C35.36 23.2765 35.5367 23.0992 35.5367 22.8827V22.095C35.5367 21.9614 35.4661 21.8353 35.3525 21.76H35.3518ZM24.5483 22.4882C21.9545 22.4084 21.9551 18.63 24.5483 18.5509C27.142 18.6307 27.1414 22.409 24.5483 22.4882Z"
        fill="white"
      />
      <path
        d="M26.5105 31.9382V25.6384H22.5864V31.9382H26.5105Z"
        fill="white"
      />
      <path
        d="M21.0167 31.9382V25.6384H17.0927V31.9382H21.0167Z"
        fill="#FF9657"
      />
      <path
        d="M32.0043 31.9382V25.6384H28.0802V31.9382H32.0043Z"
        fill="#09AD4F"
      />
      <path
        d="M35.1442 34.3007H33.9672V33.1197C33.9672 32.9032 33.7905 32.7259 33.5747 32.7259C32.565 32.7259 29.1502 32.7259 28.0805 32.7259C26.208 32.7259 22.8892 32.7259 21.0167 32.7259C19.9401 32.7259 16.5524 32.7259 15.5225 32.7259C15.3066 32.7259 15.1299 32.9032 15.1299 33.1197V34.3007H13.953C13.7371 34.3007 13.5604 34.478 13.5604 34.6945V36.6632C13.5604 36.8797 13.7371 37.057 13.953 37.057H35.1442C35.3601 37.057 35.5368 36.8797 35.5368 36.6632V34.6945C35.5368 34.478 35.3601 34.3007 35.1442 34.3007Z"
        fill="white"
      />
      <path
        d="M44.5608 0.184631C39.1233 0.184631 34.7158 4.60697 34.7158 10.0627C34.7158 15.5184 39.1233 19.9408 44.5608 19.9408C49.9982 19.9408 54.4057 15.5184 54.4057 10.0627C54.4057 4.60697 49.9982 0.184631 44.5608 0.184631ZM44.5267 18.283C40.0207 18.283 36.3686 14.618 36.3686 10.0975C36.3686 5.57704 40.0207 1.91139 44.5267 1.91139C49.0327 1.91139 52.6848 5.57641 52.6848 10.0969C52.6848 14.6174 49.032 18.2824 44.5267 18.2824V18.283Z"
        fill="#1DB272"
      />
      <path
        d="M44.492 17.1084C48.3702 17.1084 51.5141 13.9539 51.5141 10.0627C51.5141 6.17145 48.3702 3.01697 44.492 3.01697C40.6138 3.01697 37.4698 6.17145 37.4698 10.0627C37.4698 13.9539 40.6138 17.1084 44.492 17.1084Z"
        fill="#1DB272"
      />
      <path
        d="M47.9594 7.84017L47.6041 9.19777H41.0762L41.4264 7.84017H47.9588H47.9594ZM44.5667 15.3621L41.2371 11.3861L41.2421 10.1653H42.8874C43.2541 10.1653 43.5608 10.0969 43.8075 9.96015C44.0543 9.82338 44.2392 9.63785 44.3622 9.40356C44.4853 9.16928 44.549 8.90206 44.5522 8.60255C44.549 8.15297 44.4178 7.78698 44.1578 7.5033C43.9015 7.21646 43.4781 7.07335 42.8874 7.07335H41.0768L41.4763 5.35925H42.888C43.7154 5.35925 44.4052 5.48463 44.9567 5.73538C45.5114 5.98296 45.9273 6.3363 46.2031 6.79537C46.4821 7.25445 46.6234 7.80154 46.6266 8.43665C46.6234 9.00021 46.5294 9.49158 46.3445 9.9114C46.1627 10.3312 45.8756 10.6814 45.4831 10.9613C45.0937 11.2411 44.5856 11.4495 43.9596 11.5862L43.882 11.6154L46.8613 15.2734V15.3614H44.5686L44.5667 15.3621ZM47.9695 5.35925L47.6041 6.73648H42.2298L42.5952 5.35925H47.9695Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_104_61">
        <rect
          width={54.0002}
          height={52.2791}
          fill="white"
          transform="translate(0.405518 0.184631)"
        />
      </clipPath>
    </defs>
  </svg>
  )
}

export default NetbankingDepositIcon