import React from 'react'

type Props = {}

const FastUpiDepositIcon = (props: Props) => {
  return (
    <svg
    width={72}
    height={54}
    viewBox="0 0 72 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.4889 51.0221H1.71157C1.03697 51.0221 0.489685 51.5653 0.489685 52.2348C0.489685 52.9043 1.03697 53.4475 1.71157 53.4475H13.4889C14.1635 53.4475 14.7108 52.9043 14.7108 52.2348C14.7108 51.5653 14.1635 51.0221 13.4889 51.0221Z"
      fill="white"
    />
    <path
      d="M11.0598 26.2149H15.4583C16.1329 26.2149 16.6802 25.6717 16.6802 25.0022C16.6802 24.3327 16.1329 23.7895 15.4583 23.7895H11.0598C10.3852 23.7895 9.83789 24.3327 9.83789 25.0022C9.83789 25.6717 10.3852 26.2149 11.0598 26.2149Z"
      fill="white"
    />
    <path
      d="M58.9289 17.9427C58.3588 17.3141 57.472 16.5596 57.472 16.5596C55.8206 15.1489 54.0945 14.1839 52.0802 13.3383C50.0919 12.5041 48.0098 11.9848 45.8669 11.788V11.7823H45.8796C45.8796 11.7823 45.6895 11.7823 45.2461 11.7195H42.5224C42.3324 11.7823 42.079 11.7823 42.079 11.7823H41.9276V11.7874C38.4349 12.1086 35.0967 13.2849 32.1703 15.2375H18.6959C18.0213 15.2375 17.4741 15.7807 17.4741 16.4503C17.4741 17.1198 18.0213 17.663 18.6959 17.663H29.171C28.6212 18.198 28.0998 18.7632 27.6102 19.3566C26.4859 20.719 25.5433 22.2108 24.8009 23.8007L19.7677 23.7907H19.7652C19.0918 23.7907 18.5446 24.332 18.5433 25.0009C18.542 25.6704 19.088 26.2148 19.7626 26.2161L26.4105 26.2293L26.7247 25.4812C27.4189 23.8284 28.3526 22.2844 29.501 20.8931C30.6412 19.5113 31.9726 18.3023 33.4593 17.3002C36.1368 15.4947 39.2108 14.4291 42.4249 14.1801H45.3684C47.3574 14.3341 49.2887 14.8006 51.1275 15.5726C53.3407 16.5018 55.3291 17.8321 57.0368 19.527C58.7445 21.2219 60.0849 23.1953 61.0211 25.3919C61.9902 27.6658 62.4818 30.0818 62.4818 32.5733C62.4818 35.0647 61.9902 37.4807 61.0211 39.7546C60.0849 41.9512 58.7445 43.9246 57.0368 45.6195C55.3291 47.3144 53.3407 48.6447 51.1275 49.5739C48.8364 50.5357 46.4022 51.0236 43.8919 51.0236H18.6966C18.022 51.0236 17.4747 51.5668 17.4747 52.2363C17.4747 52.9059 18.022 53.449 18.6966 53.449H43.8931C46.7322 53.449 49.487 52.8971 52.0809 51.8082C54.5854 50.7564 56.8347 49.2514 58.7661 47.3345C60.6974 45.4177 62.2138 43.1853 63.2736 40.6995C64.3707 38.1251 64.9268 35.391 64.9268 32.5733C64.9268 29.7555 64.3707 27.0214 63.2736 24.447C62.3906 22.3755 61.436 20.7579 59.943 19.075"
      fill="white"
    />
    <path
      d="M28.2822 42.5946C26.8291 40.3691 25.8941 37.9128 25.5046 35.2938L25.3506 34.2584H7.60891C6.93431 34.2584 6.38702 34.8015 6.38702 35.4711C6.38702 36.1406 6.93431 36.6838 7.60891 36.6838H23.2673C23.6423 38.556 24.27 40.3483 25.141 42.0414H13.7658C13.0912 42.0414 12.544 42.5845 12.544 43.2541C12.544 43.9236 13.0912 44.4668 13.7658 44.4668H29.5053L28.2828 42.5946H28.2822Z"
      fill="white"
    />
    <path
      d="M9.16838 42.0405H6.0082C5.3336 42.0405 4.78632 42.5836 4.78632 43.2532C4.78632 43.9227 5.3336 44.4659 6.0082 44.4659H9.16838C9.84298 44.4659 10.3903 43.9227 10.3903 43.2532C10.3903 42.5836 9.84298 42.0405 9.16838 42.0405Z"
      fill="white"
    />
    <path
      d="M43.9792 48.3704C52.5501 48.3704 59.4982 41.4745 59.4982 32.9679C59.4982 24.4613 52.5501 17.5654 43.9792 17.5654C35.4082 17.5654 28.4601 24.4613 28.4601 32.9679C28.4601 41.4745 35.4082 48.3704 43.9792 48.3704Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.8484 32.3133L46.349 21.4397L40.239 42.8241L51.8484 32.3126V32.3133Z"
      fill="#097939"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.9366 32.3133L42.4378 21.4397L36.3278 42.8241L47.9366 32.3126V32.3133Z"
      fill="#ED752E"
    />
    <ellipse cx={61.6152} cy={10.3136} rx={10.1146} ry={10.0704} fill="white" />
    <path
      d="M69.4393 10.3154C69.4393 14.613 65.9392 18.102 61.6154 18.102C57.2916 18.102 53.7916 14.613 53.7916 10.3154C53.7916 6.0178 57.2916 2.52878 61.6154 2.52878C65.9392 2.52878 69.4393 6.0178 69.4393 10.3154Z"
      fill="white"
      stroke="#909090"
      strokeWidth={1.36884}
    />
    <ellipse cx={61.6167} cy={10.312} rx={7.17031} ry={7.13897} fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.8599 9.33082L63.0076 9.46584C63.0506 9.47209 63.0879 9.49972 63.1071 9.53934C63.1268 9.58053 63.1237 9.62901 63.0998 9.66759L63.0983 9.67072L62.0484 11.304L63.1164 11.4297C63.1532 11.4359 63.1853 11.4594 63.2024 11.4922C63.2221 11.5292 63.221 11.5741 63.1998 11.61L59.8901 15.767C59.8616 15.7978 59.815 15.8046 59.7788 15.7837C59.742 15.7623 59.7244 15.718 59.7368 15.6768L60.7012 12.281L59.4628 12.136C59.4374 12.1293 59.4167 12.11 59.4079 12.085C59.4001 12.0625 59.4022 12.0375 59.4141 12.0167L60.5127 10.3072L59.3649 10.1717C59.3401 10.1618 59.3214 10.1409 59.3142 10.1149C59.3074 10.0888 59.3126 10.0617 59.3287 10.0403L62.4446 6.12834C62.4923 6.07725 62.5674 6.06057 62.6321 6.08977C62.7052 6.12209 62.7445 6.20185 62.7259 6.27953L61.8599 9.32977V9.33082Z"
      fill="#F4A322"
    />
  </svg>
  )
}

export default FastUpiDepositIcon