const _myProfileDataBD = {
    33:[
      {
        id: "1",
        question: "Why Baazi Adda?",
        answer:"Baazi Adda is not just another white label product or trying to offer similar products from other players in the market.The highly skilled team have built this product from scratch after intensive market research with the aim to be the best eGaming entertainment service provider.We offer a wide range of options to bet on: from multiple sports events, virtual sports, special markets to live casino and live card games. Our odds and policies are designed to be the best. We’ve also simplified the promotions to ensure you are entertained - 24/7.",
        lists: [
          {
            id: "1",
            content: "300% welcome bonus",
          },
          {
            id: "2",
            content: "2% reload bonus on every deposit",
          },
          {
            id: "3",
            content: "2% cashback bonus every week<",
          },
          {
            id: "4",
            content: "Faster withdrawals",
          },
          {
            id: "5",
            content: "Exciting loyalty programs",
          },
          {
            id: "6",
            content: "24/7 client support to ensure 24/7 entertainment",
          },
        ]
      },
      {
        id: 2,
        question: "How to log in at Baazi Adda?",
        answer: "",
        lists: [
          {
            id: "1",
            content: "Click on the login option",
          },
          {
            id: "2",
            content: "Fill in your personal details",
          },
          {
            id: "3",
            content: "Get your phone number verified through OTP",
          },
          {
            id: "4",
            content: "Accept T&C's",
          },
          {
            id: "5",
            content: "Click on the sign-up option & you are ready to roll!!",
          },
        ]
      },
      {
        id: "3",
        question: "How can I update my personal information?",
        answer: "",
        lists: [
          {
            id: "1",
            content: "Click on the drop-down arrow next to your username",
          },
          {
            id: "2",
            content: "Choose Profile",
          },
          {
            id: "3",
            content: "Click on 'Account Info' ",
          },
          {
            id: "4",
            content: "Accept T&C's",
          },
          {
            id: "5",
            content: "Then click on the edit option.",
          },
        ]
      },
      {
        id: "4",
        question: "How to reset password?",
        answer: "",
        lists: [
          {
            id: "1",
            content: "Click on the dropdown arrow next to your username",
          },
          {
            id: "2",
            content: "Choose Profile",
          },
          {
            id: "3",
            content: "Select the option password on the left menu",
          },
          {
            id: "4",
            content: "Change your password",
          },
        ]
      },
      {
        id: "5",
        question: "Where can I see my betting history?",
        answer: "",
        lists: [
          {
            id: "1",
            content: "Click on the dropdown arrow next to your username",
          },
          {
            id: "2",
            content: "Select the option Bet details.",
          },
        ],
        redirect: ""
      },
      {
        id: "6",
        question: "I did not receive OTP while verification, what should I do now?",
        answer:
          "Please verify the entered mobile number once again & if the problem still persists in spite of having mobile in network coverage area, Kindly contact customer care for assistance",
        lists: [],
      },
      {
        id: "7",
        question: "Why I am not able to see my balance?",
        answer:
          "Your balance is safe! If your balance still isn't visible after coming back to the homepage, Kindly contact customer care for assistance",
        lists: [],
      },
      {
        id: "8",
        question: "What if I lose internet connectivity while playing the game?",
        answer:
          "All the rounds in all games are logged and the successful bets are still valid. Please refer to our disconnection policy.",
        lists: []
      },
      {
        id: "9",
        question: "Suddenly the video I was streaming got stuck, what should I do?",
        answer:
          "No problem!! This usually happens due to slow internet connectivity or when you run multiple programs. Try clearing cache & reducing the screen resolution. ",
        lists: []
      },
    ],
    34:[
      {
        id: "1",
        question: "Why Betdaily?",
        answer:"Betdaily is not just another white label product or trying to offer similar products from other players in the market.The highly skilled team have built this product from scratch after intensive market research with the aim to be the best eGaming entertainment service provider.We offer a wide range of options to bet on: from multiple sports events, virtual sports, special markets to live casino and live card games. Our odds and policies are designed to be the best. We’ve also simplified the promotions to ensure you are entertained - 24/7.",
        lists: [
          {
            id: "1",
            content: "300% welcome bonus",
          },
          {
            id: "2",
            content: "2% reload bonus on every deposit",
          },
          {
            id: "3",
            content: "2% cashback bonus every week<",
          },
          {
            id: "4",
            content: "Faster withdrawals",
          },
          {
            id: "5",
            content: "Exciting loyalty programs",
          },
          {
            id: "6",
            content: "24/7 client support to ensure 24/7 entertainment",
          },
        ]
      },
      {
        id: 2,
        question: "How to log in at Betdaily?",
        answer: "",
        lists: [
          {
            id: "1",
            content: "Click on the login option",
          },
          {
            id: "2",
            content: "Fill in your personal details",
          },
          {
            id: "3",
            content: "Accept T&C's",
          },
          {
            id: "5",
            content: "Click on the Login option & you are ready to roll!!",
          },
        ]
      },
      {
        id: "3",
        question: "How can I update my personal information?",
        answer: "",
        lists: [
          {
            id: "1",
            content: "Click on the drop-down arrow next to your username",
          },
          {
            id: "2",
            content: "Choose Profile",
          },
          {
            id: "3",
            content: "Click on 'Account Info' ",
          },
          {
            id: "4",
            content: "Accept T&C's",
          },
          {
            id: "5",
            content: "Then click on the edit option.",
          },
        ]
      },
      {
        id: "4",
        question: "How to reset password?",
        answer: "",
        lists: [
          {
            id: "1",
            content: "Click on the dropdown arrow next to your username",
          },
          {
            id: "2",
            content: "Choose Profile",
          },
          {
            id: "3",
            content: "Select the option password on the left menu",
          },
          {
            id: "4",
            content: "Change your password",
          },
        ]
      },
      {
        id: "5",
        question: "Where can I see my betting history?",
        answer: "",
        lists: [
          {
            id: "1",
            content: "Click on the dropdown arrow next to your username",
          },
          {
            id: "2",
            content: "Select the option Bet details.",
          },
        ],
        redirect: ""
      },
      {
        id: "6",
        question: "Why I am not able to see my balance?",
        answer:
          "Your balance is safe! If your balance still isn't visible after coming back to the homepage, Kindly contact customer care for assistance",
        lists: [],
      },
      {
        id: "7",
        question: "What if I lose internet connectivity while playing the game?",
        answer:
          "All the rounds in all games are logged and the successful bets are still valid. Please refer to our disconnection policy.",
        lists: []
      },
      {
        id: "8",
        question: "Suddenly the video I was streaming got stuck, what should I do?",
        answer:
          "No problem!! This usually happens due to slow internet connectivity or when you run multiple programs. Try clearing cache & reducing the screen resolution. ",
        lists: []
      },
    ]
  };
  
  export const myProfileDataBD = (brancdID) => {
    return _myProfileDataBD[brancdID]
  }
    
  export const bonusDataBD = [
      {
        id: "1",
        question: "How to get a bonus?",
        answer:
          "Yes, who wouldn't like a little extra! We have various types of bonuses and most of them are automatically activated once your deposits are successful. ",
        lists: []
      },
      {
        id: "2",
        question: "How much is welcome bonus?",
        answer: `At Betdaily, We would always want to welcome you with the best and simplest bonus offers <a href='/promotions/welcome-bonus'>CLICK HERE</a> to check the latest promotion offer . `,
        lists: []
      },
      {
        id: "3",
        question: "How much is refill bonus?",
        answer:
          `A little extra never hurts and you do deserve that for your continued trust in us. So <a href='/promotions/refill-bonus'>CLICK HERE</a> to check the latest refill bonus.`,
        lists: []
      },
      {
        id: "4",
        question: "How much is cashback bonus?",
        answer:
          `We understand every week isn't as lucky as your luckiest week. We've got you covered with the weekly losses in such cases. <a href='/promotions/cashback-bonus'>CLICK HERE</a> to know more about the latest cash back offer Why wait? `,
        lists: []
      },
      {
        id: "5",
        question: "Why am I not able to withdraw my bonus amount?",
        answer:
          "We have curated our bonus structure to be simple yet effective.Bonus amount(s) cannot be withdrawn, but any winnings from the bonus amount can be withdrawn immediately",
        lists: []
      },
      {
        id: "6",
        question: "What are the various bonus offers available at Betdaily?",
        answer:
          "Click on promotions on the home screen page and then you can view the various available bonus programs.",
        lists: []
      },
      {
        id: "7",
        question: "What is the validity of the bonus program?",
        answer:
          "It depends upon the chosen bonus program and will expire if not consumed during the allotted time period.",
        lists: [
          {
            id: "1",
            content:
              "Welcome bonus: 30 days; Refill bonus: 24 hours & Cashback bonus: 7 days",
          },
        ]
      },
      {
        id: "8",
        question: "How is RO calculated at Betdaily?",
        answer:
          "Rollover is a simple concept, yet at Betdaily, we have simplified it further by saying 'A valid RO is when your win/loss is at least 10% of your stake amount.",
        lists: []
      },
    ];

    export const bonusDataBAdda = [
      {
        id: "1",
        question: "How to get a bonus?",
        answer:
          "Yes, who wouldn't like a little extra! We have various types of bonuses and most of them are automatically activated once your deposits are successful. ",
        lists: []
      },
      {
        id: "2",
        question: "How much is welcome bonus?",
        answer: `At Baazi Adda, We would always want to welcome you with the best and simplest bonus offers <a href='/promotions/welcome-bonus'>CLICK HERE</a> to check the latest promotion offer . `,
        lists: []
      },
      {
        id: "3",
        question: "How much is refill bonus?",
        answer:
          `A little extra never hurts and you do deserve that for your continued trust in us. So <a href='/promotions/refill-bonus'>CLICK HERE</a> to check the latest refill bonus.`,
        lists: []
      },
      {
        id: "4",
        question: "How much is cashback bonus?",
        answer:
          `We understand every week isn't as lucky as your luckiest week. We've got you covered with the weekly losses in such cases. <a href='/promotions/cashback-bonus'>CLICK HERE</a> to know more about the latest cash back offer Why wait? `,
        lists: []
      },
      {
        id: "5",
        question: "Why am I not able to withdraw my bonus amount?",
        answer:
          "We have curated our bonus structure to be simple yet effective.Bonus amount(s) cannot be withdrawn, but any winnings from the bonus amount can be withdrawn immediately",
        lists: []
      },
      {
        id: "6",
        question: "What are the various bonus offers available at Baazi Adda?",
        answer:
          "Click on promotions on the home screen page and then you can view the various available bonus programs.",
        lists: []
      },
      {
        id: "7",
        question: "What is the validity of the bonus program?",
        answer:
          "It depends upon the chosen bonus program and will expire if not consumed during the allotted time period.",
        lists: [
          {
            id: "1",
            content:
              "Welcome bonus: 30 days; Refill bonus: 24 hours & Cashback bonus: 7 days",
          },
        ]
      },
      {
        id: "8",
        question: "How is RO calculated at Baazi Adda?",
        answer:
          "Rollover is a simple concept, yet at Baazi Adda, we have simplified it further by saying 'A valid RO is when your win/loss is at least 10% of your stake amount.",
        lists: []
      },
    ];
    
  const _depositDataBD = {
    33:[
      {
        id: "1",
        question: "What is the minimum & maximum amount for Deposit?",
        answer:
          "The minimum deposit amount can range from INR 100 to INR 2,00,000 depending upon the payment channel used",
        lists: []
      },
      {
        id: "2",
        question: "How can I deposit?",
        answer:
          "Click on the Deposit option on the home page and choose amongst the various options we have for you",
        lists: []
      },
      {
        id: "3",
        question: "Can I transfer my balance from one account to another?",
        answer:
          "You cannot transfer your balance from one account to another. However, you could earn by referring your friends to Baazi Adda ",
        lists: []
      },
      {
        id: "4",
        question: "My amount is stuck in casino?",
        answer: "",
        lists: [
          {
            id: "1",
            content:
              "Not to worry. Just click on the dropdown arrow next to your username",
          },
          {
            id: "2",
            content: "Click on the option finances",
          },
          {
            id: "3",
            content:
              "Select the option wallet transfer🡪then select transfer All to main wallet & you are done!",
          },
        ]
      },
      {
        id: "5",
        question: "Can I transfer my balance from one account to another?",
        answer:
          "Hassle-free payment options are available at Baazi Adda for your use like Net banking, UPI, Gpay, Astropay, Neteller, Skrill & Paytm  ",
        lists: []
      },
      {
        id: "6",
        question: "Do you accept any other currency except INR?",
        answer: "Do you accept any other currency except INR?",
        lists: []
      },
      {
        id: "7",
        question:
          "How much time will it take for my deposit to reflect in my account?",
        answer:
          "Depending on the mode of payment, it will be reflected within 1 hour in your account.",
        lists: []
      },
      {
        id: "8",
        question: "Where can I see my transaction history? ",
        answer: "",
        lists: [
          {
            id: "1",
            content: "Click on the dropdown arrow next to your username",
          },
          {
            id: "2",
            content: "Click on the option finances",
          },
          {
            id: "3",
            content: "Select the option history",
          },
        ]
      },
      {
        id: "9",
        question: "What is the minimum & maximum amount for withdrawal? ",
        answer:
          "The minimum withdrawal limit currently is INR 1,000 and the maximum withdrawal is INR 50,000 per day.",
        lists: [],
      },
      {
        id: "10",
        question: "How can I withdraw my balance? ",
        answer: "",
        lists: [
          {
            id: "1",
            content: "Just click on the dropdown arrow next to your username",
          },
          {
            id: "2",
            content: "Click on the option finances",
          },
          {
            id: "3",
            content: "Select the option withdrawal !",
          },
        ]
      },
      {
        id: "11",
        question: "How many withdrawal accounts can I have?",
        answer: "You can have 3 withdrawal accounts",
        lists: []
      },
      {
        id: "12",
        question: "Can I withdraw my money into another account?",
        answer:
          "No, you cannot transfer money into another account. This is for your safety and security.",
        lists: []
      },
      {
        id: "13",
        question: "Why was my withdrawal put on hold?",
        answer:
          "Due to below reasons most of the withdrawals are either kept on hold or cancelled..",
        lists: [
          {
            id: "1",
            content: "The betting criteria were not met",
          },
          {
            id: "2",
            content: "Withdrawal was requested for another account",
          },
          {
            id: "3",
            content: "Withdrawal amount in the form was not filled correctly !",
          },
        ]
      },
      {
        id: "14",
        question: "How to add a new withdrawal account?",
        answer: "",
        lists: [
          {
            id: "1",
            content: "Just click on the dropdown arrow next to your username",
          },
          {
            id: "2",
            content: "Click on the option finances",
          },
          {
            id: "3",
            content: "Select the option withdrawal !",
          },
          {
            id: "4",
            content: "You can here now add or edit your account Info",
          },
        ]
      },
      {
        id: "15",
        question: "Can I cancel my withdrawal?",
        answer: "Yes, you can!! Please contact customer care for this request.",
        lists: []
      },
    ],
    34:[
      {
        id: "1",
        question: "What is the minimum & maximum amount for Deposit?",
        answer:
          "The minimum deposit amount can range from INR 100 to INR 2,00,000 depending upon the payment channel used",
        lists: []
      },
      {
        id: "2",
        question: "How can I deposit?",
        answer:
          "Click on the Deposit option on the home page and choose amongst the various options we have for you",
        lists: []
      },
      {
        id: "3",
        question: "Can I transfer my balance from one account to another?",
        answer:
          "You cannot transfer your balance from one account to another. However, you could earn by referring your friends to Betdaily ",
        lists: []
      },
      {
        id: "4",
        question: "My amount is stuck in casino?",
        answer: "",
        lists: [
          {
            id: "1",
            content:
              "Not to worry. Just click on the dropdown arrow next to your username",
          },
          {
            id: "2",
            content: "Click on the option finances",
          },
          {
            id: "3",
            content:
              "Select the option wallet transfer🡪then select transfer All to main wallet & you are done!",
          },
        ]
      },
      {
        id: "5",
        question: "Can I transfer my balance from one account to another?",
        answer:
          "Hassle-free payment options are available at Betdaily for your use like Net banking, UPI, Gpay, Astropay, Neteller, Skrill & Paytm  ",
        lists: []
      },
      {
        id: "6",
        question: "Do you accept any other currency except INR?",
        answer: "Do you accept any other currency except INR?",
        lists: []
      },
      {
        id: "7",
        question:
          "How much time will it take for my deposit to reflect in my account?",
        answer:
          "Depending on the mode of payment, it will be reflected within 1 hour in your account.",
        lists: []
      },
      {
        id: "8",
        question: "Where can I see my transaction history? ",
        answer: "",
        lists: [
          {
            id: "1",
            content: "Click on the dropdown arrow next to your username",
          },
          {
            id: "2",
            content: "Click on the option finances",
          },
          {
            id: "3",
            content: "Select the option history",
          },
        ]
      },
      {
        id: "9",
        question: "What is the minimum & maximum amount for withdrawal? ",
        answer:
          "The minimum withdrawal limit currently is INR 1,000 and the maximum withdrawal is INR 50,000 per day.",
        lists: [],
      },
      {
        id: "10",
        question: "How can I withdraw my balance? ",
        answer: "",
        lists: [
          {
            id: "1",
            content: "Just click on the dropdown arrow next to your username",
          },
          {
            id: "2",
            content: "Click on the option finances",
          },
          {
            id: "3",
            content: "Select the option withdrawal !",
          },
        ]
      },
      {
        id: "11",
        question: "How many withdrawal accounts can I have?",
        answer: "You can have 5 withdrawal accounts",
        lists: []
      },
      {
        id: "12",
        question: "Can I withdraw my money into another account?",
        answer:
          "No, you cannot transfer money into another account. This is for your safety and security.",
        lists: []
      },
      {
        id: "13",
        question: "Why was my withdrawal put on hold?",
        answer:
          "Due to below reasons most of the withdrawals are either kept on hold or cancelled..",
        lists: [
          {
            id: "1",
            content: "The betting criteria were not met",
          },
          {
            id: "2",
            content: "Withdrawal was requested for another account",
          },
          {
            id: "3",
            content: "Withdrawal amount in the form was not filled correctly !",
          },
        ]
      },
      {
        id: "14",
        question: "How to add a new withdrawal account?",
        answer: "",
        lists: [
          {
            id: "1",
            content: "Just click on the dropdown arrow next to your username",
          },
          {
            id: "2",
            content: "Click on the option finances",
          },
          {
            id: "3",
            content: "Select the option withdrawal !",
          },
          {
            id: "4",
            content: "You can here now add or edit your account Info",
          },
        ]
      },
      {
        id: "15",
        question: "Can I cancel my withdrawal?",
        answer: "Yes, you can!! Please contact customer care for this request.",
        lists: []
      },
    ],
  }
  
  export const DepositDataBD = (brandid) => _depositDataBD[brandid]
    
    export const ProductsDataBD = [
      {
        id: "1",
        question:
          "I am trying to play a game (casino/slot/teenpati) however, the page is not loading?",
        answer:
          "Slow internet connection might be the reason however kindly contact our customer care for assistance on this.",
        lists: [],
        redirect: ""
      },
      {
        id: "2",
        question: "Where can I check the status of my bet?",
        answer:
          "Click on the dropdown arrow next to your username🡪select the option bet details.",
        lists: [],
        redirect: ""
      },
      {
        id: "3",
        question: "What does blue & red colour in exchange means?",
        answer: "Blue means to Back & Red means to Lay.",
        lists: [],
        redirect: ""
      },
      {
        id: "4",
        question: "What does blue & red colour in exchange means?",
        answer: "Blue means to Back & Red means to Lay.",
        lists: [],
        redirect: ""
      },
      {
        id: "5",
        question: "What are the betting rules?",
        answer:
          "Kindly click on the terms & conditions available at the bottom of your homepage",
        lists: [],
        redirect: ""
      },
    ];
    
    export const affiliateDateBD = [
      {
        id: "1",
        question: "I am unable to view the affiliate program, when will it start?",
        answer:
          "We are currently working on the best affiliate program for our clients. Stay tuned, it will be available soon!",
        lists: [
          {
            id: "1",
            content:
              "In the meanwhile, please contact our Customer Care team with your details.",
          },
        ],
        redirect: ""
      },
    ];
    
  export const securityDataBD = [
      {
        id: "1",
        question: "Is Betdaily secure?",
        answer:
          "Betdaily is a secure gaming website. We have put strict policies in place to safeguard user's privacy.",
        lists: [],
        redirect: ""
      },
      {
        id: "2",
        question: "Why is my personal data accessed by Betdaily?",
        answer:
          "This is to verify your identity from any kind of fraud or malicious activity..",
        lists: [],
        redirect: ""
      },
      {
        id: "3",
        question: "Is Betdaily licensed and legitimate?",
        answer:
          "Betdaily is a licensed gaming company under the regulations and is certified to operate globally. We assure you that at Betdaily, we keep your information private and are not shared with any third party..",
        lists: [],
        redirect: ""
      },
    ];
    export const securityDataBazziadda = [
      {
        id: "1",
        question: "Is Baazi Adda secure?",
        answer:
          "Baazi Adda is a secure gaming website. We have put strict policies in place to safeguard user's privacy.",
        lists: [],
        redirect: ""
      },
      {
        id: "2",
        question: "Why is my personal data accessed by Baazi Adda?",
        answer:
          "This is to verify your identity from any kind of fraud or malicious activity..",
        lists: [],
        redirect: ""
      },
      {
        id: "3",
        question: "Is Baazi Adda licensed and legitimate?",
        answer:
          "Baazi Adda is a licensed gaming company under the regulations and is certified to operate globally. We assure you that at Baazi Adda, we keep your information private and are not shared with any third party..",
        lists: [],
        redirect: ""
      },
    ];
  
  
    