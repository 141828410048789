import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import CalenderIcon from '../icon/CalenderIcon';
import styled from 'styled-components';
import Intl from '../../components/common/Intl';
import { SvgIcon as CalenderSvg } from './commonStyle';
import { theme } from 'styled-tools';

interface IDatepickerProps {
  // startAt : Date;
  // endAt: Date;
  // handleDayChange: (from: Date, to: Date) => void;
  setStartAt: (from: Date) => void;
  setEndAt: (to: Date) => void;
}

export type TDateMode = 'from' | 'to';

const handleDateChangeRaw = (e) => {
  e.preventDefault();
}

var date = new Date();
const minDate = date.setDate((new Date()).getDate() - 90);

function DatePickerUdb(props: IDatepickerProps) {
  const [startAt, setStartAt] = useState(new Date());
  const [endAt, setEndAt] = useState(new Date());

  const changeFromdate = (date: Date | null) => {
    props.setStartAt(date);
    setStartAt(date)
  }

  const changeTodate = (date: Date | null) => {
    props.setEndAt(date)
    setEndAt(date)
  }

  return (
    <DatePickerWrapper>
      <SvgIcon>
        <CalenderIcon />
      </SvgIcon>
      <BlockPick>
        {/* <Intl langKey="DATE_PICKER@FROM" />  */}
        <DatePicker
          mode="from"
          selected={startAt}
          onChange={changeFromdate}
          onChangeRaw={handleDateChangeRaw}
          minDate={minDate}
          maxDate={new Date()}
          disabledKeyboardNavigation
          placeholderText="From"
        />
      </BlockPick>
      <BlockPick>
        {/* <Intl langKey="DATE_PICKER@TO" /> */}
        <DatePicker
          mode="to"
          selected={endAt}
          onChange={changeTodate}
          onChangeRaw={handleDateChangeRaw}
          minDate={minDate}
          maxDate={new Date()}
          disabledKeyboardNavigation
          placeholderText="To"
        />
      </BlockPick>
    </DatePickerWrapper>
  )
}

export default DatePickerUdb

export const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 3.06233px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 8px;
`;

export const SvgIcon = styled(CalenderSvg)`
  margin-right: 0px;
`;

// const SvgIcon = styled(CalenderSvg)`
//   margin-right: 0px;
// `;

// const CalenderIcon = styled.div`
//   width: 24px;
//   height: 24px;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   background-image: url(${DatePickerIcon});
//   background-size: auto;
//   background-repeat: no-repeat;
// `;

export const BlockPick = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  background: radial-gradient(50% 50% at 50% 50%,rgba(255,255,255,0.7) 0%,rgba(255,255,255,0.8) 100%);
  padding: 0px 0px 0px 20px;
  border-radius: 3px;

  .react-datepicker__input-container {
    display: flex;
    align-items: center;

      :after {
      content: "";
      width: 33px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 0px 3px 3px 0px;
      background: ${theme('palette.secondary-background-color-linear-new1')};
    }
     :before {
      content: "";
      display: inline-block;
      border-style: solid;
      border-color: #ffffff;
      border-width: 0 2px 2px 0;
      padding: 2.5px;
      vertical-align: middle;
      transition: transform 0.3s;
      transform: rotate(45deg);
      position: absolute;
      right: 12px;
      z-index: 1;
     }
  }

  input {
    width: 120px;
    height: 40px;
    display: flex;
    color: #56567F;
    text-transform: capitalize;
    font-size: 12px;
    border: none;
    background: none;
    z-index: 4;
    cursor: pointer;
  }

  .react-datepicker__day--selected {
    background: ${theme('palette.datepickerBginside')};
  }

  .react-datepicker__day--outside-month {
    color: transparent !important;
    pointer-events: none;
  }
`;