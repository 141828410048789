import React, { useEffect, useState } from "react";
import styled, { withTheme } from 'styled-components';
import { color } from '../../../sass-files/variable';
// import Openable from 'src/components/common/Openable';
import GameDetailsTable from './detailed-table';
// import Clock from "src/components/Clock";
import ExchangeTopGames from "src/components/eu-content/exchange-page/exchangepage-topgames/index";
import BetSlip_myBet_tab from "src/components/BetSlip/betslip-mybet_tabs";
// import BetSlip from "src/components/BetSlip";
// import { scrollbarContainerStyle } from 'src/components/common/commonCSSStyles';
// import SportRadar from '../../eu-content/sportradar/SportRadar';
import BlueShirt from '../../../images/blue_shirt.png';
import GreenShirt from '../../../images/green_shirt.png';
import LiveScoreIcon from '../../../images/live_score.png';
import DropDown from 'src/images/Vector.png'
import { MatchListContext } from '../../../store/MatchListContext';
import { OrderListContext } from '../../../store/OrderListContext';
// import { any } from "ramda";
import { Outlet, useNavigate } from "react-router-dom";
// import SportTable from '../table/SportList'
import { withAppContext } from '../../../store/initAppContext';
// import { motion } from 'framer-motion';
// import Bg from '../../../images/iMAGE1_iexch.png';
// import BgDark from '../../../images/iMAGE1_iexch.jpg';
import EventLiveTvIcon from "src/images/livetv_white.png";
import util from "src/util/util";
// import TeamName from '../MarketList'
// import { ECookieName, EMarketSource } from "src/util/utilModel";
// import { useQuery, useLazyQuery } from '@apollo/client';
import { eventInfo, GET_EVENTS, GET_INNER_EVENTS } from "src/graphql/events";
import { IEventInfo, ILiveInfo, IScoreInfo } from "src/graphql/schema";
// import Marquee from "react-fast-marquee";
// import Speaker from "../../../images/speaker-red_blue.png"
import Icon, { IconWrapper } from '../../common/PNGIcon';
import NewsEvent from "src/pages/eu-content/news-event";
import Intl from '../../common/Intl';
import Live from '../Live';
import SubscribedMarketList from "src/components/subscription/SubscribedMarketList";
// import Switch from "../Switch";
// import { getGTMId } from "src/util/gtmUtil";
// import cookieUtil from "src/util/cookieUtil";
import config from "src/config/config";
import { theme, withProp } from 'styled-tools';
// import MenuSidebar from 'src/components/home-exchange/menu-sidebar';

import { useParams } from 'react-router-dom';
import { BetSlipTittle as InnerMarketBetSlipTittle } from "src/components/home-exchange";

const { tabsignup, innerGameTittle, secondarycolor } = color

const InnerGameDetails = (props: any) => {
    const param = useParams();
    const navigate: any = useNavigate();
    const gqlQuery = GET_INNER_EVENTS;
    const [categoryId, setcategoryId] = useState(0);
    const [competitionId, setcompetitionId] = useState('');
    const [eventId, seteventId] = useState('');
    const [matchId, setMatchId] = useState('');
    const [liveTv, setLiveTv] = useState(false);
    const [teamName, setTeamName] = useState('');
    const [results, setResults] = useState('');
    // const [loadInnerEvents, { loading, error, data }] = useLazyQuery(GET_INNER_EVENTS)
    // const { loading, error, data } = useQuery(GET_INNER_EVENTS, {
    //     variables: { input: { categoryId: Number(categoryId), competitionId: competitionId, eventId: eventId } }
    // });

    useEffect(() => {

        const { categoryId, competitionId, eventId, matchId } = param
        const { setState } = props.app || {};
        setcategoryId(Number(categoryId));
        setcompetitionId(competitionId);
        seteventId(eventId)
        setMatchId(matchId)
        setResults(eventId)

        if (matchId === '1') {
            if (typeof setState === 'function') {
                let _reqData = {
                    showLiveTv: false
                }
                setState(_reqData)
            }
        }
        if (matchId !== '1') {
            if (typeof setState === 'function') {
                let _reqData = {
                    liveTvUrl: matchId,
                    showLiveTv: true
                }
                setState(_reqData)
            }
        }

    }, [param.eventId])
    //[props.match?.params?.eventId]

    const openLiveTv = () => {
        const { categoryId, competitionId, eventId, matchId } = param
        const { setState } = props.app || {};

        setcategoryId(Number(categoryId));
        setcompetitionId(competitionId);
        seteventId(eventId)
        setMatchId(matchId)
        if (matchId === '1') {
            if (typeof setState === 'function') {
                let _reqData = {
                    showLiveTv: false
                }
                setState(_reqData)
            }
        }
        if (typeof setState === 'function') {
            let _reqData = {
                liveTvUrl: matchId,
                showLiveTv: true
            }
            setState(_reqData)
        }
    }

    const { memberId = 0 }: any = props;


    const backButton = () => {
        navigate(-1)
    }

    let catergoryIdNumber = Number(categoryId)
    const inputProps: any = {
        categoryId: catergoryIdNumber,
        competitionId: competitionId === 'e' ? '' : competitionId,
        eventId
        // eventId: !(sport && competitionId && eventId) && fancyQuery ? fancyQuery.eventId : eventId,
    };

    return (
        <InnerExchangeWrapper theme={props.app.theme} customStyle={props.theme.image}>
            <HeaderMenu>
                <ExchangeTopGames />
            </HeaderMenu>

            <MatchListContext memberId={memberId}>
                <OrderListContext>
                    <NewsEvent data={parseInt(results)} />
                    <GameDetailsWrapper >
                        <HeaderGameDetailsWrapper >
                            <HeaderSection>


                                <GameTittleSection>
                                    <Floatbtn>
                                        <button onClick={backButton}>
                                            {/* {isOpened ? 'Close' : 'Float'} */}
                                            <Intl langKey="BUTTON@BACK" />
                                        </button>
                                    </Floatbtn>
                                    <GameInfo>
                                        <GameIconBlue></GameIconBlue>
                                        <GameName>{teamName}</GameName>
                                        <GameIconGreen></GameIconGreen>
                                    </GameInfo>
                                    {/* <TimeToggleSection> */}
                                    {/* <Clock /> */}
                                    {/* <LiveScoreToggle>
                                                    <LiveIcon></LiveIcon>
                                                    <LiveTittle>Live Score</LiveTittle>  
                                                </LiveScoreToggle>
                                                <DropdownArow></DropdownArow> */}
                                    {/* </TimeToggleSection> */}
                                    {/* <GameInfo>
                                            <GameIconGreen></GameIconGreen>
                                        </GameInfo> */}
                                    {(matchId === '1') ? '' :
                                        <LiveTvHomePage onClick={() => openLiveTv()}>
                                            <img src={EventLiveTvIcon} alt="EventLiveTvIcon" />
                                            <Intl langKey="HEADER@LIVETV" />
                                        </LiveTvHomePage>
                                    }
                                    {
                                        <SubscribedMarketList app={props.app} query={gqlQuery} variables={{ input: inputProps }} option={{ isLive: false }}>
                                            {({ loading, error, data, subscribeToMore, variables, updateQuery }: any) => {
                                                const eventInfoList: IEventInfo[] = util.getValue(data, ['markets', 'list'], []);
                                                const eventInfo = eventInfoList[0] || {};
                                                const scoreInfo = eventInfo.score || ({} as IScoreInfo);
                                                const liveInfo = eventInfo.live || ({} as ILiveInfo);
                                                const matchType = eventInfo.matchType || '';
                                                const betradarId = eventInfo.betradarId || '';
                                                const teamNameInfo: any = util.getValue(data, ['markets', 'list', '0', 'eventName'], '');
                                                if (teamNameInfo) {
                                                    setTeamName(teamNameInfo)
                                                }
                                                const result = Number(results);
                                                return (
                                                    <BetRadarDiv>

                                                        <Live
                                                            scoreInfo={scoreInfo}
                                                            liveInfo={liveInfo}
                                                            betradarId={betradarId}
                                                            matchType={matchType}
                                                            eventId={eventId}
                                                        />
                                                    </BetRadarDiv>
                                                )
                                            }}
                                        </SubscribedMarketList>
                                    }
                                </GameTittleSection>
                            </HeaderSection>
                            <>
                                <GameDetailsTable categoryId={categoryId} competitionId={competitionId} eventId={eventId} matchId={matchId} />
                            </>
                        </HeaderGameDetailsWrapper>
                        <BetSlipWrapper >
                            <BetSlipTittle><Intl langKey="TITLE@BetSlipTittle" /></BetSlipTittle>
                            <BetSlip_myBet_tab />
                        </BetSlipWrapper>
                    </GameDetailsWrapper>
                </OrderListContext>
            </MatchListContext>
            {/* <Outlet /> */}
        </InnerExchangeWrapper>
        // </motion.div>
    )
}

export default withTheme(withAppContext(InnerGameDetails));

/*! styled-components */
const BetSlipTittle = styled(InnerMarketBetSlipTittle)`
    padding: 22px 10px;
`;
const HeaderGameDetailsWrapper = styled.div`
    width: 75%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    /* padding: 35px 20px 20px 65px; */
    padding: 35px 20px 80px 0px;
    padding-top: 0;
    overflow: hidden auto;
    scroll-behavior: smooth;
    overflow-y: overlay;

    ::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
`;


const SidebarWrapper = styled.div`

position: relative;


`;
const HeaderMenu = styled.div`
    display: flex;
    flex-direction: row;
`;

const InnerExchangeWrapper: any = styled.div`
  width: 100%;
  background-image: url('${(props: any) => `${config.imageV3Host}/static/cashsite/${props.customStyle['homeExchangeBgImage']}`}');
	background-size: cover;
	background-repeat:no repeat;
	background-position: top;
    // height: 100vh;
    background-attachment: fixed;
    min-height: 100vh;
`;

const BetSlipWrapper = styled.div`
    width: 25%;
    position: sticky;
    top: 0;
    overflow-y: scroll;
    // height: calc(100vh - 6em);
    right: 0;
    overflow: hidden auto;
    scroll-behavior: smooth;
    overflow-y: overlay;
    min-height:calc(20vh);
    max-height: calc(100vh - 2em);
    padding-right: 5px;
    z-index:99;

    ::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
`;

const ToggleButton = styled.div`

    /* color:white;
    font-size: 13px;
    font-weight: 600;
    margin-top: 5px;
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center; */
    /* position: fixed;
    right: 50px; */
    /* top: 0; */
    /* padding: 16px;
    background: linear-gradient(180deg,#000559 0%,#234294 99.95%);
    box-shadow: 4px 0px 4px rgb(0 0 0 / 25%); */
    /* border-radius: 25px; */
    /* z-index: 100 */
    /* padding: 16px;
    width: 20%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    background: linear-gradient(180deg,#F7E1BF 0%,#F5ECDE 48.96%,#D1BC99 100%);
    box-shadow: 0px 0px 40px rgb(0 0 0 / 17%);
    -webkit-transform: skew(340deg);
    -ms-transform: skew(340deg);
    transform: skew(340deg);
    border-radius: 0px 10px 10px 0px;
    border-left: 1px solid #203974; */

    color:white;
    width: 100%;
    font-size: 15px;
    font-weight: 600;
    // margin-top: 5px;
    /* width: 250px; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: relative;
    left: 0px;
    top: 0px;
    padding: 10px;
    background: ${theme('palette.secondary-background-color-linear-new')};

    .switchdiv{
        /* padding-left: 10px;
        padding-right: 10px; */
        padding-left: 0px;
        padding-right: 10px;
        border-radius: 99em;
        border: 1px solid #FFFF;
        width: 46px;
    } 

    /* :after {
        content: "";
        width: 45px;
        height: 100%;
        background:linear-gradient(180deg,#000559 0%,#234294 99.95%);
        position: relative; */
        /* top: 0; */
        /* right: 10%;
        transform: skew(340deg);
    } */
    
`;


const DropdownArow = styled.div`
    width: 12px;
    height: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-image: url(${DropDown});
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 5px;
`;

export const TimeToggleSection = styled.div`
    width: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${secondarycolor};
    padding: 8px 0px;
`;

const LiveScoreToggle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const LiveTittle = styled.div`
    font-size: 13px;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: #5FC5FF;
`;

const LiveIcon = styled.div`
    width: 10px;
    height: 10px;
    display: flex;
    background-image: url(${LiveScoreIcon});
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0px 5px 0px 0px;
`;

const GameDetailsWrapper = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const HeaderSection = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: #D8EFFF; */
`;

const GameTittleSection = styled.div`    
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: none;
    position: relative;
    background: linear-gradient(89deg, rgba(96, 96, 96, 0) 3.65%, rgb(0, 0, 0) 100%);
    /* box-shadow: 0px 4px 4px rgba(143, 143, 143, 0.16); */

    // :before {
    //     content: "";
    //     width: 45px;
    //     height: 66px;
    //     background: #09285F;
    //     position: absolute;
    //     top: 0;
    //     left: -19px;
    //     transform: skew(30deg);
    // }

    :after {
        content: "";
        width: calc(100% - 0px);
        height: 5px;
        /* background: ${theme('palette.primary-background-color-linear-new')}; */
        background: ${theme('palette.backBtnhover')};
        position: absolute;
        bottom: -3px;
        left: 0px;
        border-radius: 10px;
    }
`;

const Floatbtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 70px;
    height: 66px; */
    width: 54px;
    height: 40px;
    border-radius: 5px;
    /* background: ${theme('palette.secondary-background-color-linear-new')}; */
    /* background: linear-gradient(#08073d 0%,#6564d8 100%); */
    position: absolute;
    top: 10px;
    // right: -50px;
    left: 0;
    // transform: skew(-30deg);

    :hover {
        background: ${theme('palette.backBtnhover')};
        cursor: pointer;
    }

    button {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        cursor: pointer;
        font-size: 15px;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        color: #ffffff;
        // transform: skew(30deg);
        
        font-weight: 600;
    }
`;

const GameInfo = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 18px 0px;
    // padding-top: 20px;
    // position: relative;
    // top: 23px;
`;

const GameIconBlue = styled.div`
    width: 20px;
    height: 22px;
    display: flex;
    background-image: url(${BlueShirt});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0px 10px;
`;

const GameIconGreen = styled.div`
    width: 20px;
    height: 22px;
    display: flex;
    background-image: url(${GreenShirt});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0px 10px;
`;

const GameName = styled.div`
    font-size: 13px;
    letter-spacing: 0.1em;
    text-transform: capitalize;
    // 
    font-weight: 500;
    // color: ${innerGameTittle};
    color:#fff;
`;

const LiveTvHomePage = styled.div`
    width: 43px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #fff;
    font-weight: 900;
    font-size: 10px;
    text-align: center;
    position: absolute;
    right: 11px;
    top: 15px;
    cursor:pointer;
    padding: 2px;
    border-radius: 5px;
    white-space: nowrap;

    :hover {
        background: ${theme('palette.backBtnhover')};
    /* background: #6462d5bf; */
    
}

    img {
        width: 20px;
    }
`;

// export const TeamName: any = styled.div`
// 	font-size: 13px;
// 	font-weight: normal;
// 	white-space: nowrap;
// 	overflow: hidden;
// 	text-overflow: ellipsis;
// 	color: #fff;
// 	text-transform: lowercase !important;
// 	&:first-letter {
// 		text-transform: capitalize !important;
// 	}
// `;

// Wrapping title number & team name
export const TeamTitleWrapper: any = styled.div`
	display: flex;
	align-items: baseline;
	margin: 0.12em 0.2em;
	padding: 3px 0px;
	// width: 120px;
	width: 100%;
    max-width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	text-transform: capitalize;
	color: #fff;
`;

const MarqueeWrapper = styled.div`
    overflow: hidden;
    // width: calc(100% - 30px);
    margin-left: 50px;
    height: 30px;
    color:#d78e1d;  
    padding-top: 8px;
`;

const MarqueeIconWrapper: any = styled.div`
	display: flex;
	flex-shrink: 0;
	position: relative;
	top: 85%;
	left: 18px;
	z-index: 1;
    height:19px;

	transform: translateY(-50%);
	font-size: 1.4em;
	${IconWrapper} {
		filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.35));
	}
`;

const BetRadarDiv = styled.div`
    width:100%;
    text-transform: uppercase;
    color: #fff;
    font-weight: 500;
    font-size: 10px;
    position: relative;
    cursor:pointer;
`;

