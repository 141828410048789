import React from 'react'

type Props = {}

const Step3Icon = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48"><circle cx="24" cy="24" r="24" fill="url(#a)"/><g><path fill="url(#c)" d="M30.11 12.654H16.89c-.276 0-.5.229-.5.51v21.671c0 .282.224.51.5.51h13.22c.276 0 .5-.228.5-.51v-21.67a.505.505 0 0 0-.5-.51Z"/><path fill="#fff" d="M29.036 8H17.964C16.327 8 15 9.355 15 11.027v25.946C15 38.645 16.327 40 17.964 40h11.072C30.673 40 32 38.645 32 36.973V11.027C32 9.355 30.673 8 29.036 8Zm1.574 26.835c0 .282-.224.51-.5.51H16.89a.505.505 0 0 1-.5-.51v-21.67c0-.282.224-.51.5-.51h13.22c.276 0 .5.228.5.51v21.67Z"/><path fill="#fff" d="M24.955 10.724h-2.91a.42.42 0 0 1-.415-.425.42.42 0 0 1 .415-.424h2.91c.23 0 .415.19.415.424a.42.42 0 0 1-.415.425Z"/><path fill="url(#d)" d="M24.955 10.724h-2.91a.42.42 0 0 1-.415-.425.42.42 0 0 1 .415-.424h2.91c.23 0 .415.19.415.424a.42.42 0 0 1-.415.425Z"/></g><defs><linearGradient id="a" x1="24" x2="24" y1="0" y2="48" gradientUnits="userSpaceOnUse"><stop stop-color="#6765E0"/><stop offset="1" stop-color="#322DB8"/></linearGradient><linearGradient id="c" x1="23.5" x2="23.5" y1="12.654" y2="35.346" gradientUnits="userSpaceOnUse"><stop stop-color="#6765E0"/><stop offset="1" stop-color="#322DB8"/></linearGradient><linearGradient id="d" x1="23.5" x2="23.5" y1="9.875" y2="10.724" gradientUnits="userSpaceOnUse"><stop stop-color="#6765E0"/><stop offset="1" stop-color="#322DB8"/></linearGradient><clipPath id="b"><path fill="#fff" d="M15 8h17v32H15z"/></clipPath></defs></svg>
  )
}

export const FomoStep3Icon = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48"><circle cx="24" cy="24" r="24" fill="url(#a)"/><g><path fill="url(#c)" d="M30.11 12.654H16.89c-.276 0-.5.229-.5.51v21.671c0 .282.224.51.5.51h13.22c.276 0 .5-.228.5-.51v-21.67a.505.505 0 0 0-.5-.51Z"/><path fill="#fff" d="M29.036 8H17.964C16.327 8 15 9.355 15 11.027v25.946C15 38.645 16.327 40 17.964 40h11.072C30.673 40 32 38.645 32 36.973V11.027C32 9.355 30.673 8 29.036 8Zm1.574 26.835c0 .282-.224.51-.5.51H16.89a.505.505 0 0 1-.5-.51v-21.67c0-.282.224-.51.5-.51h13.22c.276 0 .5.228.5.51v21.67Z"/><path fill="#fff" d="M24.955 10.724h-2.91a.42.42 0 0 1-.415-.425.42.42 0 0 1 .415-.424h2.91c.23 0 .415.19.415.424a.42.42 0 0 1-.415.425Z"/><path fill="url(#d)" d="M24.955 10.724h-2.91a.42.42 0 0 1-.415-.425.42.42 0 0 1 .415-.424h2.91c.23 0 .415.19.415.424a.42.42 0 0 1-.415.425Z"/></g><defs><linearGradient id="a" x1="24" x2="24" y1="0" y2="48" gradientUnits="userSpaceOnUse"><stop stop-color="#FF8351" /><stop offset="0.479533" stop-color="#FF5F1F" /><stop offset="1" stop-color="#BA3500" /></linearGradient><linearGradient id="c" x1="23.5" x2="23.5" y1="12.654" y2="35.346" gradientUnits="userSpaceOnUse"><stop stop-color="#FF8351" /><stop offset="0.479533" stop-color="#FF5F1F" /><stop offset="1" stop-color="#BA3500" /></linearGradient><linearGradient id="d" x1="23.5" x2="23.5" y1="9.875" y2="10.724" gradientUnits="userSpaceOnUse"><stop stop-color="#FF8351" /><stop offset="0.479533" stop-color="#FF5F1F" /><stop offset="1" stop-color="#BA3500" /></linearGradient><clipPath id="b"><path fill="#fff" d="M15 8h17v32H15z"/></clipPath></defs></svg>
  )
}

export default Step3Icon;