import React, { useEffect } from 'react'
import PlaceholderImage from 'src/components/Placeholder';
import { MainInfoWrapper } from 'src/components/udb/commonStyle';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import FooterContainer from "src/components/footer/footer";

const Catch99_s2=(props)=> {
    const { app: {
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <MainInfoWrapper>
            <Catch99ContentWrapper>
                <h3>Catch99 is back on YOLO247 with Season 2:<br></br>
                    Go Bold for Gold!</h3>
                <p>
                    Ignite Your Diwali with our Catch99 Glamour! 🌟 This festive season, seize the opportunity to rule the casino realm with your favourite games like Teen Patti, Baccarat, and more. Unleash the dazzle of Diwali with electrifying gameplay and a treasure trove of opulent rewards. Let the games of splendour commence! 🪔💃🎮🌟
                    <br></br>
                </p>
                <p className='m-0'>
                    Join the Yolo247 "Go Bold for Gold " and embark on an electrifying adventure where you can strike it rich with our dazzling Jackpot Prize - a staggering 99 grams of pure Gold, along with a treasure chest full of exhilarating free bets!
                </p>
                <JackPotWrapper>
                    <EnterJackPot>How to Unleash<br />the Gold Rush</EnterJackPot>
                    <ProcessColumn>
                        <StepTittle>Step1: REGISTER NOW!</StepTittle>
                        <TextContent className='steps'>Forge your path to fortune by creating your Yolo247 account if you haven't already. It's as easy as pie - just sign up with your name and mobile number!</TextContent>
                        <StepTittle>Step2: Deposit & Ignite the Game!</StepTittle>
                        <TextContent className='steps'>Supercharge your gaming journey by making a deposit using your preferred method. Once done, plunge headfirst into the thrill of your favourite games! chance to win the jackpot prize of 990 grams Gold/-</TextContent>
                    </ProcessColumn>
                    <JackPotTable>
                    </JackPotTable>
                </JackPotWrapper>
                <h4>
                    🌟 Now, here's where the magic happens! 🌟
                </h4>
                <p>
                    🎰 Deposit & play each bet worth Rs. 500/- or more from November 1st to November 25th, 2023, and you're on the express train to the Grand Jackpot! The jackpot holds 99 grams of Gold, and it's yours for the taking!</p>

                <p>🚀 Jackpot Chips Chips Required to Unlock Rewards Winners</p>
                <p className='p-0'>
                    For every single bet worth Rs. 500/- and above, we'll hand you 1 prized Chip(s).
                    To unlock the glittering jackpot, collect a stash of minimum <span>100 Chips.</span>
                </p>
                <h4>
                    The Rewards Await 💎
                </h4>
                <p>
                    🥇99 grams of Gold          -   5 Golden Achievers<br></br>
                    🥈 9 grams of Gold           -   20 Golden Dreamers<br></br>
                    💰 Rs. 9,900                -   50 Winners with Pockets Brimming<br></br>
                    💵 Rs. 990                   -   99 Winners with Pockets Full<br></br>
                </p>
                <p>Get ready to fuel your wildest dreams and set your heart racing with the "Go Bold for Gold" at Yolo247. Step into the spotlight, grab your chance at riches, and let the excitement ignite your gaming passion! Fortune favors the bold! 🌟💰🏆🎉</p>
                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.jackPotTableImgh}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
                <p>If there are more winners for a particular slab, winners with the highest number of chips will be chosen</p>
                    <TermsHead>
                        <TermsTittle className='terms'>TERMS AND CONDITIONS:</TermsTittle>
                    </TermsHead>
                <TermsConditionPoints>
                    <ul>
                        <ul>
                            <li><TextContent>The more you play with bets of Rs. 500 and above, the higher your chances of winning the Jackpot.</TextContent></li>
                            <li><TextContent>Winners will be personally notified via their Yolo247 inbox and through Yolo247's official social media pages.</TextContent></li>
                            <li><TextContent>This promotion is valid for all new & existing players.</TextContent></li>
                            <li><TextContent>This contest is valid from November 1st to November 25th 2023. Winners for the MEGA RAFFLE will be announced on 30th Nov 2023 on YOLO247 social media handles (Instagram & telegram).</TextContent></li>
                            <li><TextContent>Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.</TextContent></li>
                            <li><TextContent>Multiple accounts will not be considered for this promotion eligibility/criteria. All players will be subject to duplicate checking. Yolo247 reserves the right to disqualify players if collusion or any foul play takes place in the promotion.</TextContent></li>
                            <li><TextContent>This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.</TextContent></li>
                            <li><TextContent>Yolo247 reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.</TextContent></li>
                            <li><TextContent>Yolo247 General Terms & Conditions apply.</TextContent></li>
                        </ul>
                    </ul>
                </TermsConditionPoints>
            </Catch99ContentWrapper>
            <FooterContainer />
        </MainInfoWrapper>
    )
}

export default withTheme(withAppContext(Catch99_s2));

//Styled Components
const TermsConditionPoints = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 2rem;

    p {
        padding-left: 1rem;
        text-transform: capitalize;
    }

    ul {
        list-style: number;
        color: #fff;
        padding-left: 35px;
        margin-top: 0;

        li {
            p{
                padding-left: 0rem;
                margin: 0 0 20px 0;
                font-style: normal;
                color: #FFF;
                text-align: justify;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.8px;
                text-transform: capitalize;
            }
        }
    }
`;

const TermsTittle = styled.h4`
    color: ${theme('palette.enterJackPotTextColor')};
    font-size: 2rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-style: italic;
    font-family: 'Inter-Bold';
    font-size: 36px;
    margin-bottom: 1rem;
`;

const TermsHead = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    margin: 1rem 0 2rem;
    .terms{
        font-size: 35px;
        padding-bottom: 15px;
    }

    :after {
        content: "";
        width: 107px;
        height: 3px;
        display: flex;
        position: absolute;
        bottom: 0;
        background: ${theme('palette.processColumnLeftLine')};
    }

    p {
        font-style: italic;
        margin: 0 0 2rem;
    }
`;

const JackPotTable = styled.div`
    width: 90%;
    display: flex;
    padding-top: 47px;

    img {
        max-width: 100%;
    }
`;

const TextContent = styled.p`
    display: flex;
    color: #ffff;
    font-style: normal;
    font-size: 17px;
    font-size: 20px;
`;

const StepTittle = styled.span`
    display: flex;
    color: #F0BE21;
    font-size: 17px;
    font-family: 'Inter-Bold';
`;

const ProcessColumn = styled.div`
    width: 60%;
    padding: 2rem;
    margin-left: 2rem;
    position: relative;

    .steps{
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        padding: 0rem;
    text-align: left;
    }
    &::before {
        content: "";
        width: 2px;
        height: 100%;
        display: flex;
        position: absolute;
        left: 0;
        top: 0;
        background: ${theme('palette.processColumnLeftLine')};
    }
`;

const EnterJackPot = styled.h1`
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 3rem;
    background: linear-gradient(180deg, #FADA34 0%, #DE9101 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    text-align: right;
    text-transform: capitalize;
    font-family: 'Inter-Bold';
`;

const JackPotWrapper = styled.div`
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: ${theme('palette.jackPotWrapperBg')};
    backdrop-filter: blur(45.5px);
    border: ${theme('palette.jackPotWrapperBgBorder')};
    border-radius: 16px;
    padding: 3rem;
    margin: 2rem 0;
`;

const Catch99ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url(${config.imageV3Host}/static/cashsite/${theme('image.Catch99ContentWrapperBg')});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    h3{
        text-align: center;
font-size: 26px;
font-style: italic;
font-weight: 700;
line-height: normal;
letter-spacing: 1.3px;
text-transform: uppercase;
background: linear-gradient(180deg, #FADA34 0%, #DE9101 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding-top: 20px;
    }
h4{
    color: #E5A20D;
text-align: center;
margin: 0;
font-size: 24px;
font-style: italic;
font-weight: 700;
line-height: normal;
}
    p{
        color: #FFF;
text-align: center;
font-size: 21px;
font-style: italic;
font-weight: 400;
line-height: normal;
padding: 0rem 5rem;

    }

    img{
        padding: 0rem 10rem;
    }
    .m-0{
        margin: 0;
    }
    .p-0{
        padding: 0rem 20rem;
        span{
            color: #E5A20D;
        }
    }
`;
