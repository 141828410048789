import React from 'react';
import Intl from 'src/components/common/Intl';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from 'src/components/Placeholder';
import config from 'src/config/config';
import { theme } from 'styled-tools';
import { withAppContext } from 'src/store/initAppContext';
import { DownloadBtn, SmallText } from 'src/pages/app-download-page/app-download';
import AndroidIcon from './icon/AndroidIcon';

type Props = {
    id: number;
    imgsrc: any;
    icon: any;
    title: any;
    desc: any;
    apkUrl: any;
    app: any;
    theme: any;
}

type StyleProps = {
    reverseProp: any;
}



const DownldAndInstallStepsContent = (props: Props) => {
    const { app: { brandId }, theme } = props;
    const brandApp = (brandId === 31 || brandId === 32) ? 'yolo app' : (brandId === 39 || brandId === 40)? 'fomo app' : 'betdaily app';

    return (
        <Row reverseProp={props.id}>
            <MobileMockupImg reverseProp={props.id}>
                <LazyLoadImage src={`${config.imageV3Host}${props.imgsrc}`} effect="none" placeholder={<PlaceholderImage/>}  alt={brandApp}/>
            </MobileMockupImg>
            <ContentWrapper>
                <StepIcon reverseProp={props.id}>
                    <LazyLoadImage src={`${config.imageV3Host}${props.icon}`} effect="none" placeholder={<PlaceholderImage/>}/>
                </StepIcon>
                <MoreTextLeft>
                    <FlexWrapMore className='twoRow'>
                        {props.title}
                    </FlexWrapMore>
                    <SmallText>{props.desc}</SmallText>
                    {props.id === 2 || props.id === 4 ? 
                        <DownloadBtn href={props.apkUrl}>
                            <AndroidIcon/>
                            <Intl langKey="YoloAppDwnld@Download"/>
                        </DownloadBtn> 
                        : 
                        <></>
                    }
                </MoreTextLeft>
            </ContentWrapper>
        </Row>
    )
}

export default withTheme(withAppContext(DownldAndInstallStepsContent));

//Styled Components
const MoreTextLeft = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    font-family: 'Inter-Bold';
    color: ${theme('palette.appDownloadPageSmallText')};
`;
const FlexWrapMore = styled.div`
    width: 285px;
    align-items: flex-start;
    display: flex;
    gap: 10px;
    color: ${theme('palette.appDownloadPageSmallText')};
    font-size: 26px;

    &.twoRow {
        flex-direction: column;
        gap: unset;
    }
`;

const StepIcon = styled.div<Pick<StyleProps, "reverseProp">>`
    width: 80px;
    display: flex;
    position: absolute;
    right: 20px;
    right: ${(props) => (props.reverseProp === 1 ? "20px" : props.reverseProp === 2 ? "40px" : props.reverseProp === 3 ? "20px" : props.reverseProp === 4 ? "40px" : "")};
    top: 20px;

    img {
        max-width: 100%;
    }
`;

const ContentWrapper = styled.div`
    width: calc(100% - 274px);
    display: flex;
    background: ${theme('palette.appDownloadInstallContentWrapper')};
    mix-blend-mode: normal;
    backdrop-filter: blur(40.3956px);
    border-radius: 12.4294px;
    padding: 4rem 3rem;
    border: ${theme('palette.appDownloadInstallContentWrapperBorder')};
    position: relative;
`;

const MobileMockupImg = styled.div<Pick<StyleProps, "reverseProp">>`
    display: flex;
    width: 274px;
    /* margin-right: -30px; */
    position: relative;
    z-index: 1;
    margin-bottom: -70px;
    margin-right: ${(props) => (props.reverseProp === 1 ? "-50px" : props.reverseProp === 2 ? "unset" : props.reverseProp === 3 ? "-50px" : props.reverseProp === 4 ? "unset" : "")};
    margin-left: ${(props) => (props.reverseProp === 1 ? "unset" : props.reverseProp === 2 ? "-30px" : props.reverseProp === 3 ? "unset" : props.reverseProp === 4 ? "-30px" : "")};

    img {
        max-width: 100%;
    }
`;

const Row = styled.div<Pick<StyleProps, "reverseProp">>`
    width: 60%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: ${(props) => (props.reverseProp === 1 ? "row" : props.reverseProp === 2 ? "row-reverse" : props.reverseProp === 3 ? "row" : props.reverseProp === 4 ? "row-reverse" : "")};

    @media only screen and (min-width: 1500px){
        width: 50%;
    }
`;