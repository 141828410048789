import  { useEffect } from 'react'
import PlaceholderImage from 'src/components/Placeholder';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import FooterContainer from "src/components/footer/footer";



const CrazyTimeEpicBonanza = (props) => {
    const { app: { brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = brandId === 31 || brandId === 32 ? 'Yolo247' : brandId === 33 ? 'Baazi Adda' : 'Betdaily';

    return (
        <>
            <InnerPromoWrapper>
                    <CrazyTimeEBContentWrapper>
                        <h3>Crazy Time – Double Extravaganza!</h3>
                        <p>
                             {domain} introduces double payouts on your favorite game – Crazy Time, a game that takes your betting experience to new heights. Brace yourself for an edge of the seat adventure, where strategy meets luck. A total pool prize of ₹ 3,000,000 awaits you!
                             <br /> <br />
                             Get your gaming wits on and have a wheel of a time! 
                       </p>
                        <CrazyTimeEBCupBox>
                            <h4>Embark on a money spinning adventure:</h4>
                            <p>
                                 <span>Double Bonus: </span>lucky players who win on the Bonus of Crazy Time: the Cash hunt, Coin Flip, Pachinko, and Crazy Time games , will receive a bonus between  ₹50 to ₹500  bonus from {domain}!.
                            </p>
                            <p>
                                 <span>Strategy Meets Luck : </span>Crazy Time is not just about luck, it's mainly strategy. Plot your course wisely, and seize opportunities to emerge as the ultimate betting maverick.
                            </p>
                            <p>
                                 <span>Bet and Win: </span>Place a minimum bet of  ₹ 300 & qualify for the Crazy Time Double Bonus!
                            </p>
                            <h4 className='mt-5'>Crazy Time – Double Bonus <br /> Extravaganza!</h4>
                            <CrazyTimeEBCupBoxInner>
                                <CrazyTimeEBCupBoxInnerLeft>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.crazyTimeEBGirlLeft}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                </CrazyTimeEBCupBoxInnerLeft>
                                <CrazyTimeEBCupBoxInnerRight>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.crazyTimeEBStepsRight}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                </CrazyTimeEBCupBoxInnerRight>
                            </CrazyTimeEBCupBoxInner>
                        </CrazyTimeEBCupBox>

                        <CrazyTimeEBTerms>
                            <CrazyTimeEBTermsLeft>
                               <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.crazyTimeLogoRight}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                            </CrazyTimeEBTermsLeft>
                            <CrazyTimeEBTermsRight>
                               <h4>Terms and Conditions:</h4>
                               <ol>
                                    <li>This promotion is valid for all new & existing players.</li>
                                    <li>This promotion is valid from 20th Jan 2024 to 20th Feb 2024.</li>
                                    <li>Each Player can only claim the bonus once during the promotional period.</li>
                                    <li>Minimum bet amount is ₹300 & the total cash prize is ₹3,000,000/-</li>
                                    <li>Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.</li>
                                    <li>Multiple accounts will not be considered for this promotion eligibility/criteria. All players will be subject to duplicate checking. {domain} reserves the right to disqualify players if collusion or any foul play takes place in the promotion.</li>
                                    <li>{domain} reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.</li>
                                    <li>{domain} General Terms & Conditions apply.</li>
                                </ol>
                            </CrazyTimeEBTermsRight>
                        </CrazyTimeEBTerms>
                    </CrazyTimeEBContentWrapper>
            </InnerPromoWrapper>
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(CrazyTimeEpicBonanza));

//Styled Components
const CrazyTimeEBContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(${config.imageV3Host}/static/cashsite/${theme('image.crazyTimeEBInnerBg')});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    gap: 2em;

    h3 {
        text-align: center;
        font-size: 26px;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        background: linear-gradient(180deg, #FFC01D 0%, #FFDF6E 49.5%, #F46D04 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding-top: 20px;
        margin: 10px 0px;
    }

    p {
        color: #FFF;
        text-align: center;
        font-size: 21px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        margin: 5px 0px;
    }

    .m-0 {
        margin: 0;
    }

    .mt-5 {
        margin-top: 2em;
        width: 100%;
        margin-bottom: 0em;
    }

    .p-0 {
        padding: 0rem 20rem;

        span{
            color: #E5A20D;
        }
    }

    a {
        width: 100%;
        height: auto;

        & span {
            display: flex !important;
            justify-content: center !important;

            & img {
                width: 60%;
                height: 80%;
            }
        }
    }

    .lazy-load-image-background {
        display: flex !important;
        justify-content: center !important;
    }

    .container {
        max-width: 1620px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;


const InnerPromoWrapper = styled.div`
    width: 98%;
    height: auto;
    border: 1px solid white;
    border-radius: 1px;
    background: rgba(111,111,111,0.5);
    margin: 0em 1.2em;
    -webkit-backdrop-filter: brightness(0.5);
    backdrop-filter: brightness(0.5);
`

const CrazyTimeEBCupBox = styled.div`
   width: 90%;
   height: auto;
   border-radius: 30px;
   border: 4px solid #FCFAFF;
   background: radial-gradient(126.78% 96.86% at 24.15% 70.64%, #391B00 0%, #1E0906 33.1%, #130E06 100%);
   padding: 2em;
   display: flex;
   flex-direction: column;
   gap: 5px;

   & > h4 {
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        background: linear-gradient(180deg, #FFC01D 0%, #FFDF6E 49.5%, #F46D04 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
   }

   & p {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24.5px;
        text-align: left;

        & span {
            color: #DFB44B;
            text-align: justify;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 24.5px; /* 122.5% */
        }
    }
`

const CrazyTimeEBCupBoxInner = styled.div`
    width: 95%;
    height: auto;
    display: flex;
    gap: 15px;
    align-items: center;
    margin-top: 1em;
`

const CrazyTimeEBCupBoxInnerLeft = styled.div`
    width: 50%;
    height: auto;

    & img {
        width: 90%;
        height: auto;
        margin-left: -120px;
        margin-bottom: -35px;
    }
`

const CrazyTimeEBCupBoxInnerRight = styled.div`
   width: 50%;
   height: auto;


    & .headViolet {
        text-align: left;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        background: linear-gradient(180deg, #E62ED4 0%, #9F00AF 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    
`

const CrazyTimeEBTerms = styled.div`
    width: 90%;
    height: auto;
    display: flex;
    gap: 10px;
    align-items: center;
    flex-direction: row-reverse;
`

const CrazyTimeEBTermsLeft = styled.div`
     width: 40%;
     height: auto; 

     & img {
        width: 100%;
        height: auto;
     }
`

const CrazyTimeEBTermsRight = styled.div`
    width: 50%;
    height: auto;

    & img {
        width: 60%;
        height: auto;
    }

    & li {
        color: #FFF;
        text-align: justify;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 165%;
        letter-spacing: -0.8px;
        text-transform: capitalize;
    }

    & h4 {
        text-align: left;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        background: linear-gradient(180deg, #FFC01D 0%, #FFDF6E 49.5%, #F46D04 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 10px 0px 0px 15px;

    }
`