import { useEffect, useState } from "react";
import styled from "styled-components";
import { ifProp, theme, withProp } from 'styled-tools';
import { color, bgcolor } from '../../../sass-files/variable';
// import Poker1 from '../../../images/casino-images/poker1.png';
import Jackpot from '../../../images/casino-images/jackpot.png';
import Baccarat1 from '../../../images/casino-images/baccarat1.png';
import SpinAWin1 from '../../../images/casino-images/spin-a-win.png';
import Baccarat2 from '../../../images/casino-images/baccarat2.png';
import Roulette1 from '../../../images/casino-images/roulette1.png';
import Wheel1 from '../../../images/casino-images/wheel1.png';
// import { Link } from "react-router-dom";
// import Testing from '../../../images/casino-images/poker_laptop.jpg'
// import Modal from 'src/components/Modal';
// import CasinoGames from 'src/components/eu-content/casino-page/casino-slider-Games';
// import casinoGameList1 from "./data";
import Loading from "src/pages/loading";
import LeftArrow from "../../../images/Left_arrow.png";
import RightArrow from "../../../images/Right_arrow.png";
import Slider from "react-slick";
import "../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";
import CasinoFirst from '../../../images/bet_war_of_bets.png';
import CasinoSecond from '../../../images/evo_dreamcatcher.png';
import CasinoThird from '../../../images/ezugi_dragon tiger.png';
// import CasinoFourth from '../../../images/Netent_blackjack.png';
import CasinoFifth from '../../../images/Super7_teenpatti.png';
import CasinoSixth from '../../../images/Superspade_andharbahar.png';
import CasinoSeventh from '../../../images/Superspade_dragontiger.png';
import config from "src/config/config";
import cookieUtil from "src/util/cookieUtil";
import { ECookieName } from "src/util/utilModel";
import Intl from '../../common/Intl';
import { ONE_CLICK_RECYCLE } from "src/graphql/system";
import { useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from 'src/components/Placeholder';
import {SRTM} from 'src/util/sportradarEvent';
import { withAppContext } from "src/store/initAppContext";

// import LeftArrow from '../../images/Left_arrow1.png';
// import RightArrowIcon from "src/images/Right_arrow1.png"
// import LeftArrow from 'src/images/Left_arrow1.png';
// import LeftArrowHover from 'src/images/hover_left arrow.png';
// import RightArrowHover from 'src/images/hover_right arrow.png';
// import NormalButton from '../../images/newnormalbutton.png';

const { secondarycolor, menucolor } = color;
// const { loginbghover } = bgcolor
// const { signupbghover } = bgcolor

const AllGame: any = {
    web: [
        {
            img: 'slider_1', name: 'slider_image_5', id: 1, src: CasinoFirst, alt_name: 'Casino_image_1', pathName: '/casino', type: 'BETGAMES', platformCode: "WACS", code: "BGAMES-8"
        },

        {
            img: 'slider_2', name: 'slider_image_5', id: 2, src: CasinoSecond, alt_name: 'Casino_image_2', pathName: '/casino', type: 'EVOLUTION', platformCode: "WACS", code: "EVOLUTION-moneywheel-MOWDream00000001"
        },

        {
            img: 'slider_3', name: 'slider_image_5', id: 3, src: CasinoThird, alt_name: 'Casino_image_3', pathName: '/casino', type: 'EZUGI', platformCode: "WACS", code: "EZUGI-BaccaratDragonTiger_150"
        },

        // {
        //     img: 'slider_4', name: 'slider_image_5', id: 4, src: CasinoFourth, alt_name: 'Casino_image_4', pathName: '/casino', type: 'NETENT', platformCode: "WACS", code: "EVOLUTION-blackjack3ne-blackjack3ne0000"
        // },

        {
            img: 'slider_5', name: 'slider_image_5', id: 5, src: CasinoFifth, alt_name: 'Casino_image_5', pathName: '/casino', type: 'SUPERSPADE', platformCode: "WACS", code: "Tnptikajgh8t2020"
        },

        {
            img: 'slider_6', name: 'slider_image_5', id: 6, src: CasinoSixth, alt_name: 'Casino_image_6', pathName: '/casino', type: 'SUPERSPADE', platformCode: "WACS", code: "Ab1lkajgh7trgh20"
        },

        {
            img: 'slider_7', name: 'slider_image_5', id: 7, src: CasinoSeventh, alt_name: 'Casino_image_7', pathName: '/casino', type: 'SUPERSPADE', platformCode: "WACS", code: "Dt19er0d1t2iew37",
        },
    ]
}

function AllGames(props: any) {

    const settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        arrows: true,
        pauseOnHover: false,

    };
    const isAuth = cookieUtil.get(ECookieName.COOKIE_TOKEN);
    const [filteredData, setfilteredData] = useState([]);
    const [search, setSearch] = useState('')
    const [isGameTrigger, setGameTrigger] = useState(false)
    const [gameDetails, setGameDetails] = useState({})

    const Language = cookieUtil.get(ECookieName.COOKIE_LANG);

    const [triggerOneClickRecycle, { recycleLoading, recycleError, recycleData }]: any = useMutation(ONE_CLICK_RECYCLE)
    // const [isGameTrigger, setGameTrigger] = useState(false);
    const [disabled, setDisabled] = useState(true)

    const { app: { brandId }} = props
    //for sportradar
    useEffect(()=>{
       SRTM.init(brandId)
    },[])

    const loadGame = (event, game) => {
        setGameTrigger(true);
        setGameDetails(game);
        setDisabled(false);

        //for sport Radar
        if(game && typeof game === 'object'){
            SRTM.retargetCasinoEvent(brandId,{
                id:game.id || '',
                name:game.game,
                category:game.type,
                image:game.imgsrc[0]
            })
        }

    }

    const [selectedGame, setSelectedGame] = useState([]);


    let navigate: any = useNavigate();
    const backButton = () => {
        window.location.reload();
        triggerOneClickRecycle()
            .then((response) => {
                if (response) {
                    return;
                }
            })
            .catch((error) => {
                console.log("Error in one click recycle");
            });

        // setDisabled(true)
        setTimeout(() => {
            setDisabled(true)
        }, 1000)
    }

    return (
        <>

            <BackButton onClick={backButton} disabled={disabled}> <img src={LeftArrow} alt="LeftArrow" />
                <Intl langKey="BUTTON@BACK" />
            </BackButton>

            <div style={{ width: "100%" }}>
                {
                    isGameTrigger ? <Loading modalState={props.data} platformDetails={gameDetails} /> : <>
                        <SectionWrapper>
                            <FirstColoumn>
                                <RowFirst>

                                    {
                                        isGameTrigger ? <Loading modalState={props.modalState} platformDetails={gameDetails}  /> : <>
                                            <GamesSliderWrapper>

                                                <Slider {...settings}>
                                                    {
                                                        AllGame['web'].map((each, index) => {
                                                            return (
                                                                <div key={index} className="blocks-top" >
                                                                    <div className="banner_img"><LazyLoadImage src={`${config.imageS3Host}${each.src}`} onClick={(event) => loadGame(event, each)} effect="none" height="100%" width="100%"  placeholder={<PlaceholderImage/>} visibleByDefault={false}/></div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </Slider>

                                            </GamesSliderWrapper>
                                        </>
                                    }
                                </RowFirst>

                                <RowFirstExtented>
                                    {props.data?.length !== 0 ? props.data.map((each, index) => {
                    
                                          //Modification Required as imgsrc might be an array
                                          let _imgSrc = each.imgsrc;
                                          if(typeof _imgSrc === 'object' && Array.isArray(_imgSrc)){
                                              _imgSrc = (Language === 'hindi-ind') ?  _imgSrc[0] : _imgSrc[1]
                                          }

                                        return (
                                            <div key={index} className="blocks"  >
                                                <div className="subblocks">
                                                    <span className="coltenth">
                                                        <LazyLoadImage src={`${config.commonImgS3Host}${_imgSrc}`}  effect="none" height="100%" width="100%"  placeholder={<PlaceholderImage/>}/>
                                                        <div className="overlay">
                                                            <div className="buttons">
                                                                {
                                                                    cookieUtil.get(ECookieName.COOKIE_TOKEN) ? <PlayButton>
                                                                        <button onClick={(event) => loadGame(event, each)}><Intl langKey="TITLE@PLAY" /></button>
                                                                    </PlayButton> : <DemoButton>
                                                                        <button onClick={(event) => loadGame(event, each)}><Intl langKey="TITLE@DEMO" /></button>
                                                                    </DemoButton>

                                                                }


                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    }) : <MsgWrap><NoGamesMessage><Intl langKey="Games@NoGamesMessage" /> <br /> <Intl langKey="Games@NoGamesMessage2" /></NoGamesMessage></MsgWrap>
                                    }

                                </RowFirstExtented>
                            </FirstColoumn>
                        </SectionWrapper>
                    </>
                }


            </div>
        </>
    )
}

export default withAppContext(AllGames);
const HoverEffect = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0;
  z-index: 9;
  display: none;
`;

const FirstColoumn = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    span {
        width: 100%;
        display: flex!important;
        align-items: center;
        flex-direction: row;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;  
        /* margin: 5px 5px 0px 0px; */
        margin: 0px;
        position: relative;
        cursor: default;   
    }

    span:hover .overlay {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    .coltenth {
        // height: 230px;
       
        align-items: flex-end;

        h4 {
            bottom: 30px;
        }

        img {
            border: 2px solid;
            background: ${theme('palette.casinoBorder')};
        }
    }
   
    h4 {
        position: absolute;
        left: 1.5em;
        margin: 0;
        font-size: 30px;
        color: ${secondarycolor};
        
        text-transform: uppercase;
    }
`;

const SectionWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;

    img {
        height:100%;
        width: 100%;

    }

    .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.9;
        background: ${theme('palette.overlayBGColor')};
        overflow: hidden;
        width: 100%;
        height: 100%;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        -webkit-transition: .5s ease;
        transition: .5s ease;
      }
      
      .buttons {
        color: #fff;
        font-size: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
`;

const PlayButton = styled.div`
    transform: skew(340deg);
    border: 1px solid #CB9640;
   
    box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);

    :hover {
        background: ${theme('palette.primary-background-color-linear-new')};
        transition: color 0.1s linear 0s;
    }

    button {
        width: 80px;
        text-align: center;
        display: block;
        text-decoration: none;
        padding: 10px 0px;
        font-size: 14px;
        transform: skew(-340deg);
        color: ${secondarycolor};
        
        text-transform: uppercase;
        background-color: transparent;
        border: none;
        cursor: pointer;
    }
`;

const DemoButton = styled.div`
    transform: skew(340deg);
    border: 1px solid #CB9640;
    margin-left: 10px;
    background:linear-gradient(180deg, #000000 0%, #2F3236 100%); 
    box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);
    transition: color 0.1s linear 0s;
    

    :hover {
        background: ${theme('palette.primary-background-color-linear-new')};
        transition: color 0.1s linear 0s;
    }

    button {
        width: 80px;
        text-align: center;
        display: block;
        text-decoration: none;
        padding: 10px 0px;
        font-size: 14px;
        transform: skew(-340deg);
        color: ${secondarycolor};
        
        text-transform: uppercase;
        background-color: transparent;
        border: none;
        cursor: pointer;
    }
`;



export const RowSecond = styled.link`
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    // background-color: ${secondarycolor};
    flex-wrap: wrap;
`;

export const RowFirst = styled.div`
    width:100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    /* justify-content: center; */
    // background-color: ${secondarycolor};


    .blocks {
        /* width: 15.8%; */
        flex: 0 0 15.8%;
        /* width: 12.8vw; */

        @media only screen and (min-width: 1500px){
            /* width: 13.5%; */
            flex: 0 0 13.5%;
            /* width: 11.7vw; */
        }
    }

    .blocks-top {
        /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 4px 4px 4px rgba(0, 0, 0, 0.25); */
        padding: 2px;
        margin: 2px;
        border-radius: 5px;
        width: 25%;
        box-sizing: border-box;
        flex: 0 0 24%; /* explanation below */
        margin: 5px;
        /* clip-path: polygon(0% 0%,85% 0%,100% 100%,13% 100%); */
    }

    .blocks_fullwidth {
        width: 100%;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 4px 4px 4px rgba(0, 0, 0, 0.25);
        padding: 2px;
        margin: 3px;
        border-radius: 5px;
    }

    .top_up {
        position: relative;
        top: -25px;
    }
`;

export const RowFirstExtented = styled(RowFirst)`

`;

export const GameName = styled.div`
    display: none;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${menucolor};
    padding: 10px;
    font-size: 0px;
    font-weight: 600;
    line-height: 20px;
`;

export const Col1: any = styled.div`
    width: 170px;
    height: 170px;
   
    background-image: url(${(props: any) => props.imageSrc});
    background-size: cover;
    background-repeat: no-repeat;
`;

export const Col2 = styled.link`
    width: 100%;
    height: 170px;
    display: flex;
    background-image: url(${Baccarat1});
    background-size: cover;
    background-repeat: no-repeat;
`;

export const Col3 = styled.link`
    width: 100%;
    height: 550px;
    display: flex;
    background-image: url(${Jackpot});
    background-size: cover;
    background-repeat: no-repeat;
`;

export const Col4 = styled.link`
    width: 100%;
    height: 290px;
    display: flex;
    background-image: url(${SpinAWin1});
    background-size: cover;
    background-repeat: no-repeat;
`;

export const Col5 = styled.link`
    width: 100%;
    height: 264px;
    display: flex;
    background-image: url(${Baccarat2});
    background-size: cover;
    background-repeat: no-repeat;
`;

export const Col6 = styled.link`
    width: 100%;
    height: 197px;
    display: flex;
    background-image: url(${Roulette1});
    background-size: cover;
    background-repeat: no-repeat;
`;

export const Col7 = styled.link`
    width: 100%;
    height: 222px;
    display: flex;
    background-image: url(${Wheel1});
    background-size: cover;
    background-repeat: no-repeat;
`;

const GamesSliderWrapper = styled.div`
    width: 100%;
    /* height: 100px; */
    display: flex;
    align-items: flex-start;
    flex-direction: row;

    :hover {
        ${HoverEffect} {
          display:  block;
          background: rgba(5, 11, 9, 0.5);
          // transform: skew(-27deg);
          transition: 0.3s ease-in;
        }
      }
    
    
    
      .slick-arrow {
        color: black;
      }

    .slick-next:before {
        font-size:0px;
    }

    .slick-prev:before{
        font-size:0px;
    } 

     .slick-prev{
        z-index: 100;
        left: 12px !important;
        color: black;
        position: absolute;
        cursor: pointer;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        height: 30px;
        width: 20px;
        border: none;
        background-image:url(${LeftArrow});
        background-repeat: no-repeat;
        background-size: contain;
        content: none;
        background-color: transparent;
        font-size:0px;
    }

    .slick-next{
        z-index: 100;
        right: 10px !important;
        color: black;
        position: absolute;
        cursor: pointer;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        height: 30px;
        width: 20px;
        border: none;
        background-image:url(${RightArrow});
        background-repeat: no-repeat;
        background-color: transparent;
        background-size: contain;
        content: none;
        font-size:0px;
    } 

    .slick-list{
        // height: 152px;

        .slick-slide {
            padding: 0px 3px;
        }
    }

    .slick-slider {
        z-index: 1;
        position: relative;
        // height: 152px;
        width:100%;  
    }

    .ul slick-dots {
        display:none;
    }

    .slick-arrow slick-prev slick-disabled {
        color:black;
        display: none;
    }
`;


export const BackButton = styled.button`
    display: flex;
    font-size: 15px;
    color: #D0B99F;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    padding: -0.1em 1.7em;
    background: ${theme('palette.secondary-background-color-linear-new1')};

    border-radius: 3px;
    // margin-left: 150px;
    position: absolute;
    top: -28px;
    right: 15px;
    cursor:pointer;
    border: 0.25px solid #D0B99F;
    // box-shadow: 0 0 3px #786B57;
    height:25px;
img{
    padding-right:5px;
    color:#fff;
    height:15px;
    
}

:disabled{
    color: red;
    background-color: red;
    display: none;
}

`;

export const NoGamesMessage = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    background-color: #0000008a;
    padding: 2rem;
    color: #ffffff;
    text-align: center;
    line-height: 25px;
    text-transform: capitalize;
    border-radius: 20px;
`
export const MsgWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50vh;
`