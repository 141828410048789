import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import config from 'src/config/config';
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import { FifaFeverFirstSecHead1, TermsConditionPoints } from 'src/pages/promotions/evolutionPromo';
import { InformationWrapper } from '../../components/Bonus';
import FooterContainer from "src/components/footer/footer";

function EvoBaccaratBetWin(props) {
    const { app: {
        brandId
    }, theme } = props;



    const domain = brandId === 31 || brandId === 32 ? 'Yolo247' : brandId === 33 ? 'Baazi Adda' : 'Betdaily';

    return (
        <>
            <InformationWrapper className='evoCasinoCupPromo'>
                <Wrapper>
                    <section className='evoBaccratTopSec'>
                        <h2>EVOLUTION BACCARAT BET & WIN !</h2>
                        <span></span>
                        <NoBorderTop>
                            <h4>Embark on a 12-Week Baccarat Bonanza with Evolution's Epic Journey!</h4>
                            <p>Join us for an extended adventure spanning January, February, and March, following the phenomenal success of our December Evolution Baccarat promotion. The thrill continues with 12 weeks of non-stop excitement, promising extraordinary rewards and unforgettable moments.</p>
                            <p>If you missed December's spectacle, fear not! The Evolution Baccarat tables are still buzzing, and we're intensifying the excitement for this epic 12-week journey.</p>
                        </NoBorderTop>
                    </section>

                    <div className='blockWrapper'>
                        <ProcessColumn>
                            <Firstrow>
                                <img src={`${config.imageV3Host}/static/cashsite/${theme.image.baccaratgrl}`} className='img' />
                                <h4>
                                   Why Dive Into the Action:
                                </h4>
                            </Firstrow>
                            <SecondRow>
                                <LiTextContent>
                                    <StepTittleMore>✪  12 Weeks of Thrilling Moments:</StepTittleMore>
                                    <TextContent> Every week brings a fresh chance to showcase your skills and conquer the Baccarat arena.</TextContent>
                                </LiTextContent>
                                <LiTextContent>
                                    <StepTittleMore>✪  Weekly Winner Celebrations: </StepTittleMore>
                                    <TextContent>Join us in celebrating the victories of our 1000 weekly champions, making each win a special occasion.</TextContent>
                                </LiTextContent>
                                <LiTextContent>
                                    <TextContent>
                                        This isn't just a journey; it's an opportunity to grab your share of the jaw-dropping <span> 2 Crore total prize pool. </span> With <span> 20 lakhs distributed among 1000 players every week.</span> the stakes are higher than ever! Answer the call of Evolution's Baccarat promotion and be part of this exhilarating 12-week saga! 
                                    </TextContent>
                                </LiTextContent>
                            </SecondRow>
                        </ProcessColumn>
                    </div>

                    <section>
                        <PriceTable>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/brand/6/image/evoBaccaratPrizeTable.png`} effect="none" placeholder={<PlaceholderImage />} />
                        </PriceTable>
                    </section>

                    <NoborderTop1>
                        <p><StepTittle>✪ </StepTittle> The winners are based on the players who wager in Evolution Baccarat games during the promotion from 2nd JAN – 25th MAR 2024</p>
                        <p><StepTittle>✪ </StepTittle>Winners will be determined on a weekly basis from the 2nd JAN – 25th MAR 2024.</p>
                        <p><StepTittle>✪ </StepTittle> There are 1000 prizes worth INR 20 Lakhs which will be given to players weekly during the promotion period.</p>
                    </NoborderTop1> 

                    <section>
                        <PriceTable>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/brand/6/image/baccaratgrl2.png`} effect="none" placeholder={<PlaceholderImage />} />
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.priceAllocationTablebaccarat}`} effect="none" placeholder={<PlaceholderImage />} />
                        </PriceTable>
                    </section>

                    <TermsConditionPoints>
                        <FifaFeverFirstSecHead1 className="frstbettc"><p>TERMS & CONDITIONS</p></FifaFeverFirstSecHead1>
                        <ul>
                            <li><TextContent> This promotion is valid for all new & existing players. </TextContent></li>
                            <li><TextContent> The Cash Drop winners are based on the players who wager in any Evolution Baccarat tables during the promo cash drop periods.</TextContent></li>
                            <li><TextContent> The cash drop will run weekly from 2nd JAN – 25th MAR 2024 </TextContent></li>
                            <li><TextContent> Qualified Game: All Evolution Baccarat tables (Exclude First person tables and RNG). </TextContent></li>
                            <li><TextContent> The Qualified members will continue to be eligible for prizes in the next period. </TextContent></li>
                            <li><TextContent> Winners will be announced in the week after the promo within 3 working days on {domain} social media handles (Instagram & telegram). </TextContent></li>
                            <li><TextContent> Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements</TextContent></li>
                            <li><TextContent> Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. {domain} reserves the right to disqualify players if collusion or any foul play takes place in the promotion. </TextContent></li>
                            <li><TextContent> {domain} & Evolution reserves the right to amend ,suspend or cancel the promotion at any time and without prior notice. </TextContent></li>
                            <li><TextContent> {domain} General Terms & Conditions apply</TextContent></li>
                        </ul>
                    </TermsConditionPoints>
                </Wrapper>
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(EvoBaccaratBetWin));

const SecondRow = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
`
const Firstrow = styled.div`
    width: 50%;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .img{
        width: 351px;
        height: 445px;
        flex-shrink: 0;
    }

    h4 {
        text-align: right;
        leading-trim: both;
        text-edge: cap;
        text-shadow: 0px 4.999px 38.393px #000;
        font-size: 50px;
        font-style: normal;
        font-weight: 800;
        line-height: 60px; /* 118.572% */
        text-transform: uppercase;
        margin: 3px 0px;
        background: linear-gradient(180deg, #FADA34 0%, #DE9101 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .paragraph{
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        text-transform: capitalize;
        background: linear-gradient(180deg, #FFF 0%, #DED8CD 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
    }
`


const PriceTable = styled.div`  
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 1rem;
    text-align: center;
    margin: 0 auto;

    img {
        max-width: 100%;
    }
`;

const TextContent = styled.p`
    /* display: flex; */
    color: ${theme('palette.catch99TextContentColor')};
    font-size: 20px;

    & span {
        color: #F0BE21 !important;
        font-size: 20px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        text-transform: capitalize;
        margin: 0px;
        display: contents !important;
    }
`;

const LiTextContent = styled.div`
    width: 75%;
    display: flex;
    flex-direction: column;

    p {
        margin: 0;
        margin-top: 10px;
    }

    span {
        text-transform: uppercase;
        font-size: 18px;
    }
`;

const ProcessColumn = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    padding: 0;
    position: relative;
    border-radius: 10px;
    border: 3px solid rgba(255, 208, 132, 0.74);
    backdrop-filter: blur(12.5px);
    background: url(${config.imageV3Host}/static/cashsite/brand/6/image/baccaratbgbox1.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    margin-bottom: 2.5rem;

    img {
        width: 50px;
        height: 50px;
    }
`;

const StepTittleMore = styled.div`
    color: #F0BE21;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
`;

const StepTittle = styled.div`
    display: flex;
    color: #FFFF81;
    font-family: 'Inter-Bold';
    display: contents;
`;

const NoBorderTop = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 1.5rem;
    text-align: justify;
    align-items: center;

    & p {
        color: #FFF;
        text-align: center;
        font-size: 21px;
        font-style: italic;
        font-weight: 400;
        margin: 1 auto;
        line-height: normal;
    }

    & h4 {
        color: #F0BE21;
        text-align: center;
        font-size: 21px;
        font-style: italic;
        font-weight: 600;
        line-height: normal;
        margin: 20px 0px 3px 0px;
    }
`;

const NoborderTop1 = styled(NoBorderTop)`
    align-items: flex-start;
    justify-content: center;
    padding: 0rem 10rem 3rem 10rem;

    p {
        margin-bottom: 0;
        color: #F1C123;
        text-align: justify;
        font-size: 21px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
    }
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    section {
        width: 100%;
        padding: 4rem 5rem;
        padding-top: 0;
        padding-bottom: 2rem;
    }
    

    .evoBaccratTopSec {
        width: 100%;
        padding: 1rem 5rem;
        padding-top: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        h2 {
            text-align: center;
            font-size: 28px;
            font-style: italic;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 1.8px;
            text-transform: uppercase;
            background: var(--Yellow, linear-gradient(180deg, #FADA34 0%, #DE9101 100%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin: 3px 0px;
        }

        & span {
          width: 10%;
          height: 2px;
          background-color: #DE9101;
          margin-top: 5px;
        }

        .top{
           padding-top:2.5rem;
        }
    }

    .blockWrapper {
        width: 100%;
        padding: 0rem 6rem;

        .frstbet {
            height: 80px;
        }
        .first {
            background: url(${config.imageV3Host}/static/cashsite/brand/6/image/evoCasinoCupRegisterBg.png);
            background-repeat: no-repeat;
            width: 90%;

        }

    }

    .textSection {
        width: 100%;
        padding: 1rem 6rem;
        text-align: center;
        text-transform: capitalize;
        line-height: 22px;
        font-size: 18px;
    }

  

    .frstbetprize {
        height: 80px;
    }

    .blockCasinoWrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1rem;
        margin: 0;
        padding-bottom: 2rem;

        li {
            flex: 0 0 12.5%;
            list-style: none;

            img {
                max-width: 100%;
            }
        }
    }


`;