import React from 'react'
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Slider from "react-slick";
import config from 'src/config/config';
import { theme } from 'styled-tools';
import PlaceholderImage from 'src/components/Placeholder';

const FomoSponsorsSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        pauseOnHover: true
    };

    const sponsorImages = ["fomoSponsorSilderOne", "fomoSponsorSilderTwo"];

    return (
        <ImageBannerWrapper>
            {sponsorImages && sponsorImages.length > 0 ? (
                <Slider {...settings}>
                    {sponsorImages.map((img, index) => {
                        return (
                            <div key={index}>
                                <LazyLoadImage
                                    src={`${config.imageV3Host}/static/cashsite/brand/6/image/${img}.png`}
                                    alt="Sponsors"
                                    effect="none"
                                    height="100%"
                                    width="100%"
                                    placeholder={<PlaceholderImage />}
                                />
                            </div>
                        );
                    })}
                </Slider>
            ) : null}
        </ImageBannerWrapper>
    )
}

export default FomoSponsorsSlider

const ImageBannerWrapper = styled.div`
    width: 100%;
    position: relative;
    padding: 1rem;
    margin-bottom: 4rem;

    .slick-dots {
        display: flex!important;
        align-items: center;
        justify-content: center;
        padding: 10px;
        bottom: -2rem;

        li {
            margin: 0px 1px;
        }
        li button:before {
            font-size: 13px;
            color: ${theme('palette.BannerDotsColor')};
        }
        li.slick-active button:before {
            opacity: .75;
            color: ${theme('palette.BannerDotsActiveColor')};
        }
    }
`;