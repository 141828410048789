import React from 'react'

type Props = {}

export default function DeleteIcon({}: Props) {
  return (
    <svg fill="none" xmlns="" viewBox="0 0 16 16"><g clip-path="url(#a)" stroke="#E8E8E8" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"><path d="M2.13 4.464h11.25M5.256 4.464v-1.25a1.25 1.25 0 0 1 1.25-1.25h2.5a1.25 1.25 0 0 1 1.25 1.25v1.25m1.875 0v8.75a1.25 1.25 0 0 1-1.25 1.25H4.63a1.25 1.25 0 0 1-1.25-1.25v-8.75h8.75ZM6.506 7.589v3.75M9.006 7.589v3.75"/></g><defs><clipPath id="a"><path fill="#fff" transform="translate(.256 .714)" d="M0 0h15v15H0z"/></clipPath></defs></svg>
  )
}

export const XCircleIcon = (props) => {
  return (  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.99992 14.6667C11.6818 14.6667 14.6666 11.6819 14.6666 8.00001C14.6666 4.31811 11.6818 1.33334 7.99992 1.33334C4.31802 1.33334 1.33325 4.31811 1.33325 8.00001C1.33325 11.6819 4.31802 14.6667 7.99992 14.6667Z" stroke="#B7B5CD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10 6L6 10" stroke="#B7B5CD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6 6L10 10" stroke="#B7B5CD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>)
}