import React, { createRef, useState } from 'react'
import styled, { withTheme } from 'styled-components';
import { color } from '../../sass-files/variable';
// import searchicon from '../../images/search_icon.png';
import util from 'src/util/util';
import { useQuery } from '@apollo/client';
import { SEARCH_EVENTS } from 'src/graphql/events';
import { diffColor } from 'src/util/colorUtil';
import { getGTMId } from 'src/util/gtmUtil';
import Intl from '../common/Intl';
// import routerUtil from 'src/util/routerUtil';
import EventTypeConfig from '../../config/eventTypeConfig';
// import loading from 'src/pages/loading';
import { withAppContext } from 'src/store/initAppContext';
import Spinner from '../common/Spinner';
import { useNavigate } from "react-router-dom";
// import { any } from 'ramda';
// import Clock from "../Clock/index";
import cookieUtil from 'src/util/cookieUtil'
import { ECookieName } from 'src/util/utilModel'
import { ifProp, theme, withProp } from 'styled-tools';
import ReactPixel from 'react-facebook-pixel';
import config from 'src/config/config';

const { yellowfont } = color

function Search(props) {
    let navigate: any = useNavigate();
    const [search, setSearch] = useState("")
    const [isSearchFieldFocused, setIsSearchFieldFocused] = useState(false)

    const inputRef = createRef();

    const handleSearch = ({ target: { value } }) => {
        setSearch(value)
    }

    const handleFocus = () => {
        setIsSearchFieldFocused(true)
        window.addEventListener('keydown', pressESC);
        try {
            ReactPixel.trackSingle('1312062079326762', 'track', 'Search');
            
        } catch (error) {
            
        }
    };

    const clearSearchState = () => {
        setTimeout(() => {
            window.removeEventListener('keydown', pressESC);
            setSearch('')
            setIsSearchFieldFocused(false)
        });
    };

    const pressESC = (e: KeyboardEvent) => {
        const { current } = inputRef;
        const { key } = e;

        if (key === 'Escape') {
            current instanceof HTMLInputElement && current.blur();
            this.clearSearchState();
        }
    };

    const gotoEvents = (item: any): any => (): void => {

        const { categoryId, competitionId, eventId } = item;
        const isSpecailFancy = competitionId === '0';
        // const routerPath = `/sports/${EventTypeConfig(categoryId).eventTypeKey}${ 
        // 	isSpecailFancy ? `/e/${eventId}` : `/${competitionId}/${eventId}`
        // }`;
        const routerPath = `/market/${EventTypeConfig(categoryId).eventTypeKey}${isSpecailFancy ? `/${eventId}` : `/${competitionId}/${eventId}/'1'`}`
        setSearch('')
        navigate(routerPath);

    };

    const { data, loading, error } = useQuery(SEARCH_EVENTS, {
        variables: {
            input: {
                keyword: search
            }
        }
    });
    const events = util.getValue(data, ['markets', 'search'], []);

    const isAuth = cookieUtil.get(ECookieName.COOKIE_TOKEN);

    const { app: {
        brandId
    }, theme, image } = props

    return (
        <>
            <Intl langKey="SEARCH">
                {(message: string) => {
                    return (
                        <>
                            <SearchContainer theme={props.app.theme} customStyle={props.theme.palette}>
                                <InputWrapp customStyle={props.theme.image}>
                                    <input
                                        autoFocus={false}
                                        onChange={handleSearch}
                                        onFocus={handleFocus}
                                        placeholder={message}
                                        value={search}
                                        // ref={this.inputRef}
                                        id={getGTMId('search_event')} />
                                </InputWrapp>
                            </SearchContainer >
                            {/* {
                        isAuth && <Clock />
                    } */}

                        </>
                    );
                }}
            </Intl>
            <div>
                {search.length >= 1 && (
                    <SearchResultWrapper >
                        {loading ? (
                            <Spinner mode="client" />
                        ) :
                            events.length > 0 ? (
                                events.map((event: any, idx: number) => {
                                    return (
                                        <SearchResultItem
                                            key={idx}
                                            onClick={gotoEvents(event)}
                                        >
                                            {event.eventName}
                                        </SearchResultItem>
                                    );
                                })
                            ) : (
                                <SearchNotFoundItem>
                                    <Intl langKey='No events found' /> "{search}"
                                </SearchNotFoundItem>
                            )}
                    </SearchResultWrapper>
                )}
            </div>
        </>
    )
}

export default withTheme(withAppContext(Search));

const SearchClockWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const SearchResultWrapper = styled.ul`
	display: block;
	position: absolute;
	// z-index: 10;
    // width: calc(100% - 1300px);
    width: 100%;
	// max-height: calc(100% - 80px);
    // height:100%;
	padding: 0;
	margin: 0px;
	list-style: none;
	box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
	font-size: 12px;
	background:#EBEBEB;
	color: white;
	border-radius: 4px;
	overflow: hidden;
	overflow-y: auto;
    max-height: 300px;
    // min-height: 8em;
    top: 45px;
    right: 0;
    z-index:99;
`;

const SearchResultItem = styled.li`
	display: block;
    -webkit-box-align: center;
    align-items: center;
    padding: 0.75em 1em;
    cursor: pointer;
    background-color: ${theme('palette.SearchResultItemBg')};
    position: relative;
    color: ${theme('palette.SearchResultItemColor')};
    z-index: 999;
    border-bottom: ${theme('palette.SearchResultItemBorder')};

	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 2px;
        background-color: ${theme('palette.SearchContainerBg')};
		/* opacity: 0; */
	}

	&:nth-of-type(2n) {
        background-color: ${theme('palette.SearchResultItemBg')};
	}

	&:hover {
        background-color: ${theme('palette.SearchResultItemBgHover')};
		&::before {
			opacity: 1;
		}
	}
`;


const Query = styled.div``;

const SearchInput = styled.div``;

const SearchNotFoundItem = styled.div`
    color: ${theme('palette.SearchNotFoundColor')};
	text-align: center;
	padding: 1em;
`;

const InputWrapp: any = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    :after {
        content: "";
        width: 15px;
        height: 15px;
        display: inline-block;
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.SearchIcon')});
        background-size: cover;
        background-repeat: no-repeat;
        // transform: skew(-340deg);
        position: absolute;
        left: 10px;
    }
`;

const SearchContainer: any = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // transform: skew(340deg); /* SKEW */
    background: ${(props: any) => props.customStyle['SearchContainerBg']};
    box-shadow: ${(props: any) => props.customStyle['SearchContainerBoxShadow']};
    border-radius: 5px;
    position: relative;
    border: ${(props: any) => props.customStyle['SearchContainerBorder']};
    position: relative;

    /* ::before {
        content: "";
        width: 30px;
        height: 100%;
        background: #7B7B9C;
        position: absolute;
        top: 1px;
        left: -10px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        transform: skew(340deg);
        box-shadow: inset 5px 3.43095px 3.43095px rgba(0, 0, 0, 0.25);
    } */

    input {
        width:100%;
        background: transparent;
        border: none;
        text-align: left;
        // margin-left: 20px;
        // transform: skew(-340deg); /* UNSKEW */
        text-transform: uppercase;
        padding: 10px 5px 10px 12px;
        outline:0;
        color: white;
        /* color: ${(props: any) => props.customStyle['searchTextColor']}; */
        /* color: ${(props) => (props.theme == 'Dark' ? '#000' : '#DEDEFF')}; */
        margin-left: 20px;
      
        ::placeholder {
            /* color: ${(props) => (props.theme == 'Dark' ? '#000' : '#DEDEFF')}; */
            /* color: ${(props: any) => props.customStyle['searchTextColor']}; */
            color: white;
            opacity: 1; /* Firefox */
        }
          
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            /* color: ${(props) => (props.theme == 'Dark' ? '#000' : '#DEDEFF')}; */
            color: ${(props: any) => props.customStyle['searchTextColor']};
        }
        
        ::-ms-input-placeholder { /* Microsoft Edge */
            /* color: ${(props) => (props.theme == 'Dark' ? '#000' : '#DEDEFF')}; */
            color: ${(props: any) => props.customStyle['searchTextColor']};
        }
        :focus {
            outline:0;
            border:none
        }
    }
`;
