import  { useEffect } from 'react'
import PlaceholderImage from 'src/components/Placeholder';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import FooterContainer from "src/components/footer/footer";



const AviatorHighFlyer = (props: any) => {
    const { app: { brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const domain = brandId === 31 || brandId === 32 ? 'Yolo247' : brandId === 33 ? 'Baazi Adda' : 'Betdaily';

    return (
        <>
            <InnerPromoWrapper>
                    <AviatorHFContentWrapper>
                        <div>
                            <div className='textWrapper'>
                                <h3>Aviator – 100% Bonus Extravaganza!</h3>
                                <p>
                                    {domain} introduces <b>100% bonus</b> payouts on your favourite game – Aviator, a game that takes your entertainment quotient to new heights. Brace yourself for an edge of the seat adventure , where strategy meets skills. A total pool prize of <b>₹5,000,000</b> awaits you!
                                    <br /> <br />
                                    Get your gaming wits on and Soar high!
                                </p>
                            </div>
                            <AviatorHFCupBox>
                                <h4>Embark on a high flying adventure:</h4>
                                <AviatorHFCupBoxInner>
                                    <AviatorHFCupBoxInnerLeft>
                                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.aviatorHighFlyerLeftPlane}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                    </AviatorHFCupBoxInnerLeft>
                                    <AviatorHFCupBoxInnerRight>
                                        <p>
                                            <span>100% Bonus: </span>Earn coins on your aviator bets. Earn 1 aviator coin for every ₹100 bet on aviator.  Lucky players will receive 100% bonus of their bet amount from {domain}, limited to the announced Prize slabs!.
                                        </p>
                                        <p>
                                            <span>Strategy Meets Luck : </span>Aviator is not just about strategy, it's mainly skills. Plot your course wisely, and seize opportunities to emerge as the ultimate flying maverick.
                                        </p>
                                        <p>
                                            <span>Bet and Win: </span>Place a cumulative bet of  ₹100 to earn an aviator coin
                                        </p>
                                    </AviatorHFCupBoxInnerRight>
                                </AviatorHFCupBoxInner>
                            </AviatorHFCupBox>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.aviatorHighFlyerPrizeTable}`} effect="none" width="80%" placeholder={<PlaceholderImage />} />
                            <AviatorHFSteps>
                                <p>Top winners in each category will be randomly chosen by the system generator</p>
                                <AviatorHFStepsSec>
                                     <AviatorHFStepsBox>
                                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.aviatorHighFlyerIllus01}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                        <h5>Visit {domain}</h5>
                                        <p>Log in to your {domain} account and head to aviator!</p>
                                     </AviatorHFStepsBox>

                                     <AviatorHFStepsBox>
                                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.aviatorHighFlyerIllus02}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                        <h5>Deposit & Dive In</h5>
                                        <p>Select your preferred deposit method and dive headfirst into the immersive world of  Aviator. Let the excitement fuel your every move as you chase victory and fortune!</p>
                                     </AviatorHFStepsBox>

                                     <AviatorHFStepsBox>
                                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.aviatorHighFlyerIllus03}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                        <h5>Place Your Bets</h5>
                                        <p>Zero down on your strategy,  get your game face on and launch into the thrilling world of Aviator.</p>
                                     </AviatorHFStepsBox>

                                     <AviatorHFStepsBox>
                                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.aviatorHighFlyerIllus04}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                        <h5>Soar to Victory</h5>
                                        <p>Navigate through the challenges, plan your bets and win 100% Bonuses.</p>
                                     </AviatorHFStepsBox>
                                </AviatorHFStepsSec>

                                    <AviatorHFTermsModal>
                                       <h4>TERMS AND CONDITIONS</h4>
                                        <ul>
                                            <li>This promotion is valid for all new & existing players.</li>
                                            <li>This promotion is valid from <span>1st Feb 2024 to 29th Feb 2024.</span></li>
                                            <li>Each Player can only claim the bonus once during the promotional period.</li>
                                            <li>Minimum bet amount to earn coins is ₹100 & the total cash prize is <span>₹5,000,000/-</span></li>
                                            <li>Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.</li>
                                            <li>Multiple accounts will not be considered for this promotion eligibility/criteria. All players will be subject to duplicate checking. {domain} reserves the right to disqualify players if collusion or any foul play takes place in the promotion.</li>
                                            <li>{domain} reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.</li>
                                        </ul>
                                    </AviatorHFTermsModal>
                            </AviatorHFSteps>
                            <AviatorHFTerms>
                            </AviatorHFTerms>
                        </div>
                    </AviatorHFContentWrapper>
                 

            </InnerPromoWrapper>
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(AviatorHighFlyer));

//Styled Components
const AviatorHFContentWrapper = styled.div`
    width: 100%;
    background: url(${config.imageV3Host}/static/cashsite/${theme('image.aviatorHighFlyerInnerBgDeskNew')});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 3em 0em;
    background-color: ${(props:any) => (props.bgColor ? 'rgba(0,0,0,0.4)' : 'transparent')};
    
    & > div {
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2em;
    }

    & .textWrapper {
       padding: 0em 4.8em;
    }

    h3 {
        color: #F00;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 50.26px */
        text-transform: capitalize;
        text-align: left;
        margin: 5px 0px;
        width: 100%;
    }

    & b {
            color: #F00;
            font-weight: 700;
        }

    p {
        color: #FFF;
        text-align: left;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 27px */
        text-transform: capitalize;
        margin: 5px 0px;
    }

    .m-0 {
        margin: 0;
    }

    .mt-5 {
        margin-top: 2em;
        width: 100%;
        margin-bottom: 0em;
    }

    .p-0 {
        padding: 0rem 20rem;

        span{
            color: #E5A20D;
        }
    }

    a {
        width: 100%;
        height: auto;

        & span {
            display: flex !important;
            justify-content: center !important;

            & img {
                width: 60%;
                height: 80%;
            }
        }
    }

    .lazy-load-image-background {
        display: flex !important;
        justify-content: center !important;
    }

    .container {
        max-width: 1620px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;


const InnerPromoWrapper = styled.div`
    width: 98%;
    height: auto;
    border: 1px solid white;
    border-radius: 1px;
    background: rgba(111,111,111,0.5);
    margin: 0em 1.2em;
    -webkit-backdrop-filter: brightness(0.5);
    backdrop-filter: brightness(0.5);
`

const AviatorHFCupBox = styled.div`
   width: 90%;
   height: auto;
   border-radius: 40px;
    border: 2px solid #F00;
    background: rgba(0, 0, 0, 0.50);
   padding: 2em;
   display: flex;
   flex-direction: column;
   gap: 5px;

   & > h4 {
        color: #F00;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 50.26px */
        text-transform: capitalize;
        text-align: center;
        margin: 5px 0px;
        width: 100%;
   }

   & p {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24.5px;
        text-align: left;

        & span {
            color: #F00;
            text-align: justify;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 24.5px; /* 122.5% */
        }
    }
`

const AviatorHFCupBoxInner = styled.div`
    width: 95%;
    height: auto;
    display: flex;
    gap: 15px;
    align-items: center;
    margin-top: 1em;
`

const AviatorHFCupBoxInnerLeft = styled.div`
    width: 50%;
    height: auto;

    & img {
        width: 90%;
        height: auto;
    }
`

const AviatorHFCupBoxInnerRight = styled.div`
   width: 50%;
   height: auto;
   display: flex;
   flex-direction: column;
   gap: 1em;

    & .headViolet {
        text-align: left;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        background: linear-gradient(180deg, #E62ED4 0%, #9F00AF 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`

const AviatorHFTerms = styled.div`
    width: 90%;
    height: auto;
    display: flex;
    justify-content: center;
    gap: 10px;

    & h5 {
        color: #FFF;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-decoration-line: underline;
        text-transform: capitalize;   
        cursor: pointer;
        margin: 5px 0px;
    }
`

const AviatorHFSteps = styled.div`
   width: 90%;
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 1em;
   margin: 1em 0em;
   position: relative;
`

const AviatorHFStepsSec = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.4em;
    margin: 5em 0em 0em 0em;
`

const AviatorHFStepsBox = styled.div`
     width: 30%;
     height: auto;
     max-height: 45vh;
     min-height: 35vh;
     padding: 1em;
     display: flex;
     flex-direction: column;
     gap: 0.5em;
     align-items: center;
     border-radius: 20px;
    border: 1.5px solid #F00;
    background: radial-gradient(100% 100% at 49.8% 0%, rgba(255, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%), rgba(0, 0, 0, 0.50);
    box-shadow: 0px 0px 10px 0px rgba(255, 0, 0, 0.25);

    & h5 {
        color: #F00;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        margin: 3px 0px;
    }

    & img {
        width: 85%;
        height: auto;
        object-fit: contain;
        margin-top: -105px;
    }

    & p {
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        margin: 3px 0px;
    }
`

const AviatorHFTermsModalWrap = styled.div`
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
`

const AviatorHFTermsModal = styled.div`
   width: 90%;
   height: 40vh;
   border-radius: 29.187px;
   border: 8px solid #F00;
   background: #000;
   padding: 2em;
   margin-top: 3em;

   & h2 {
        cursor: pointer;
        color: #ffffff;
        margin: 5px 0px;
        font-family: system-ui;
        font-weight: 700;
        font-size: 25px;
        text-align: right;
   }

   & h4 {
        color: #FF0000;
        font-size: 26px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-transform: uppercase;
        margin: 3px 0px;
        text-align: center;
   }

   & ul li {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 177.778% */
        text-transform: capitalize;
        margin: 3px 0px;
   }

   & ul {
     height: 22vh;
     width: 99%;
     overflow-y: auto;

     ::-webkit-scrollbar {
        width: 5px; 
     }

        /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 5px;
        width: 5px;
    }
        
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #ffffff; 
        border-radius: 5px;
        width: 20px;
        height: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #ffffff; 
    }

    & span {
        color: #FF0000;
        font-weight: 700;
    }
   }
`