import React from 'react'

type Props = {}

const FomoAppDownloadBg = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="647" height="585" viewBox="0 0 647 585" fill="none">
            <g>
                <path d="M255.02 580.152C252.642 584.031 247.98 585 244.079 585H12.792C6.98846 585 2.8022 583.061 0.2334 578.116C-0.337444 574.238 -0.0519523 569.294 3.27797 565.416C64.9292 493.188 126.676 421.832 187.375 349.604C249.122 278.345 310.773 206.02 372.614 134.761H58.0788C50.3724 134.761 45.6155 128.944 45.7106 122.061C48.3746 84.9287 51.1335 48.8631 53.8926 12.7005C53.7975 4.84753 60.8379 0 67.5929 0H634.632C642.338 0 646.905 4.84753 647 12.7005C644.621 51.7716 641.291 90.8427 638.152 130.883C638.532 133.792 636.915 135.731 635.202 137.767C571.934 212.031 508.475 285.229 445.111 359.396C381.653 432.594 318.384 506.858 254.925 580.055L255.02 580.152Z" fill="#284C75" />
            </g>
        </svg>
    )
}

export default FomoAppDownloadBg