// Imports from library
import styled, { withTheme } from 'styled-components';
import { theme } from "styled-tools";
import { useNavigate } from "react-router-dom";
import Intl from "src/components/common/Intl";
import { color } from '../../sass-files/variable';
import { withAppContext } from 'src/store/initAppContext';
import { CRMLoginBtnClick, CRMSignupBtnClick } from 'src/util/crmUtil';
// import { clubsitePixel } from '../WhatsappIco';
// import Clock, { MainWrapper, DateWrapper } from "../Clock/index";
const { secondarycolor } = color

function LoginSignup(props) {
    const { theme, app: { brandId } } = props;
    const { config: { showsignup = true } } = theme;
    const navigate = useNavigate();

    const loginButtonClickHandler = () => {
        navigate('/login');
        CRMLoginBtnClick(brandId)
       // clubsitePixel(brandId, true, false);
    }

    const signupBtnClickHandler = () => {
        navigate('/signup');
        CRMSignupBtnClick(brandId);
    }

    return (
        <LoginSignupSection>
            <LoginSignupWrapper>
                <LoginButton>
                    <button type="button" onClick={loginButtonClickHandler}>
                        <Intl langKey="LOGIN@TITLE" />
                    </button>
                </LoginButton>
                <SignupButton>
                    {showsignup && <button id="signup_headerbutton" onClick={signupBtnClickHandler}>
                        <Intl langKey="SIGNUP@TITLE" />
                    </button>}
                </SignupButton>
            </LoginSignupWrapper>
            {/* <ClockWrapper>
                <Clock />
            </ClockWrapper> */}
        </LoginSignupSection>
    )
}

export default withAppContext(withTheme(LoginSignup))

const SignupButton = styled.div`
    transform: skew(340deg);
    margin-left: 10px;
    margin-right: 10px;
    background: ${theme('palette.SignupButtonBg')};
    border-radius: 5px;

    :hover {
        background: ${theme('palette.SignupButtonHover')};
    }

    button {
        width: 80px;
        text-align: center;
        display: block;
        text-decoration: none;
        padding: 10px 0px;
        font-size: 14px;
        transform: skew(-340deg);
        color: ${secondarycolor};
        text-transform: capitalize;
        background-color: transparent;
        border: none;
        cursor: pointer;
    }
`;

const LoginButton = styled.div`
transform: skew(340deg);
// border: 1px solid #CB9640;
margin-left: 10px;
background: ${theme('palette.primary-background-color-linear-new')};
// box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);
/* background: #6778E3; */

border-radius: 5px;
  
    button {
        width: 80px;
        text-align: center;
        display: block;
        text-decoration: none;
        padding: 10px 0px;
        font-size: 14px;
        transform: skew(-340deg);
        color: ${theme('palette.logintextcolor-new')};
        text-transform: capitalize;
        background-color: transparent;
        border: none;
        cursor: pointer;
    }
`;

const LoginSignupSection = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    // top: 5px;
`;

const LoginSignupWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

// const ClockWrapper = styled.div`
//     /* width: 170px; */
//     display: flex;
//     align-items: center;

//     ${MainWrapper} {
//         width: 70px;
//         flex-direction: column;
//         align-items: flex-end;
//         margin-right: 20px;

//         ${DateWrapper} {
//             margin-right: 0;
//         }
//     }
// `;