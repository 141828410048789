import React, { useEffect } from 'react'
import PlaceholderImage from 'src/components/Placeholder';
import { MainInfoWrapper } from 'src/components/udb/commonStyle';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Intl from 'src/components/common/Intl';
import FooterContainer from "src/components/footer/footer";
import { FifaFeverFirstSecHead, PlayNowButtonNew2, Section } from './crazyCrashPromo';
import { useNavigate } from 'react-router-dom';
function Firstbet(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigate = useNavigate();

    return (
        <MainInfoWrapper>
            <BannerWrapper>
                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.firstbetbannermain}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
                <PlayNow onClick={() => navigate("/online-casino-games/evo")}>Play Now</PlayNow>
            </BannerWrapper>
            <ContentWrapper>
                <NoBorderTop>
                    <p>Get ready for an electrifying experience with Evolution's mind-blowing BET & WIN promotion, where a jaw-dropping prize pool of  <StepTittle>INR 32 lakhs</StepTittle> awaits you! Brace yourself for an adrenaline-pumping adventure as you compete for a chance to win an incredible weekly pool prize of <StepTittle>INR 800,000.
                    </StepTittle> </p>

                    <p>Participate in this thrilling competition by playing your favourite.<StepTittle>Evolution First Person games</StepTittle> Your chance to win awaits </p>
                    <p>Get ready for an incredible gaming experience with Evolution's First Person games! Choose from a wide range of thrilling options, including<StepTittle> American Roulette, Baccarat, Blackjack, Craps, Deal or No Deal, Dragon Tiger, Dream Catcher, Golden Wealth Roulette, Lightning Baccarat, Lightning Blackjack, Lightning Roulette, Mega Ball, and more. </StepTittle>
                        Brace yourself for pulse-pounding action and immerse yourself in the captivating world of Evolution's First Person games! </p>
                    <p>
                        But wait, there's more! Participating is as easy as 1-2-3:</p>


                </NoBorderTop>

                <FifaFeverFirstSecHead1 className="frstbet"><p>HOW TO PARTICIPATE?</p></FifaFeverFirstSecHead1>
                <Section>
                    <ImageWrapper>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.firstbetmodel}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                    </ImageWrapper>
                    <ProcessColumn>
                        <li>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.firstbetRegisterIcon}`} effect="none" placeholder={<PlaceholderImage />} />
                            <LiTextContent>
                                <StepTittle>REGISTER</StepTittle>
                                <TextContent>Unlock the gateway to endless thrills by creating your very own account. Register using your name and mobile number, and prepare to embark on a thrilling journey like no other.</TextContent>
                            </LiTextContent>
                        </li>
                        <li>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.firstbetdepositIcon}`} effect="none" placeholder={<PlaceholderImage />} />
                            <LiTextContent>
                                <StepTittle>DEPOSIT & UNLEASH</StepTittle>
                                <TextContent>Choose your preferred deposit method and dive headfirst into the immersive world of Evolution First Person games. Let the excitement fuel your every move as you chase victory and fortune!</TextContent>
                            </LiTextContent>
                        </li>
                        <li>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.firstbetWinIcon}`} effect="none" placeholder={<PlaceholderImage />} />
                            <LiTextContent>
                                <StepTittle>WIN BIG!</StepTittle>
                                <TextContent>With every game you play, you're one step closer to seizing greatness. Show off your skills, dominate the leaderboard, and claim your share of the staggering prize pool.</TextContent>
                            </LiTextContent>
                        </li>
                    </ProcessColumn>
                </Section>

                <Section>
                    <NoborderTop1>
                        <p style={{ textAlign: 'left' }}>Don't miss out on this sensational opportunity to be a part of Evolution's BET & WIN promotion. It's time to unleash your inner champion and make your mark in the world of gaming!</p>

                        <p><StepTittle>✪ </StepTittle>The winners are based on the players who wager in Evolution First Person games during the promotion from
                            <StepTittle> 23rd August – 19th September 2023</StepTittle></p>
                        <p><StepTittle>✪ </StepTittle> Winners will be determined on a weekly basis from <StepTittle>31st August to 21st September 2023</StepTittle></p>

                        <p><StepTittle>✪ </StepTittle> This promotion is only applicable for <StepTittle>Evolution First Person tables</StepTittle></p>

                        <p><StepTittle>✪ </StepTittle>There are <StepTittle>2,008 prizes worth INR 800,000 </StepTittle>which will be given to players weekly during the promotion period.</p>
                    </NoborderTop1>
                </Section>


                <JackPotTable>
                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.firstTable}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
                </JackPotTable>
                <FifaFeverFirstSecHead1 className="frstbetprize"><p>PRIZE ALLOCATION</p></FifaFeverFirstSecHead1>
                <JackPotTable>
                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.firstTable2}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
                </JackPotTable>
                <TermsConditionPoints>
                    <FifaFeverFirstSecHead1 className="frstbettc"><p>TERMS & CONDITIONS</p></FifaFeverFirstSecHead1>

                    <ul>
                        <li><TextContent><Intl langKey="firstbet@GeneralTermsConditionFirst" /></TextContent></li>
                        <li><TextContent><Intl langKey="firstbet@GeneralTermsConditionSecond" /> </TextContent></li>
                        <li><TextContent><StepTittle><Intl langKey="firstbet@GeneralTermsConditionThird" /></StepTittle></TextContent></li>
                        <li><TextContent><Intl langKey="firstbet@GeneralTermsConditionFourth" /></TextContent></li>
                        <li><TextContent><Intl langKey="firstbet@GeneralTermsConditionFifth" /></TextContent></li>
                        <li><TextContent><Intl langKey="firstbet@GeneralTermsConditionSixth" /></TextContent></li>
                        <li><TextContent><Intl langKey="firstbet@GeneralTermsConditionSeventh" /></TextContent></li>
                    </ul>
                </TermsConditionPoints>
                <PlayNowButtonNew2 onClick={() => navigate("/online-casino-games/evo")}>Play Now</PlayNowButtonNew2>
            </ContentWrapper>
            <FooterContainer />
        </MainInfoWrapper>
    )
}



export default withTheme(withAppContext(Firstbet));
const PlayNow = styled(PlayNowButtonNew2)`
position: absolute;
right: 45%;
bottom: 38%;
padding: 10px 25px;
`
export const FifaFeverFirstSecHead1 = styled(FifaFeverFirstSecHead)`
text-transform: uppercase;
font-size: 30px;
`
const LiTextContent = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;

    p {
        margin: 0;
        margin-top: 10px;
    }

    span {
        text-transform: uppercase;
        font-size: 18px;
    }
`;


const NoBorderTop = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0rem 17rem 2.5rem 17rem;
    text-align: justify;
    align-items: flex-start;

    p {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        color: ${theme('palette.catch99TextContentColor')};
        font-size: 21px;
        gap: 5px;
        text-align: center;
        margin: 13px 0;
        text-align: justify;
    }
    span{
        font-size: 21px;
        text-align: justify;
    }
`;

export const NoborderTop1 = styled(NoBorderTop)`
align-items: flex-start;
justify-content: center;
`

export const TermsConditionPoints = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 2rem;
    /* border: ${theme('palette.AviatorjackPotWrapperBgBorder')}; */
    border-radius: 16px;
    padding: 3rem;
    /* background: ${theme('palette.AviatorTermsConditionPointsBg')}; */

    p {
        padding-left: 1rem;
        text-transform: capitalize;
    }

    ul {
        list-style: number;
        color: #fff;
        margin-top: 0;


        li {
            p{
                display: flex;
                gap: 5px;
                padding-left: 0rem;
                margin: 0 0 10px 0;
                color: #FFF;

text-align: justify;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.8px;
text-transform: capitalize;
            }
        }
       
    }
    li::marker {
        font-size:18px;
}
`;

const JackPotTable = styled.div`
    width: 70%;
    display: flex;
padding-bottom: 3rem;
    img {
        max-width: 100%;
    }
`;

export const ImageWrapper = styled.div`
     width: 35%;
    display: flex;
    padding-top: 47px;

    img {
        max-width: 100%;
    }
`;

export const TextContent = styled.p`
    display: flex;
    color: ${theme('palette.catch99TextContentColor')};
    font-size: 17px;
`;

const StepTittle = styled.span`
    display: flex;
    color: #FFFF81;
    font-size: 17px;
    font-family: 'Inter-Bold';
    display: contents;
    

`;

export const ProcessColumn = styled.ul`
    width: 50%;
    padding: 2rem;
    position: relative;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    li {
        border:1.2px solid #FCF29E;
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 1.5rem;
        padding: 1.5rem;
        border-radius: 8.09632px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.005) 100%);
        mix-blend-mode: normal;
        backdrop-filter: blur(42.6023px);
    }

    img {
        width: 98px;
        height: 98px;
    }
 `;

export const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url(${config.imageV3Host}/static/cashsite/${theme('image.firstbetbannermainBG')});
    background-position: 100% 5%;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 4rem 0;
    margin-top: -5px;
    
`;
export const BannerWrapper = styled.div`
    width: 100%;
    display: flex;
    transition: 700ms;
    position: relative;
    img {
        max-width: 100%;
        transition: 700ms;
    }
`;