import React, { useEffect } from 'react'
import PlaceholderImage from 'src/components/Placeholder';
import { MainInfoWrapper } from 'src/components/udb/commonStyle';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import cookieUtil from 'src/util/cookieUtil';
import { ECookieName } from 'src/util/utilModel';
import Intl from 'src/components/common/Intl';
import FooterContainer from "src/components/footer/footer";
import { BannerWrapper, BBLFirstSecRIght, FifaFeverContentWrapper, FifaFeverFirstSec, FifaFeverFirstSecHead, ImageWrapper, LiTextContent, ProcessColumn, Section, StepImg, StepTittle, TermsConditionPoints, TermsConditionWrapper, TextContent } from './BblBonusInner';
import { useNavigate } from 'react-router-dom';

const Language = cookieUtil.get(ECookieName.COOKIE_LANG)


const Jetx_promo = (props) => {
  const { app: {
    brandId
  }, theme } = props;

  const routeTo = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <MainInfoWrapper>
      <BannerWrapper1>
        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.jetxImg}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
        <PlayNow onClick={() => routeTo('/online-casino-games/jetx')}>play now</PlayNow>
      </BannerWrapper1>
    <MainInfoWrapper2>
        <FifaFeverFirstSec1>
          <FifaFeverFirstSecHead1>How to participate?</FifaFeverFirstSecHead1>
          <Section1>
            <ImageDiv>
              <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.astronut}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
            </ImageDiv>
            <ProcessColumn>
              <li>
                  <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.registerIcon_jetx}`} effect="none" placeholder={<PlaceholderImage />} />
                    <LiTextContent>
                    <StepTittle><Intl langKey="jetx@Step1"/></StepTittle>
                    <TextContent><Intl langKey="PROMOTIONS@jetxt" /></TextContent>
                    </LiTextContent>
                    <StepImg src={`${config.imageV3Host}/static/cashsite/${theme.image.BBlStepOne}`}></StepImg>
              </li>
              <li>
                  <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.depositIcon_jetx}`} effect="none" placeholder={<PlaceholderImage />} />
                    <LiTextContent1>
                    <StepTittle><Intl langKey="jetx@depnote" /></StepTittle>
                    <TextContent><Intl langKey="jetx@deposit2" /></TextContent>
                    <TextContent><Intl langKey="jetx@deposit3" /></TextContent>
                    </LiTextContent1>
                    <StepImg src={`${config.imageV3Host}/static/cashsite/${theme.image.BBlStepTwo}`}></StepImg>
              </li>
            </ProcessColumn>
          </Section1>
          <p>To get the right to spin the wheel the player must complete <span>one of 2</span> different types of missions.</p>
        </FifaFeverFirstSec1>
        <ImageDiv1>
          <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.tablejet1}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
        </ImageDiv1>
        <ImageDiv1 className='secImage'>
          <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.tablejet2}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
        </ImageDiv1>
        <ImageDiv1 className='plane'>
          <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.jetxplane}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
        </ImageDiv1>
        <ImageDiv1 className='thirdImag'>
          <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.tablejet3}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
        </ImageDiv1>
        <Termsjetx>
          <TermsConditionWrapper1>
          </TermsConditionWrapper1>
          <TermsConditionPoints1>
                  <ul>
                    <li><TextContent><Intl langKey="jetx@GeneralTermsConditionFirst" /></TextContent></li>
                    <li><TextContent><Intl langKey="Jetx@GeneralTermsConditionSecond" /></TextContent></li>
                    <li><TextContent><Intl langKey="Jetx@GeneralTermsConditionThird" /></TextContent></li>
                    <li><TextContent><Intl langKey="Jetx@GeneralTermsConditionFourth" /></TextContent></li>
                    <li><TextContent><Intl langKey="Jetx@GeneralTermsConditionFifth" /></TextContent></li>
                    <li><TextContent><Intl langKey="Jetx@GeneralTermsConditionSixth" /></TextContent></li>
                    <li><TextContent><Intl langKey="Jetx@GeneralTermsConditionSeventh" /></TextContent></li>
                    <li><TextContent><Intl langKey="Jetx@GeneralTermsConditionEightth" /></TextContent></li>
                    <li><TextContent><Intl langKey="Jetx@GeneralTermsConditionnine" /></TextContent></li>
                    <li><TextContent><Intl langKey="Jetx@GeneralTermsConditionten" /></TextContent></li>
                    <li><TextContent><Intl langKey="Jetx@GeneralTermsConditioneleven" /></TextContent></li>
                    <li><TextContent><Intl langKey="Jetx@GeneralTermsConditiontwelve" /></TextContent></li>
                    <li><TextContent><Intl langKey="Jetx@GeneralTermsCondition13" /></TextContent></li>
                    <li><TextContent><Intl langKey="FifaFever@GeneralTermsConditionSeventh" /></TextContent></li>
                  </ul>
          </TermsConditionPoints1>
        </Termsjetx>
    </MainInfoWrapper2>
      <FooterContainer />
    </MainInfoWrapper>
  )
}

export default withTheme(withAppContext(Jetx_promo));

const Section1 = styled(Section)` 
  p{
    font-style: normal;
    text-align: left;
  }`

const LiTextContent1 = styled(LiTextContent)`
span{
  text-align: left;
}`

const PlayNow = styled.button`
background: #FEFD00;
border-radius: 27px;
position: absolute;
right: 25%;
bottom: 16%;
cursor:pointer;
padding: 10px 30px 10px 30px;
color:#000000;
align-items: center;
letter-spacing: 0.01em;
text-transform: uppercase;
font-size: 18px;
font-weight: bold;
border:none;

@media only screen and (min-width: 1600px){
     right: 27%;
     bottom: 17%;
}
:hover{
  background: #aaff00;
}`

const TermsConditionWrapper1 = styled(TermsConditionWrapper)`
      backdrop-filter: none;
      background: none;
      padding: 0;
      ::before {
    content: "";
      width: 800px;
      height: 700px;
      background: url(${config.imageV3Host}/static/cashsite/${theme('image.jetc')});
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      left:0px;
    }`

const Termsjetx = styled.div`
width:100%;
display:flex;
justify-content: space-around;
`

const FifaFeverFirstSec1 = styled(FifaFeverFirstSec)`
font-family: 'Inter';
font-style: italic;
font-weight: 400;
font-size: 19px;
line-height: 23px;
text-align: center;

color: #FFFFFF;
 span {
  color:#FCFC01;
 }
`
const Plane = styled.div`
position: relative;
`
const TermsConditionPoints1 = styled(TermsConditionPoints)`
width :100%;
display: flex;
flex-direction: row;
border: none;
backdrop-filter: none;
background: none;
position: relative;
padding-right: 13rem;
padding-top: 15rem;
  ul{
    width: 100%;
  }
  &::after {
      content: "";
      width: 200px;
      height: 140px;
      display: flex;
      position: absolute;
      right: 26rem;
      top: 50px;
      background: url(${config.imageV3Host}/static/cashsite/${theme('image.BBLTCText')});
      background-position: bottom;
      background-size: contain;
      background-repeat: no-repeat;
    }
`
const ImageDiv = styled.div`
width: 60%;
img {
  max-width: 60%;
  height: 550px;
}
  animation: myAnim 50s ease-out 0s infinite alternate-reverse none;
@keyframes myAnim {
	0%,
	100% {
		transform: rotate(0deg);
		transform-origin: 50% 50%;
	}

	10% {
		transform: rotate(8deg);
	}
	20%,
	40%,
	60% {
		transform: rotate(-10deg);
	}
	30%,
	50%,
	70% {
		transform: rotate(10deg);
	}

	80% {
		transform: rotate(-8deg);
	}

	90% {
		transform: rotate(8deg);
	}
     }
    img {
        max-width: 60%;
    }
`

const ImageDiv1 = styled.div`
width: 100%;
img {
  max-width: 100%;
  height: auto;
  padding: 6rem 12rem 0rem 12rem;
}
`
const MainInfoWrapper2 = styled(MainInfoWrapper)`
background: url(${config.imageV3Host}/static/cashsite/${theme('image.jetxfullbg')});
background-repeat: no-repeat;
background-size: cover;
.secImage{
  img{
    height: 800px;
  }
}
.plane{
  width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  img{
   max-width: 50%;
   height: auto;
  }
}
.thirdImag{
  padding-bottom: 3rem;
}
`
const BannerWrapper1 = styled(BannerWrapper)`
margin-bottom: -5px;
position: relative;
`
const FifaFeverFirstSecHead1 = styled(FifaFeverFirstSecHead)`
color: white;
white-space: nowrap;

&::after {
      content: "";
      width: 190px;
      height:2px;
      background: url(${config.imageV3Host}/static/cashsite/${theme('image.jetxline')});
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
`