import React, { useState } from "react";
import styled from "styled-components";
import {
  Label as LabelStyle,
  RadioBtn as RadioBtnMore,
  Input,
  SvgIcon as DeletSvgIcon,
  ActionWrapper,
  EditButton,
  DeletBtn,
} from "./commonStyle";
import { BankDataProps, UPIdataProps } from "../../pages/udb/profile/Bankdetails";
import BankIcon from "../icon/BankIcon";
import DeleteIcon from "../icon/DeleteIcon";
import EditIcon from "../icon/EditIcon";
import Confirm from 'src/components/udb/Confirm';
import Intl from 'src/components/common/Intl'
import UPIIcon from "../icon/UPIIcon";
import { theme } from "styled-tools";

type BankDropDownProps = {
  data: {
    bank_name: string;
    bank_code: string;
    bankName?: string;
    accountNumber?: string;
  }[];
  placeholder: string;
  req?: boolean;
  icon?: string;
} & Omit<React.ComponentProps<"select">, "placeholder">;

type BankInputFieldProps = {
  placeholder: string;
  req?: boolean;
  icon?: string;
} & Omit<React.ComponentProps<'input'>, 'placeholder'>


type BankListProps = {
  listData: BankDataProps;
  onEdit: (data: BankDataProps) => void;
  onDelete: (uuid: string) => void;
};

type UPIListProps = {
  upilistData: UPIdataProps;
  onEdit: (data: UPIdataProps) => void;
  onDelete: (uuid: string) => void;
};

type BankDetailDropDownProps = {
  // data: AccountDataProps | UPIDataProps;
  data: any;
  placeholder: string;
  icon?: string;
  req?: boolean;
} & Omit<React.ComponentProps<"select">, "placeholder">;

export const BankDropDown = ({
  data,
  placeholder,
  req,
  value,
  icon,
  onChange,
  ...props
}: BankDropDownProps) => {

  //Some functionality has been added for window;
  const [state, setState] = useState({
    hidePlaceHolder: false,
    defailtValue: ''
  });

  const { onFocus, onBlur } = props;

  const focusHandler = (e: React.FocusEvent<HTMLSelectElement, Element>) => {
    if (typeof onFocus === 'function') {
      onFocus(e);
    }
    setState({
      hidePlaceHolder: true,
      defailtValue: placeholder
    })
  }

  const blurHandler = (e: React.FocusEvent<HTMLSelectElement, Element>) => {
    if (typeof onBlur === 'function') {
      onBlur(e);
    }
    setState({
      hidePlaceHolder: false,
      defailtValue: ''
    })
  }

  return (
    <Label>
      {!state.hidePlaceHolder && <PlaceHolder className={!value ? "" : "dispN"}>
        {placeholder}
        {req && <span>*</span>}
      </PlaceHolder>}
      <select value={value} {...props} onChange={onChange} onFocus={focusHandler} onBlur={blurHandler}>
        <option value="">{state.defailtValue} </option>
        {data?.map((elm) => (
          <option key={elm.bank_code} value={elm.bank_code}>
            {elm.bank_name || elm.bankName.concat(Array(40).fill('\xa0').join(''), (elm.accountNumber.replace(/\d{8}(\d{4})/, '********$1')))}
          </option>
        ))}
      </select>
    </Label>
  );
};

export const BankInputField = ({ placeholder, req, value, icon, onChange, type, ...props }: BankInputFieldProps) => {
  return (
    <Label>
      <PlaceHolder className={!value ? '' : 'dispN'}>
        {placeholder}{req && <span>*</span>}
      </PlaceHolder>
      {icon && <img src={icon} alt='' />}
      <input value={value} type={type ? type : 'text'} onChange={onChange} {...props} />
    </Label>
  )
}

const Message = () => <span><Intl langKey="DASHBOARD@Deleteconfirm" /><br /><Intl langKey="DASHBOARD@Deleteconfirm1" /></span>

export const BankList = ({ listData, onEdit, onDelete }: BankListProps) => {
  const [modalShow, setmodalShow] = useState(false);
  const {
    accountName,
    accountNumber,
    bankAddress,
    bankName,
    branchName,
    isDefault,
    ifscCode,
    remarks,
    uuid,
  } = listData || {};

  const showModel = () => {
    setmodalShow(true);
  };

  const hideModel = () => {
    setmodalShow(false);
  };


  return (
    <>
      <BankDetailContainer>
        <TopCol>
          {isDefault && <Default><Intl langKey="DASHBOARD@DefaultAcc" /></Default>}
          <ActionWrapper>
            <EditButton onClick={() => onEdit(listData)}>
              <SvgIcon>
                <EditIcon />
              </SvgIcon>
              <Intl langKey="DASHBOARD@edit" />
            </EditButton>

            <DeletBtn onClick={showModel}>
              <SvgIcon>
                <DeleteIcon />
              </SvgIcon>
              <Intl langKey="DASHBOARD@delete" />
            </DeletBtn>
          </ActionWrapper>
        </TopCol>
        <MiddleCol>
          <UserName>{accountName}</UserName>
          <InformationWrapper>
            <Icon>
              <BankIcon />
            </Icon>
            <BankDetails>
              <BankName>{bankName}</BankName>
              <AccountName>{accountNumber}</AccountName>
              <IfcCode>{ifscCode}</IfcCode>
              <Address>{bankAddress}</Address>
            </BankDetails>
          </InformationWrapper>
        </MiddleCol>
        <Col>
          <RemarksSection>
            <Text><Intl langKey="DASHBOARD@Remarks" /></Text>
            <RemarksInput>{remarks}</RemarksInput>
          </RemarksSection>
        </Col>
      </BankDetailContainer>

      <Confirm
        modalShow={modalShow}
        cancelHandler={hideModel}
        deleteHandel={() => onDelete(uuid)}
        labels={['Confirm@Delete', 'Confirm@Cancel']}
        heading="Confirm@heading"
        subHeading={<Message />}
      />

    </>
  );
};


export const UPIList = ({ upilistData, onEdit, onDelete }: UPIListProps) => {
  const [modalShow, setmodalShow] = useState(false);
  const {
    isDefault,
    remarks,
    uuid,
    upiId
  } = upilistData || {};

  const showModel = () => {
    setmodalShow(true);
  };

  const hideModel = () => {
    setmodalShow(false);
  };


  return (
    <>
      <UpiDetailsContainer>
        <MiddleColUpi>
          <InformationWrapper style={{paddingLeft:"10px",paddingBottom:"10px"}}>
            <Icon>
              <UPIIcon />
            </Icon>
            <BankDetails>
              <BankName style={{fontSize: "17px"}}>{upiId}</BankName>
            </BankDetails>
          </InformationWrapper>
          {isDefault && <DefaultUPI><Intl langKey="DASHBOARD@DefaultUPI" /></DefaultUPI>}
        </MiddleColUpi>
        <Col>
          <RemarksSection>
            <RemarksInputUpi>{remarks}</RemarksInputUpi>
          </RemarksSection>
        </Col>
        <TopCol>
          <ActionWrapper>
            <EditButton onClick={() => onEdit(upilistData)}>
              <SvgIcon>
                <EditIcon />
              </SvgIcon>
              <Intl langKey="DASHBOARD@edit" />
            </EditButton>

            <DeletBtn onClick={showModel}>
              <SvgIcon>
                <DeleteIcon />
              </SvgIcon>
              <Intl langKey="DASHBOARD@delete" />
            </DeletBtn>
          </ActionWrapper>
        </TopCol>
      </UpiDetailsContainer>

      <Confirm
        modalShow={modalShow}
        cancelHandler={hideModel}
        deleteHandel={() => onDelete(uuid)}
        labels={['Confirm@Delete', 'Confirm@Cancel']}
        heading="Confirm@heading"
        subHeading={<Message />}
      />

    </>
  );
};



export const BankDetailDropDown = ({
  data,
  placeholder,
  req,
  value,
  onChange,
  icon,
  ...props
}: BankDetailDropDownProps) => {
  return (
    <Label>
      <PlaceHolder className={!value ? "" : "dispN"}>
        {placeholder}
        {req && <span>*</span>}
      </PlaceHolder>
      {icon && <img src={icon} alt='' />}
      <select value={value} {...props} onChange={onChange}>
        <option value="none"> </option>
        {data?.map((elm,i) => (
          <option key={i} value={elm.accountNumber||elm.upiId}>
            {elm.accountNumber ? (`${elm.bankName}${elm.accountNumber.replace(/^\d+(\d{3}$)/, '********$1')}`) : (`${elm.upiId}`)}
          </option>
        ))}
      </select>
    </Label>
  );
};





const Label = styled(LabelStyle)`
  select {
    border: none;
    width: 100%;
    z-index: 1;
    background: transparent;
  }

  input {
    border: none;
    width: 100%;
    z-index: 1;
    background: transparent;
  }

  .dispN {
    display: none;
  }

  .disabled {
    opacity: 0.7;
    background: #c5c5c7;

    input {
      cursor: not-allowed;
    }
  }
`;
const PlaceHolder = styled.span`
  position: absolute;
  font-size: 0.8rem;
  z-index: 1;
  span {
    color: red;
    padding-left: 2px;
  }
`;
const BankDetailContainer = styled.div`
  width: 46%;
  min-height: 274px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(
    97.59deg,
    rgba(0, 0, 0, 0.3) 0.35%,
    rgba(0, 0, 0, 0.15) 100%
  );
  border-radius: 5px;
  border: 1px solid #787878;
  margin-right: 2em;
  margin-bottom: 2em;
  padding-top: 1rem;
  overflow: hidden;
`;

const UpiDetailsContainer = styled.div`
  width: 100%;
  min-height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(
    97.59deg,
    rgba(0, 0, 0, 0.3) 0.35%,
    rgba(0, 0, 0, 0.15) 100%
  );
  border-radius: 5px;
  border: 1px solid #787878;
  margin-right: 2em;
  margin-bottom: 2em;
  padding: 1rem 0rem 0rem 0rem;
  overflow: hidden;
`
const TopCol = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
const Default = styled.div`
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  padding: 1em;
  color: #ffffff;
  border-radius: 0px 3px 3px 0px;
  background: ${theme('palette.secondary-background-color-linear-new1')};
`;
const DefaultUPI = styled(Default)`
   min-width: 150px;
   font-size: 12px;
`
const NormalAccount = styled.div`
  min-width: 200px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  border-radius: 0px 3px 3px 0px;
  background: linear-gradient(86deg, #565B76 1.63%, #5F637D 95.1%);
`;

const RadioBtn = styled(RadioBtnMore)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  position: absolute;
  right: 0;
`;
const MiddleCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 10px 10px 15px;
`;
const MiddleColUpi = styled.div`
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px 0px 0px 0px;
`
const UserName = styled.div`
  max-width: 300px;
  display: inline-block;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: ${theme('palette.usernameBG')};
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 0.3em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const InformationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
const Icon = styled.div`
  margin-right: 10px;

  svg {
    width: 16px;
    height: 16px;
  }
`;
const BankDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  letter-spacing: 0.01em;
  /* text-transform: capitalize; */
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
`;
const BankName = styled.div`
  padding-bottom: 0.1em;
`;
const AccountName = styled.div`
  padding-bottom: 0.1em;
`;
const IfcCode = styled.div`
  padding-bottom: 0.1em;
`;
const Address = styled.div`
  max-width: 250px;
  padding-bottom: 0.1em;
`;

const Col = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
`;
const RemarksSection = styled.div`
  flex: auto;
  display: flex;
  align-items: center;
`;
const Text = styled.div`
  display: flex;
  align-items: center;
  color: #e8e8e8;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  font-size: 13px;
  background: #000000;
  padding: 1em;
  height: 56px;
`;
const RemarksInput = styled.div`
  flex: auto;
  height: 56px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px;
  text-align:center;
  padding: 1em;
  color: #ffffff;
  background: rgb(88, 86, 86) !important;
  /* background: #1f2040; */
  box-shadow: none;
  border: none;
  justify-content: center;
`;
const RemarksInputUpi = styled.div`
  flex: auto;
  height: 56px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px;
  text-align:center;
  padding: 1em;
  color: #ffffff;
  background: #1F2040 !important;
  /* background: #1f2040; */
  box-shadow: none;
  border: none;
  justify-content: center;
  margin-bottom: 1rem;
`;
const SvgIcon = styled(DeletSvgIcon)`
  background: linear-gradient(
    97.59deg,
    rgba(117, 104, 166, 0.3) 0.35%,
    rgba(58, 58, 90, 0.3) 100%
  );
  border-radius: 3px;
  padding: 4px;
  flex-direction: column;
  color: #e8e8e8;
  font-size: 11px;
  cursor: pointer;
  margin-right: 0px;
  border: 1px solid #8a9ec5f7;

  :hover {
    background: #222;
  }

  svg {
    width: 15px;
    height: 15px;
  }
`;


