import  { useEffect } from 'react'
import PlaceholderImage from 'src/components/Placeholder';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import FooterContainer from "src/components/footer/footer";



const AviatrixDoubleDhamaka = (props) => {
    const { app: { brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = brandId == 31 || brandId == 32 ? "Yolo247" : 'baaziadda';

    return (
        <>
            <InnerPromoWrapper>
                    <AviatrixDDContentWrapper>
                        <div className='container'>
                            <AVDDTopSec>
                                    <AVDDTopLeft>
                                        <LazyLoadImage src={`${config.commonImgS3Host}/static/cashsite/${theme.image.aviatrixPromoTopLeftCoins}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} alt="aviatrixPromoTopLeftCoins"/>
                                    </AVDDTopLeft>
                                <hr />
                                    <AVDDTopRight>
                                        <h6>
                                        <span>Christmas has arrived,</span> and at {domain}, we're playing Santa to bring you the merriest holiday season ever! 🎅✨ Get ready for an exclusive gift - Double Payouts on Aviatrix!
                                        </h6>
                                    </AVDDTopRight>
                            </AVDDTopSec>

                            <AVDDSecondSec>
                                <AVDDSecondSecLeft>
                                    <h6>
                                        <span> Christmas Special: Double Payouts!</span> <br />
                                        Play Aviatrix during the holiday season, and watch your winnings double! It's our way of spreading joy and doubling the excitement during this festive time. For example, if you placed a bet of INR 1000 and cashed out at 5.0X, your payout will get double, that's an incredible 10.0X!
                                    </h6>
                                </AVDDSecondSecLeft>
                                <hr />
                                <AVDDSecondSecRight>
                                    <LazyLoadImage src={`${config.commonImgS3Host}/static/cashsite/${theme.image.aviatrixPromoXmasTree}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} alt="aviatrixPromoXmasTree"/>
                                </AVDDSecondSecRight>
                            </AVDDSecondSec>

                            <AVDDThirdSec>
                            <AVDDThirdSecTop>
                                    <AVDDThirdSecTopLeft>
                                        <LazyLoadImage src={`${config.commonImgS3Host}/static/cashsite/${theme.image.aviatrixPromoCalendar}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} alt="aviatrixPromoCalendar"/>
                                        <h5>Program dates:</h5>
                                    </AVDDThirdSecTopLeft>
                                    <AVDDThirdSecTopRight>
                                        <h4>
                                                <span>December 22nd</span> to  <span>December 29th,</span> 2023 <br />
                                                <span>January 5th</span> to <span>January 12th,</span> 2024
                                        </h4>
                                        <p></p>
                                    </AVDDThirdSecTopRight>
                            </AVDDThirdSecTop>

                            <AVDDThirdSecBody>
                                    <AVDDThirdSecBodyBox>
                                        <LazyLoadImage src={`${config.commonImgS3Host}/static/cashsite/${theme.image.aviatrixPromoGirl}`} effect="none" height="100%" width="50%" placeholder={<PlaceholderImage />} alt="aviatrixPromoGirl"/>
                                        <h1>How to Claim Your Double Payouts:</h1>

                                        <div>
                                            <h6>
                                                Log in to your <span className={brandId == 33 || brandId == 34 ? "bdcolor" : "yolocolor"}>{domain}</span> account and play Aviatrix
                                            </h6>

                                            <h5>
                                            Enjoy the thrilling adventures from December 22nd to 29th 2023 and January 5th to 12th 2024
                                            </h5>

                                            <p>
                                            Watch your daily payouts double during these special weeks!
                                            </p>
                                        </div>
                                    </AVDDThirdSecBodyBox>
                                    <AVDDThirdSecBodyBgImg>
                                        <LazyLoadImage src={`${config.commonImgS3Host}/static/cashsite/${theme.image.aviatrixPromoRGPlains}`} effect="none" height="100%" width="80%" placeholder={<PlaceholderImage />} alt="aviatrixPromoRGPlains"/>
                                    </AVDDThirdSecBodyBgImg>
                            </AVDDThirdSecBody>
                            </AVDDThirdSec>

                            <AVDDFourthSec>
                                <h6>
                                At {domain}, we believe in making your holidays extra special. The Double Payouts on Aviatrix are our way of adding a dash of magic to your gaming experience. 🌟
                                </h6>
                            </AVDDFourthSec>

                            <AVDDFifthSec>
                                <LazyLoadImage src={`${config.commonImgS3Host}/static/cashsite/${theme.image.aviatrixPromoGalaxyBg}`} effect="none" height="100%" width="80%" placeholder={<PlaceholderImage />} alt="aviatrixPromoGalaxyBg"/>
                            </AVDDFifthSec>

                            <AVDDSixthSec>
                                <LazyLoadImage src={`${config.commonImgS3Host}/static/cashsite/${theme.image.aviatrixPromoParterSec}`} effect="none" height="100%" width="80%" placeholder={<PlaceholderImage />} alt="aviatrixPromoParterSec"/>
                            </AVDDSixthSec>

                            <AVDDSeventhSec>
                                    <AVDDTermsSec>
                                    <AVDDTermsSecBody>
                                            <AVDDTermsSecHead>
                                                <h5>Terms & Conditions:</h5>
                                            </AVDDTermsSecHead>
                                            <AVDDTermsBody>
                                                <ol>
                                                    <li>This promotion is valid for all new & existing players.</li>
                                                    <li>All winning bets payout will be doubled during this promotion.</li>
                                                    <li>This promotion is only applicable only on Aviatrix.</li>
                                                    <li>Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.</li>
                                                    <li>Multiple accounts will not be considered for this promotion eligibility/criteria. All players will be subject for duplicate checking. {domain} reserves the right to disqualify players if collusion or any foul play takes place in the promotion.</li>
                                                    <li>{domain} & Aviatrix reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.</li>
                                                    <li>{domain} General Terms & Conditions apply.</li>
                                                </ol>
                                            </AVDDTermsBody>
                                    </AVDDTermsSecBody>
                                    </AVDDTermsSec>
                            </AVDDSeventhSec>
                       </div>
                    </AviatrixDDContentWrapper>
            </InnerPromoWrapper>
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(AviatrixDoubleDhamaka));

//Styled Components

const AviatrixDDContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(${config.imageV3Host}/static/cashsite/${theme('image.aviatrixPromoInnerBg')});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    gap: 2em;

    h3 {
        text-align: center;
        font-size: 26px;
        font-style: italic;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        background: linear-gradient(180deg, #FADA34 0%, #DE9101 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding-top: 20px;
    }

    h4 {
        color: #E5A20D;
        text-align: center;
        margin: 0;
        font-size: 24px;
        font-style: italic;
        font-weight: 700;
        line-height: normal;
    }

    p {
        color: #FFF;
        text-align: center;
        font-size: 21px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        padding: 0rem 5rem;
    }

    .m-0 {
        margin: 0;
    }

    .p-0 {
        padding: 0rem 20rem;

        span{
            color: #E5A20D;
        }
    }

    a {
        width: 100%;
        height: auto;

        & span {
            display: flex !important;
            justify-content: center !important;

            & img {
                width: 60%;
                height: 80%;
            }
        }
    }

    .lazy-load-image-background {
        display: flex !important;
        justify-content: center !important;
    }

    .container {
        max-width: 1620px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;


const InnerPromoWrapper = styled.div`
    width: 98%;
    height: auto;
    border: 1px solid white;
    border-radius: 1px;
    background: rgba(111,111,111,0.5);
    margin: 0em 1.2em;
    -webkit-backdrop-filter: brightness(0.5);
    backdrop-filter: brightness(0.5);
`

const AVDDTopSec = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    width: 85%;
    height: auto;
    margin-top: 2em;

    & hr {
        width: 0px;
        height: 10em;
        flex-shrink: 0;
        stroke-width: 1px;
        stroke: #F8E200;
        filter: blur(1px);
        border: 1px solid #F8E200;
    }
`
const AVDDTopRight = styled.div`
   width: 70%;
   height: auto;
   margin-left: 1em;

   & h6 {
        color: #FFF;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.323px;
        text-transform: uppercase;
        margin: 3px 0px;
   }

   & span {
        color: #F8E200;
        text-align: justify;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.323px;
        text-transform: uppercase;
        margin: 3px 0px;
   }
`

const AVDDTopLeft = styled.div`
    width: 40%;
    height: auto;
    margin-right: 1em;
    margin-top: 2em;

    & img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }
`

const AVDDSecondSec = styled.div`
    display: flex;
    align-items: center;
    width: 85%;
    height: auto;
    gap: 10px;

    & hr {
        width: 0px;
        height: 17em;
        flex-shrink: 0;
        stroke-width: 1px;
        stroke: #F8E200;
        filter: blur(1px);
        border: 1px solid #F8E200;
    }
`

const AVDDSecondSecLeft = styled.div`
    width: 70%;
    height: auto;
    margin-right: 1em;

    & h6 {
        color: #FFF;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.323px;
        text-transform: uppercase;
        margin: 3px 0px;
        
   }

   & span {
        color: #F8E200;
        text-align: justify;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.323px;
        text-transform: uppercase;
        margin: 3px 0px;
   }
`

const AVDDSecondSecRight = styled.div`
    width: 30%;
    height: auto;
    margin-left: 1em;

    & img {
        width: 75%;
        height: auto;
        object-fit: contain;
    }
`

const AVDDThirdSec = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 85%;
    height: auto;
    margin: 3em 0em;
`
const AVDDThirdSecTop = styled.div`
       display: flex;
       gap: 10px;
       width: 100%;
`
const AVDDThirdSecTopLeft = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: auto;

    & img {
        width: 45%;
        height: auto;
        object-fit: cover;
    }

    & h5 {
        color: #FFF;
        text-align: justify;
        font-size: 25px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 0.32px;
        text-transform: uppercase;
        margin: 3px 0px;
    }
`
const AVDDThirdSecTopRight = styled.div`
        width: 70%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      

       & h4 {
            color: #FFF;
            font-size: 28px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            letter-spacing: 0.343px;
            text-transform: uppercase;
            margin: 3px 0px;
            
       }

       & span {
            color: #F8E200;
            text-align: center;
            font-size: 28px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            letter-spacing: 0.343px;
            text-transform: uppercase;
            margin: 3px 0px;
       }
       & p {
            flex-shrink: 0;
            stroke-width: 1px;
            stroke: #F8E200;
            filter: blur(1px);
            border: 1px solid #F8E200;
            width: 20em;
            margin: 3px 0px;
       }   
`

const AVDDThirdSecBody = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 750px;
    margin-top: 4em;
`
const AVDDThirdSecBodyBox = styled.div`
    border-radius: 62.244px;
    background: rgb(51 15 54 / 60%);
    backdrop-filter: blur(4.610687732696533px);
    height: auto;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    z-index: 999;
    padding: 0em 2em 2em 2em;


    & img {
        width: 100%;
        height: auto;
        margin-top: -25px;
    }

    & h1 {
        color: #F8E200;
        text-align: center;
        text-shadow: rgba(72,13,76,2) 1px 0px 0px, #ffffff 1px 1px 0px, 0px 2px 4px rgba(107,31,80,0);
        font-weight: bold;
        line-height: normal;
        font-size: 40px;
        font-style: italic;
        letter-spacing: 0.576px;
        text-transform: uppercase;
        width: 80%;
        margin-top: -15px;
        margin-bottom: 0px;
    }

    & div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 15px;
        margin: 2em 0em;
    }

    & div > h6 {
        color: #FFF;
        font-size: 23px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 0.261px;
        text-transform: uppercase;
        text-align: left;
        margin: 3px 0px;

        & span {
            color: #77D6FF;
            font-size: 23px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            letter-spacing: 0.261px;
            text-transform: uppercase;
            text-align: left;
            margin: 3px 0px;
        }
    }

    & div > h5 {
        color: #F8E200;
        font-size: 23px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 0.277px;
        text-transform: uppercase;
        text-align: left;
        margin: 3px 0px;
    }

    & div > p {
        color: #FFF;
        font-size: 23px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 0.277px;
        text-transform: uppercase;
        text-align: left;
        padding: 0rem;
        margin: 3px 0px;
    }
    
    & .bdcolor{
        color: #FF0000;
    }

    & .yolocolor{
        color: #77D6FF;
    }
`
const AVDDThirdSecBodyBgImg = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
    width: 62%;
    z-index: 9;

    & img {
        width: 100%;
        height: auto;
    }
`

const AVDDFourthSec = styled.div`
    width: 85%;
    height: auto;
    margin: 2em 0em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background: url(${config.imageV3Host}/static/cashsite/${theme('image.aviatrixPromoSanta')});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 55vh;

    @media only screen and (min-width: 1500px){
        height: 70vh;
    }
   

    & img {
        width: 100%;
        height: auto;
    }

    & h6 {
        color: #FFF;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.336px;
        text-transform: uppercase;
        margin: 3px 0px;
    }
`

const AVDDFifthSec = styled.div`
    width: 85%;
    height: auto;
    margin: 2em 0em;
    display: flex;
    justify-content: center;

    & img {
        width: 100%;
        height: auto;
    }
`

const AVDDSixthSec = styled.div`
     width: 85%;
    height: auto;
    margin: 2em 0em;
    display: flex;
    justify-content: center;

    & img {
        width: 100%;
        height: auto;
    }
`

const AVDDSeventhSec = styled.div`
        width: 85%;
        height: auto;
        margin: 2em 0em;
        display: flex;
        justify-content: center;
`

const AVDDTermsSec = styled.div`
    width: 70%;
    border-radius: 70px;
    background-color: rgba(72, 13, 76, 0.60);
    backdrop-filter: blur(6.0460357666015625px);
    padding: 1em;
`

const AVDDTermsSecBody = styled.div`
    background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.aviatrixPromoTermsSec')});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 545px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    padding: 10px;

    @media only screen and (min-width: 1500px){
        height: 640px;
    }
`

const AVDDTermsSecHead = styled.div`
    width: 30%;
    background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.avatrixPromoTCBG')});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 125px;
    margin-left: 4%;
    display: flex;
    align-items: center;
    justify-content: center;

    & h5 {
        color: #F8E200;
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 47.586px; /* 134.333% */
        text-transform: capitalize;
        margin: 3px 0px;
    }
`

const AVDDTermsBody = styled.div`
     & ol {
        padding-left: 3em;

        & li {
            color: #FFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.25px;
            text-transform: capitalize;
            margin: 10px 0px;

            ::marker {
                color: #F8E200;
            }
        }
     }
`