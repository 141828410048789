import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';

function AviatorRainPromo(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = (brandId == 31 || brandId == 32) ? "YOLO247" : (brandId == 39 || brandId == 40) ? "FOMO7" : 'Baazi Adda';

    return (
        <>
            <InformationWrapper>
                {(brandId == 39 || brandId == 40) ? (
                    <TitleText>
                        <FomoHeading>
                            <h2>Get Ready for Aviator Thunder!</h2>
                            <UnderLine></UnderLine>
                        </FomoHeading>
                        <h6>From <span className='orange'>September 1<sup>st</sup> to October 31<sup>st</sup>, 2024</span> get ready for the electrifying excitement of Aviator shower! Experience daily adrenaline rushes with thrilling bonus shower showers eight times throughout these two months. Each bonus shower gives you a chance to claim your share of FREE bets worth <span className='orange'>₹9.2 lakhs!</span> With a massive giveaway totaling <span className='orange'>₹75 Lacs</span> in enticing free bets over two months, this is your opportunity to ride the storm and elevate your betting game to new heights. Don’t miss out—join us and take flight with Aviator shower!</h6>
                    </TitleText>
                ) : (
                    <TitleText>
                        <h1>Get Ready for Aviator Thunder!</h1>
                        <h6>From <span className='red'>September 1<sup>st</sup> to October 31<sup>st</sup>, 2024</span> get ready for the electrifying excitement of Aviator Rain! Experience daily adrenaline rushes with thrilling bonus rain showers four times throughout these two months. Each bonus rain gives you a chance to claim your share of FREE bets worth <span className='red'>₹9.2 lakhs!</span> With a massive giveaway totalling <span className='red'>₹75 Lacs</span> in enticing free bets over two months, this is your opportunity to ride the storm and elevate your betting game to new heights. Don’t miss out—join us and take flight with Aviator Rain!</h6>
                    </TitleText>
                )}
                {(brandId == 39 || brandId == 40) && <FomoHeading>
                    <Divider></Divider>
                    <h3>Weather Alert: The Aviator Thunder Daily Forecast is In!</h3>
                    <UnderLine></UnderLine>
                </FomoHeading>}
                {(brandId == 39 || brandId == 40) ?
                    <FomoBodyWrapper>
                        <RightWrapper>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatorRainPromoPlain}`}
                                alt="jetxPromoStepThree"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                        </RightWrapper>
                        <LeftWrapper>
                            <RoContent>
                                <TextWrapper>
                                    <p><span className='labelHeaderFomo'>Thunder Showers : </span>The {domain} clouds are set to burst with thrilling bonuses eight times over the next two months, creating an electrifying betting experience. Stay tuned to {domain}, as each bonus shower will bring a delightful surprise! Hint: Expect these surprises every week</p>
                                </TextWrapper>
                                <TextWrapper>
                                    <p><span className='labelHeaderFomo'>Claim Your Free Bets : </span>Complete the required turnover and get ready for the {domain} AVIATOR shower. Once you’ve met the criteria, your well-earned free bets will be waiting for you!</p>
                                </TextWrapper>
                                <TextWrapper>
                                    <p><span className='labelHeaderFomo'>Two-Month Weather Extravaganza : </span>Aviator Thunder isn’t just a one-day event—it’s a two-month-long spectacle! With a colossal total giveaway of ~₹75 lacs, each day brings a fresh chance to win big. Get ready to ride the waves of excitement and make the most of this extraordinary betting adventure!</p>
                                </TextWrapper>
                            </RoContent>
                        </LeftWrapper>
                    </FomoBodyWrapper> :
                    <BodyWrapper>
                        <LeftWrapper>
                            <RoContent>
                                <h1>Weather Alert: The Aviator Thunder Daily Forecast is In!</h1>
                                <TextWrapper>
                                    <p><span className='labelHeader'>Thunder Showers : </span> The {domain} clouds are set to burst with thrilling bonuses eight times over the next two months, creating an electrifying betting experience. Stay tuned to {domain}, as each bonus shower will bring a delightful surprise! Hint: Expect these surprises every week</p>
                                </TextWrapper>
                                <TextWrapper>
                                    <p><span className='labelHeader'>Claim Your Free Bets : </span> Complete the required turnover and get ready for the {domain} AVIATOR RAIN. Once you’ve met the criteria, your well-earned free bets will be waiting for you!</p>
                                </TextWrapper>
                                <TextWrapper>
                                    <p><span className='labelHeader'>Two-Month Weather Extravaganza : </span> Aviator Thunder isn’t just a one-day event—it’s a two-month-long spectacle! With a colossal total giveaway of ₹75 lacs, each day brings a fresh chance to win big. Get ready to ride the waves of excitement and make the most of this extraordinary betting adventure!</p>
                                </TextWrapper>
                            </RoContent>
                        </LeftWrapper>
                        <RightWrapper>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatorRainPromoPlain}`}
                                alt="jetxPromoStepThree"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                        </RightWrapper>
                    </BodyWrapper>}
                <HeaddingWrapper>
                    <HeadingText className='titleText'>
                        <h1 className={(brandId == 31 || brandId == 32) && 'yoloTitle'}>Prize Allocation</h1>
                    </HeadingText>
                    {(brandId == 39 || brandId == 40) && <UnderLine></UnderLine>}
                    {(brandId == 39 || brandId == 40) ? <p>with each shower, bonus-drop worth INR 926,250 will be distributed upon completion of the following criteria:</p> : <p>With each Rain, bonus-drop worth INR 926,250 will be distributed upon completion of the following criteria:</p>}
                </HeaddingWrapper>
                <ROWrapper className={(brandId == 39 || brandId == 40) && 'fomoTableBg'}>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatorRainPromoTable}`}
                        alt="jetxPromoStepThree"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                    />
                </ROWrapper>
                {(brandId == 39 || brandId == 40) ? (
                    <Termsandconditions>
                        <TermsandconditionsHeading>
                            <h1>Terms & Conditions</h1>
                        </TermsandconditionsHeading>
                        <UnderLine></UnderLine>
                        <TermsandconditionsBody>
                            <ol>
                                <li>This promotion is exclusively for the game Aviator from September 1<sup>st</sup> to October 31<sup>st</sup>, 2024.</li>
                                <li>Bonus shower will occur every week.</li>
                                <li>To be eligible, bets must achieve a minimum cashout of 1.95X.</li>
                                <li>The minimum turnover required to qualify for the competition is INR 3 lacs.</li>
                                <li>Each Bonus shower shower will have a prize pool of INR 9.26 lakhs, distributed among winners based on the highest turnover achievements.</li>
                                <li>he total prize pool for all eight showers is INR 74.1 Lacs.</li>
                                <li>Multiple accounts will not be considered for promotion eligibility. Duplicate checking will be conducted, and {domain} reserves the right to disqualify players if collusion or foul play is detected.</li>
                                <li>{domain} reserves the right to amend, suspend, or cancel the promotion at any time without prior notice. General Terms & Conditions apply.</li>
                            </ol>
                        </TermsandconditionsBody>
                    </Termsandconditions>
                ) : (
                    <AviatorRainTermsAndCondition>
                        <h4>Terms & Conditions</h4>
                        <ul>
                            <li>This promotion is exclusively for the game AVIATOR from September 1<sup>st</sup> to October 31<sup>st</sup>, 2024.</li>
                            <li>Bonus Rain will occur every week.</li>
                            <li>To be eligible, bets must achieve a minimum cashout of 1.95X.</li>
                            <li>The minimum turnover required to qualify for the competition is INR 3 lacs.</li>
                            <li>Each Bonus Rain shower will have a prize pool of INR 9.26 lakhs, distributed among winners based on the highest turnover achievements.</li>
                            <li>The total prize pool for all four showers is INR 74.1 Lacs.</li>
                            <li>Multiple accounts will not be considered for promotion eligibility. Duplicate checking will be conducted, and {domain} reserves the right to disqualify players if collusion or foul play is detected.</li>
                            <li>{domain} reserves the right to amend, suspend, or cancel the promotion at any time without prior notice. General Terms & Conditions apply.</li>
                        </ul>
                    </AviatorRainTermsAndCondition>
                )}
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}



export default withTheme(withAppContext(AviatorRainPromo));

const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 3rem 3rem 8rem 3rem;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em 0 1.2em;
    border: 1px solid #E5B358;
    border-radius: 0 0 20px 20px;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.AviatorRainBackroung")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    .yoloTitle {
        margin-top: 0;
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px; /* 140% */
        text-transform: capitalize;
        background: linear-gradient(180deg, #F00 18.06%, #F00 91.67%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 0;
    }

    .fomoTableBg {
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.AviatorRainPromoTableBackgroun")});
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
    }
`;

const TitleText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 7rem 4rem 7rem;

    h1 {
        color: #F00;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        padding-bottom: 1rem;
    }

    h6 {
        margin: 0;
        padding: 0;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        text-align: center;
        font-weight: 500;
        line-height: 160.3%;
        text-transform: capitalize;
    }

    .red {
        color: #F00;
        font-weight: 700;
    }

    .orange {
        color: #FEBA3B;
        font-weight: 700;
    }
`;

const Divider = styled.div`
    height: 2px;
    width: 100%;
    background: radial-gradient(#1182C1 0%, #00000000 50%);
`;

const FomoHeading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 0 2rem 0;

    h2, h3 {
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: 107.7%; /* 26.925px */
        letter-spacing: 1.25px;
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 115.57%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0;
        padding: 1.5rem 0;
    }

    h3 {
        padding-top: 4rem;
    }
`;

const BodyWrapper = styled.div`
    display: flex;
    /* justify-content: center; */
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;
    gap: 1rem;
    padding: 0 5rem;
`;

const FomoBodyWrapper = styled(BodyWrapper)`
    h1 {
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 91.67%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 0.25rem !important;
    }

    .fomoBullet {
        color: #FEBA3B;
    }
`;

const LeftWrapper = styled.div`
    display: flex;
    flex-direction: row;
    /* width: 45%; */
    width: 50%;
`;

const RightWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    align-self: center;

    img {
        width: 35rem;
    }
`;

const RoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    h1 {
        margin: 0 0 1.5rem 0;
        color: #F00;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    text-transform: capitalize;

    .labelHeader {
        color: #F00;
        font-weight: 700;
    }

    .labelHeaderFomo {
        color: #FEBA3B;
        font-weight: 700;
    }

    p {
        margin: 0;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 170.3%; /* 19.248px */
        text-align: left;
    }
`;

const HeaddingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    margin: 0 -2rem;

    .titleText {
        padding: 0;
        h1 {
            margin-top: 0;
        }
    }

    .yoloTitle {
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px; /* 140% */
        text-transform: capitalize;
        margin-bottom: 0;
    }

    p {
        margin: 2rem 0 0 0;
        color: #FFF;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px; /* 153.333% */
        text-transform: capitalize;
    }
`;

const HeadingText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 91.67%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`;

const UnderLine = styled.div`
    height: 2px;
    width: 184px;
    background: radial-gradient(#FFF 0%, rgba(153, 153, 153, 0.00) 80%);
`;

const ROWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    flex-direction: column;

    img {
        width: 70%;
    }

    p {
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: 160.3%; /* 25.648px */
    }

    h3 {
        color: #FFE500;
        text-align: center;
        font-size: 16px;
        font-style: italic;
        font-weight: 700;
        line-height: 26px; /* 162.5% */
        text-transform: capitalize;
    }

    h3.fomoPrize {
        color: #FEBA3B;
        text-align: center;
        font-style: normal;
    }

    h4 {
        color: #FFF;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px; /* 153.333% */
        text-transform: capitalize;
    }
`;

const Termsandconditions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    width: 80%;
    height: auto;
    margin: 8rem 10% 3rem 10%;
    padding: 5rem 3rem;
    position: relative;
    background: linear-gradient(#020405, rgba(0, 0, 0, 0.935), #020405);
    background-clip: padding-box;
    border: 2px solid transparent;

    ::after {
        position: absolute;
        top: -2px; bottom: -2px;
        left: -2px; right: -2px;
        background: linear-gradient(90deg, #53C1FF, black, #53C1FF, black, #53C1FF);
        content: '';
        z-index: -1;
        border-radius: 30px;
    }
`;

const AviatorRainTermsAndCondition = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    margin: 4rem 0rem 5rem 0rem;
    padding: 0 9rem 0 4rem;
    position: relative;

    & li {
        color: #FFF;
        text-align: justify;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 166.667% */
        text-transform: capitalize;
    }

    & h4 {
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px; /* 140% */
        text-transform: capitalize;
        color: #F00;
        margin: 10px 0px 0px 15px;
    }

    .bold {
        color: #FFF;
        font-weight: 700;
    }
`;

const TermsandconditionsHeading = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    padding-bottom: 2rem;

    h1 {
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 121.53%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`;

const TermsandconditionsBody = styled.div`
    ol {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        padding-top: 1rem;
    }

    li {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
    }

    .yellow {
        color: #FEBA3B;
        font-weight: 700;
    }
`;