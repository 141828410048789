import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import {
    BodyWrapper as HistoryBodyWrapper,
    FirstCol,
    Header,
    MainInfoWrapper,
    SecondCol,
    Select,
    TableBodyWrapper,
    TableHeadRow,
    TableWrapper,
    TypeCol,
    TimeStyle,
    DateStyle,
    TableBodyRow,
    TableFooter,
    // TypeColColumn
} from 'src/components/udb/commonStyle';
import Intl from '../../../../src/components/common/Intl';
import { ChooseDocument as HistoryChooseDocument } from '../profile/KYCPage';
import CustomRadioButton from 'src/components/udb/kyc/CustomRadioButton';
import Pagination from '../../../../src/components/common/Pagination';
import { transactionHistory } from 'src/config/api';
import { DateSelector } from 'src/pages/Statements/My-Orders';
import DatePicker from "react-datepicker";
import slashIcon from '../../../images/svg/slash.svg'

//importing provider Icons------------
import astorpayIcon from '../../../images/svg/astorpay.svg';
import exchageIcon from '../../../images/svg/exchange.svg';
import netellerIcon from '../../../images/svg/neteller.svg';
import rupeepayIcon from '../../../images/svg/rupeepay.svg';
import UDBHeader from 'src/components/UDBHeader';
import { DatePickerWrapper, BlockPick, SvgIcon } from 'src/components/udb/DatePickerUdb';
import CalenderIcon from 'src/components/icon/CalenderIcon';
import Tab from 'src/components/common/Tab';
import { indexOf, values } from 'lodash';
import moment from 'moment';
import { TimeColContentWrapper } from '../betdetails/MyBets';
import SEOContents from 'src/components/SEOContents';


const TabTypes = [
    {
        value: 'ALL',
        label: <Intl langKey='HISTORY@All' />,
        methodType: '',
    },
    {
        value: "DEPOSIT",
        label: <Intl langKey='HISTORY@deposit' />,
        methodType: '',
    },
    {
        value: "WITHDRAWAL",
        label: <Intl langKey='HISTORY@withdraw' />,
        methodType: '',
    }
];

const statusType = [
    {
        value: 'ALL',
        label: 'ALL',
        id: 1,

    },
    {
        value: "PROCESSED",
        label: 'Processed',
        id: 2,

    },
    {
        value: "SUCCESS",
        label: 'Success',
        id: 3,
    },
    {
        value: "INITIATED",
        label: 'Initiated',
        id: 4,
    },
    {
        value: "REJECTED",
        label: 'Rejected',
        id: 5,

    },
    {
        value: "FAILURE",
        label: 'Failed',
        id: 6,
    },
    {
        value: "STARTED",
        label: 'Started',
        id: 7,
    },
    {
        value: "PENDING",
        label: 'Pending',
        id: 8,
    },
    {
        value: "APPROVED",
        label: 'Approved',
        id: 9,
    },
    {
        value: "REVERTED",
        label: 'Reverted',
        id: 10,
    },{
        value: "TIMEOUT",
        label: 'Time Out',
        id: 11,
    }
]

const ProviderIcon = [
    {
        name: "astorPay",
        icon: astorpayIcon
    },
    {
        name: "exchange",
        icon: exchageIcon
    },
    {
        name: "neteller",
        icon: netellerIcon
    },
    {
        name: "rupeepay",
        icon: rupeepayIcon
    }
]

const statusColorPairs = {
    PROCESSED: '#AB7F3A',
    SUCCESS: '#0EAD69',
    FAILURE: '#E30E33'
}


function History(props: any) {
    const [startAt, setStartAt] = useState(new Date(new Date().setHours(0, 0, 0))); //Date
    const [endAt, setEndAt] = useState(new Date(new Date().setHours(23, 59, 59))); //Data
    const [tabIndex, setTabIndex] = useState('ALL'); //Tab State
    const [filtered, setFiltered] = useState('ALL'); //Search
    const [page, setPage] = useState(1); //pagination
    const [filteredData, setFilteredData] = useState([]); //data to be filtered
    const [count, setCount] = useState(0);

    const devRef = useRef<HTMLDivElement>(null);

    useEffect(() => { searchApi() }, [])

    // searchApi function is used for calling the api----------------

    function searchApi(start = startAt, end = endAt, pageNo = page, tabCount = tabIndex, status = filtered) {
        const payloads = {
            start_date: start,
            end_date: end,
            page: pageNo
            // payment_type: tabCount
        }

        if (tabCount && tabCount !== 'ALL') {
            payloads['payment_type'] = tabCount;
        }

        if (status && status !== 'ALL') {
            payloads['status'] = status;
        }

        transactionHistory(payloads).then((response) => {
            let data = response.data.data;
            let countData = response.data.count;
            setCount(countData)
            // setDetails(data)
            setFilteredData(data)
            let transactionHistoryWithCharges = data.map((arrayItem)=>{
                let paymentType = arrayItem.payment_type
                //let transactionType = arrayItem.transaction_type.name
                //we no need to check transactionType
                // if(paymentType === "WITHDRAWAL" && transactionType === "Fast Withdrawal UPI" || transactionType === "Fast Withdrawal") {   
                if(paymentType === "WITHDRAWAL") { 
                  let serviceChange = (arrayItem.requested_amount - arrayItem.amount).toFixed(2);      
                  return {...arrayItem,serviceCharges:serviceChange}          
              }
                  return arrayItem;
              });
              setFilteredData(transactionHistoryWithCharges);
            // setTabIndex((preS) => preS ? preS : 'ALL' )
            devRef.current.scrollIntoView();
           
        }).catch((error) => {
            console.log(error)
        })

    }

    //sorting based on Tabs selected-----------------------    

    function tabSearchHandler(start = startAt, end = endAt, pageNo = page, event) {
        const value = event.target.value;
        setTabIndex(value);
        setFiltered('ALL');
        setPage(1);
        searchApi(start, end, pageNo, value, 'ALL')
    }

    //sorting based on status-----------------------    

    function statusSearchHandler(start = startAt, end = endAt, pageNo = page, tabCount, event) {

        const value = event.target.value;
        setFiltered(value);
        searchApi(start, end, pageNo, tabCount, value)
    }

    const date = new Date();
    const minDate = date.setDate((new Date()).getDate() - 30);


    const handleChangePageIndex = (index: number) => {
        const value = index;
        setPage(value);
        searchApi(startAt, endAt, value, tabIndex, filtered)

    }

    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    const handlefromDateChange = (startAt: Date | null) => {
        setStartAt(startAt)
        searchApi(startAt)

    }

    const handletoDateChange = (endAt: Date | null) => {
        setEndAt(endAt)
        searchApi(startAt, endAt)

    }

    function cancelWithdrawHandler() {

    }



    return (
        <MainInfoWrapper ref={devRef}>
            <SEOContents pageName="memberFinanceHistory"/>
            <Header>
                <FirstCol>
                    <UDBHeader title="DASHBOARD@HISTORY" />
                </FirstCol>

                <SecondCol>
                    <DatePickerWrapper>
                        <SvgIcon>
                            <CalenderIcon />
                        </SvgIcon>
                        <BlockPick>
                            {/* <Intl langKey= "DATE_PICKER@FROM" /> */}
                            <DatePicker selected={startAt} onChange={handlefromDateChange} onChangeRaw={handleDateChangeRaw} minDate={minDate} maxDate={new Date()} placeholderText="From" />
                        </BlockPick>
                        <BlockPick>
                            {/* <Intl langKey= "DATE_PICKER@TO" /> */}
                            <DatePicker selected={endAt} onChange={handletoDateChange} onChangeRaw={handleDateChangeRaw} minDate={minDate} maxDate={new Date()} placeholderText="To" />
                        </BlockPick>
                    </DatePickerWrapper>
                </SecondCol>
            </Header>
            <BodyWrapper>
                <ChooseDocument className='error'>
                    <DepositeTypeWrapper>
                        {TabTypes.map(elm => <CustomRadioButton key={elm.methodType} name="methodType" label={elm.label} checked={tabIndex === elm.value} value={elm.value} onChange={(e) => tabSearchHandler(startAt, endAt, page, e)} />)}

                    </DepositeTypeWrapper>
                    <Select className="select">
                        <select value={filtered} onChange={(event) => statusSearchHandler(startAt, endAt, page, tabIndex, event)}>
                            {statusType.map(element => <option key={element.id} label={element.label} value={element.value} />)}
                            {/* onChange={(event) =>statusSearchHandler(startAt, endAt, page, event)} */}
                        </select>
                    </Select>
                </ChooseDocument>
                <TableWrapper>
                    <TableHeadRow>
                        <TypeCol className='moreWidth'>
                            <span> <Intl langKey="HistoryTable@ReferanceNo" /></span>
                        </TypeCol>
                        <TypeCol>
                            <span> <Intl langKey="HistoryTable@TimeDate" /></span>
                        </TypeCol>
                        <TypeCol>
                            <span> <Intl langKey="Table@Type" /></span>
                        </TypeCol>
                        <TypeCol>
                            <span> <Intl langKey="HistoryTable@Description" /></span>
                        </TypeCol>
                        <TypeCol>
                            <span> <Intl langKey="HistoryTable@Amount" /></span>
                        </TypeCol>
                        <TypeCol>
                            <span> <Intl langKey="HistoryTable@Status" /></span>
                        </TypeCol>
                        {/* <TypeCol>
                            <span></span>
                        </TypeCol> */}

                    </TableHeadRow>

                    <TableBodyWrapper>

                        {filteredData.map((items, index) => {
                            return (
                                <>
                                    <TableBodyRow key={index}>
                                        <TypeCol className='moreWidth'>
                                            <span>{items.transaction_sn}</span>
                                        </TypeCol>
                                        <TypeCol>
                                            <TimeColContentWrapper>
                                                <TimeStyle> {moment.utc(items.created).local().format('DD-MMM-YYYY')} </TimeStyle>
                                                <DateStyle>{moment.utc(items.created).local().format('h:mm A')} </DateStyle>
                                            </TimeColContentWrapper>
                                        </TypeCol>
                                        <TypeCol>
                                            <span>{items.payment_type}</span>
                                        </TypeCol>
                                        <TypeCol className='moreRadius'>
                                            <SvgIcon></SvgIcon>
                                            <ProviderChannel>{items.transaction_type.name}</ProviderChannel>
                                        </TypeCol>
                                        <TypeCol style={{flexDirection: 'column'}}>
                                            <span>{Number(items.amount).toFixed(2)}</span>
                                            {items.serviceCharges > 0 &&
                                               <span>(Charges- {items.serviceCharges})</span>
                                            }
                                        </TypeCol>
                                        <TypeCol>
                                            <StatusChannel style={{ color: statusColorPairs[items.status] }}>{[items.status]}</StatusChannel>
                                        </TypeCol>

                                        <CancelWithdrawWrap>
                                            {/* {(items.payment_type === "WITHDRAWAL" && items.status === "PROCESSED") ? <img src='' onClick={cancelWithdrawHandler} /> : ''} */}
                                        </CancelWithdrawWrap>

                                    </TableBodyRow>
                                </>)
                        })}


                    </TableBodyWrapper>

                    <TableFooter>
                        <Pagination
                            numberPerPage={10}
                            totalLength={count || 0}
                            // onClick={(e)=>{handleChangePageIndex(e ,start=startAt, end=endAt,tabCount=tabState)}}
                            onClick={handleChangePageIndex}
                            currentIndex={page}
                        />
                    </TableFooter>
                </TableWrapper>
            </BodyWrapper>
        </MainInfoWrapper>
    )
}

export default History

const CancelWithdrawWrap = styled.div`
    display: flex;
    position: absolute;
    right: 25px;
    top: 8px;
    cursor: pointer;
`;

const BodyWrapper = styled(HistoryBodyWrapper)`
    flex: 1;
    width: auto;

    label {
        width: auto;

        .checkmark {
            padding: 0em 1.5em;
        }
    }

    .processing {
        color: #AB7F3A;
    }

    .sucessful {
        color: #0EAD69;
    }

    .failed {
        color: #E30E33;
    }
`;

export const DepositeTypeWrapper = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
`;

export const ChooseDocument = styled(HistoryChooseDocument)`
    flex: 1;
    align-items: center;

    select {
        color: #53537C;
        font-size: 12px;
        z-index: auto;
    }

    section {
        width: 100px;
        padding: 8px;
        margin: 0px;
    }
`;

const ProviderChannel = styled.div`
    color: #53537B;
    font-weight: 500;
    font-size: 11px;
    text-transform: capitalize;
    text-align: center;
    font-style: italic;
`;

const StatusChannel = styled.div`
    width: 90px;
    background: #FFFFFF;
    border-radius: 21px;
    font-weight: 500;
    font-size: 11px;
    height: 75%;
    /* padding: 0px 15px; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #53537C;
`;

const NoContent = styled.span`
display:inline-block;
padding:10px;
`
// const DateWrp = styled.span`
// white-space: nowrap;
// `
