import React from 'react'

type Props = {}

const RegularUpiDepositIcon = (props: Props) => {
  return (
    <svg
    width={31}
    height={31}
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.367 9.13954H4.54561V7.0318L0.560059 11.0173L4.54561 15.0029V12.7035H8.5311"
      stroke="white"
      strokeWidth={0.582016}
    />
    <path
      d="M19.5427 22.0459H26.3641V24.1536L30.3496 20.1681L26.3641 16.1825V18.4819H22.3786"
      stroke="white"
      strokeWidth={0.582016}
    />
    <circle
      cx={15.4049}
      cy={15.6687}
      r={9.16723}
      fill="#FFF9F9"
      stroke="white"
      strokeWidth={0.817077}
    />
    <g clipPath="url(#clip0_724_137)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6564 15.7674L16.6444 9.76715L13.2979 21.5678L19.6564 15.7674Z"
        fill="#097939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5124 15.7674L14.5009 9.76715L11.1543 21.5678L17.5124 15.7674Z"
        fill="#ED752E"
      />
    </g>
    <defs>
      <clipPath id="clip0_724_137">
        <rect
          width={11.8006}
          height={11.8006}
          fill="white"
          transform="translate(9.50439 9.76733)"
        />
      </clipPath>
    </defs>
  </svg>
  )
}

export default RegularUpiDepositIcon