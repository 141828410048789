import styled from 'styled-components';
import { color } from '../../../sass-files/variable';
import searchicon from '../../../images/search_icon.png';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { theme } from 'styled-tools';

const { titleHeadColor, secondarycolor } = color

export default function GameSearch({ placeholder, onSearch, searchText }) {

    const {id=''} = useParams();
    const searchValue = id === "evolution-gaming" || id === "ezugi-live-casino" ? id : id.replace('-',' ');
   
    const [searchTerm, setSearchTerm] = useState('');

    const searchHandler = (e) => {
        setSearchTerm(e.target.value)
        onSearch(e.target.value)
    }
    
    useEffect(() => {
        let t = null;
        setSearchTerm(searchValue);
        if(searchValue){
            onSearch(searchValue);
           // t = setTimeout(()=> {onSearch(searchValue)},1000)
        }
        return ()=>{
           // clearTimeout(t);
        }
    },[searchValue])

    return (
        <SearchBg>
            <SearchContainer>
                <input value={searchTerm} type="text" placeholder='Search Games' onChange={searchHandler} />
            </SearchContainer>
        </SearchBg>
    )
}

const SearchBg = styled.div`
    padding: 8px 17px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), rgba(0, 0, 0, 0.3);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /* margin-bottom: 5px; */
    border-radius: 3px;
`;

const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    /* transform: skew(340deg); SKEW */
    /* background: ${secondarycolor};; */
    background:${theme('palette.SearchContainerBg')};
    border: 1px solid #7B7B9C;
    border-radius:5px;
    /* box-shadow: inset 4px 4px 6px #A8A6A3; */

    input {
        width: 180px;
        background: transparent;
        border: none;
        text-align: left;
        margin-left: 20px;
        position: relative;
        text-transform: uppercase;
        padding: 12px 5px 12px 0px;
        outline:0;
        color: #ffffff;
        font-size: 14px;

        ::placeholder {
            color: #d1c9c9;
            opacity: 1; /* Firefox */
        }
          
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #6d6a6a;
        }
        
        ::-ms-input-placeholder { /* Microsoft Edge */
        color: #6d6a6a;
        }
        :focus {
            outline:0;
            border:none
        }
    }

    :after {
        content: "";
        width: 15px;
        height: 15px;
        display: inline-block;
        background-image: url(${searchicon});
        background-size: cover;
        background-repeat: no-repeat;
        /* transform: skew(-340deg); */
        position: absolute;
        left: 35px;
    }
`;

function e(e: any) {
    throw new Error('Function not implemented.');
}
