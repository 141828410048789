import { SubmitBtn, ButtonWrapper } from "../udb/commonStyle";
import styled, { css } from "styled-components";
import { ifProp, theme } from "styled-tools";

export const AuthButtonWrap = styled(ButtonWrapper)`
  justify-content: center;
  width: 100%;

  button[disabled]:hover {
    background: ${theme('palette.authBtnHoverBg')}
   }
`;

export const AuthSubmitBtn = styled(SubmitBtn)`
  width: 85%;
  justify-content: center;
  background: ${theme('palette.betdailyBtnBG')};
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  /* color: #fff; */
  color: ${theme('palette.authLoginTextColor')};
  text-transform: uppercase;

  :hover {
    background: ${theme('palette.authBtnHoverBg')}
  }

  :disabled {
    cursor: not-allowed;
    color: #ffffff;
    background: ${theme('palette.authBtnHoverBg')};
    opacity: 0.8;
  }
`;
export const AuthTopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 10px;
  background: #020032;
  border-radius: 10px 10px 0px 0px;
`;

export const OtpButton: any = styled.button`
  ${ifProp(
    "isActive",
    css`
      background-color: #38bc7d;
      height: 49px !important;
      color: white;
      padding: 2px 51px !important;
      cursor: pointer;

      @media (min-width: 1600px) {
        height: 49px !important;
      }
    `,
    css`
      background-color: #d0d0d0;
      padding: 2px 51px !important;
      height: 49px !important;

      @media (min-width: 1600px) {
        height: 49px !important;
      }
    `
  )}
  width:20%;
  border-radius: 0px 5px 5px 0px;
  height: 51px;
  color: white;
  padding: 2px 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;

  .send-otp {
    padding-left: 15px;
    display: inline-block;
    height: 40px;
    width: 40px;
    object-fit: contain;
  }
`;

export const VerifyButton: any = styled.button`
  ${ifProp(
    "isActive",
    css`
      background: ${theme('palette.verifyBTNBg')};
      color: white !important;
      padding: 2px 51px !important;
      cursor: pointer;
      height: 49px !important;

      @media (min-width: 1600px) {
        height: 49px !important;
      }
    `,
    css`
      height: 49px !important;
      padding: 2px 51px !important;
      background-color: #d0d0d0;

      @media (min-width: 1600px) {
        height: 49px !important;
      }
    `
  )}
  width:20%;
  border-radius: 0px 5px 5px 0px;
  height: 49px;
  color: #828282;
  color: darkgray;
  padding: 2px 22.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  text-transform: uppercase;

  & img {
    margin-left: 6px;
  }
`;

export const AuthPaginationButton: any = styled.div`
  ${ifProp(
    "isActive",
    css`
      background-color: #e7c40f;
    `,
    css`
      background-color: #fff;
    `
  )}
  width: 12px;
  height: 12px;
  border-radius: 50px;
  outline: none;
  border: none;
  margin-right: 5px;
  // cursor: pointer;
  margin-top: 20px;

  // &:hover {
  //   background-color: #d5b92eb0;
  // }
`;
export const PhoneNumberAndOtpWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  & label {
    border-radius: 0px 0px 0px 0px;
  }

  & p {
    background-color: white;
    height: 49px !important;
    font-size: .8rem;
    width: 10%;
    display: flex;
    align-items: center;
    padding: 0px 7px;
    margin: 7px 0px;
    background: #fff;
    border: 1px solid #ffffff;
    box-shadow: 0px 0px 50px rgb(37 40 63 / 50%);
    border-radius: 5px 0px 0px 5px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 14px;
    color: #7568a6;
    position: relative;
}
  
`;

export const PhoneNumberAndVerifyWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  & label {
    border-radius: 5px 0px 0px 5px !important;
  }
  
`;

export const TextHeadVertical = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme('palette.secondary-background-color-linear-new')};
  padding: 5px 15px;
  position: absolute;
  left: -63px;
  -ms-transform: rotate(20deg);
  transform: rotate(270deg);
  height: 25px;
`;

export const Tittle = styled.div`
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  position: relative;
  top: -12px;
  color: #fff;
`;

export const BackButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0em 3em;

  .backbutton {
    color: #fff;
    border: none;
    text-decoration: underline;
    cursor: pointer;
    background: none;
    position: relative;
    top: -60px;
    right: -24px;
    z-index: 999;

    :hover {
      color: #e7ab0f;
    }
  }
`;

export const ErrorMessage = styled.h5`
      position: relative;
      background-color: #fce4e4;
      border: 1px solid #fcc2c3;
      float: left;
      width: 100%;
      text-align: center;
      padding: 40px 30px;
      color: #cc0033;
      font-size: 13px;
      line-height: 20px;
      text-shadow: 1px 1px rgba(250,250,250,.3);
`
export const CloseButton = styled.div`
  position: absolute;
  color: #FFFFFF;
  right: 5px;
  font-size: 15px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  padding:4px 10px;
  cursor: pointer;
  top: 5px;
`