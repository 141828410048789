import React, { useEffect } from 'react'
import { MainInfoWrapper } from 'src/components/udb/commonStyle';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { motion } from 'framer-motion';
import FooterContainer from "src/components/footer/footer";
import { Section as DDBSection, PlayNowButtonNew2 } from './crazyCrashPromo';
import { NewContent as DDBContentWrapper, TermsConditionPoints as DDBTermsConditionPoints } from './Funkytimepromo';
import { TextContent } from './aviatorBonusPage';
import SEOContents from 'src/components/SEOContents';
import { useNavigate } from 'react-router-dom';

function WcCashbackBonanza(props) {
    const { app: {
        brandId
    }, theme } = props;

    const domain = brandId == 33 ? 'Baazi Adda' : brandId == 31 || brandId == 32 ? 'Yolo247' : 'Betdaily';

    const data = [
        {
            step: 'STEP 1',
            secondLine: 'HIT YOUR DEPOSIT SHOTS:',
            desc: 'Just Like The Cricketers Perfect Shots, Make A Deposit Each Day Of The Week, From Monday To Sunday. Stay Consistent Throughout The Entire Week.'
        }, {
            step: 'STEP 2',
            secondLine: 'Maintain Your Cricket Form',
            desc: 'Just as cricketers maintain their form throughout a tournament, keep up your deposit streak for all seven days. This is your ticket to unlocking the fantastic additional cashback bonus.'
        }, {
            step: 'STEP 3',
            secondLine: 'Celebrate Like a Cricket Champion',
            desc: 'Once you successfully deposit every day of the week, you become eligible for the additional cashback bonus.'
        }, {
            step: 'STEP 4',
            secondLine: 'Boundaries from Losses',
            desc: 'Just as cricketers maintain their form throughout a tournament, keep up your deposit streak for all seven days. This is your ticket to unlocking the fantastic additional cashback bonus.'
        }, {
            step: 'STEP 5',
            secondLine: 'Monday Winning Shot',
            desc: 'Kickstart your week with a winning shot – the cashback bonus amount will be swiftly credited to your account every Monday. Its an exceptional opportunity to boost your earnings and dive back into the cricket action with renewed zeal'
        }
    ]


    const navigate = useNavigate()

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <SEOContents pageName={"dailyDepositBonus"} />
            <MainInfoWrapper>
                <ContentWrapper>
                    <TextContent className='header'>Embark on the ultimate World Cup adventure: Boost your thrill with daily deposits, seize a turbocharged 
                    </TextContent>
                    <TextContent className='afterhead'>
                        weekly double cashback upto 10%, and elevate the excitement with each electrifying match!
                    </TextContent>
                    <PlayNowButtonNew2 onClick={() => navigate("/member/finance/deposit")}>DEPOSIT NOW</PlayNowButtonNew2>
                    <SectionGroup>
                        <Tittle>Elevate Your Betting Game with a Cricket Twist!</Tittle>
                        <p>
                            While the cricket fever sweeps the nation during the World Cup 2023, {domain} has a sensational offer that'll leave you stumped. Get ready to amplify the excitement of cricket and boost your deposits with an astounding cashback bonus! Don't let this golden opportunity slip through your fingers – with our unbeatable Daily Deposit Bonus, you can bag up to an astonishing 10% cashback!<br></br> Join us now and make every deposit a boundary of success!
                        </p>
                    </SectionGroup>
                    <SectionGroup>

                        <Tittle>How It's Woven into Cricket Glory</Tittle>
                        <p style={{ fontSize: '18px' }}>To partake in this thrilling offer inspired by the Cricket World Cup 2023, here's how it plays out:</p>
                        <ColoumnWrap>
                            {data.map((elm) => (
                                <Coloumn>
                                    <p className='step'>
                                        {elm.step}
                                    </p>
                                    <p className='stepafter'>
                                        {elm.secondLine}
                                    </p>
                                    <p className='desc'>{elm.desc}</p>
                                </Coloumn>
                            ))}
                        </ColoumnWrap>
                    </SectionGroup>
                    <SectionGroup>
                        <Tittle>Daily Deposit Bonus Scoreboard for Blue, Silver, Gold & Platinum Tiers</Tittle>
                        <TierTable>
                            <img src={`${config.imageV3Host}/static/cashsite/${theme.image.DailyDepoBonusTable}`} />
                        </TierTable>
                        <p style={{ marginBlockEnd: 0, textAlign: 'left' }}>
                            Remember, just like a cricket team's consistency leads to victory, the more consistent and committed you are with your daily deposits, the higher your cashback bonus will soar. So, ensure you deposit every day of the week and enjoy the rewards of your dedication and loyalty. Begin now and maximize your cashback earnings this world cup!
                        </p>
                        <p style={{ marginBlockStart: 0, textAlign: 'left' }}>Also It's not just cricket fans who can enjoy the daily deposit bonus – even if you're not a cricket enthusiast, you can still be a part of the action. Keep playing your favorite casino games, deposit every day, and continue to reap the benefits of our Daily Deposit Bonus!
                        </p>
                    </SectionGroup>
                    <Tittle>Terms and Conditions</Tittle>
                    <TermsConditionPoints>
                        <ul>
                            <li><TextContent1>This promotion is valid for all new & existing {domain} members.</TextContent1></li>
                            <li><TextContent1>This bonus amount will be capped at INR 7,000.</TextContent1></li>
                            <li><TextContent1>Bonus amount will be issued into your Main wallet as cashback bonus every Monday only if you have successfully deposited all 7 days in a week (Mon-Sun).</TextContent1></li>
                            <li><TextContent1>Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. {domain} reserves the right to disqualify players if any collusion or any foul play takes place in the promotion.</TextContent1></li>
                            <li><TextContent1>This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.</TextContent1></li>
                            <li><TextContent1>{domain} reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.</TextContent1></li>
                            <li><TextContent1>In the event, any fraudulent activity of the player(s) has been identified, {domain} reserves the right to withhold your funds and reject your withdrawals.</TextContent1></li>
                            <li><TextContent1>{domain} General Terms & Conditions apply.</TextContent1></li>
                            
                        </ul>
                    </TermsConditionPoints>
                </ContentWrapper>
                <FooterContainer />
            </MainInfoWrapper>
        </motion.div>
    )
}

export default withTheme(withAppContext(WcCashbackBonanza));

//Styled Components
const TermsConditionPoints = styled(DDBTermsConditionPoints)`
    width: 75%;
    padding-top: 30px;
 ul li {
    font-size: 20px;

 }
`;

export const TextContent1=styled(TextContent)`
font-size: 20px;
`   

const TierTable = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: 85%;
    }
`;

export const Coloumn = styled.li`
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    min-height: 375px;
    max-height: 375px;
    background: linear-gradient(139deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 100%);
    backdrop-filter: blur(14.5px);
    border: 2px solid var(--AC-B, #F28500);
    border-radius:10px;
    padding-bottom: 2rem;

    padding: 4rem;
    .step{
        font-size: 30px;
font-style: italic;
font-weight: 900;
line-height: normal;
letter-spacing: 0.6px;
text-transform: uppercase;
background: var(--Ticket, linear-gradient(104deg, #D08D00 0%, #F3E35B 50.52%, #E9B701 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
margin-block-end: 0;
    }

    .stepafter{
        color: #FFC51B;
font-size: 24px;
font-style: normal;
font-weight: 700;
text-transform: uppercase;
margin-block-start: 0;
padding-top: 5px;
    text-align: inherit;
    }

    .desc{
        font-size: 20px;
font-style: normal;
font-weight: 300;
text-transform: capitalize;
text-align: justify;
    }

    @media only screen and (min-width: 1700px){
		min-height: 375px;
	}	

    h3 {
        width: 100%;
        height: 63px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(90deg,#b67f38 6.25%,#EECB67 38.90%,#b67f38 81.77%);
        text-transform: capitalize;
        font-size: 20px;
        margin: 0;

        @media only screen and (min-width: 1700px){
		    height: 83px;
	    }
    }

    p {
        padding: 0 2px;
        color: #fff;
        font-size: 18px;
        text-transform: capitalize;
        text-align: justify;
    }

    &.moreWidth {
        width: 45%;
    }
`;

export const ColoumnWrap = styled.ul`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin: 0;
    padding: 0;
    gap: 2rem;
    padding-bottom: 2rem;
`;

const Tittle = styled.h2`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 49%;
    font-weight:900;
    font-style: italic;
    font-size: 36px;
    font-family: 'Inter-Bold';
    text-transform: uppercase;
    text-align: center;
    position: relative;
    background: linear-gradient(104deg, #D08D00 0%, #F3E35B 50.52%, #E9B701 100%);
            -webkit-background-clip: text; /* For Safari */
            -webkit-text-fill-color: transparent; /* For Safari */
            color: white;
            margin-block-end:0;

    :after {
        /* content: ""; */
        width: 180px;
        height: 2px;
        background-color: #DADADA;
        position: absolute;
        bottom: -20px;
    }
    p{
        font-size: 18px;
        font-weight: 300;
    }
`;

const SectionGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 0 3%;
  
/* font-weight: 900; */
    p{
        color: white;
        font-size: 18px;
        line-height: 32px;
        text-align: -webkit-center;
    }
`;

export const ContentWrapper = styled(DDBContentWrapper)`
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.DailyDepoBonusContentWrapperBg')});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding:6rem 8rem;
    .afterhead{
        margin: 0 auto;
        font-size: 20px;
    }
    .header{
        width: 100%;
        text-align: center;
        font-size: 20px;
        margin: 0 auto;
font-style: normal;
padding-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    }
`
