import styled, { css, withTheme } from "styled-components";
import { ifProp, theme, withProp } from "styled-tools";
import config from "src/config/config";
// import approvedIcon from "approvedIcon.svg";

//Table Styles
export const TableWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #FFFFFF;
    background: rgba(255, 255, 255, 0.9);
    /* box-shadow: 0px 0px 50px rgba(16, 12, 74, 0.4); */
    border-radius: 5px;
    text-align: center;

    .moreWidth {
      flex-basis: 17%;
      padding-left:10px;
      justify-content: flex-start;
    }

    .moreRadius {
      background: #FFFFFF;
      border-radius: 25px;
      padding: 5px;
      margin: 3px 0px;
      justify-content: center;
    }
`;

export const TableHeadRow = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    color: #505050;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 13px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.56) 0%, rgba(255, 255, 255, 0.56) 100%);
    border: 1px solid #FFFFFF;
    /* box-shadow: 0px 0px 50px rgba(19, 14, 74, 0.41); */
    padding: 0px 8px;
`;

export const TableBodyWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0px 5px;
`;

export const TableBodyRow = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    color: #53537B;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    font-size: 12px;
    box-shadow: 0px 0px 50px rgba(16, 12, 74, 0.4);
    background: rgba(255, 255, 255, 0.25);
    border-radius: 3px;
    margin-top: 0.5em;
    padding: 0px 8px;
    position: relative;
`;

export const TypeCol = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 16%;
    height: 40px;
    padding: 0px 3px;
`;

export const TimeStyle = styled.div`
    width: 8em;
`;

export const DateStyle = styled.div`
    /* padding-left:10px; */
`;

export const TableFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  margin-top: 1em;
  /* border-top: 1px dashed #4F6380; */
`;

export const OpenWrapper = styled.div`
    /* width: 100%;
    height: 44px;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    position: absolute;
    background: #eeeef2;
    padding: 8px; */

    width: 100%;
    height: 51px;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
    background: #eeeef2;
    padding: 8px;
    flex-direction: column;
`;

export const OpenWrapperContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  /* background: #fff; */
  padding: 8px;
  border-top: 1px dashed rgba(138, 158, 197, 0.97);
`;

export const EventNameWrap = styled.div`
    align-items: center;
    display: flex;
    padding-right: 5px;
    height: 40px;
`;

export const DetailMainWrapper = styled.div`
  background: rgba(42,40,117,0.12);
  padding: 4px 10px;
  border-radius: 3px;
`;

export const SvgIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;

  svg {
    width: 17px;
    height: 17px;
  }

  img {
    width: 16px;
    height: 16px;
  }
`;

export const Button = styled.button`
  padding: 1em 2em;
  background: ${theme('palette.secondary-background-color-linear-new')};  
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  margin: 0;
  border: none;
  box-shadow: none;
  cursor: pointer;
`;

export const ResetBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 5px;
  background: #ebebeb;
  border: none;
  box-shadow: 0px 0px 50px rgb(37 40 63 / 50%);
  border-radius: 7px;
  font-size: 14px;
  font-weight: 600;
  padding: 14px 30px;
  color: ${theme('palette.UDBResetBtnColor')};  
  cursor: pointer;

  :hover {
    color: ${theme('palette.UDBResetBtnHoverColor')};  
 }
`;

export const SubmitBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 5px;
  border: none;
  /* background: linear-gradient(180deg,#6778E3  0%, #6731E3 100%); */
  background: linear-gradient(180deg, #CCB091 0%, #EBC396 100%);
  /* box-shadow: 0px 0px 50px rgb(37 40 63 / 50%); */
  border-radius: 7px;
  font-size: 14px;
  padding: 14px 30px;
  color: #fff;
  margin-right: 10px;
  cursor: pointer;

  :hover {
    background: linear-gradient(180deg, #ebcfb0 0%, #d0b28f 100%);
  }

  :disabled {
    cursor: not-allowed;
    // pointer-events: none;
    /* color: ${theme("palette.disabled-button-font-color")}; */
    color: #ffffff;
    // background-color: #ffffff;
    /* background: ${theme("palette.disabled-button-background")}; */
    background: linear-gradient(180deg, #ebcfb0 0%, #d0b28f 100%);
    opacity: 0.8;
  }
 
`;

export const ButtonWrapper = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content:center;
  padding-top: 10px;

  .activeBtn {
    background: linear-gradient(180deg, #ccb091 0%, #ebc396 100%);
        opacity: unset;
  }

  button[disabled]:hover {
    background: linear-gradient(180deg, #d0b99f 0%, #bba58d 100%);
   }
`;

export const PasswordStrength = styled.div`
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 11px;
  color: #e8e8e8;
  // width: 170px;
  // width: 28%;
  margin-left: 1em;
`;

export const Label = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px 10px;
  height: 49px !important;
  margin: 7px 0px;
  // background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.56) 0%, rgba(255, 255, 255, 0.56) 100%);
  background: #fff;
  border: 1px solid #ffffff;
  box-shadow: 0px 0px 50px rgb(37 40 63 / 50%);
  border-radius: 5px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 14px;
  color: ${theme('palette.formtextsBG')};
  position: relative;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  &.error label { 
    border:1px solid red;    
    background: #efdfdf;
  }
`;

export const InputWrapperHalf = styled.div`
  width: 49%;
  display: flex;
  align-items: center;
  margin: 15px 0;
  position: relative;
  padding: 15px;
  margin: 7px 0px;
  // background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.56) 0%, rgba(255, 255, 255, 0.56) 100%);
  background: #fff;
  border: 1px solid #ffffff;
  box-shadow: 0px 0px 50px rgb(37 40 63 / 50%);
  border-radius: 7px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 14px;
  color: #7568a6;

  & .pr-5 {
    padding-right: 20px;
  }
`;

export const Input = styled.input`
  width: 370px;
  border: none;
  background: transparent;
`;

export const Select = styled.section`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  margin: 15px 0;
  position: relative;
  padding: 15px;
  margin: 7px 0px;
  // background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.56) 0%, rgba(255, 255, 255, 0.56) 100%);
  background: rgba(255,255,255,0.8);
  border: 0.727998px solid rgba(152, 183, 244, 0.97);
  box-shadow: 0px 0px 50px rgb(37 40 63 / 50%);
  border-radius: 3px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 14px;
  color: #7568a6;

  /* :not(.select--multiple)::after {
    content: "";
    justify-self: end;
    width: 0.8em;
    height: 0.5em;
    background-color: #4644c3;
    -webkit-clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    display: flex;
    position: absolute;
    right: 20px;
  } */

  select {
    width: 100%;
    border: none;
    background: transparent;
    /* -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; */
    z-index: 1;
    outline: none;
  }

  select::-ms-expand {
    display: none;
  }

  .select {
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    position: relative;
    min-width: 15ch;
    max-width: 30ch;
    border: 1px solid var(--select-border);
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
    font-size: 1.25rem;
    cursor: pointer;
    line-height: 1.1;
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  }
  /* .select select,
  .select::after {
    grid-area: select;
  }
  .select:not(.select--multiple)::after {
    content: "";
    justify-self: end;
    width: 0.8em;
    height: 0.5em;
    background-color: var(--select-arrow);
    -webkit-clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  } */
`;

export const RadioBtn = styled.div`
    ${Input} {
        width: 18px;
        height: 18px;
        border: 1px solid #4643C2;
        margin: 0;
        margin-right: 5px;
    }
`;

export const PlaceHolder = styled.span`
  width: 100%;
  position: absolute;
  // top: 7px;
  left: 5px;
  font-size: 0.8rem;
  span {
    color: red;
    padding-left: 2px;
  }
`;

export const BodyWrapper = styled.div`
  width: calc(100% / 2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background: ${theme('palette.UdbInfo_TabBG')};
  border-radius: 5px;
  padding: 1em;
  

  .space label {
    padding-right: 27px;
  }

  .react-tabs__tab {
    background: linear-gradient(266.91deg, #ffffff 0%, #efeff7 100%);
    border: 1px solid #ffffff;
    box-shadow: 0px 0px 50px rgba(194, 199, 233, 0.5);
    border-radius: 7px;
    color: #3e3999;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 15px;
    margin-right: 6px;
    padding: 10px 14px;
  }

  .react-tabs__tab--selected {
    color: #ffffff;
    background: linear-gradient(84.47deg, #6778e3 0%, #2d2abb 100%);
    border: 1px solid #fdfdff;
    box-shadow: 0px 0px 50px rgba(194, 199, 233, 0.5);
  }

  .react-tabs__tab-list {
    border-bottom: none;
    margin: 0 0 10px;
    margin-top: -35px;
  }

  .react-tabs__tab-panel {
    ${Label} {
      width: auto;
    }
  }

  .disabled {
    opacity: 0.7;
    background: #c5c5c7;

    input {
      cursor: not-allowed;
    }
  }

  .active {
    opacity: unset;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(255, 255, 255, 0.56) 0%,
      rgba(255, 255, 255, 0.56) 100%
    );
    border: 1px solid #ffffff;
    box-shadow: 0px 0px 50px rgba(194, 199, 233, 0.5);
  }
`;

export const SubBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  background: linear-gradient(
    126.22deg,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.15) 100%
  );
  /* box-shadow: 0px 0px 40px rgba(14, 9, 67, 0.44); */
  border-radius: 5px;
  padding: 1em;
  border: 1px solid #787878;

  .customStyle {
    input {
      padding-left: 25px;
    }
  }

.space label {
  padding-right: 27px;
}

.react-tabs__tab {
  background: linear-gradient(266.91deg, #ffffff 0%, #efeff7 100%);
  border: 1px solid #ffffff;
  box-shadow: 0px 0px 50px rgba(194, 199, 233, 0.5);
  border-radius: 7px;
  color: #3e3999;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 15px;
  margin-right: 6px;
  padding: 10px 14px;
}

.react-tabs__tab--selected {
  color: #ffffff;
  background: linear-gradient(84.47deg, #6778e3 0%, #2d2abb 100%);
  border: 1px solid #fdfdff;
  box-shadow: 0px 0px 50px rgba(194, 199, 233, 0.5);
}

.react-tabs__tab-list {
  border-bottom: none;
  margin: 0 0 10px;
  margin-top: -35px;
}

.react-tabs__tab-panel {
  ${Label} {
    width: auto;
  }
}

.disabled {
  opacity: 0.7;
  background: #c5c5c7;

  input {
    cursor: not-allowed;
  }
}

.active {
  opacity: unset;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 255, 255, 0.56) 0%,
    rgba(255, 255, 255, 0.56) 100%
  );
  border: 1px solid #ffffff;
  box-shadow: 0px 0px 50px rgba(194, 199, 233, 0.5);
}

`;

export const Breadcrumbs = styled.div`
  font-size: 12px;
  /* color: #8b9bca; */
  color: ${theme('palette.breadcrumbs')};
  margin: 10px 0;
  a {
    color: ${theme('palette.breadcrumbs')};
    font-size: 12px;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  span {
    color: #fff;
  }
`;

export const Tittle = styled.div`
  /* color: #ffd085; */
  color: ${theme('palette.account-info-color')};
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.03em;
  text-transform: capitalize;
`;

export const FirstCol = styled.div``;

export const SecondCol = styled.div`
    position:relative;
    display: flex;
    align-items: center;


    .approved {
        align-items: center;
        justify-content: center;
        background: ${theme('palette.kycApprovedBtnBg')};
        border: ${theme('palette.kycApprovedBtnBorder')};
        color: #FFFFFF;
        display: flex;
        align-items: center;
        opacity: unset;
        padding-right: 3.5em;
        padding-left: 1em;
        background-repeat: no-repeat;
        background-position: center;
        background-size: ${theme('palette.kycApprovedBtnBgSize')};


       
        :after {
            content: "";
            width: 38px;
            height: 100%;
            display: flex;
            position: absolute;
            right: 0;
            background: ${theme('palette.kycApprovedBtnBgAfter')};
            border-radius: 4px;
            background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.statusIcon')});
            background-position: center;
            background-size: 50%;
            background-repeat: no-repeat;
        }
    }
  

    .rejected {
      align-items: center;
      justify-content: center;
      background: ${theme('palette.kycRejectedBtnBg')};
      border: ${theme('palette.kycRejectedBtnBorder')};
      color: #FFFFFF;
      display: flex;
      align-items: center;
      opacity: unset;
      padding-right: 3.5em;
      padding-left: 1em;
      background-repeat: no-repeat;
      background-position: center;
      background-size: ${theme('palette.kycRejectedBtnBgSize')};

        :after {
            content: "";
            width: 38px;
            height: 100%;
            display: flex;
            position: absolute;
            right: 0;
            background: ${theme('palette.kycRejectedBtnBgAfter')};
            border-radius: 4px;
            background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.statusIcon')});
            background-position: center;
            background-size: 50%;
            background-repeat: no-repeat;
        }
    }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #e1e1e1;
  padding-bottom: 10px;
  margin-bottom: 30px;
`;

export const MainInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  // background: radial-gradient(100% 178.67% at 0% 0.96%, rgba(63, 59, 106, 0.8) 0%, rgba(6, 0, 68, 0.8) 100%);
  // box-shadow: 0px 0px 10px rgb(16 12 71 / 40%);
  // border-radius: 10px;
  // padding: 3em;
  // padding-top: 2em;
 
  .evolution{
    flex-basis: 28%;
      @media only screen and (min-width: 1600px) {
        flex-basis: 20%;
    display: flex;
    justify-content: center;
    // justify-content: center;
    }}
`;

export const ContentWrapper = styled.div`
    width: 100%;
    padding: 0 4rem;
    /* background: url(${config.imageV3Host}/static/cashsite/${theme('image.CasnoCarnvlContentBg')}); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;

export const BannerWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const MainWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 98px);
  padding: 3em;
  background: linear-gradient(
      0deg,
      rgba(17, 12, 86, 0.7),
      rgba(17, 12, 86, 0.7)
    );
  box-shadow: 0px 0px 100px rgba(0, 7, 66, 0.77);
`;

export const PasswordInputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px;
  margin: 7px 0px;
  // background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.56) 0%, rgba(255, 255, 255, 0.56) 100%);
  background: #fff;
  border: 1px solid #ffffff;
  box-shadow: 0px 0px 50px rgb(37 40 63 / 50%);
  border-radius: 5px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 14px;
  color: #7568a6;
  position: relative;

  // .invalid {
  //     border: 2px solid red;
  //     margin: 0px;
  //     padding: 0px;
  //     border-radius: 10px;
  //     margin-bottom: 10px;
  //     padding: 15px;
  //     margin: 7px 0px;
  //     background: radial-gradient(
  //       50% 50% at 50% 50%,
  //       rgba(255, 255, 255, 0.56) 0%,
  //       rgba(255, 255, 255, 0.56) 100%
  //     );
  //     box-shadow: 0px 0px 50px rgba(194, 199, 233, 0.5);
  //     letter-spacing: 0.01em;
  //     text-transform: capitalize;
  //     font-weight: 400;
  //     font-size: 14px;
  //   }
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1em;
  position: absolute;
  right: 0;
`;

export const EditButton = styled.button`
  display: flex;
  align-items: center;
  background: no-repeat;
  border: none;
  color: #ffffff;
  flex-direction: column;
  gap: 3px;
  font-size: 11px;
`;

export const DeletBtn = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: no-repeat;
  border: none;
  color: #ffffff;
  gap: 3px;
  font-size: 11px;
`;

export const BonusBtn = styled.button`
        background: rgba(255, 255, 255, 0.21);
        border-radius: 2px;
        font-size: 10px;
        color: white;
        padding: 5px 13px;
        text-transform: capitalize;
        cursor: pointer;
        border: none;
        outline: none;
        margin-top: 6px;

  &:hover {
    background-color: #0c438fae;
  }
       
`