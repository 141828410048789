import React, { useState } from 'react'
import GlobalModal from 'src/components/GlobalModal';
import styled, { withTheme } from 'styled-components';
import config from 'src/config/config';
import { theme } from 'styled-tools';
import { Controller } from 'react-hook-form';
import { LoginInput } from 'src/components/Auth/Login/Login';
import { useForm } from "react-hook-form";
import Intl from "src/components/common/Intl";
import { ResetBtn, SubmitBtn } from 'src/components/udb/commonStyle';
import { useNavigate } from "react-router-dom";
import { setAccountInfoData } from 'src/config/api';
import { Notify } from 'src/components/Notification';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { withAppContext } from 'src/store/initAppContext';

type UserDataModelProps = {
    successhandler: () => void;
    userData: any
    theme?: any
}

type UserOptions = {
    firstName: string;
    lastName: string;
    email?: string;
};

function UserRegisterPopup(props: UserDataModelProps) {
    const [loading, setLoader] = useState(false);
    const { successhandler, userData, theme } = props;

    const navigate = useNavigate();

    const {
        handleSubmit,
        control,
        reset,
        formState: { isValid, errors },
    } = useForm({
        mode: "all",
    });

    const onCloseHandler = () => {
        navigate('/member/profile/bankdetails');
    }

    const submitHandler = (formData: any, event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setLoader(true);

        const {
            firstName,
            lastName,
            email,
        }: UserOptions = formData;

        const params = JSON.stringify({
            ...userData,
            firstName,
            lastName,
            email
        })

        setAccountInfoData(params).then(res => {
            if (res?.data?.success) {
                Notify.success('Account details updated successfully');
                successhandler();
                reset();
            }


        }).catch((e: any) => {
            let error = 'Something went wrong please try again2'
            if (e.response && e.response.data) {
                error = e.response.data.error;
            }
            Notify.error(error);
        });
    }

    let isDisabled = true;
    if (isValid && !loading) {
        isDisabled = false;
    }

    return (
        <GlobalModal isModalOpen={true}>
            <PopUpcontainer>
                <button
                    className="close"
                    type="button"
                    onClick={() => onCloseHandler()}
                ></button>
                <PopUpActionDiv>
                    <ImgSection>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.userPopupCashImg}`} />
                    </ImgSection>
                    <Typo><Intl langKey="USERREGPOPUP@Text" /></Typo>
                    <form onSubmit={handleSubmit(submitHandler)}>
                        <PopUpInputWrapper>
                            <Controller
                                name="firstName"
                                control={control}
                                defaultValue={""}
                                rules={{
                                    required: {
                                        value: true,
                                        message: " *Please provide the first name",
                                    },
                                    pattern: {
                                        value: /^[a-zA-Z0-9\b]+$/,
                                        message:
                                            "* Name must not contain special characters and spaces",
                                    },
                                    minLength: { value: 2, message: "* Minimum Character : 2" },
                                    maxLength: { value: 18, message: "* Maximum Character : 18" },
                                }}

                                render={({

                                    field: { onChange, value },
                                    fieldState: { error },

                                }) => (
                                    <>
                                        <LoginInput
                                            id="firstName"
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            placeholder={<Intl langKey="SIGNUP@FIRSTNAME" />}
                                            type="text"
                                            req={true}
                                            autoComplete="new-password"
                                            name={'firstName'}
                                        />
                                        <h3>{error && error.message}</h3>
                                    </>
                                )}
                            />
                            <Controller
                                name="lastName"
                                control={control}
                                defaultValue={""}
                                rules={{
                                    required: {
                                        value: true,
                                        message: " *Please provide the last name",
                                    },
                                    pattern: {
                                        value: /^[a-zA-Z0-9\b]+$/,
                                        message:
                                            "* Name must not contain special characters and spaces",
                                    },
                                    minLength: { value: 2, message: "* Minimum Character : 2" },
                                    maxLength: { value: 18, message: "* Maximum Character : 18" },
                                }}
                                render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                }) => (
                                    <>
                                        <LoginInput
                                            id="lastName"
                                            onChange={onChange}
                                            // onKeyDown={formatTextInput}
                                            value={value}
                                            error={error}
                                            placeholder={<Intl langKey="SIGNUP@LASTNAME" />}
                                            type="text"
                                            req={true}
                                            autoComplete="new-password"
                                            name={'lastName'}
                                        />
                                        <h3>{error && error.message}</h3>
                                    </>
                                )}
                            />
                            <Controller
                                name="email"
                                control={control}
                                defaultValue={""}
                                rules={{
                                    required: {
                                        value: false,
                                        message: " *Please provide valid email",
                                    },
                                    pattern: {
                                        value:
                                            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: "* Email is invalid",
                                    },
                                }}
                                render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                }) => (
                                    <>
                                        <LoginInput
                                            id="email"
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            placeholder={<Intl langKey="TITLE@Email" />}
                                            type="text"
                                            req={false}
                                            autoComplete="new-password"
                                            name={'email'}
                                        />
                                        <h3>{error && error.message}</h3>
                                    </>
                                )}
                            />
                        </PopUpInputWrapper>
                        <ButtonWrapper>
                            <SubmitButton
                                type="submit"
                                disabled={!isValid}
                            // onClick={onSubmit}
                            >
                                <Intl langKey="DASHBOARD@submit" />
                            </SubmitButton>
                            <ResetBtn type="button" onClick={()=>reset()}>  
                                <Intl langKey="DASHBOARD@reset" />
                            </ResetBtn>
                        </ButtonWrapper>
                    </form>
                </PopUpActionDiv>
            </PopUpcontainer>
        </GlobalModal>
    )
}

export default withTheme(withAppContext(UserRegisterPopup));

const SubmitButton = styled(SubmitBtn)`
    background: linear-gradient(179deg, #E7A915 -39.56%, #FECE86 66.22%, #EBAF1D 157.62%), linear-gradient(180deg, #D0D0D0 0%, #B5B5B5 100%);
    font-weight: 700;
    color: black;

    :disabled {
        cursor: not-allowed;
        color: #ffffff;
        background: linear-gradient(180deg, #D0D0D0 0%, #B5B5B5 100%);
        opacity: 0.8;
    }
`;

export const PopUpcontainer: any = styled.div`
    display: flex;
    width: 24rem;
    height: auto;
    flex-shrink: 0;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background: #182A3E;
    border-radius: 15px;
    border: 0.3px solid #757E8F;

    .close {
        width: 25px;
        height: 25px;
        background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.closeIcon')});
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
        cursor: pointer;
        border: none;
        display: inline-block;
        margin: 0;
        padding: 0;
        position: absolute;
        right: -8px;
        top: -7px;
        border-radius: 5px;
        cursor: pointer;
        z-index: 1;
    }

`

const ImgSection = styled.div`
    display: flex;
    align-self: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 60%;
    height: 60%;

    img {
        width: 100%;
        height: 100%;
    }
`;

const PopUpInputWrapper = styled.div`
    display: flex;
    flex-direction: column;

    h3 {
        display: flex;
        margin: 0;
        padding: 0;
        color: red;
        align-items: flex-start;
        font-size: 11px;
    }
`;


export const PopUpActionDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    cursor: pointer;
    color: white;
    padding: 1.5rem 1.5rem 2.5rem 1.5rem;
`

const Typo = styled.p`
    color: #FF5C00;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.15px;
    text-transform: capitalize;
    padding: 1rem 2.5rem;
    margin: -3rem 0 0 0; 
`;

const ButtonWrapper = styled.div`
    margin-top: 1rem;
    display: flex;
    justify-content: flex-start;
`;