import React, { useEffect } from 'react'
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import FooterContainer from "src/components/footer/footer";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from 'src/components/Placeholder';

const AviatorSkyBoundMiles = (props:any) => {
    
    const { app: { brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = brandId === 31 || brandId === 32 ? 'Yolo247' : brandId === 33 ? 'Baazi Adda' : 'Betdaily';

  return (
        <>
            <InnerPromoWrapper>
                    <AviatorSKMontentWrapper>
                        <InnerBox>
                            <MainHeads><h3>Aviator - Sky Bound Miles!</h3></MainHeads>
                            <SecondRow>
                                    <LiTextContent>
                                        <StarBox>✪</StarBox>
                                        <TextContent> 
                                                This IPL, Yolo247 introduces Sky Bound Miles  on your favourite game –  Aviator! This is a thrilling opportunity for all aviator 
                                                enthusiasts to take their gaming experience to new heights!
                                        </TextContent>
                                    </LiTextContent>
                                    <LiTextContent>
                                        <StarBox>✪</StarBox>
                                        <TextContent>
                                                Be a part of Aviator's Sky bound Miles and immerse yourself in the thrilling world of virtual aviation! Whether you're an experienced 
                                                pilot or a novice, this program is tailored to captivate and engage players of all skill levels. Earn miles with every flight and unlock 
                                                a treasure trove of exciting prizes. This is one frequent flyer program you wouldn’t want to miss!
                                        </TextContent>
                                    </LiTextContent>
                                    <LiTextContent>
                                        <StarBox>✪</StarBox>
                                        <TextContent>
                                            Get set to win!                                   
                                        </TextContent>
                                    </LiTextContent>
                            </SecondRow>

                            <AviatorSKMBox>
                                <h4>Embark on a high flying adventure</h4>
                                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.aviatorSKMTableJet}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                <p>
                                    <span>Bet and Win: </span> Place a minimum bet of  ₹ 10 to earn miles & win free bets of ₹ 50000 every month
                                </p>
                                <p>
                                      Embark on an exhilarating journey in Aviator's Sky bound Miles, where every bet you place brings you closer to incredible rewards. For example, for every INR 10 wagered, you'll earn 10 miles, propelling you towards thrilling victories.
                                </p>
                                <p>
                                     Get ready for an exhilarating experience in Aviator's Sky bound Miles! With every mile you accumulate, you'll unlock a world of unmatched prizes. Don't miss out on the opportunity to fuel your passion for aviator and claim the rewards you deserve.
                                </p>
                                <p>
                                    Start collecting miles today and embark on an incredible journey of excitement and fulfilment.
                                </p>
                                <p>
                                     Scheme valid from 22nd March to 29th June
                                </p>
                                <p>
                                     Stand a chance to not only win on Aviator, but additional bonuses every month – courtesy <span>Spribe & Yolo247</span>
                                </p>

                                <AviatorSKMBoxInner>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.aviatorSKMSteps01}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.aviatorSKMSteps02}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.aviatorSKMSteps03}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                </AviatorSKMBoxInner>
                            </AviatorSKMBox>

                            <AviatorSKMPrizeSec>
                                <MainHeads><h3>Monthly Prize Allocation</h3></MainHeads>
                                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.aviatorSKMPriceTable}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                <p>
                                  Winners list will be announced on the 3rd  of every month <span> (May, June, July)</span>2024 on our Social Media Handles and  bonuses will be credited to your wallet
                                </p>
                            </AviatorSKMPrizeSec>

                            <AviatorSKMTerms>
                                <h4>Terms and Conditions:</h4>
                                    <ul>
                                        <li>This promotion is valid for all new & existing players.</li>
                                        <li>Aviator's Sky bound Miles is valid from <span>22nd March to 29th June 2024.</span></li>
                                        <li>Bonuses will be released every month.</li>
                                        <li>No Minimum bet amount is required to enter this contest.</li>
                                        <li>No Minimum odds to be maintained.</li>
                                        <li>Void/Cancelled/Draw bets and cashed-out bets do not count towards the deposit or bonus rollover requirements.</li>
                                        <li>Multiple accounts will not be considered for this promotion eligibility/criteria. All players will be subject to duplicate checking. 
                                            Yolo247 reserves the right to disqualify players if collusion or foul play occurs in the promotion.
                                        </li>
                                        <li>
                                            Spribe & Yolo247 reserves the right to amend, cancel, reclaim or refuse any promotion at its own discretion.
                                        </li>
                                        <li>{domain} General Terms & Conditions apply.</li>
                                    </ul>
                            </AviatorSKMTerms>

                        </InnerBox>
                    </AviatorSKMontentWrapper>
            </InnerPromoWrapper>
            <FooterContainer />
        </>
  )
}

export default  withTheme(withAppContext(AviatorSkyBoundMiles));


//Styled Components
const AviatorSKMontentWrapper = styled.div`
    width: 100%;
    background: url(${config.imageV3Host}/static/cashsite/${theme('image.aviatorSKMInnerBg')});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    gap: 2em;

    p {
        color: #FFF;
        text-align: center;
        font-size: 21px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        margin: 5px 0px;
    }

    .m-0 {
        margin: 0;
    }

    .mt-5 {
        margin-top: 2em;
        width: 100%;
        margin-bottom: 0em;
    }

    .p-0 {
        padding: 0rem 20rem;

        span{
            color: #E5A20D;
        }
    }

    a {
        width: 100%;
        height: auto;

        & span {
            display: flex !important;
            justify-content: center !important;

            & img {
                width: 60%;
                height: 80%;
            }
        }
    }

    .lazy-load-image-background {
        display: flex !important;
        justify-content: center !important;
    }

    .container {
        max-width: 1620px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;


const InnerPromoWrapper = styled.div`
    width: 98%;
    height: auto;
    border: 1px solid white;
    border-radius: 1px;
    background: rgba(111,111,111,0.5);
    margin: 0em 1.2em;
    -webkit-backdrop-filter: brightness(0.5);
    backdrop-filter: brightness(0.5);
`

const AviatorSKMBox = styled.div`
   width: 90%;
   height: auto;
   border-radius: 40px;
   border: 2px solid #282623;
   background: rgba(10, 9, 9, 0.80);
   padding: 2em;
   display: flex;
   flex-direction: column;
   gap: 15px;
   margin-top: 4em;
   padding-bottom: 2em;

   & img {
    width: 80%;
    height: auto;
   }

   & > h4 {
        color: #E50539;
        text-align: center;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 50.26px */
        text-transform: capitalize;
        margin: 3px 0px;
   }

   & p {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%;
        text-align: left;
        text-transform: capitalize;

        & span {
            color: #E50539;
            text-align: justify;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 24.5px; /* 122.5% */
        }
    }
`

const AviatorSKMBoxInner = styled.div`
    width: 95%;
    height: auto;
    display: flex;
    gap: 15px;
    align-items: center;
    margin: 1.5 0em;

`


const AviatorSKMTerms = styled.div`
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin: 5em 0em 2em 0em;


    & li {
        color: #FFF;
        text-align: justify;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 165%;
        letter-spacing: -0.8px;
        text-transform: capitalize;

        & span {
            color: #E50539;
        }
    }

    & h4 {
        text-align: left;
        font-size: 26px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 1.3px;
        text-transform: capitalize;
        color: #E50539;
        margin: 10px 0px 0px 15px;
    }
`


const SecondRow = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 10px;
`

const LiTextContent = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;

    p {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-transform: capitalize;
        margin: 3px 0px;
        text-align: left;
    }

    span {
        text-transform: uppercase;
        font-size: 18px;
    }
`;


const TextContent = styled.p`
    color: ${theme('palette.catch99TextContentColor')};
    font-size: 20px;

    & span {
        color: #F0BE21 !important;
        font-size: 20px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        text-transform: capitalize;
        margin: 0px;
        display: contents !important;
    }
`;

const StarBox = styled.p`
    color: #E50539 !important;
`
const MainHeads = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 90%;
    padding-top: 15px;

    & h3 {
        color: #E50539;
        text-align: left;
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        line-height: 179.5%; /* 50.26px */
        text-transform: capitalize;
        margin: 0px 0px;
    }
`

const InnerBox = styled.div`
    padding: 3em 0em;
    margin: auto;
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const AviatorSKMPrizeSec = styled.div`
    display: flex;
    width: 90%;
    height: auto;
    flex-direction: column;
    align-items: center;
    margin-top: 3em;
    gap: 15px;

    & img {
        width: 90%;
        height: auto;
    }

    & h4 {
        color: #E50539;
        text-align: left;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 50.26px */
        text-transform: capitalize;
        margin: 10px 0px;
    }

    & p {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24.5px;
        text-align: center;
        width: 80%;
        text-transform: capitalize;
        margin: 5px 0px;

        & span {
            color: #E50539;
            text-align: justify;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 24.5px; /* 122.5% */
        }
    }
`