import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import config from "src/config/config";
import { withAppContext } from 'src/store/initAppContext'
import styled, { withTheme } from 'styled-components'
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from 'src/components/Placeholder';
import Footer from 'src/components/footer/footer';

const WorldCupLossback = (props) => {
    const {
        app: { brandId },
        theme,
    } = props;

    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const domain = brandId == 31 || brandId == 32 ? "YOLO247" : "BaaziAdda";

    const teams = [
        { imageSvg: `${config.imageV3Host}/static/cashsite/${theme.image.WcIndia}` },
        { imageSvg: `${config.imageV3Host}/static/cashsite/${theme.image.WcAustralia}` },
        { imageSvg: `${config.imageV3Host}/static/cashsite/${theme.image.WcNewZealand}` },
        { imageSvg: `${config.imageV3Host}/static/cashsite/${theme.image.WcEngland}` },
        { imageSvg: `${config.imageV3Host}/static/cashsite/${theme.image.WcSouthAfrica}` },
        { imageSvg: `${config.imageV3Host}/static/cashsite/${theme.image.WcWestIndies}` },
        { imageSvg: `${config.imageV3Host}/static/cashsite/${theme.image.WcSrilanka}` },
        { imageSvg: `${config.imageV3Host}/static/cashsite/${theme.image.WcPakistan}` },
        { imageSvg: `${config.imageV3Host}/static/cashsite/${theme.image.WcBangladesh}` },
        { imageSvg: `${config.imageV3Host}/static/cashsite/${theme.image.WcAfghanistan}` },
    ]

    return (
        <>
            <MainWrapper>
                <ContentWrapper>
                    <TeamsSection>
                        {
                            teams.map((elm) => (
                                <LazyLoadImage src={elm.imageSvg} effect="none" placeholder={<PlaceholderImage />} />
                            ))
                        }
                    </TeamsSection>
                    <SectionTitle>
                        <h1>World Cup Daily Bonus!</h1>
                        <p>This World Cup, play like a <span className='boldtext'>Cricket Superstar</span> only on <span className='boldtext'>{domain}!</span></p>
                        <p className='secondPara'>Presenting the offer of the year for all cricket fans! Lost the last match? No worries, <span className='boldtext'>{domain}</span> gives you a chance to play every match!</p>
                    </SectionTitle>
                    <BonusTitle className='secondTitle'>
                        <h1>Experience the thrill of daily bonus – 100% Loss back</h1>
                    </BonusTitle>
                    <Prizewrapper>
                        <PrizeContent>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.WcLossBackBonusImgOne}`} effect="none" placeholder={<PlaceholderImage />} />
                            <PrizeText>
                            <h1>Put on the Jersey</h1>
                            <p>If you're not already part of the {domain} family, sign up for an account. Existing members can log in with ease!</p>
                            </PrizeText>
                        </PrizeContent>
                        <PrizeContent>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.WcLossBackBonusImgTwo}`} effect="none" placeholder={<PlaceholderImage />} />
                            <PrizeText>
                            <h1>Pad UP</h1>
                            <p>Deposit in your {domain} account. Keep it loaded and ready for action throughout the T20 WC.</p>
                            </PrizeText>
                        </PrizeContent>
                        <PrizeContent>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.WcLossBackBonusImgFour}`} effect="none" placeholder={<PlaceholderImage />} />
                            <PrizeText>
                            <h1>Play</h1>
                            <p>Dive into the excitement of the World Cup matches by placing your bets on our cutting-edge Exchange platform.</p>
                            </PrizeText>
                        </PrizeContent>
                        <PrizeContent className="beforeContent">
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.WcLossBackBonusImgThree}`} effect="none" placeholder={<PlaceholderImage />} />
                            <PrizeText>
                            <h1>Claim your Prize</h1>
                            <p>For cumulative losses of the day, we'll reward you with an 100% additional loss back bonus the very next day.</p>
                            </PrizeText>
                        </PrizeContent>
                    </Prizewrapper>
                    <WcLossbackTerms>
                        <h4>Terms and Conditions:</h4>
                        <ul>
                            <li>The loss back bonus will be applied to ‘lost T20 WC EXCHANGE bets - for the day’, placed during the T20 World Cup 2024.</li>
                            <li>The bonus amount for each qualifying bet will be capped at INR 50.</li> 
                            <li>The bonus will be credited to your {domain} account the next day.</li>
                            <li>Bonus will be expired within 24-48 hours.</li>
                            <li>There is no limit to the number of bets you can place to earn the 100% Daily bonus, ‘for the day’.</li>
                            <li>The daily bonus can be used to place future bets on {domain} as this bonus cannot be withdrawn. Only winnings can be withdrawn.</li>
                            <li>This promotion is valid only for the  T20 World Cup 2024.</li>
                            <li>{domain} reserves the right to disqualify any user found engaging in fraudulent activities.</li>
                            <li>{domain} General Terms & Conditions apply.</li>
                            <li>Valid from June 2 to June 29, 2024.</li>
                        </ul>
                    </WcLossbackTerms>
                </ContentWrapper>
            </MainWrapper>
            <Footer />
        </>
    )
}

export default withTheme(withAppContext(WorldCupLossback));

//Styled Components

const MainWrapper = styled.div`
    width: 98%;
    height: auto;
    border: 1px solid white;
    border-top: 0;
    display: flex;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em;
    -webkit-backdrop-filter: brightness(0.5);
    backdrop-filter: brightness(0.5);
`;

const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.worldCupLossBackBackground")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    .lazy-load-image-background {
      display: flex !important;
      justify-content: center !important;
    }

    .secondTitle {
        padding-top: 2rem;
    }
`;

const TeamsSection = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 0rem 15rem;
    margin: 3.5rem 0;
    gap: 20px;
    position: relative;

    img{
        width: 100%;
    }

    ::before {
        content: "";
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.WcLossBackBeforeImgFive")});
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        right: 2%;
        bottom: -75%;
        width: 8rem;
        height: 8rem;
    }
`;

const SectionTitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;

    ::after {
        content: "";
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.WcLossBackBeforeImgFour")});
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        left: 0%;
        bottom: -40%;
        width: 7rem;
        height: 10rem;
    }

    h1 {
        background: linear-gradient(69deg, #BD8C1B 0%, #FEF4A2 52.81%, #DCB64B 99.4%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px; /* 125% */
        text-transform: capitalize;
    }
    p {
        margin: 0;
        padding: 1rem 0;
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .boldtext {
        font-weight: 700;
    }

    .secondPara {
        width: 53%;
    }
`;

const BonusTitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    h1 {
        background: linear-gradient(69deg, #BD8C1B 0%, #FEF4A2 52.81%, #DCB64B 99.4%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px; /* 125% */
        text-transform: capitalize;
    }
`;

const Prizewrapper = styled.div`
    display: flex;
    justify-content: center;
    align-item: center;
    padding: 3rem 0;
    gap: 2rem;
    position: relative;

    ::before {
        content: "";
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.WcLossBackBeforeImgOne")});
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        right: -14%;
        top: -21%;
        width: 19rem;
        height: 19rem;
    }


`;

const PrizeContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-item: center;
    padding: 3rem 1rem;
    width: 16rem;
    height: 23rem;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1.5px solid #FFF;
    background: rgba(255, 255, 255, 0.12);
    backdrop-filter: blur(3px);
    position: relative;

    img {
        width: 50%;
    }

    h1 {
        margin-bottom: 0;
    }
`;

const WcLossbackTerms = styled.div`
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin: 5em 0em 5em 0em;
    position: relative;

    ::before {
        content: "";
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.WcLossBackBeforeImgThree")});
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        left: -12%;
        bottom: -19.5%;
        width: 8rem;
        height: 8rem;
    }

    ::after {
        content: "";
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.WcLossBackBeforeImgTwo")});
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        right: -12.5%;
        bottom: -8%;
        width: 16rem;
        height: 21rem;
    }


    & li {
        color: #FFF;
        text-align: justify;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 177.778% */
        text-transform: capitalize;
    }

    & h4 {
        background: linear-gradient(69deg, #BD8C1B 0%, #FEF4A2 52.81%, #DCB64B 99.4%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        font-size: 28px;
        font-weight: 700;
        font-style: normal;
        text-transform: capitalize;
        line-height: 35px; /* 125% */
        margin: 10px 0px 0px 15px;
    }
`

const PrizeText = styled.div`
    display: flex;
    flex-direction: column;
    h1 {
        color: #FFE073;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }

    p {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
    }
`;