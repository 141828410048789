import React, { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components";
import Navbar from "../../components/udb/Navbar";
import { Outlet, useLocation } from "react-router-dom";
import config from "src/config/config";
import { AccountInfoProps } from './profile/AccountInfo'
import { getAccountInfoData } from "src/config/api";
import QuickLinks from '../../components/udb/QuickLinks'
import ProBonus from '../../components/udb/ProBonus';
import { theme } from "styled-tools";
import WhatsappIco from "src/components/WhatsappIco";
import { withAppContext } from "src/store/initAppContext";

type ContextProps = {
  userInfo: AccountInfoProps;
  setUserInfo: React.Dispatch<React.SetStateAction<AccountInfoProps>>;
}

const quickLinks = [
  {
    title: "QuickLink@Inplay",
    imageName: 'infinite',
    count: '',
    url: '/betting-exchange'
  },
  {
    title: 'QuickLink@livecasino',
    imageName: 'liveCasino',
    count: '',
    url: '/online-casino-games'
  },
  {
    title: 'QuickLink@History',
    imageName: 'History',
    count: '',
    url: '/member/finance/history'
  },
  {
    title: "QuickLink@Message",
    imageName: 'Message',
    count: '',
    url: '/member/message',
    authKey:'showDBMessageLink'
  }
]

export const UserContext = React.createContext({} as ContextProps)

function Dashboard(props) {
  const { theme, app: { brandId } } = props;
  const { config: { showDBBonusBox = true } } = theme;

  const [userInfo, setUserInfo] = useState({} as AccountInfoProps);
  const [showLoader, setShowLoader] = useState(true);

  const { pathname } = useLocation();
  let currPage = pathname.split("/").pop();

  useEffect(() => {
    const getData = getAccountInfoData();
    getData.then(response => {
      const { data: { data } } = response;
      setUserInfo(data);
      setShowLoader(false);
    }).catch(err => console.log('error', err.message))
  }, []);


  return (
    <MainWrapper className="contxx">
      <Navbar />
      <SectionWrapper>
        <UserContext.Provider value={{ userInfo, setUserInfo }}>
          <Container>
            {showLoader ? <div>Loading...</div> : <Outlet />}
          </Container>

          <AsideBar>
            <QuickLinks heading="Quicklink@Top" data={quickLinks} />
            {showDBBonusBox && <ProBonus />}
          </AsideBar>
          
        </UserContext.Provider>
        {/* whatsApp Icon Support  */}
        {brandId === 32 || brandId === 40 && (currPage === "accountinfo" || currPage === "deposit" || currPage === "withdrawal" || currPage === "profile" || currPage === "finance" ) && <WhatsappIco rightSpace="20px"/> }
      </SectionWrapper>
    </MainWrapper>
  );
}

export default withTheme(withAppContext(Dashboard))

const MainWrapper = styled.div`
    width: 100%;
    /* height: calc(100vh - 70px); */
    height: calc(100vh - 100px);
    display: flex;
    background: #fff;
    flex-direction:row;
     background-color: ${theme('palette.headerBgColor')};;
    background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.dashboardBg')});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`;

const Container = styled.div`
    flex: 1;
    display: flex;
    align-items: flex-start;
    /* background: #35353591; */
    background:  ${theme('palette.profileBgColor')};
    backdrop-filter: ${theme('palette.profileBgBlur')};
    border: ${theme('palette.profileBgBorder')};
    /* background: radial-gradient(100% 178.67% at 0% 0.96%, rgba(63, 59, 106, 0.8) 0%, rgba(6, 0, 68, 0.8) 100%); */
    /* box-shadow: 0px 0px 10px rgba(16, 12, 71, 0.4); */
    border-radius: 10px;
    /* border: 1px solid #8a9ec5f7; */
    overflow-y: auto;
    padding: 1.5rem;
    backdrop-filter: contrast(0.5);
    /* ::-webkit-scrollbar {
    width: 10px;
    scrollbar-width: thin;
}

::-webkit-scrollbar-track {
    background-color: darkgrey;
}

::-webkit-scrollbar-thumb {
    background-image: linear-gradient(180deg, #504d4e 0%, #020202 99%);
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
    border-radius: 100px;
} */
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #9b9b9b;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 50%);
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    border-radius: 5px;
}



`;
const AsideBar = styled.div`
  width: 20%;
  margin-left: 20px;
`;

const SectionWrapper = styled.div`
  width: 85%;
  display: flex;
  flex-direction: row;
  padding: 1.5em;
//   background: linear-gradient(
//       0deg,
//       rgba(17, 12, 86, 0.7),
//       rgba(17, 12, 86, 0.7)
//     ),
//     url(login_background.jpg);
//   box-shadow: 0px 0px 100px rgba(0, 7, 66, 0.77);
`;