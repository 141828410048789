import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import { Divider, UnderLine } from './FomoWelcomeBonus';

function FomoRefillBonus(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <InformationWrapper>
            <TitleText>
                <h6>FOMO7 values and rewards every user and every deposit. You will get up to a 5% redeposit </h6>
                <h6>bonus, irrespective of the deposit amount.</h6>
            </TitleText>
            <Divider></Divider>
            <LoyaltyWrapper>
                <HeadingText>
                    <h1>Best Redeposit Bonus for Casino & Sports Betting</h1>
                </HeadingText>
                <UnderLine></UnderLine>
                <HeadingText className="joiningTxt">
                    <p>You are eligible for a 5% Refill bonus on every deposit.</p>
                    <p>On FOMO7, every move is a winning opportunity, which includes redeposits as well.</p>
                </HeadingText>
                <StepsWrapper>
                    <p>Let’s understand how it works:</p>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.RefillStepsFomoDesk}`}
                        alt="jetxPromoStepThree"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                    />
                </StepsWrapper>
                <HeadingText className="joiningTxt">
                    <p>There are no RO requirements or hidden conditions.</p>
                </HeadingText>
            </LoyaltyWrapper>
            <JoiningWrapper>
                <RedepositText>
                    <h1>FOMO7 Redeposit Bonus | Based on Loyalty Program</h1>
                </RedepositText>
                <UnderLine></UnderLine>
                <HeadingText className="joiningTxt">
                    <p>The entire bonus structure relies on the Loyalty Program.</p>
                </HeadingText>
                <LazyLoadImage
                    src={`${config.imageV3Host}/static/cashsite/${theme.image.RefillPercentageFomoDesk}`}
                    alt="jetxPromoStepThree"
                    effect="none"
                    placeholder={<PlaceholderImage />}
                />
            </JoiningWrapper>
            <Termsandconditions>
                <TermsandconditionsHeading>
                    <h1>Terms & Conditions</h1>
                </TermsandconditionsHeading>
                <UnderLine></UnderLine>
                <TermsandconditionsBody>
                    <ol>
                        <li>Any misuse of this bonus offer will lead to an account being closed. </li>
                        <li>The bonus amount will be issued to your main wallet after your successful redeposit.</li>
                        <li>This promotion is available on every redeposit, and every bonus received during the day shall expire within 24 hours.</li>
                        <li>FOMO7 does not consider multiple accounts for this promotion. All players will be subject to duplicate checking. FOMO7 reserves the right to disqualify players if collusion or foul play occurs in the promotion.</li>
                        <li>FOMO7 reserves the right to amend, cancel, reclaim, or refuse any promotion at its discretion.</li>
                        <li>You cannot withdraw the bonus amount, but the winnings from the bonus are withdrawable.</li>
                        <li>FOMO7 general terms & conditions apply.</li>
                    </ol>
                </TermsandconditionsBody>
            </Termsandconditions>
        </InformationWrapper>
    )
}



export default withTheme(withAppContext(FomoRefillBonus));

export const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 3rem 3rem 8rem 3rem;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em 1.2em 1.2em;
    border: 1px solid #E5B358;
    border-radius: 0 0 20px 20px;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.RefillBonusFomoDeskBg")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
`;

const TitleText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 3rem 0 4rem 0;

    h6 {
        margin: 0;
        padding: 0;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 160.3%; /* 25.648px */
    }
`;

const LoyaltyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: radial-gradient(37.24% 47.13% at 50% 0%, #051829 0%, rgba(1, 4, 6, 0.00) 100%);
    backdrop-filter: blur(6.849999904632568px);
    padding: 3rem;
    margin: 0 -2rem;

    .joiningTxt {
        padding-top: 1.5rem;
    }
`;

const HeadingText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 91.67%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    p {
        margin: 0;
        padding: 0.5em 0 0 0;
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120.3%; /* 19.248px */
    }
`;

const RedepositText = styled(HeadingText)`
    display: flex;
    padding: 1rem 0;
`;

const JoiningWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.RefillBgFomoDesk")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -999;
    margin: 0 -3rem;
    padding-bottom: 10rem;

    img {
        padding-top: 4rem;
        width: 73%;
    }

    .joiningTxt {
        padding-top: 2rem;
    }
`;

const JoiningContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding-top: 2rem;
`;

const StepsWrapper = styled(JoiningContainer)`
    padding: 3rem 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    flex-direction: column;

    p {
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120.3%; /* 19.248px */
    }
    
    img {
        width: 80%;
    }
`;

const Termsandconditions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    width: 70%;
    height: auto;
    margin: 0 15% 3rem 15%;
    padding: 5rem 3rem;
    position: relative;
    background: linear-gradient(126deg, #061625 1%, #190c12 40%, #182419);
    background-clip: padding-box;
    border: 2px solid transparent;

    ::after {
        position: absolute;
        top: -2px; bottom: -2px;
        left: -2px; right: -2px;
        background: linear-gradient(90deg, #53C1FF, black, #53C1FF, black, #53C1FF);
        content: '';
        z-index: -1;
        border-radius: 30px;
    }

    ::before {
        content: "";
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.RefillBonusFomoCardsDesk")});
        width: 506.063px;
        height: 433.768px;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        right: -22%;
        bottom: -47%;
        z-index: -999;
    }
`;

const TermsandconditionsHeading = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 121.53%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 1.5rem;

    h1 {
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
    }

    ::before {
        content: "";
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.RefillBonusDiceFomoDesk")});
        width: 307px;
        height: 182.731px;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: -17.7%;
        top: 3%;
        z-index: -999;
    }
`;

const TermsandconditionsBody = styled.div`
    ol {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        padding-top: 1rem;
    }
    li {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
`;